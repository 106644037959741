/* eslint-disable indent */
/* eslint-disable no-magic-numbers */
import type { GaugeConfigType, RangeConfigType } from 'Constants/OverViewTypes';
import GaugeComponent from 'react-gauge-component';
import type { PointerProps } from 'react-gauge-component/dist/lib/GaugeComponent/types/Pointer';
import { metricCardIconColor } from 'Constants/OverviewConsts';
const getSubArcConfig = (value: number, rangeConfig: RangeConfigType) => {
  // - Sets range color selection based on IF value is in a particular range.
  // - Adds default styles to tooltip config.
  let baseLimit = 0;
  return rangeConfig.map((range, index, arr) => {
    const { limit, tooltip, defaultColor, selectedColor } = range;
    const arcConfig = {
      limit: limit,
    };
    let endLimit = limit;
    const isLastIndex = index === arr.length - 1;
    if (isLastIndex) {
      // We must adjust the bounds when reaching the last index
      // to reflect that anything past the previous range endpoint
      // is considered the final range.
      const prevLimit = arr[index - 1].limit ?? 0;
      baseLimit = prevLimit;
      endLimit = Infinity;
    }
    const isValueInRange = value >= baseLimit && value < endLimit;
    if (isValueInRange) {
      arcConfig['color'] = selectedColor;
    } else {
      arcConfig['color'] = defaultColor;
    }
    if (tooltip) {
      arcConfig['tooltip'] = {
        text: isValueInRange ? tooltip.selectedText : tooltip.defaultText,
        // we add default tooltip styles if tooltip config exists
        style: {
          color: 'white',
          backgroundColor: 'black',
        },
      };
    }
    baseLimit = limit;
    return arcConfig;
  });
};

type Props = { gaugeConfig: GaugeConfigType };

export default function MetricGauge({ gaugeConfig }: Props) {
  const {
    value,
    rangeConfig,
    mainGaugeLabel: mainGaugeLabelFromProps,
    minValue,
    maxValue,
  } = gaugeConfig;
  const hasNoData = value === null || value === undefined;
  const mainGaugeLabel = hasNoData ? '--' : mainGaugeLabelFromProps;
  const subArcsConfig = hasNoData
    ? [
        {
          color: 'rgb(124, 124, 124,0.2)',
          limit: minValue,
          text: 'No data available',
        },
      ]
    : getSubArcConfig(value, rangeConfig);
  const pointerConfig: PointerProps | undefined = hasNoData
    ? { color: 'transparent' }
    : { type: 'blob', animationDelay: 0, animationDuration: 1500, width: 17 };

  return (
    <GaugeComponent
      minValue={minValue}
      maxValue={maxValue}
      type='semicircle'
      arc={{
        padding: 0.02,
        cornerRadius: 5,
        subArcs: subArcsConfig,
      }}
      labels={{
        tickLabels: {
          hideMinMax: true,
        },
        valueLabel: {
          formatTextValue: () => mainGaugeLabel,
          style: {
            fill: metricCardIconColor,
            textShadow: 'none',
            fontSize: 35,
          },
        },
      }}
      pointer={pointerConfig}
      value={value ?? 0}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minWidth: 400,
        maxWidth: 600,
        width: '100%',
        height: '100%',
      }}
    />
  );
}
