import { RoleEnum } from 'Constants/OrgSettingsEnums';

const { ADMIN, STANDARD_USER, READ_ONLY, CUSTOM } = RoleEnum;

export function getRoleType(roles) {
  const {
    account_read: accountRead,
    account_write: accountWrite,
    location_read: locationRead,
    location_write: locationWrite,
    device_read: deviceRead,
    device_write: deviceWrite,
    users_read: usersRead,
    users_write: usersWrite,
    report_read: reportRead,
    report_write: reportWrite,
  } = roles;

  if (
    accountRead &&
    accountWrite &&
    locationRead &&
    locationWrite &&
    deviceRead &&
    deviceWrite &&
    usersRead &&
    usersWrite &&
    reportRead &&
    reportWrite
  ) {
    return ADMIN;
  }

  if (
    accountRead &&
    !accountWrite &&
    locationRead &&
    locationWrite &&
    deviceRead &&
    deviceWrite &&
    usersRead &&
    !usersWrite &&
    reportRead &&
    !reportWrite
  ) {
    return STANDARD_USER;
  }
  if (
    accountRead &&
    !accountWrite &&
    locationRead &&
    !locationWrite &&
    deviceRead &&
    !deviceWrite &&
    usersRead &&
    !usersWrite &&
    reportRead &&
    !reportWrite
  ) {
    return READ_ONLY;
  }

  return CUSTOM;
}

export function formatTimestamp(timestamp): string {
  const date = new Date(timestamp);
  const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' };
  return date.toLocaleDateString('en-US', options);
}

export function formatStatus(statusRaw: string | null | undefined): string {
  if (!statusRaw) {
    return 'Unknown';
  }
  const statusMap = {
    UNCONFIRMED: 'Unconfirmed',
    CONFIRMED: 'Active',
    EXTERNAL_PROVIDER: 'Third-party login',
    ARCHIVED: 'Deactivated',
    UNKNOWN: 'Unknown',
    RESET_REQUIRED: 'Reset req',
    FORCE_CHANGE_PASSWORD: 'Invited',
  };
  return statusMap[statusRaw] ?? 'Unknown';
}
