/* eslint-disable no-nested-ternary */
/* eslint-disable no-magic-numbers */
import { useLazyQuery, useMutation, useReactiveVar } from '@apollo/client';
import styled from '@emotion/styled';
import { Close as CloseIcon, Search as SearchIcon } from '@mui/icons-material';
import {
  Autocomplete,
  autocompleteClasses,
  Box,
  Chip,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  InputBase,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import {
  GetAvailableAccountsDataQuery,
  GetAvailableAccountsDataQueryVariables,
  OrgCoreFragment,
  UpdateUserPreferencesMutation,
  UpdateUserPreferencesMutationVariables,
} from '__generated__/graphql';
import { selectedOrgVar } from 'Apollo/ApolloCache';
import debounce from 'debounce';
import useSwitchOrg from 'Hooks/useSwitchOrg';
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { GET_AVAILABLE_ACCOUNTS_QUERY, UPDATE_USER_ORG } from './graphql';

type Props = {
  onModalClose: () => void;
  modalProps: unknown;
};

const MAX_RESULTS = 50;
const DEBOUNCE_WAIT = 500;

interface PopperComponentProps {
  anchorEl?: unknown;
  disablePortal?: boolean;
  open: boolean;
}

const StyledAutocompletePopper = styled('div')(() => ({
  [`& .${autocompleteClasses.paper}`]: {
    boxShadow: 'none',
    margin: 0,
    color: 'inherit',
  },
}));

function PopperComponent(props: PopperComponentProps) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { disablePortal, anchorEl, open, ...other } = props;
  return <StyledAutocompletePopper {...other} />;
}

export default function SwitchOrgModal({ onModalClose, modalProps }: Props) {
  const { origin } = modalProps as {
    origin: 'switch-session-org' | 'switch-user-org';
  };
  const theme = useTheme();
  const [switchOrg, switchDefaultOrg] = useSwitchOrg();
  const selectedOrg = useReactiveVar(selectedOrgVar);
  const [orgList, setOrgList] =
    useState<NonNullable<GetAvailableAccountsDataQuery['accounts']>['accounts']>();
  const [searchString, setSearchString] = useState('');
  const [userPrefOrgId, setUserPrefOrgId] = useState<string>();
  const [updateUserOrg, { loading: prefLoading }] = useMutation<
    UpdateUserPreferencesMutation,
    UpdateUserPreferencesMutationVariables
  >(UPDATE_USER_ORG);
  const [searchOrgs, { loading: isLoading }] = useLazyQuery<
    GetAvailableAccountsDataQuery,
    GetAvailableAccountsDataQueryVariables
  >(GET_AVAILABLE_ACCOUNTS_QUERY, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      setOrgList(data.accounts?.accounts);
    },
    variables: {
      pagination: {
        maxResults: MAX_RESULTS,
        nextToken: '',
      },
      searchString: '',
    },
  });

  const selectedOrgIdFromLocalStorage = useMemo(() => {
    let selectedId: string | null = null;
    if (origin === 'switch-session-org') {
      selectedId = localStorage.getItem('SELECTED_ORGANIZATION_ID');
    } else if (origin === 'switch-user-org') {
      selectedId = localStorage.getItem('SELECTED_USER_ORGANIZATION_ID');
    }
    if (!selectedId) {
      selectedId = selectedOrg?.id as string | null;
    }
    return selectedId;
  }, [origin, selectedOrg]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const searchOrgsDebounced = useMemo(() => debounce(searchOrgs, DEBOUNCE_WAIT), []);

  const selectOrgHandler = useCallback(
    (org?: OrgCoreFragment | null) => {
      if (!org) return;
      if (origin === 'switch-session-org') {
        switchOrg(org);
        onModalClose();
      } else {
        setUserPrefOrgId(org.id);
        updateUserOrg({ variables: { preferences: { defaultAccountId: org.id } } })
          .then(() => {
            switchDefaultOrg(org);
          })
          .catch(console.error)
          .finally(() => {
            setUserPrefOrgId(void 0);
            onModalClose();
          });
      }
    },
    [onModalClose, origin, switchDefaultOrg, switchOrg, updateUserOrg]
  );

  useEffect(() => {
    searchOrgsDebounced({
      variables: {
        searchString,
      },
    });
  }, [searchString, searchOrgsDebounced]);

  return (
    <Dialog
      open={true}
      onClose={() => {
        onModalClose();
      }}
      fullWidth
      maxWidth='md'
      disableRestoreFocus
    >
      <DialogTitle>
        {origin === 'switch-session-org' ? (
          <Stack gap={0.5}>
            <Typography variant='h6'>Select Organization for current session</Typography>
            <Typography variant='body2' color='grey'>
              Only applicable to current session. Changes are reverted back to default if you log
              out or close the tab
            </Typography>
          </Stack>
        ) : (
          <Stack gap={0.5}>
            <Typography variant='h6'>Select Default Organization</Typography>
            <Typography variant='body2' color='grey'>
              Changes are permanent accross different sessions even if you log out.
            </Typography>
          </Stack>
        )}
      </DialogTitle>
      <IconButton
        onClick={onModalClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers sx={{ height: 500 }}>
        <Autocomplete
          open
          fullWidth
          freeSolo
          disableCloseOnSelect
          loading={isLoading}
          inputValue={searchString}
          onInputChange={(_, value) => {
            setSearchString(value);
          }}
          onChange={(_, newSelectedOrg) => {
            if (typeof newSelectedOrg === 'string') {
              return;
            }
            if (newSelectedOrg?.id === selectedOrgIdFromLocalStorage) return;
            selectOrgHandler(newSelectedOrg);
          }}
          PopperComponent={PopperComponent}
          ListboxProps={{
            sx: {
              maxHeight: 400,
              [theme.breakpoints.down('sm')]: {
                width: 'max-content',
                overflowX: 'auto',
              },
            },
          }}
          options={orgList ?? []}
          getOptionLabel={(option) =>
            typeof option === 'string' ? option : option?.name ?? 'Unknown'
          }
          renderGroup={(params) => (
            <Table key={params.key} component='div'>
              <TableBody component='div'>{params.children}</TableBody>
            </Table>
          )}
          renderOption={(props, option) => {
            if (typeof option === 'string') {
              return (
                <TableRow component='li' {...props}>
                  <TableCell sx={{ borderBottom: 0 }} component='span'>
                    {option}
                  </TableCell>
                </TableRow>
              );
            }
            return (
              <TableRow component='li' {...props} sx={{ width: '100%', display: 'flex' }}>
                <TableCell
                  component='span'
                  width='100%'
                  sx={{ borderBottom: 0, flexGrow: 1 }}
                  size='small'
                >
                  <OrgTableDataCell title='Name'>
                    <Stack direction='row' gap={1} alignItems='center'>
                      <Typography variant='body2'>{option?.name ?? 'Unknown'}</Typography>
                      {selectedOrgIdFromLocalStorage === option?.id && (
                        <Chip color='success' label='Selected' />
                      )}
                      {prefLoading && option?.id === userPrefOrgId && (
                        <CircularProgress size={16} />
                      )}
                    </Stack>
                  </OrgTableDataCell>
                </TableCell>
                <TableCell
                  component='span'
                  width={150}
                  sx={{ borderBottom: 0 }}
                  size='small'
                  align='right'
                >
                  <OrgTableDataCell title='Number of Members'>
                    {String(option?.numMembers) ?? 'Unknown'}
                  </OrgTableDataCell>
                </TableCell>
                <TableCell
                  component='span'
                  width={150}
                  sx={{ borderBottom: 0 }}
                  size='small'
                  align='right'
                >
                  <OrgTableDataCell title='Number of Locations'>
                    {String(option?.numLocations) ?? 'Unknown'}
                  </OrgTableDataCell>
                </TableCell>
              </TableRow>
            );
          }}
          renderInput={(params) => (
            <InputBase
              ref={params.InputProps.ref}
              inputProps={params.inputProps}
              autoFocus
              fullWidth
              placeholder='Search Orgs'
              sx={{
                width: '100%',
                bgcolor: (theme) =>
                  theme.palette.mode === 'light'
                    ? theme.palette.grey[200]
                    : theme.palette.grey[700],
                padding: 1,
                borderRadius: 1,
              }}
              startAdornment={
                <SearchIcon
                  sx={{
                    color: (theme) => theme.palette.text.primary,
                    marginRight: 1,
                  }}
                />
              }
            />
          )}
        />
      </DialogContent>
    </Dialog>
  );
}

function OrgTableDataCell({ title, children }: { title: string; children: ReactNode }) {
  return (
    <Box display='flex' flexDirection='column' justifyContent='center' alignItems='flex-start'>
      <Typography fontWeight='bold' color='gray' variant='body2'>
        {title}
      </Typography>
      {typeof children === 'string' ? (
        <Typography variant='body2'>{children}</Typography>
      ) : (
        children
      )}
    </Box>
  );
}
