/* eslint-disable no-magic-numbers */
import { Typography, useTheme } from '@mui/material';
import { GridConfigType } from 'Components/MetricCards/QuickMetricsCard';
import { useMemo } from 'react';
import { getPastDateTimeRange } from 'Utils/getPastDateTimeRange';

import { useQuery, useReactiveVar } from '@apollo/client';
import { Apps as AppsIcon } from '@mui/icons-material';
import { alpha, colors } from '@mui/material';
import {
  SurfaceInteractionHeatMapQuery,
  SurfaceInteractionHeatMapQueryVariables,
} from '__generated__/graphql';
import { selectedLocationVar } from 'Apollo/ApolloCache';
import { ChartData, ChartOptions } from 'chart.js';
import { MatrixDataPoint } from 'chartjs-matrix-chart';
import MediumMetricsCard from 'Components/MetricCards/MediumMetricsCard';
import { metricCardIconColor } from 'Constants/OverviewConsts';
import { Chart as Chartjs } from 'react-chartjs-2';
import { SURFACE_INTERACTIONS_HEATMAP } from '../ReportsSurface.gql';
import { HOUR_LABELS, WEEKDAY_LABELS } from './heatmap-labels';

const CHART_ID = 'surface-interactions-heatmap';

type Props = {
  selectedLocationId?: string | null;
  selectedOrgId?: string | null;
  gridConfig?: GridConfigType;
};
const TITLE = 'Surface Interactions Heatmap';
const INFO_TEXT = 'Surface Interactions Heatmap by week';
export default function SurfaceInteractionHeatmap({
  selectedLocationId,
  selectedOrgId,
  gridConfig,
}: Props) {
  const theme = useTheme();
  const currentlySelectedLocation = useReactiveVar(selectedLocationVar);
  const [startDate, endDate] = useMemo(() => getPastDateTimeRange(7, 'days'), []);

  const {
    data,
    loading: isLoading,
    error: queryError,
  } = useQuery<SurfaceInteractionHeatMapQuery, SurfaceInteractionHeatMapQueryVariables>(
    SURFACE_INTERACTIONS_HEATMAP,
    {
      variables: {
        accountId: selectedOrgId ?? '',
        locationId: selectedLocationId ?? '',
        timeRange: {
          startDate,
          endDate,
        },
      },
    }
  );

  const heatmapData = useMemo(() => {
    const report = data?.report?.uvangel?.surfaceInteractionHeatMap;
    if (!report || report.length === 0) return null;

    const finalReport = report.map((item) => {
      return {
        y: item?.dayOfWeek as number,
        x: item?.hour as number,
        v: item?.value as number,
      };
    });

    if (finalReport.every((i) => i.v === 0)) {
      return null;
    }

    return finalReport;
  }, [data]);

  const chartOptions: ChartOptions<'matrix'> = useMemo(() => {
    return {
      responsive: true,
      maintainAspectRatio: false,
      aspectRatio: 5,
      layout: {
        padding: {
          top: 10,
        },
      },
      scales: {
        x: {
          offset: true,
          grid: {
            display: false,
          },
          type: 'category',
          labels: Array.from(Array(24).keys()).map((k) => k.toString()),
          title: {
            display: true,
            text: 'Hour of the Day',
          },
          ticks: {
            display: true,
            callback: (value) => {
              return HOUR_LABELS[value];
            },
          },
        },
        y: {
          offset: true,
          grid: {
            display: false,
          },
          type: 'category',
          labels: Array.from(Array(7).keys()).map((k) => k.toString()),
          title: {
            display: true,
            text: 'Day of the Week',
          },
          ticks: {
            display: true,
            callback: (value) => {
              return WEEKDAY_LABELS[value];
            },
          },
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: true,
          callbacks: {
            title(context) {
              const raw = context.at(0)?.raw as MatrixDataPoint;
              const val = raw.v;
              return `${val} Interactions`;
            },
            label(context) {
              const raw = context.raw as MatrixDataPoint;
              const hour = raw.x;
              const day = raw.y;
              return `${WEEKDAY_LABELS[day]}, ${HOUR_LABELS[hour]}`;
            },
          },
        },
      },
    };
  }, []);
  const chartData: ChartData<'matrix', MatrixDataPoint[]> | null = useMemo(() => {
    if (!heatmapData || heatmapData.length === 0) return null;
    return {
      datasets: [
        {
          data: heatmapData.map((i) => ({ x: i.x.toString(), y: i.y.toString(), v: i.v })),
          backgroundColor(c) {
            const point = c.dataset.data[c.dataIndex] as MatrixDataPoint;
            const opacity = (10 + point.v) / 60;
            return alpha(colors.green['500'], opacity);
          },
          borderWidth: 1,
          hoverBackgroundColor: 'yellow',
          width(c) {
            const a = c.chart.chartArea || {};
            return (a.right - a.left) / 24 - 4;
          },
          height(c) {
            const a = c.chart.chartArea || {};
            return (a.bottom - a.top) / 7 - 4;
          },
        },
      ],
    };
  }, [heatmapData]);
  return (
    <MediumMetricsCard
      isLoading={isLoading}
      icon={
        <AppsIcon sx={{ color: queryError ? theme.palette.error.main : metricCardIconColor }} />
      }
      title={TITLE}
      infoText={INFO_TEXT}
      gridConfig={gridConfig}
      error={!!queryError}
    >
      {chartData && chartOptions ? (
        <Chartjs id={CHART_ID} type='matrix' data={chartData} options={chartOptions} />
      ) : (
        <>
          <Typography alignContent='center' color='InactiveCaptionText'>
            No data available for <b>{currentlySelectedLocation?.name}</b>
          </Typography>
          <Typography alignContent='center' fontStyle='italic' color='InactiveCaptionText'>
            in the last <b>7 days</b>
          </Typography>
        </>
      )}
    </MediumMetricsCard>
  );
}
