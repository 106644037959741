import React, { useEffect, useMemo } from 'react';
import {
  Authenticator,
  useAuthenticator,
  ThemeProvider,
  Theme as AuthenticatorThemeType,
} from '@aws-amplify/ui-react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Grid } from '@mui/material';
import '@aws-amplify/ui-react/styles.css';
import RouteStatusEnum from 'Constants/RouteStatusEnum';
import type { Theme as MUIThemeType } from '@mui/material';

const { STATUS_AUTHENTICATED } = RouteStatusEnum;

type Props = {
  theme: MUIThemeType;
};

export default function Login({ theme }: Props) {
  const { route: routeAuthStatus } = useAuthenticator((context) => [context.route]);
  const location = useLocation();
  const navigate = useNavigate();
  const previousLocation = useMemo(() => {
    const previousPath = location.state?.from?.pathname ?? '/';
    const search = location.state?.from?.search ?? '';
    return previousPath + search;
  }, [location]);
  useEffect(() => {
    if (routeAuthStatus === STATUS_AUTHENTICATED) {
      navigate(previousLocation, { replace: true });
    }
  }, [routeAuthStatus, navigate, previousLocation]);

  // customize our auth fields, so they are easier to understand
  //    ie. username = email, family_name = last name, etc.
  const authFormFields = {
    signIn: {
      username: {
        label: 'Email',
        placeholder: 'Enter your Email',
        isRequired: true,
      },
    },
    signUp: {
      username: {
        label: 'Email',
        placeholder: 'Enter your Email',
        isRequired: true,
        order: 1,
      },
      given_name: {
        label: 'First Name',
        placeholder: 'Enter your First Name',
        isRequired: true,
        order: 2,
      },
      family_name: {
        label: 'Last Name',
        placeholder: 'Enter your Last Name',
        isRequired: true,
        order: 3,
      },
      password: {
        order: 4,
        isRequired: true,
      },
      confirm_password: {
        order: 5,
        isRequired: true,
      },
      'custom:signup_org_name': {
        type: 'string',
        label: 'Organization Name',
        placeholder: 'My Organization',
        order: 6,
        isRequired: true,
      },
    },
    resetPassword: {
      username: {
        label: 'Email',
        placeholder: 'Enter your Email',
      },
    },
  };

  const { palette } = theme;

  const AuthenticatorTheme: AuthenticatorThemeType = {
    name: 'uva-theme',
    tokens: {
      components: {
        authenticator: {
          state: {
            inactive: {
              // top inactive tab
              backgroundColor: palette.background.default,
            },
          },
        },
        tabs: {
          item: {
            // top tab text color
            color: palette.primary.main,
            // top inactive tab borderColor
            borderColor: palette.background.paper,
            _active: {
              color: palette.primary.main,
              backgroundColor: palette.background.paper,
              borderColor: palette.primary.light,
            },
          },
        },
        button: {
          link: {
            //forget password link text
            color: palette.primary.main,
          },
          primary: {
            // sign in button
            backgroundColor: palette.primary.main,
          },
        },
      },
      colors: {
        font: {
          primary: palette.text.primary,
          secondary: palette.text.secondary,
        },
        background: {
          primary: palette.background.paper,
        },
      },
    },
  };
  return (
    <Grid
      container
      spacing={0}
      direction='column'
      alignItems='center'
      justifyContent='center'
      paddingTop={10}
      paddingBottom={10}
    >
      <Grid item>
        <ThemeProvider theme={AuthenticatorTheme}>
          <Authenticator formFields={authFormFields} />
        </ThemeProvider>
      </Grid>
    </Grid>
  );
}
