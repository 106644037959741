import { ArrowDownward as ArrowDownwardIcon } from '@mui/icons-material';
import { Stack, Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import { Fragment, ReactNode } from 'react';

const accordionDetails: {
  key: string;
  title: string;
  description: ReactNode;
}[] = [
  {
    key: 'read-chart',
    title: 'How to read this chart?',
    description: (
      <Fragment>
        <Typography sx={{ mb: 2 }}>
          If you and an infectious person were present in this space for an entire hour, your risk
          of contracting that infection would be shown risk percentage.
        </Typography>
        <Typography>
          Risk of contracting infection <strong>does NOT</strong> mean risk of you becoming sick
          with that infection. This model looks at spread risk, so this is the risk of that
          infection spreading to you as a carrier.
        </Typography>
        <Typography>
          Individualized risk of contraction is dependent upon a number of health factors such as
          age and immune system health.
        </Typography>
      </Fragment>
    ),
  },
  {
    key: 'mean',
    title: 'What does this mean?',
    description:
      'Contamination Spread Risk (Beta) is the likelihood of an average-health individual contracting an infection in a given space, assuming an infector is also present in the same space.',
  },
  {
    key: 'calc',
    title: 'How is this calculated?',
    description:
      'Calculated using proprietary viral spread risk models derived from Wells-Riley probabilistic infectivity models. For simplicity sake, this model computes the risk in discrete hours.',
  },
];
export default function InfectionQA() {
  return (
    <Stack width='100%'>
      {accordionDetails.map((det) => (
        <Accordion key={det.key}>
          <AccordionSummary
            expandIcon={<ArrowDownwardIcon fontSize='small' />}
            aria-controls={`${det.key}-content`}
            id={`${det.key}-header`}
          >
            <Typography>{det.title}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {typeof det.description === 'string' ? (
              <Typography>{det.description}</Typography>
            ) : (
              det.description
            )}
          </AccordionDetails>
        </Accordion>
      ))}
    </Stack>
  );
}
