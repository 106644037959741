import { Box, Typography, Tooltip } from '@mui/material';
import {
  Check as CheckIcon,
  Close as CloseIcon,
  WarningAmber as WarningIcon,
} from '@mui/icons-material';
import moment from 'moment';
import type { DeviceState } from '__generated__/graphql';
interface DeviceStateCellProps {
  state?: DeviceState | null;
}

const iconProps = {
  sx: {
    height: 20,
    width: 20,
  },
};

const icons = {
  'SPEED 1': <CheckIcon color='success' {...iconProps} />,
  'SPEED 2': <CheckIcon color='success' {...iconProps} />,
  'SPEED 3': <CheckIcon color='success' {...iconProps} />,
  'SPEED 4': <CheckIcon color='success' {...iconProps} />,
  'SPEED 5': <CheckIcon color='success' {...iconProps} />,
  NORMAL: <CheckIcon color='success' {...iconProps} />,
  DYNAMIC: <CheckIcon color='success' {...iconProps} />,
  'L-FAULT': <CloseIcon color='error' {...iconProps} />,
  MAINT: <CloseIcon color='error' {...iconProps} />,
  STANDBY: <WarningIcon color='warning' {...iconProps} />,
};

export default function DeviceStateCell({ state }: DeviceStateCellProps) {
  return (
    <Box display='flex' flexDirection='column' justifyContent='center' alignItems='flex-start'>
      <Typography fontWeight='bold' color='gray' variant='body2'>
        Last Status
      </Typography>
      <Tooltip title={moment(state?.timestamp).fromNow()}>
        <Box display='flex' justifyContent='center' alignItems='center' gap={1}>
          {icons[state?.state ?? ''] !== undefined ? (
            icons[state?.state ?? '']
          ) : (
            <CloseIcon color='error' {...iconProps} />
          )}
          <Typography variant='body2'>
            {state?.state === 'MAINT' ? 'Maintenance Lock' : state?.state ?? 'Unknown'}
          </Typography>
        </Box>
      </Tooltip>
    </Box>
  );
}
