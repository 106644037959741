import RoutePaths from 'Constants/RoutePaths';

const { PATH_HOME, PATH_LOGIN, PATH_DOCS, PATH_DEVICE_INFO } = RoutePaths;

enum ExternalAppPageEnums {
  LANDING_PAGE,
  LOGIN_PAGE,
  DOC_PAGE,
  DEVICE_INFO_PAGE,
}

const { LANDING_PAGE, LOGIN_PAGE, DOC_PAGE, DEVICE_INFO_PAGE } = ExternalAppPageEnums;

export const externalAppPathToViewMap = {
  [PATH_HOME]: LANDING_PAGE,
  [PATH_LOGIN]: LOGIN_PAGE,
  [PATH_DOCS]: DOC_PAGE,
  [PATH_DEVICE_INFO]: DEVICE_INFO_PAGE,
};

export default ExternalAppPageEnums;
