import { Box, Button, Typography } from '@mui/material';
import type { Account, Maybe } from '__generated__/graphql';
import { ModalNotificationTypeEnum, useModal } from 'Providers/ModalProvider';
interface DeviceOrgCellProps {
  account?: Maybe<Pick<Account, 'id' | 'name' | '__typename'>>;
  serialNumber?: Maybe<string>;
  postRegisterDevice: (
    serialNumber: string,
    org: Record<'id' | 'name', string>,
    location: Record<'id' | 'name', string>
  ) => void;
}

export default function DeviceOrgCell({
  account,
  serialNumber,
  postRegisterDevice,
}: DeviceOrgCellProps) {
  const { dispatchModal } = useModal();
  return (
    <Box display='flex' flexDirection='column' justifyContent='center' alignItems='flex-start'>
      <Typography fontWeight='bold' color='gray' variant='body2'>
        Account
      </Typography>
      <Box display='flex' justifyContent='center' alignItems='center' gap={1}>
        {account ? (
          <Typography variant='body2'>{account?.name}</Typography>
        ) : (
          <Button
            variant='contained'
            color='info'
            disableElevation
            onClick={(e) => {
              e.preventDefault();
              dispatchModal({
                type: ModalNotificationTypeEnum.ADMIN_REGISTER_DEVICE_TO_ORG,
                modalProps: {
                  serialNumber: serialNumber,
                  onSuccess: postRegisterDevice,
                },
              });
            }}
          >
            Register
          </Button>
        )}
      </Box>
    </Box>
  );
}
