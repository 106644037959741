import { useTheme, Card, CardHeader, Avatar } from '@mui/material';
import { WarningAmber as WarningIcon } from '@mui/icons-material';
import { alpha } from '@mui/material/styles';
import { IntShadowValue } from '__generated__/graphql';
import { activeFaults } from 'Components/AuthedPages/DevicesListPage/PageViews/DevicesListFilters';

interface DeviceFaultCardProps {
  type?: string | null;
  code?: IntShadowValue | null;
}

export default function DeviceFaultCard({ code, type }: DeviceFaultCardProps) {
  const theme = useTheme();

  return (
    <Card
      sx={{
        height: '100%',
        borderLeft: `.5rem solid ${theme.palette.error.dark}`,
        // eslint-disable-next-line no-magic-numbers
        bgcolor: alpha(theme.palette.error.dark, 0.2),
      }}
    >
      <CardHeader
        avatar={
          <Avatar sx={{ bgcolor: theme.palette.error.dark }} aria-label='fault-fan-current'>
            <WarningIcon color='inherit' sx={{ height: 28, width: 28 }} />
          </Avatar>
        }
        title={activeFaults[type ?? ''] ?? 'Fault'}
        titleTypographyProps={{ variant: 'body2', fontWeight: 500 }}
        subheader={`Code: ${code?.value}`}
      />
    </Card>
  );
}
