import React, { useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
  CircularProgress,
  useMediaQuery,
  useTheme,
  Stack,
  Tooltip,
  IconButton,
} from '@mui/material';
import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { useToast, ToastNotificationSeverityTypeEnum } from 'Providers/ToastProvider';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import OrgProfilePhotoRendererContainer from 'Components/AuthedPages/OrgDetailsPage/OrgProfilePhotoRendererContainer';

import type { GetSelectedOrgDataQuery as SelectedOrgDataType } from '__generated__/graphql';

const REMOVE_ACCOUNT_API_KEY_MUTATION = gql(`
  mutation RemoveAccountApiKey($accountApiKeyId: ID!) {
    removeAccountApiKey(accountApiKeyId: $accountApiKeyId)
  }
`);

const getFormattedScopeAccess = (apiKeyScope): string => {
  const hasLocationReadAccess = apiKeyScope?.location_read;
  const hasLocationWriteAccess = apiKeyScope?.location_write;
  const hasEnvReadAccess = apiKeyScope?.envData_read;
  const hasEnvWriteAccess = apiKeyScope?.envData_write;
  const hasDeviceReadAccess = apiKeyScope?.device_read;
  const hasDeviceWriteAccess = apiKeyScope?.device_write;
  const hasAccountReadAccess = apiKeyScope?.account_read;
  const hasAccountWriteAccess = apiKeyScope?.account_write;

  if (
    !(
      hasLocationReadAccess ||
      hasLocationWriteAccess ||
      hasEnvReadAccess ||
      hasEnvWriteAccess ||
      hasDeviceReadAccess ||
      hasDeviceWriteAccess ||
      hasAccountReadAccess ||
      hasAccountWriteAccess
    )
  ) {
    return 'No scope access';
  }

  let scopeAccess = '';

  if (hasLocationReadAccess && hasLocationWriteAccess) {
    scopeAccess = 'Location read & write';
  } else if (hasLocationReadAccess) {
    scopeAccess = 'Location read';
  } else if (hasLocationWriteAccess) {
    scopeAccess = 'Location write';
  }

  if (scopeAccess.length > 0) {
    scopeAccess += ' / ';
  }

  if (hasEnvReadAccess && hasEnvWriteAccess) {
    scopeAccess += 'Env read & write';
  } else if (hasEnvReadAccess) {
    scopeAccess += 'Env read';
  } else if (hasEnvWriteAccess) {
    scopeAccess += 'Env write';
  }
  if (scopeAccess.length > 0) {
    scopeAccess += ' / ';
  }

  if (hasDeviceReadAccess && hasDeviceWriteAccess) {
    scopeAccess += 'Device read & write';
  } else if (hasDeviceReadAccess) {
    scopeAccess += 'Device read';
  } else if (hasDeviceWriteAccess) {
    scopeAccess += 'Device write';
  }
  if (scopeAccess.length > 0) {
    scopeAccess += ' / ';
  }

  if (hasAccountReadAccess && hasAccountWriteAccess) {
    scopeAccess += 'Account read & write';
  } else if (hasAccountReadAccess) {
    scopeAccess += 'Account read';
  } else if (hasAccountWriteAccess) {
    scopeAccess += 'Account write';
  }

  if (scopeAccess.endsWith(' / ')) {
    // eslint-disable-next-line no-magic-numbers
    scopeAccess = scopeAccess.slice(0, -3);
  }
  return scopeAccess;
};

type ApiKeyCardTextRowProps = {
  label: string;
  content: string | null;
};

function ApiKeyCardTextRow({ label, content }: ApiKeyCardTextRowProps) {
  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));
  if (!content) {
    return null;
  }
  return (
    <Box display='flex' flexDirection={isMediumScreen ? 'column' : 'row'} gap={1}>
      <Typography fontWeight='bold'>{label}</Typography>
      <Typography marginBottom={0.5}>{content}</Typography>
    </Box>
  );
}

type OrgKeyListViewProps = {
  selectedOrgData: SelectedOrgDataType | undefined;
  userHasOrgWritePermission: boolean;
};

export default function OrgKeyListView({
  selectedOrgData,
  userHasOrgWritePermission,
}: OrgKeyListViewProps) {
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [deletingButtonId, setDeletingButtonId] = useState<string | undefined>(undefined);
  const [RemoveAccountApiKey] = useMutation(REMOVE_ACCOUNT_API_KEY_MUTATION);
  const { dispatchToast } = useToast();

  const {
    name,
    accountApiKeys,
    id: organizationID,
    preferences,
    rootLocation,
  } = selectedOrgData?.account ?? {};
  const rootLocationID = rootLocation?.id;

  const theme = useTheme();
  const handleDelete = async (targetID = '', name) => {
    try {
      setIsDisabled(true);
      await RemoveAccountApiKey({
        variables: {
          accountApiKeyId: targetID,
        },
        update: (cache) => {
          const account = selectedOrgData?.account ?? {};
          cache.modify({
            id: cache.identify(account),
            fields: {
              accountApiKeys(existingAPIKeys, { readField }) {
                return existingAPIKeys.filter((key) => targetID !== readField('id', key));
              },
            },
          });
        },
      });
      const notifConfig = {
        severity: ToastNotificationSeverityTypeEnum.SUCCESS,
        title: 'Key Removed',
        message: `You've successfully removed key: ${name}`,
      };
      dispatchToast(notifConfig);
      setIsDisabled(false);
    } catch (error) {
      const notifConfig = {
        severity: ToastNotificationSeverityTypeEnum.ERROR,
        title: 'Error Deleting Key',
        message: `Error deleting key: ${name}. Please try again`,
      };
      dispatchToast(notifConfig);
      console.error('ERROR: REMOVE_ACCOUNT_API_KEY_MUTATION - ', error);
      setIsDisabled(false);
    }
    setDeletingButtonId(undefined);
  };

  return (
    <Box display='flex' flexDirection='column' gap={2}>
      <Stack alignSelf='center' direction='row' padding={2} gap={2}>
        <OrgProfilePhotoRendererContainer
          accountId={organizationID ?? ''}
          imageURL={preferences?.logo_image_url}
          userHasEditPermission={userHasOrgWritePermission}
          size='small'
        />
        <Stack direction='column' gap={0.25}>
          <Typography color='primary'>{name}</Typography>
          <Stack direction='row' gap={2}>
            <Stack alignItems='center' direction='row' gap={1}>
              <Typography fontWeight='bold'>Organization ID: </Typography>
              <Typography>{organizationID}</Typography>
              <CopyToClipboard text={organizationID}>
                <Tooltip title='Click to copy'>
                  <IconButton sx={{ width: 10, height: 10 }}>
                    <ContentCopyIcon
                      sx={{
                        width: 20,
                        height: 20,
                        '&:hover': {
                          color: theme.palette.primary.main,
                        },
                      }}
                    />
                  </IconButton>
                </Tooltip>
              </CopyToClipboard>
            </Stack>
          </Stack>
          <Stack alignItems='center' direction='row' gap={1}>
            <Typography fontWeight='bold'>Root Location ID: </Typography>
            <Typography>{rootLocationID}</Typography>
            <CopyToClipboard text={rootLocationID}>
              <Tooltip title='Click to copy'>
                <IconButton sx={{ width: 10, height: 10 }}>
                  <ContentCopyIcon
                    sx={{
                      width: 20,
                      height: 20,
                      '&:hover': {
                        color: theme.palette.primary.main,
                      },
                    }}
                  />
                </IconButton>
              </Tooltip>
            </CopyToClipboard>
          </Stack>
        </Stack>
      </Stack>
      {accountApiKeys?.length === 0 ? (
        <Typography fontWeight='bold' color='primary.secondary'>
          There currently are no API keys available for this organization
        </Typography>
      ) : (
        accountApiKeys?.map((keyData) => {
          const { apiKeyScope, expirationTimestamp, lastUsedTimestamp, id, name, description } =
            keyData ?? {};
          const expirationDate = expirationTimestamp
            ? new Date(expirationTimestamp)?.toLocaleDateString('en-US', {
              month: 'long',
              day: 'numeric',
              year: 'numeric',
            })
            : null;
          const lastUsedDate = lastUsedTimestamp
            ? new Date(lastUsedTimestamp)?.toLocaleDateString('en-US')
            : null;

          const cardContentList = [
            { label: 'Access:', content: getFormattedScopeAccess(apiKeyScope) },
            { label: 'Description:', content: description ?? null },
            { label: 'Expires on:', content: expirationDate ?? 'no expiration' },
            { label: 'Last used:', content: lastUsedDate ?? 'never' },
          ];

          return (
            <Card key={id} elevation={0} variant='outlined'>
              <CardContent>
                <Grid container>
                  <Grid item container xs={10}>
                    <Typography color='primary.main'>{name}</Typography>
                  </Grid>
                  {userHasOrgWritePermission ? (
                    <Grid item container justifyContent='flex-end' xs={2}>
                      <Button
                        disabled={isDisabled}
                        onClick={() => {
                          setDeletingButtonId(id);
                          handleDelete(id, name);
                        }}
                        sx={{
                          height: 30,
                          width: 90,
                          backgroundColor: theme.palette.error.main,
                          color: theme.palette.warning.contrastText,
                        }}
                        variant='contained'
                      >
                        {deletingButtonId === id ? <CircularProgress size={20} /> : 'Delete'}
                      </Button>
                    </Grid>
                  ) : null}
                  <Grid item justifyContent='flex-end' xs={6}>
                    {cardContentList.map((row) => {
                      return (
                        <ApiKeyCardTextRow
                          key={row.label}
                          label={row.label}
                          content={row.content}
                        />
                      );
                    })}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          );
        })
      )}
    </Box>
  );
}
