/* eslint-disable no-magic-numbers */
import { gql, useMutation, useReactiveVar } from '@apollo/client';
import { Close as CloseIcon } from '@mui/icons-material';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Stack,
  TextField,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import {
  AdminChangeDownlightEnabledMutation,
  AdminChangeDownlightEnabledMutationVariables,
  BooleanShadowValue,
  DeviceType,
  Maybe,
  StringShadowValue,
  UpdateDeviceInfoMutation,
  UpdateDeviceInfoMutationVariables,
} from '__generated__/graphql';
import { currentUserDataVar, selectedOrgVar } from 'Apollo/ApolloCache';
import moment, { Moment } from 'moment';
import { ToastNotificationSeverityTypeEnum, useToast } from 'Providers/ToastProvider';
import { useCallback, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { compareFwVersions } from 'Utils/device';
interface Props {
  onModalClose: () => void;
  modalProps?: unknown;
}

type EditDeviceModalProps = {
  serialNumber?: string | null;
  nickname?: string | null;
  installationDate?: string | null;
  downlightEnabled?: Maybe<BooleanShadowValue>;
  firmwareVersion?: Maybe<StringShadowValue>;
  type?: DeviceType | null;
  onSuccess: () => Promise<void>;
};

const UPDATE_DEVICE = gql`
  mutation updateDeviceInfo(
    $accountId: ID!
    $serialNumber: String!
    $deviceInfo: DeviceInfoInput!
  ) {
    updateDeviceInfo(accountId: $accountId, serialNumber: $serialNumber, deviceInfo: $deviceInfo) {
      serialNumber
      nickname
      installationDate
      __typename
    }
  }
`;

const CHANGE_DOWNLIGHT_ENABLED = gql`
  mutation adminChangeDownlightEnabled(
    $accountId: ID!
    $serialNumber: String!
    $downlightEnabled: Boolean!
  ) {
    adminChangeDownlightEnabled(
      accountId: $accountId
      serialNumber: $serialNumber
      downlightEnabled: $downlightEnabled
    ) {
      serialNumber
      type
      nickname
      installationDate
      __typename
    }
  }
`;

export default function EditDeviceModal({ onModalClose, modalProps }: Props) {
  const {
    serialNumber,
    nickname,
    installationDate,
    type,
    onSuccess,
    downlightEnabled,
    firmwareVersion,
  } = modalProps as EditDeviceModalProps;
  const { dispatchToast } = useToast();
  const selectedOrg = useReactiveVar(selectedOrgVar);
  const currentUser = useReactiveVar(currentUserDataVar);

  const {
    control,
    handleSubmit,
    formState: { isDirty },
  } = useForm({
    defaultValues: {
      nickname: nickname ?? '',
      installationDate: installationDate ? moment(installationDate) : void 0,
      downlightEnabled: downlightEnabled?.value ?? false,
    },
  });

  const [updateDownlightStatus, { loading: updateDownlightStatusLoading }] = useMutation<
    AdminChangeDownlightEnabledMutation,
    AdminChangeDownlightEnabledMutationVariables
  >(CHANGE_DOWNLIGHT_ENABLED);
  const [updateDevice, { loading }] = useMutation<
    UpdateDeviceInfoMutation,
    UpdateDeviceInfoMutationVariables
  >(UPDATE_DEVICE);

  const showChangeDownlightOption = useMemo(() => {
    const minFWVersionForDownlightEnabled = '1.24.067.17.27';
    const currentDeviceFWVersion = firmwareVersion?.value ?? undefined;
    return (
      type === DeviceType.Air20 &&
      currentUser?.currentUser?.globalRoles?.device_write &&
      compareFwVersions(currentDeviceFWVersion, minFWVersionForDownlightEnabled) < 1
    );
  }, [type, currentUser, firmwareVersion]);
  const opsInFlight = useMemo(
    () => loading || updateDownlightStatusLoading,
    [loading, updateDownlightStatusLoading]
  );

  const saveHandler = useCallback(
    (d: {
      nickname?: string | null;
      downlightEnabled?: boolean | null;
      installationDate?: Moment | null;
    }) => {
      if (!selectedOrg?.id || !serialNumber) {
        dispatchToast({
          severity: ToastNotificationSeverityTypeEnum.WARNING,
          title: 'Unexpected error occurred',
          message: 'Please contact support',
        });
        return;
      }
      if (!isDirty) {
        onModalClose();
        return;
      }
      const ops = [
        updateDevice({
          variables: {
            accountId: selectedOrg?.id,
            serialNumber,
            deviceInfo: {
              nickname: d.nickname?.trim() ?? null,
              installationDate: d.installationDate?.toISOString(),
            },
          },
        }),
      ];
      if (showChangeDownlightOption) {
        ops.push(
          updateDownlightStatus({
            variables: {
              accountId: selectedOrg?.id,
              serialNumber,
              downlightEnabled: d.downlightEnabled ?? false,
            },
          })
        );
      }
      Promise.all(ops)
        .then((d) => {
          if (d.some((r) => !!r.errors)) {
            throw new Error('Update Device Failed');
          }
          dispatchToast({
            severity: ToastNotificationSeverityTypeEnum.SUCCESS,
            title: 'Device Information Updated',
          });
          onSuccess();
        })
        .catch((e) => {
          dispatchToast({
            severity: ToastNotificationSeverityTypeEnum.ERROR,
            title: 'Could not update device information',
            message: e.message,
          });
        })
        .finally(() => {
          onModalClose();
        });
    },
    [
      serialNumber,
      selectedOrg,
      dispatchToast,
      onSuccess,
      updateDevice,
      updateDownlightStatus,
      onModalClose,
      isDirty,
      showChangeDownlightOption,
    ]
  );
  return (
    <Dialog
      open={true}
      onClose={() => {
        onModalClose();
      }}
      fullWidth
      maxWidth='sm'
    >
      <DialogTitle>Edit Device</DialogTitle>
      <IconButton
        onClick={onModalClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Stack gap={2}>
          <Controller
            name='nickname'
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                fullWidth
                label='Nickname'
                {...field}
                error={!!error}
                helperText={error ? error.message : void 0}
                disabled={opsInFlight}
              />
            )}
          />

          <Controller
            name='installationDate'
            control={control}
            render={({ field }) => (
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker label='Installation Date' {...field} disabled={opsInFlight} />
              </LocalizationProvider>
            )}
          />
          {showChangeDownlightOption && (
            <FormControlLabel
              control={
                <Controller
                  name='downlightEnabled'
                  control={control}
                  render={({ field }) => (
                    <Checkbox
                      {...field}
                      checked={field.value}
                      onChange={(e) => field.onChange(e.target.checked)}
                    />
                  )}
                />
              }
              label='Downlight Enabled'
            />
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Box width='100%' gap={1} display='flex' justifyContent='flex-end' alignItems='center'>
          <Button
            color='primary'
            sx={{ fontWeight: 500 }}
            onClick={onModalClose}
            disabled={opsInFlight}
          >
            Cancel
          </Button>
          <Button
            color='error'
            sx={{ fontWeight: 500 }}
            onClick={(e) => (opsInFlight ? e.preventDefault() : handleSubmit(saveHandler)(e))}
            endIcon={loading && <CircularProgress color='inherit' size={16} />}
          >
            Save
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}
