/* eslint-disable no-magic-numbers */
import { Typography, useTheme } from '@mui/material';
import { GridConfigType } from 'Components/MetricCards/QuickMetricsCard';
import { useMemo } from 'react';
import { getPastDateTimeRange } from 'Utils/getPastDateTimeRange';

import { useQuery, useReactiveVar } from '@apollo/client';
import { AvTimer as AvTimerIcon } from '@mui/icons-material';
import {
  AverageTouchDurationByTimeframeQuery,
  AverageTouchDurationByTimeframeQueryVariables,
  TimeInterval,
} from '__generated__/graphql';
import { selectedLocationVar } from 'Apollo/ApolloCache';
import { Chart } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import MediumMetricsCard from 'Components/MetricCards/MediumMetricsCard';
import { metricCardIconColor } from 'Constants/OverviewConsts';
import { Bar, ChartProps } from 'react-chartjs-2';
import { AVERAGE_TOUCH_DURATION } from '../ReportsSurface.gql';

Chart.register(annotationPlugin);

export type AverageSurfaceInteractionTimeProps = {
  selectedLocationId?: string | null;
  selectedOrgId?: string | null;
  gridConfig: GridConfigType;
};
const TITLE = 'Average Surface Interaction Duration';
const INFO_TEXT = 'Average Surface Interaction Duration for the past 24 hours';
export default function AverageSurfaceInteractionTime({
  selectedLocationId,
  selectedOrgId,
  gridConfig,
}: AverageSurfaceInteractionTimeProps) {
  const theme = useTheme();
  const currentlySelectedLocation = useReactiveVar(selectedLocationVar);
  const [startDate, endDate] = useMemo(() => getPastDateTimeRange(24, 'hours'), []);

  const {
    data,
    loading: isLoading,
    error: queryError,
  } = useQuery<AverageTouchDurationByTimeframeQuery, AverageTouchDurationByTimeframeQueryVariables>(
    AVERAGE_TOUCH_DURATION,
    {
      variables: {
        accountId: selectedOrgId ?? '',
        locationId: selectedLocationId ?? '',
        timeFrame: {
          startDate,
          endDate,
          timeInterval: TimeInterval.Hourly,
        },
      },
    }
  );
  const { maxValue, maxValueIndex, cumulativeAmount } = useMemo(() => {
    const report = data?.report?.uvangel?.averageTouchDurationByTimeframe?.locationReport?.data;
    if (!report || report.length === 0)
      return {
        maxValue: 0,
        maxValueIndex: 0,
        cumulativeAmount: 0,
      };
    let maxValue = -Infinity;
    let maxValueIndex = 0;
    let cumulativeAmount = 0;
    for (let i = 0; i < report.length; ++i) {
      const point = report[i];
      if (!point || !point.y || !point.x) {
        continue;
      }
      if (maxValue < point.y) {
        maxValue = point.y;
        maxValueIndex = i;
      }
      cumulativeAmount += point.y;
    }
    return {
      maxValue,
      maxValueIndex,
      cumulativeAmount,
    };
  }, [data]);
  const chartOptions: ChartProps<'bar'>['options'] | null = useMemo(() => {
    const report = data?.report?.uvangel?.averageTouchDurationByTimeframe?.locationReport?.data;
    if (!report || report.length === 0) return null;

    return {
      responsive: true,
      maintainAspectRatio: false,
      elements: {
        bar: {
          borderRadius: 5,
        },
      },
      scales: {
        x: {
          type: 'time' as const,
          time: {
            unit: 'hour' as const,
          },
          grid: {
            display: false,
          },
          ticks: {
            major: {
              enabled: true,
            },
            font: function (context) {
              if (context.tick && context.tick.major) {
                return {
                  size: 14, // 12 is 'normal'
                  weight: 'bold',
                };
              }
            },
            display: true,
          },
        },
        y: {
          display: false,
          grid: {
            display: false,
          },
        },
      },
      plugins: {
        annotation: {
          annotations: [
            {
              type: 'line',
              borderDash: [10],
              yMin: maxValue,
              yMax: maxValue,
              borderColor: '#51ba38',
              borderWidth: 2,
              label: {
                position: 'end',
                content: `${maxValue.toLocaleString()}s`,
                display: true,
                backgroundColor: 'transparent',
                color: '#51ba38',
                yAdjust: -12,
              },
            },
          ],
        },
        legend: {
          display: false,
        },
        tooltip: {
          callbacks: {
            label: (context) => `${context.formattedValue}s`,
          },
        },
      },
    };
  }, [data, maxValue]);
  const chartData: ChartProps<'bar'>['data'] | null = useMemo(() => {
    const report = data?.report?.uvangel?.averageTouchDurationByTimeframe?.locationReport?.data;
    if (!report || report.length === 0) return null;
    const backgroundColors = report.map((_, index) =>
      index === maxValueIndex ? '#51ba38' : '#d7d7d7'
    );
    const labels = report.map((dataPoint) => {
      const timestamp = dataPoint?.x;
      return new Date(timestamp);
    });
    return {
      labels,
      datasets: [
        {
          data: report.map((point) => Math.round(point?.y ?? 0)),
          backgroundColor: backgroundColors,
          hoverBackgroundColor: '#7f7f7f',
        },
      ],
    };
  }, [data, maxValueIndex]);
  const additionalText =
    cumulativeAmount > 0 ? `${Math.round(cumulativeAmount).toLocaleString()}s` : null;
  return (
    <MediumMetricsCard
      additionalText={additionalText}
      isLoading={isLoading}
      icon={
        <AvTimerIcon sx={{ color: queryError ? theme.palette.error.main : metricCardIconColor }} />
      }
      title={TITLE}
      infoText={INFO_TEXT}
      gridConfig={gridConfig}
      error={!!queryError}
    >
      {chartData && chartOptions ? (
        <Bar plugins={[annotationPlugin]} data={chartData} options={chartOptions} />
      ) : (
        <>
          <Typography alignContent='center' color='InactiveCaptionText'>
            No data available for <b>{currentlySelectedLocation?.name}</b>
          </Typography>
          <Typography alignContent='center' fontStyle='italic' color='InactiveCaptionText'>
            in the last <b>24 hours</b>
          </Typography>
        </>
      )}
    </MediumMetricsCard>
  );
}
