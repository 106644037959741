import { useReactiveVar } from '@apollo/client';
import { DeviceHubOutlined as DeviceHubIcon } from '@mui/icons-material';
import { StringCategoryReport, Maybe } from '__generated__/graphql';
import { selectedLocationVar } from 'Apollo/ApolloCache';
import QuickMetricsCard, { GridConfigType } from 'Components/MetricCards/QuickMetricsCard';
import { metricCardIconColor } from 'Constants/OverviewConsts';
import { useMemo } from 'react';
import { Chart, Plugin } from 'chart.js';
import { Doughnut, ChartProps } from 'react-chartjs-2';
import { Typography, useTheme } from '@mui/material';
import { capitalize } from 'Utils/stringManipulation';
import RoutePaths from 'Constants/RoutePaths';
import { useNavigate } from 'react-router-dom';

Chart.register();
const CHART_ID = 'connected-devices';
export interface ConnectedDevicesContainerProps {
  title?: string;
  data?: Maybe<Array<Maybe<StringCategoryReport>>>;
  isLoading: boolean;
  startDate?: string;
  endDate?: string;
  gridConfig: GridConfigType;
  error?: boolean;
}

export default function ConnectedDevicesCardContainer({
  title,
  data,
  isLoading,
  gridConfig,
  error,
}: ConnectedDevicesContainerProps) {
  const navigate = useNavigate();
  const selectedLocation = useReactiveVar(selectedLocationVar);
  const theme = useTheme();
  const chartData: ChartProps<'doughnut'>['data'] | null = useMemo(() => {
    if (!data || data.every((d) => d?.y === 0)) return null;
    return {
      labels: data.map((d) => capitalize(d?.x ?? '')),
      datasets: [
        {
          label: 'Devices',
          data: data.map((d) => d?.y ?? 0),
          backgroundColor: ['#51ba38', '#d7d7d7'],
          borderColor: theme.palette.background.paper,
          hoverBorderColor: 'transparent',
          cutout: '80%',
          borderRadius: 6,
          borderWidth: 3,
        },
      ],
    };
  }, [data, theme]);
  return (
    <QuickMetricsCard
      error={error}
      isLoading={isLoading}
      icon={
        <DeviceHubIcon sx={{ color: error ? theme.palette.error.main : metricCardIconColor }} />
      }
      title={title ?? 'Devices'}
      infoText={`Connected vs Disconnected Devices in ${selectedLocation?.name ?? 'All Locations'}`}
      gridConfig={gridConfig}
      link={RoutePaths.PATH_DEVICES}
      linkTooltip='View All Devices'
    >
      {chartData ? (
        <Doughnut
          id={CHART_ID}
          plugins={[
            annotateDoughnut(`${data?.find((d) => d?.x === 'connected')?.y ?? 0} Connected`),
          ]}
          data={chartData}
          options={{
            plugins: {
              legend: {
                display: false,
              },
            },
            maintainAspectRatio: true,
            aspectRatio: 1.4,
            onClick: (evt) => {
              const elements = Chart.getChart(CHART_ID)?.getElementsAtEventForMode(
                evt as never,
                'index',
                { intersect: true },
                false
              );
              if (elements?.at(0)?.index === 0) {
                navigate(`${RoutePaths.PATH_DEVICES}?connectivity=Connected`);
              } else if (elements?.at(0)?.index === 1) {
                navigate(`${RoutePaths.PATH_DEVICES}?connectivity=Disconnected`);
              }
            },
          }}
          style={{
            cursor: 'pointer',
          }}
        />
      ) : (
        <Typography textAlign='center' color='InactiveCaptionText'>
          No data available for <b>{selectedLocation?.name ?? 'All Locations'}</b>
        </Typography>
      )}
    </QuickMetricsCard>
  );
}

function annotateDoughnut(label: string): Plugin<'doughnut'> {
  return {
    id: 'innerLabel',
    afterDatasetDraw(chart, args) {
      const { ctx } = chart;
      const meta = args.meta;
      const xCoor = meta.data[0].x;
      const yCoor = meta.data[0].y;

      ctx.save();
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.fillStyle = metricCardIconColor;
      ctx.font = '16px sans-serif';
      ctx.fillText(label, xCoor, yCoor);
      ctx.restore();
    },
  };
}
