/* eslint-disable indent */
import { gql, useQuery, useReactiveVar } from '@apollo/client';
import { CircularProgress, Grid, GridSize, Stack, useMediaQuery, useTheme } from '@mui/material';
import {
  DeviceType,
  GetAllEnvOverviewDataQuery,
  GetAllEnvOverviewDataQueryVariables,
  TimeInterval,
} from '__generated__/graphql';
import { currentUserDataVar, type SelectedLocationType, type SelectedOrgType } from 'Apollo/ApolloCache';
import PageContainerFrame from 'Components/HOC/PageContainerFrame';
import LocationSelectorBreadcrumb from 'Components/LocationSelectorBreadcrumb/LocationSelectorBreadcrumb';
import useCurrentLocationList from 'Hooks/useCurrentLocationList';
import { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { getPastDateTimeRange } from 'Utils/getPastDateTimeRange';
import {
  AverageSurfaceInteractionTime,
  TotalSurfaceDisinfectionTime,
} from '../ReportsSurfacePage/SurfaceReportCards';
import AirTreatmentCardContainer, {
  AirTreatmentCardContainerProps,
} from './DashboardCardContainers/AirTreatmentCardContainer';
import CO2SmallCardContainer, {
  CO2SmallCardContainerProps,
} from './DashboardCardContainers/CO2Card/CO2SmallCardContainer';
import ConnectedDevicesCardContainer, {
  ConnectedDevicesContainerProps,
} from './DashboardCardContainers/ConnectedDevicesCardContainer';
import shouldDisplayCard, { ReportType } from './DashboardCardContainers/displayRules';
import FacilityLogoContainer, {
  FacilityLogoContainerProps,
} from './DashboardCardContainers/FacilityLogoContainer';
import IAQMetricContainer, {
  IAQMetricContainerProps,
} from './DashboardCardContainers/IAQMetricContainer';
import LocationHumidityCardContainer, {
  LocationHumidityCardContainerProps,
} from './DashboardCardContainers/LocationHumidityCardContainer';
import LocationsNeedingAttentionPanel, {
  LocationsNeedingAttentionPanelProps,
} from './DashboardCardContainers/LocationsNeedingAttenionPanel';
import LocationTemperatureCardContainer, {
  LocationTemperatureCardContainerProps,
} from './DashboardCardContainers/LocationTemperatureCardContainer';
import MoldRiskCardContainer, {
  MoldRiskProps,
} from './DashboardCardContainers/MoldRiskCardContainer';
import PowerConsumptionCardContainer, {
  PowerConsumptionCardContainerProps,
} from './DashboardCardContainers/PowerConsumptionCardContainer';
import VOCMetricCardContainer, {
  VOCMetricCardContainerProps,
} from './DashboardCardContainers/VOCMetricContainer';
import { TotalSurfaceDisinfectionTimeProps } from '../ReportsSurfacePage/SurfaceReportCards/TotalSurfaceDisinfectionTime';
import { AverageSurfaceInteractionTimeProps } from '../ReportsSurfacePage/SurfaceReportCards/AverageSurfaceInteractionTime';
import { canViewFeature, FeatureFlagFeatures } from 'Utils/FeatureFlags';
export const GET_ENV_DATA_QUERY = gql`
  query GetAllEnvOverviewData($accountId: ID!, $locationId: ID!, $timeFrame: TimeFrameInput!) {
    report {
      uvangel {
        activelyConnectedDevices(accountId: $accountId, locationId: $locationId) {
          x
          y
        }
        environmentalMetricsReportByTimeframe(
          accountId: $accountId
          locationId: $locationId
          timeFrame: $timeFrame
        ) {
          y {
            temp
            humidity
            voc
            iaq
            co2
          }
        }
      }
    }
  }
`;

type Props = {
  selectedOrg: SelectedOrgType;
  selectedLocation: SelectedLocationType;
};

type TReportCard<T extends ReportType, U extends object> = {
  reportType: T;
  component: (props: U) => JSX.Element;
  props: U;
  enabled?: boolean;
};

type ReportCard =
  | TReportCard<ReportType.FACILITY, FacilityLogoContainerProps>
  | TReportCard<ReportType.CONNECTED_DEVICES, ConnectedDevicesContainerProps>
  | TReportCard<ReportType.TEMPERATURE, LocationTemperatureCardContainerProps>
  | TReportCard<ReportType.HUMIDITY, LocationHumidityCardContainerProps>
  | TReportCard<ReportType.CO2, CO2SmallCardContainerProps>
  | TReportCard<ReportType.VOC, VOCMetricCardContainerProps>
  | TReportCard<ReportType.IAQ, IAQMetricContainerProps>
  | TReportCard<ReportType.MOLD_RISK, MoldRiskProps>
  | TReportCard<ReportType.LOCATIONS_NEEDING_ATTENTION, LocationsNeedingAttentionPanelProps>
  | TReportCard<ReportType.AIR_TREATMENT, AirTreatmentCardContainerProps>
  | TReportCard<ReportType.POWER_CONSUMPTION, PowerConsumptionCardContainerProps>
  | TReportCard<ReportType.TOTAL_SURFACE_DISINFECTION_TIME, TotalSurfaceDisinfectionTimeProps>
  | TReportCard<
      ReportType.AVERAGE_SURFACE_INTERACTION_DURATION,
      AverageSurfaceInteractionTimeProps
    >;

export default function OverviewPage({ selectedOrg, selectedLocation }: Props) {
  // default range is past hour
  const selectedOrgID = selectedOrg?.id ?? '';
  const rootLocationID = selectedOrg?.rootLocation?.id;
  const orgLogoImgUrl = selectedOrg?.preferences?.logo_image_url ?? undefined;

  const [searchParams, setSearchParams] = useSearchParams();
  const locationIDFromQueryParams = searchParams.get('locationID');

  const currentUser = useReactiveVar(currentUserDataVar);

  const [selectedLocationID, setSelectedLocationID] = useState(() => {
    if (locationIDFromQueryParams) {
      return locationIDFromQueryParams;
    } else if (selectedLocation) {
      return selectedLocation.id;
    } else {
      return rootLocationID;
    }
  });
  const orgDeviceTypes = useMemo(() => {
    if (!selectedOrg || !selectedOrg.deviceTypes) return [];
    return selectedOrg.deviceTypes.filter((i) => !!i) as Array<DeviceType>;
  }, [selectedOrg]);

  // eslint-disable-next-line no-magic-numbers
  const [startDate, endDate] = useMemo(() => getPastDateTimeRange(1, 'hour'), []);

  const [isLoading, initLocationList] = useCurrentLocationList(selectedLocationID);

  const {
    data: envData,
    loading: isLoadingEnvData,
    error: envError,
  } = useQuery<GetAllEnvOverviewDataQuery, GetAllEnvOverviewDataQueryVariables>(
    GET_ENV_DATA_QUERY,
    {
      variables: {
        accountId: selectedOrgID ?? '',
        locationId: selectedLocationID ?? '',
        timeFrame: {
          startDate,
          endDate,
          timeInterval: TimeInterval.Yearly,
        },
      },
    }
  );

  useEffect(() => {
    setSearchParams({ locationID: selectedLocationID ?? '' }, { replace: true });
  }, [selectedLocationID, setSearchParams]);

  const handleOnLocationChange = (id: string) => {
    setSelectedLocationID(id);
    setSearchParams({ locationID: id }, { replace: true });
  };

  const theme = useTheme();
  const isBelowLargeBreakpoint = useMediaQuery(theme.breakpoints.down('lg'));

  const flexDirection = isBelowLargeBreakpoint ? 'column' : 'row';
  const alignItems = isBelowLargeBreakpoint ? 'center' : '';

  const smallPlusCardGridConfig: Record<'xs' | 'sm' | 'md' | 'lg' | 'xl', GridSize | undefined> = {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 6,
    xl: 4,
  };

  const smallCardGridConfig: Record<'xs' | 'sm' | 'md' | 'lg' | 'xl', GridSize | undefined> = {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 6,
    xl: 3,
  };

  const allCards: Array<ReportCard> = [
    {
      reportType: ReportType.FACILITY,
      component: FacilityLogoContainer,
      props: {
        orgLogoImgUrl: orgLogoImgUrl,
        gridConfig: smallCardGridConfig,
      },
    },
    {
      reportType: ReportType.CONNECTED_DEVICES,
      component: ConnectedDevicesCardContainer,
      props: {
        data: envData?.report?.uvangel?.activelyConnectedDevices,
        startDate,
        endDate,
        isLoading: isLoadingEnvData,
        gridConfig: smallCardGridConfig,
        error: !!envError,
      },
    },
    {
      reportType: ReportType.TEMPERATURE,
      component: LocationTemperatureCardContainer,
      props: {
        tempDataToAverage: envData?.report?.uvangel?.environmentalMetricsReportByTimeframe?.map(
          (d) => d?.y?.temp
        ),
        selectedLocationID,
        selectedOrgID,
        startDate,
        endDate,
        isLoading: isLoadingEnvData,
        gridConfig: smallCardGridConfig,
        error: !!envError,
      },
    },
    {
      reportType: ReportType.HUMIDITY,
      component: LocationHumidityCardContainer,
      props: {
        humidityDataToAverage: envData?.report?.uvangel?.environmentalMetricsReportByTimeframe?.map(
          (d) => d?.y?.humidity
        ),
        selectedLocationID,
        selectedOrgID,
        startDate,
        endDate,
        isLoading: isLoadingEnvData,
        gridConfig: smallCardGridConfig,
        error: !!envError,
      },
    },
    {
      reportType: ReportType.CO2,
      component: CO2SmallCardContainer,
      props: {
        co2DataToAverage: envData?.report?.uvangel?.environmentalMetricsReportByTimeframe?.map(
          (d) => d?.y?.co2
        ),
        selectedLocationID,
        selectedOrgID,
        startDate,
        endDate,
        isLoading: isLoadingEnvData,
        gridConfig: canViewFeature(FeatureFlagFeatures.MOLD_ANALYSIS, currentUser, selectedOrg) ? smallCardGridConfig : smallPlusCardGridConfig,
        error: !!envError,
      },
    },
    {
      reportType: ReportType.VOC,
      component: VOCMetricCardContainer,
      props: {
        vocDataToAverage: envData?.report?.uvangel?.environmentalMetricsReportByTimeframe?.map(
          (d) => d?.y?.voc
        ),
        selectedLocationID,
        selectedOrgID,
        startDate,
        endDate,
        isLoading: isLoadingEnvData,
        gridConfig: canViewFeature(FeatureFlagFeatures.MOLD_ANALYSIS, currentUser, selectedOrg) ? smallCardGridConfig : smallPlusCardGridConfig,
        error: !!envError,
      },
    },
    {
      reportType: ReportType.IAQ,
      component: IAQMetricContainer,
      props: {
        iaqDataToAverage: envData?.report?.uvangel?.environmentalMetricsReportByTimeframe?.map(
          (d) => d?.y?.iaq
        ),
        selectedLocationID,
        selectedOrgID,
        startDate,
        endDate,
        isLoading: isLoadingEnvData,
        gridConfig: canViewFeature(FeatureFlagFeatures.MOLD_ANALYSIS, currentUser, selectedOrg) ? smallCardGridConfig : smallPlusCardGridConfig,
        error: !!envError,
      },
    },
    {
      reportType: ReportType.MOLD_RISK,
      component: MoldRiskCardContainer,
      props: {
        selectedLocation: {
          id: selectedLocationID as string,
          name: selectedLocation?.name ?? 'All Locations',
        },
        selectedOrgId: selectedOrgID,
        gridConfig: smallCardGridConfig,
      },
      enabled: canViewFeature(FeatureFlagFeatures.MOLD_ANALYSIS, currentUser, selectedOrg),
    },
    {
      reportType: ReportType.LOCATIONS_NEEDING_ATTENTION,
      component: LocationsNeedingAttentionPanel,
      props: {
        selectedLocationID,
        selectedOrgID,
        startDate,
        endDate,
        gridConfig: {
          xs: 12,
          sm: 12,
          md: 6,
          lg: 6,
          xl: 6,
        },
      },
    },
    {
      reportType: ReportType.AIR_TREATMENT,
      component: AirTreatmentCardContainer,
      props: {
        selectedLocationID,
        selectedOrgID,
        gridConfig: {
          xs: 12,
          sm: 12,
          md: 6,
          lg: 6,
          xl: 6,
        },
      },
    },
    {
      reportType: ReportType.TOTAL_SURFACE_DISINFECTION_TIME,
      component: TotalSurfaceDisinfectionTime,
      props: {
        selectedLocationId: selectedLocationID,
        selectedOrgId: selectedOrgID,
        gridConfig: { xs: 12, sm: 12, md: 6, lg: 6, xl: 6 },
      },
    },
    {
      reportType: ReportType.AVERAGE_SURFACE_INTERACTION_DURATION,
      component: AverageSurfaceInteractionTime,
      props: {
        selectedLocationId: selectedLocationID,
        selectedOrgId: selectedOrgID,
        gridConfig: { xs: 12, sm: 12, md: 6, lg: 6, xl: 6 },
      },
    },
    {
      reportType: ReportType.POWER_CONSUMPTION,
      component: PowerConsumptionCardContainer,
      props: {
        selectedLocationID,
        selectedOrgID,
        gridConfig: { xs: 12, sm: 12, md: 12, lg: 12, xl: 12 },
      },
    },
  ].filter((card) => shouldDisplayCard(card.reportType, orgDeviceTypes))
    .filter((card) => (card.enabled === true || card.enabled === false) ? card.enabled : true) as Array<ReportCard>;

  const widthAdjustedReportCards = useMemo(() => {
    // for each xs, sm, md, lg, xl
    // there must be window of cards whose columns exactly match up to 12
    // if the running sum of number of columns is less or more,
    // Check neighboring cards and adjust
    function adjust(cards: Array<ReportCard>) {
      const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl'];
      const minColumnWidth = 3; // Minimum width for any card

      // Helper function to adjust based on neighboring cards
      function adjustColumnSpan(startIdx: number, cards: Array<ReportCard>, breakpoint: string) {
        let totalColumns = 0;
        const columnsToAdjust: Array<number> = [];

        for (let i = startIdx; i < cards.length; i++) {
          totalColumns += cards[i].props.gridConfig[breakpoint];
          columnsToAdjust.push(i);

          // When total columns hit 12, break and distribute evenly
          if (totalColumns === 12) {
            return; // This block is correctly sized
          }

          // If the sum of columns exceeds 12, adjust the size
          if (totalColumns > 12) {
            // Reduce columns from the previous ones to fit into 12
            const excess = totalColumns - 12;
            for (let j = columnsToAdjust.length - 1; j >= 0 && excess > 0; j--) {
              const cardIndex = columnsToAdjust[j];
              const cardWidth = cards[cardIndex].props.gridConfig[breakpoint];

              if (cardWidth > minColumnWidth) {
                const reduction = Math.min(excess, cardWidth - minColumnWidth);
                cards[cardIndex].props.gridConfig[breakpoint] -= reduction;
                totalColumns -= reduction;
                if (totalColumns === 12) return; // Corrected to fit exactly 12
              }
            }
          }

          // If we reach the end of the grid but have less than 12 columns, fill it out
          if (i === cards.length - 1 && totalColumns < 12) {
            const deficit = 12 - totalColumns;
            for (let j = columnsToAdjust.length - 1; j >= 0 && deficit > 0; j--) {
              const cardIndex = columnsToAdjust[j];
              const availableIncrease = Math.min(
                deficit,
                12 - cards[cardIndex].props.gridConfig[breakpoint]
              );
              cards[cardIndex].props.gridConfig[breakpoint] += availableIncrease;
              totalColumns += availableIncrease;
              if (totalColumns === 12) return; // Corrected to fit exactly 12
            }
          }
        }
      }

      // Iterate over breakpoints and adjust
      breakpoints.forEach((breakpoint) => {
        let currentColumns = 0;
        let startIndex = 0;

        // Iterate through each card and adjust if columns don't sum to 12
        for (let i = 0; i < cards.length; i++) {
          // Ensure minimum width for the card
          if (cards[i].props.gridConfig[breakpoint] < minColumnWidth) {
            cards[i].props.gridConfig[breakpoint] = minColumnWidth;
          }

          currentColumns += cards[i].props.gridConfig[breakpoint];

          if (currentColumns === 12) {
            // Reset columns count
            currentColumns = 0;
            startIndex = i + 1;
          } else if (currentColumns > 12) {
            // If the columns go beyond 12, adjust them
            adjustColumnSpan(startIndex, cards, breakpoint);
            currentColumns = 0;
            startIndex = i + 1;
          }
        }

        // If we have leftover columns at the end
        if (currentColumns > 0 && currentColumns < 12) {
          adjustColumnSpan(startIndex, cards, breakpoint);
        }
      });

      return cards;
    }

    return adjust(allCards);
  }, [allCards]);

  return (
    <PageContainerFrame pageTitles={['Overview']}>
      <Stack
        alignItems={alignItems}
        flexDirection={flexDirection}
        gap={1}
        width='100%'
        maxWidth={2500}
      >
        {!!rootLocationID && !!selectedLocationID && !isLoading ? (
          <Grid container spacing={1.5}>
            <Grid item xs={12}>
              <LocationSelectorBreadcrumb
                preLoadedLocationList={initLocationList}
                onLocationSelectCallback={handleOnLocationChange}
              />
            </Grid>
            {widthAdjustedReportCards.map(
              ({
                reportType,
                props: componentProps,
                component: Component,
              }: // Need to figure out how split to individual types for each ReportCard
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              Record<string, any>) => (
                <Component key={reportType} {...componentProps} />
              )
            )}
          </Grid>
        ) : (
          <CircularProgress size={50} />
        )}
      </Stack>
    </PageContainerFrame>
  );
}
