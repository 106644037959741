import { useMemo } from 'react';
import { useTheme, Card, CardHeader, Avatar, Tooltip } from '@mui/material';
import { ModeFanOffOutlined as FanOffIcon, AirOutlined as FanOnIcon } from '@mui/icons-material';
import moment from 'moment';
import { StringShadowValue } from '__generated__/graphql';
import { capitalize } from 'Utils/stringManipulation';
interface DeviceFansCardProps {
  deviceFan?: StringShadowValue | null;
}

export default function DeviceFansCard({ deviceFan }: DeviceFansCardProps) {
  const theme = useTheme();
  const fanStatus = useMemo(() => {
    return capitalize(deviceFan?.value);
  }, [deviceFan]);
  return (
    <Tooltip placement='top' title={`As of ${moment(deviceFan?.timestamp).fromNow()}`}>
      <Card>
        <CardHeader
          avatar={
            <Avatar sx={{ backgroundColor: theme.palette.info.main }} aria-label='device-type'>
              {fanStatus === 'On' ? (
                <FanOnIcon
                  color='inherit'
                  sx={{
                    height: 28,
                    width: 28,
                  }}
                />
              ) : (
                <FanOffIcon
                  color='inherit'
                  sx={{
                    height: 28,
                    width: 28,
                  }}
                />
              )}
            </Avatar>
          }
          title='Fans'
          titleTypographyProps={{ variant: 'body2', fontWeight: 500 }}
          subheader={fanStatus}
        />
      </Card>
    </Tooltip>
  );
}
