import {
  Box,
  IconButton,
  CardHeader,
  Paper,
  Typography,
  LinearProgress,
  Card,
  CardContent,
  Grid,
} from '@mui/material';
import { Check as CheckIcon, ContentCopy as CopyIcon } from '@mui/icons-material';
import useCopy from 'Hooks/useCopy';
import DeviceFirmwareCell from './DeviceFirmwareCell';
import DeviceLocationCell from './DeviceLocationCell';
import DeviceConnectivityCell from './DeviceConnectivityCell';
import DeviceStateCell from './DeviceStateCell';
import DeviceIconCell from './DeviceIconCell';
import type { SelectedOrgType } from 'Apollo/ApolloCache';
import InfiniteScroll from 'react-infinite-scroll-component';
import { DevicesQuery } from '__generated__/graphql';
import { Link as RouterLink } from 'react-router-dom';
import { Fragment } from 'react';
import DeviceSettingsTrigger from './DeviceSettingsTrigger';

type DevicesListProps = {
  devices: NonNullable<DevicesQuery['deviceSearch']>['devices'];
  totalCount: number;
  selectedOrg?: SelectedOrgType;
  loadNextPage?: () => void;
  simple?: boolean;
};

export default function DevicesListMobileView({
  devices,
  totalCount,
  simple,
  loadNextPage,
}: DevicesListProps) {
  const [copied, copyText] = useCopy();
  if (!devices || devices.length === 0) {
    return (
      <Paper sx={{ padding: 2 }}>
        <Typography variant='h5'>No devices Found</Typography>
        <Typography variant='body1' color='gray'>
          Update the filters or add devices
        </Typography>
      </Paper>
    );
  }
  return (
    <Fragment>
      {!simple && (
        <Box width='100%' my={2} display='flex' justifyContent='space-between' alignItems='center'>
          <Typography
            sx={{
              fontWeight: 500,
            }}
          >
            {totalCount} devices that match your search criteria
          </Typography>
          <DeviceSettingsTrigger />
        </Box>
      )}
      <InfiniteScroll
        dataLength={devices.length}
        next={() => (loadNextPage ? loadNextPage() : void 0)}
        hasMore={devices.length < totalCount}
        loader={<LinearProgress />}
      >
        <Box display='flex' flexDirection='column' gap={1}>
          {devices?.map((device) => {
            return (
              <Card
                key={`${device?.serialNumber}-${device?.nickname}`}
                sx={{ width: '100%', cursor: 'pointer', textDecoration: 'none' }}
                component={RouterLink}
                color='inherit'
                to={`/devices/${device?.serialNumber}`}
              >
                <CardHeader
                  avatar={<DeviceIconCell deviceType={device?.type ?? ''} />}
                  title={
                    device?.nickname ? (
                      <Typography fontWeight='bold' color='gray' variant='body2'>
                        {device?.nickname}
                      </Typography>
                    ) : (
                      <Typography variant='body2'>{device?.serialNumber}</Typography>
                    )
                  }
                  subheader={
                    device?.nickname ? (
                      <Typography variant='body2'>{device?.serialNumber}</Typography>
                    ) : (
                      void 0
                    )
                  }
                  action={
                    copied === device?.serialNumber ? (
                      <IconButton size='small' onClick={(e) => e.preventDefault()}>
                        <CheckIcon color='success' sx={{ height: 16, width: 16 }} />
                      </IconButton>
                    ) : (
                      <IconButton
                        onClick={(e) => copyText(e, device?.serialNumber ?? '')}
                        size='small'
                      >
                        <CopyIcon sx={{ height: 16, width: 16 }} />
                      </IconButton>
                    )
                  }
                />
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                      <DeviceLocationCell
                        fullLocation={
                          device?.fullLocationPath?.map((loc) => loc?.name)?.join(' / ') ?? ''
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                      <DeviceFirmwareCell firmware={device?.firmwareVersion} />
                    </Grid>
                    <Grid item xs={6} sm={6} md={4}>
                      <DeviceConnectivityCell connectivity={device?.connectivity} />
                    </Grid>
                    <Grid item xs={6} sm={6} md={4}>
                      <DeviceStateCell state={device?.state} />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            );
          })}
        </Box>
        {totalCount === devices.length && !simple && (
          <Box sx={{ marginY: 3 }}>
            <Typography
              sx={{
                fontWeight: 500,
              }}
              align='center'
              variant='body1'
            >
              You have viewed all the devices that match your current filters
            </Typography>
            <Typography variant='body2' align='center'>
              Modify the selected filters to find more devices
            </Typography>
          </Box>
        )}
      </InfiniteScroll>
    </Fragment>
  );
}
