import { gql } from '@apollo/client';

export const POWER_CONSUMPTION_REPORT = gql`
  query GetLocationPowerConsumption(
    $accountId: ID!
    $locationId: ID!
    $timeFrame: TimeFrameInput!
  ) {
    report {
      uvangel {
        getLocationPowerConsumption(
          accountId: $accountId
          locationId: $locationId
          timeFrame: $timeFrame
        ) {
          x
          y {
            totalAirTreatedCF
            totalKWh
          }
        }
      }
    }
  }
`;
