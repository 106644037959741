/* eslint-disable indent */
import {
  Badge,
  IconButton,
  ListItemIcon,
  Menu,
  Stack,
  Typography,
  MenuItem,
  Button,
  Grid,
  Tooltip,
  CircularProgress,
} from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { AlertMetric, AlertsQuery } from '__generated__/graphql';
import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import RoutePaths from 'Constants/RoutePaths';
import { Circle, Factory, Place, Thermostat, Warning, WaterDrop } from '@mui/icons-material';
import Co2Icon from '@mui/icons-material/Co2';
import { ApolloError, gql, useMutation, useReactiveVar } from '@apollo/client';
import { selectedOrgVar } from 'Apollo/ApolloCache';
import { ToastNotificationSeverityTypeEnum, useToast } from 'Providers/ToastProvider';
import moment from 'moment';
import { GET_ACCOUNT_ALERTS } from 'Components/DesktopHeaderBar';
import { getAlertText } from 'Utils/metricHelper';

type AlertsDropdownProps = {
  fetchMoreAlerts: () => void;
  isLoading: boolean;
  isFetchingMore: boolean;
  alertsData?: AlertsQuery;
  queryError?: ApolloError;
};

const SET_ALERTS_READ_STATUS = gql`
  mutation SetAlertsReadStatus($alertIds: [ID!]!, $accountId: ID!, $setIsUnread: Boolean!) {
    setAlertsReadStatus(alertIds: $alertIds, accountId: $accountId, setIsUnread: $setIsUnread) {
      id
      isUnread
    }
  }
`;

export default function AlertsDropdown({
  alertsData,
  isLoading,
  isFetchingMore,
  fetchMoreAlerts,
}: AlertsDropdownProps) {
  const selectedOrg = useReactiveVar(selectedOrgVar);
  const [setAlertsReadStatus, { loading: setAlertsReadStatusLoading }] =
    useMutation(SET_ALERTS_READ_STATUS);
  const [alertsAnchorElement, setAlertsAnchorElement] = useState<null | HTMLElement>(null);
  const { dispatchToast } = useToast();
  const isAlertsOpen = Boolean(alertsAnchorElement);
  const handleAlertsMenuClick = (event: React.MouseEvent<HTMLElement>): void => {
    if (alertsAnchorElement) {
      setAlertsAnchorElement(null);
    } else {
      setAlertsAnchorElement(event.currentTarget);
    }
  };

  const getAlertIcon = (metric?: AlertMetric): JSX.Element => {
    switch (metric) {
      case AlertMetric.Humidity:
        return <WaterDrop />;
      case AlertMetric.Temp:
        return <Thermostat />;
      case AlertMetric.Co2:
        return <Co2Icon />;
      case AlertMetric.Voc:
        return <Factory />;
      case AlertMetric.Iaq:
        return <Factory />;
      default:
        return <Warning />;
    }
  };

  const onSetAlertsReadStatus = async (
    alertIdsUnfiltered: (string | undefined)[],
    setIsUnreadValue: boolean
  ) => {
    const alertIds = alertIdsUnfiltered.filter((alert) => alert);
    if (!alertIds || alertIds.length === 0) {
      return;
    }
    try {
      await setAlertsReadStatus({
        variables: {
          alertIds: alertIds,
          accountId: selectedOrg?.id ?? '',
          setIsUnread: setIsUnreadValue,
        },
        update: (cache, { data: setAlertsReadStatus }) => {
          let netChangeNumUnread = 0;
          for (const alert of setAlertsReadStatus.setAlertsReadStatus) {
            if (alert.isUnread) {
              netChangeNumUnread++;
            } else {
              netChangeNumUnread--;
            }
            cache.writeFragment({
              id: alert.id,
              data: {
                isUnread: alert.isUnread,
              },
              fragment: gql`
                fragment AlertFragment on Alert {
                  id
                  isUnread
                }
              `,
            });
          }
          cache.updateQuery(
            {
              query: GET_ACCOUNT_ALERTS,
              variables: { accountId: selectedOrg?.id ?? '' },
              overwrite: true, // so that merge function isn't triggered!
            },
            (data) => ({
              alerts: {
                ...data.alerts,
                totalUnread: data.alerts.totalUnread + netChangeNumUnread,
              },
            })
          );
        },
      });
    } catch (error) {
      const notifConfig = {
        severity: ToastNotificationSeverityTypeEnum.ERROR,
        title: 'Failed to Mark as Read',
        message: 'Something went wrong. Please try again',
      };
      dispatchToast(notifConfig);
      console.error(error);
    }
  };
  return (
    <>
      <Tooltip title='Alerts'>
        <IconButton
          aria-controls='menu-appbar'
          aria-haspopup='true'
          aria-label='Alerts Menu'
          onClick={handleAlertsMenuClick}
          size='large'
        >
          <Badge badgeContent={alertsData?.alerts.totalUnread} color='error'>
            <NotificationsIcon />
          </Badge>
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={alertsAnchorElement}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={isAlertsOpen}
        onClick={handleAlertsMenuClick}
        sx={{
          // eslint-disable-next-line no-magic-numbers
          maxHeight: 500,
        }}
      >
        {!alertsData && isLoading ? (
          <MenuItem key={'initial-loading-alerts'} sx={{ justifyContent: 'center' }}>
            <CircularProgress size={20} />
            <Typography fontStyle={'italic'} sx={{ marginLeft: 2 }}>
              Loading Alerts
            </Typography>
          </MenuItem>
        ) : null}
        {alertsData && alertsData?.alerts.alerts.length === 0 ? (
          <MenuItem key={'no-alerts'} sx={{ justifyContent: 'center' }}>
            <Typography fontStyle={'italic'}>No Alerts!</Typography>
          </MenuItem>
        ) : null}
        {alertsData && alertsData?.alerts.alerts.length > 0 ? (
          <MenuItem
            key={'bulk-read-alerts'}
            sx={{
              justifyContent: 'center',
              '&:hover': {
                background: 'none',
              },
            }}
            disableRipple
          >
            {setAlertsReadStatusLoading ? (
              <CircularProgress size={20} />
            ) : (
              <>
                <Button
                  size='small'
                  variant='outlined'
                  color='error'
                  sx={{ margin: 1 }}
                  disabled={isFetchingMore || setAlertsReadStatusLoading}
                  onClick={(e) => {
                    e.stopPropagation();
                    const allReadAlertIds = alertsData.alerts.alerts.filter(
                      (alert) => !alert?.isUnread
                    );
                    const allAlertIds = allReadAlertIds.map((alert) => alert?.id);
                    onSetAlertsReadStatus(allAlertIds, true);
                  }}
                >
                  Unread All
                </Button>
                <Button
                  size='small'
                  variant='outlined'
                  color='primary'
                  sx={{ margin: 1 }}
                  disabled={isFetchingMore || setAlertsReadStatusLoading}
                  onClick={(e) => {
                    e.stopPropagation();
                    const allUnreadAlertIds = alertsData.alerts.alerts.filter(
                      (alert) => alert?.isUnread
                    );
                    const allAlertIds = allUnreadAlertIds.map((alert) => alert?.id);
                    onSetAlertsReadStatus(allAlertIds, false);
                  }}
                >
                  Read All
                </Button>
              </>
            )}
          </MenuItem>
        ) : null}
        {alertsData?.alerts.alerts.map((alert) => {
          return (
            <MenuItem
              key={alert?.id}
              component={RouterLink}
              to={RoutePaths.PATH_ALERT_DETAILS.replace(':alert_id', alert?.id ?? '')}
              onClick={(e) => {
                if (!alert?.location?.id) {
                  e.preventDefault();
                  return;
                }
                if (!alert?.metric) {
                  e.preventDefault();
                  return;
                }
                if (alert.isUnread) {
                  // if not already unread, mark as unread
                  onSetAlertsReadStatus([alert.id], false);
                }
              }}
            >
              <Grid justifyContent={'flex-start'} container minWidth={300}>
                <ListItemIcon>{getAlertIcon(alert?.metric || undefined)}</ListItemIcon>
                {alert?.metricValue && alert.metric ? (
                  <Stack width={'70%'}>
                    {alert.alertConfiguration?.metricThreshold.high &&
                    alert.metricValue > alert.alertConfiguration?.metricThreshold.high ? (
                      <Typography fontWeight='bold'>High {getAlertText(alert.metric)}</Typography>
                    ) : (
                      <Typography fontWeight='bold'>Low {getAlertText(alert.metric)}</Typography>
                    )}
                    <Stack direction='row' alignItems='center' gap={1}>
                      <Place fontSize='inherit' color='primary' />
                      <Typography>
                        {alert?.location ? <>{alert?.location.name}</> : null}
                      </Typography>
                    </Stack>
                    <Typography fontStyle={'italic'} style={{ display: 'inline-block' }}>
                      {moment(alert?.startTime).fromNow()}
                      {alert.endTime ? (
                        <>
                          , active for{' '}
                          {alert?.endTime ? (
                            <>
                              {moment
                                .duration(moment(alert?.endTime).diff(moment(alert?.startTime)))
                                .humanize()}
                            </>
                          ) : null}
                        </>
                      ) : (
                        <>, ongoing...</>
                      )}
                    </Typography>
                  </Stack>
                ) : null}
                {alert?.isUnread ? (
                  <Tooltip title='Mark as read'>
                    <IconButton
                      size='medium'
                      sx={{ marginLeft: 'auto', height: '30px', width: '30px' }}
                      onClick={(e) => {
                        e.stopPropagation();
                        if (alert) {
                          onSetAlertsReadStatus([alert.id], !alert.isUnread);
                        }
                      }}
                    >
                      <Circle color='error' sx={{ fontSize: 8 }} />
                    </IconButton>
                  </Tooltip>
                ) : (
                  <Tooltip title='Mark as unread'>
                    <IconButton
                      size='medium'
                      sx={{ marginLeft: 'auto', height: '30px', width: '30px' }}
                      onClick={(e) => {
                        e.stopPropagation();
                        if (alert) {
                          onSetAlertsReadStatus([alert.id], !alert.isUnread);
                        }
                      }}
                    ></IconButton>
                  </Tooltip>
                )}
              </Grid>
            </MenuItem>
          );
        })}
        {isFetchingMore ? (
          <MenuItem key={'get-more-alerts'} sx={{ justifyContent: 'center' }}>
            <CircularProgress size={20} />
            <Typography fontStyle={'italic'} sx={{ marginLeft: 2 }}>
              Loading Alerts
            </Typography>
          </MenuItem>
        ) : null}
        {!isFetchingMore &&
        alertsData &&
        alertsData.alerts.totalCount > alertsData.alerts.alerts.length ? (
          <MenuItem
            key={'get-more-alerts'}
            sx={{
              justifyContent: 'center',
              '&:hover': {
                background: 'none',
              },
            }}
            disableRipple
          >
            <Button
              variant='outlined'
              onClick={(e) => {
                e.stopPropagation();
                fetchMoreAlerts();
              }}
            >
              Load More Alerts
            </Button>
          </MenuItem>
        ) : null}
        {!isFetchingMore &&
        alertsData &&
        alertsData.alerts.alerts.length > 0 &&
        alertsData.alerts.totalCount <= alertsData.alerts.alerts.length ? (
          <MenuItem key={'get-more-alerts'} sx={{ justifyContent: 'center' }}>
            <Typography fontStyle={'italic'}>No More Alerts!</Typography>
          </MenuItem>
        ) : null}
      </Menu>
    </>
  );
}
