/* eslint-disable no-magic-numbers */
import { Info as InfoIcon, ZoomOutMap as ZoomIcon } from '@mui/icons-material';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  IconButton,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { TimeInterval as TimeIntervalEnum } from '__generated__/graphql';
import { Chart } from 'chart.js';
import 'chartjs-adapter-moment'; // needed for time series chart.js usage
import annotationPlugin from 'chartjs-plugin-annotation';
import { CrosshairPlugin } from 'chartjs-plugin-crosshair';
import zoomPlugin from 'chartjs-plugin-zoom';
import CSVDownloadButton, { formatLocationMetricData } from 'Components/SharedUI/CSVDownloadButton';
import moment from 'moment';
import { Fragment } from 'react';
import { Line } from 'react-chartjs-2';
import { replaceConfigDataIfSkipped } from 'Utils/chartHelpers';
import type { ChartConfigType } from './ReportsContainer';

Chart.register(annotationPlugin);
const CHART_ID = 'metric-report';
export type ChartDataListType = Array<{
  label: string;
  data: Array<{
    timeStamp: string;
    value: number | null | undefined;
  }>;
}>;

export const formatTimestamp = (
  timestamp: string,
  timeInterval?: TimeIntervalEnum | undefined,
  hasDate?: boolean
) => {
  const date = new Date(timestamp);

  const options: Intl.DateTimeFormatOptions = {
    year: '2-digit',
    month: '2-digit',
    day: '2-digit',
  };
  const formattedDate = date.toLocaleDateString(undefined, options);

  if (timeInterval === TimeIntervalEnum.Daily) {
    return formattedDate;
  }

  const hour = date.getHours();
  const minute = date.getMinutes();
  const formattedTime = `${hour % 12 || 12}:${minute.toString().padStart(2, '0')} ${
    hour < 12 ? 'am' : 'pm'
  }`;
  if (hasDate) {
    return `${formattedDate} ${formattedTime}`;
  }
  return `${formattedTime}`;
};

export type MetricDataPointType = {
  timeStamp: string;
  value: number | null | undefined;
};

type Props = {
  chartConfig: ChartConfigType;
  chartDataList: ChartDataListType;
  isLoading: boolean;
  alertHighlight?: {
    startDate: string;
    endDate: string;
  };
};

// update floors metric query to single query based on metric
// sidebar with query selection options
// single line component.

const linePallette = ['#0B84A5', '#F6C85F', '#6F4E7C', '#9DD866', '#CA472F'];

export default function MetricLineChartContainer({
  chartDataList,
  chartConfig,
  isLoading,
  alertHighlight,
}: Props) {
  const { title, dataSetLabel, timeInterval, suggestedMinX, suggestedMaxX } = chartConfig;
  const theme = useTheme();
  const isSmallerScreen = useMediaQuery(theme.breakpoints.down('lg'));
  if (!chartDataList) {
    // fix this!!
    // add error boundary!!
    return null;
  }

  const inAlertLineSegment = (context, value) => {
    if (!alertHighlight) {
      return undefined;
    }
    const timestampInQuestion = moment(context.p0.parsed.x);
    const alertStartTime = moment(alertHighlight.startDate);
    const alertEndTime = moment(alertHighlight.endDate);
    if (
      timestampInQuestion.isAfter(alertStartTime) &&
      (!alertHighlight.endDate || timestampInQuestion.isBefore(alertEndTime))
    ) {
      return value;
    }
    return undefined;
  };
  const inAlertPoint = (context, value, defaultValue) => {
    if (!alertHighlight) {
      return defaultValue;
    }
    const timestampInQuestion = moment(context.parsed.x);
    const alertStartTime = moment(alertHighlight.startDate);
    const alertEndTime = moment(alertHighlight.endDate);
    if (
      timestampInQuestion.isAfter(alertStartTime) &&
      (!alertHighlight.endDate || timestampInQuestion.isBefore(alertEndTime))
    ) {
      return value;
    }
    return defaultValue;
  };

  const datasets = chartDataList.map((location, index) => {
    const { label, data } = location;
    const values = data.map((item) => {
      return {
        x: new Date(item?.timeStamp).valueOf(),
        y: item?.value,
      };
    });
    const dataColor = linePallette[index];
    return {
      label: label,
      // data for actual line chart
      data: values,
      borderColor: dataColor,
      backgroundColor: dataColor,
      tension: 0.2,
      pointRadius: 0,
      segment: {
        borderColor: (context) =>
          replaceConfigDataIfSkipped(context, 'gray', timeInterval) ||
          inAlertLineSegment(context, theme.palette.error.main),
        borderDash: (context) => replaceConfigDataIfSkipped(context, [6, 6], timeInterval),
      },
      pointBackgroundColor: (context) => inAlertPoint(context, theme.palette.error.main, dataColor),
      pointBorderColor: (context) => inAlertPoint(context, theme.palette.error.main, dataColor),
      spanGaps: true,
    };
  });
  const chartData = {
    datasets,
  };

  const options = {
    aspectRatio: isSmallerScreen ? 0.8 : 1.5,
    interaction: {
      intersect: false,
      mode: 'nearest' as const,
      axis: 'x' as const,
    },
    plugins: {
      legend: {
        display: true,
      },
      tooltip: {
        callbacks: {
          title: (context) => {
            const timeStamp = context[0].label;
            return formatTimestamp(timeStamp, timeInterval, true);
          },
          label: (context) => {
            const roundedValue = parseFloat(context.raw.y).toFixed(2);
            return `${context.dataset.label}: ${roundedValue} ${dataSetLabel}`;
          },
        },
      },
      crosshair: {
        line: {
          color: 'gray', // crosshair line color
          dashPattern: [8, 8],
          width: 1, // crosshair line width
        },
        sync: {
          enabled: true, // enable trace line syncing with other charts
          group: 1, // chart group
          suppressTooltips: false, // suppress tooltips when showing a synced tracer
        },
        zoom: {
          enabled: false, // we use the other zoom plugin for zooming, so this is to be disabled
        },
      },
      zoom: {
        limits: {
          x: {
            min: Math.min(
              ...chartDataList.map((loc) =>
                Math.min(...loc.data.map((data) => new Date(data?.timeStamp).valueOf()))
              )
            ),
            max: Math.max(
              ...chartDataList.map((loc) =>
                Math.max(...loc.data.map((data) => new Date(data?.timeStamp).valueOf()))
              )
            ),
          },
        },
        pan: {
          enabled: true,
          mode: 'x' as const,
          modifierKey: 'shift' as const,
          scaleMode: 'x' as const,
          onPan: (chart) => {
            chart.chart.canvas.style.cursor = 'grabbing';
          },
          onPanComplete: (chart) => {
            chart.chart.canvas.style.cursor = 'grab';
          },
        },
        zoom: {
          pinch: {
            enabled: true,
          },
          drag: {
            enabled: true,
            backgroundColor: 'rgba(128,128,128,0.2)',
            borderColor: 'gray',
            borderWidth: 1,
            threshold: 1,
          },
          onZoom: (chart) => {
            chart.chart.canvas.style.cursor = 'grabbing';
          },
          onZoomComplete: (chart) => {
            chart.chart.canvas.style.cursor = 'grab';
          },
          mode: 'x' as const,
        },
      },
    },
    scales: {
      x: {
        type: 'time' as const,
        suggestedMin: suggestedMinX,
        suggestedMax: suggestedMaxX,
        time: {
          unit:
            timeInterval === TimeIntervalEnum.Weekly ||
            timeInterval === TimeIntervalEnum.Monthly ||
            timeInterval === TimeIntervalEnum.Yearly
              ? ('day' as const)
              : ('hour' as const),
        },
        grid: {
          color: (context) => {
            const lineWidth =
              context.tick && context.tick.major
                ? Chart.defaults.borderColor.toString()
                : 'transparent';
            return lineWidth;
          },
        },
        ticks: {
          major: {
            enabled: true,
          },
          font: function (context) {
            if (context.tick && context.tick.major) {
              return {
                size: 14, // 12 is 'normal'
                weight: 'bold',
              };
            }
          },
          display: true,
        },
      },
      y: {
        display: true,
      },
    },
  };

  options.plugins['annotation'] = {
    annotations: [],
  };

  if (alertHighlight && alertHighlight?.startDate) {
    options.plugins['annotation'].annotations.push({
      type: 'line' as const,
      mode: 'vertical',
      scaleID: 'x',
      borderDash: [10],
      value: moment(alertHighlight?.startDate).toDate(),
      borderColor: theme.palette.error.main,
      borderWidth: 2,
      label: {
        position: 'start' as const,
        content: 'Alert Start',
        display: true,
        backgroundColor: theme.palette.error.main,
        color: theme.palette.error.contrastText,
        xAdjust: 0,
      },
    });
  }
  if (alertHighlight && alertHighlight?.endDate) {
    options.plugins['annotation'].annotations.push({
      type: 'line' as const,
      mode: 'vertical',
      scaleID: 'x',
      borderDash: [10],
      value: moment(alertHighlight?.endDate).toDate(),
      borderColor: theme.palette.error.main,
      borderWidth: 2,
      label: {
        position: 'start' as const,
        content: 'Alert End',
        display: true,
        backgroundColor: theme.palette.error.main,
        color: theme.palette.error.contrastText,
        xAdjust: 0,
      },
    });
  }

  return (
    <Card sx={{ width: '100%' }}>
      {isLoading ? (
        <CardContent
          sx={{
            height: '100%',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress size={100} />
        </CardContent>
      ) : (
        <Fragment>
          <CardHeader
            avatar={
              <Tooltip
                title={
                  <Fragment>
                    <Typography variant='subtitle2'>Click & Drag to Zoom</Typography>
                    <Typography variant='subtitle2'>Hold Shift + Click & Drag to Pan</Typography>
                  </Fragment>
                }
              >
                <Avatar>
                  <InfoIcon />
                </Avatar>
              </Tooltip>
            }
            action={
              <Box display='flex' gap={1} alignItems='center'>
                <Tooltip title='Reset Zoom'>
                  <IconButton
                    sx={{
                      borderColor: (theme) => theme.palette.primary.main,
                      borderRadius: 1,
                      borderWidth: 1,
                      borderStyle: 'solid',
                    }}
                    color='primary'
                    onClick={() => Chart.getChart(CHART_ID)?.resetZoom()}
                  >
                    <ZoomIcon />
                  </IconButton>
                </Tooltip>
                <CSVDownloadButton
                  data={chartDataList}
                  fileName='location_data'
                  formatDataCallback={formatLocationMetricData}
                />
              </Box>
            }
          />
          <CardContent>
            <Typography align='center' fontWeight='bold'>
              {title} (<span>{dataSetLabel}</span>)
            </Typography>
            <Line
              id={CHART_ID}
              data={chartData}
              options={options}
              plugins={[CrosshairPlugin, annotationPlugin, zoomPlugin]}
              style={{
                cursor: 'grab',
              }}
            />
          </CardContent>
        </Fragment>
      )}
    </Card>
  );
}
