import { Fragment, useMemo } from 'react';
import { useTheme, Card, CardHeader, Avatar, Tooltip } from '@mui/material';
import { FluorescentOutlined as BulbIcon } from '@mui/icons-material';
import moment from 'moment';
import { Maybe, StringShadowValue } from '__generated__/graphql';
import { capitalize } from 'Utils/stringManipulation';
interface DeviceUvCLAMPCardProps {
  deviceBulb?: Maybe<StringShadowValue>;
  remainingBulbLifePercentage?: Maybe<number>;
}
export default function DeviceUvCLAMPCard({
  deviceBulb,
  remainingBulbLifePercentage,
}: DeviceUvCLAMPCardProps) {
  const theme = useTheme();
  const bulbStatus = useMemo(() => {
    return capitalize(deviceBulb?.value);
  }, [deviceBulb]);
  return (
    <Tooltip placement='top' title={`As of ${moment(deviceBulb?.timestamp).fromNow()}`}>
      <Card>
        <CardHeader
          avatar={
            <Avatar sx={{ backgroundColor: theme.palette.info.main }} aria-label='device-type'>
              <BulbIcon color='inherit' sx={{ height: 28, width: 28 }} />
            </Avatar>
          }
          title='UV-C Lamp'
          titleTypographyProps={{ variant: 'body2', fontWeight: 500 }}
          subheader={
            <span>
              {bulbStatus}{' '}
              {remainingBulbLifePercentage && (
                <Fragment>&mdash; {Math.round(remainingBulbLifePercentage) > 0 ? Math.round(remainingBulbLifePercentage) : 0}% Remaining Life</Fragment>
              )}
            </span>
          }
        />
      </Card>
    </Tooltip>
  );
}
