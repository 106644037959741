const BASE = 'UV Angel Cloud';
const SEPARATOR = '·';

/**
 * 
 * @param titleStrings ordered array of strings to use for page title.
 *            if any strings are undefined|null|empty, they will be removed silently
 * @param excludeBase excludes the base name (special case only)
 * @returns 
 */
export const formatPageTitle = (titleStrings: (string | null | undefined)[], excludeBase = false) => {
  if (!titleStrings || titleStrings.length === 0) {
    return BASE;
  }

  // purposeful (deep) copy - do not alter original array or can cause infinite additions to array
  const allTitles = titleStrings.filter(s => !!s);

  // append base unless explicitly excluded
  if (!excludeBase) {
    allTitles.push(BASE);
  }

  // join with common seprarator
  return allTitles.join(` ${ SEPARATOR } `);
};
