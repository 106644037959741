import { gql, useQuery } from '@apollo/client';
import { Grid } from '@mui/material';
import {
  DeviceType,
  GetConnectedDevicesQuery,
  GetConnectedDevicesQueryVariables,
} from '__generated__/graphql';
import { SelectedLocationType, SelectedOrgType } from 'Apollo/ApolloCache';
import PageContainerFrame from 'Components/HOC/PageContainerFrame';
import SettingsPanelFrame from 'Components/HOC/SettingsPanelFrame';
import LocationSelectorBreadcrumb from 'Components/LocationSelectorBreadcrumb/LocationSelectorBreadcrumb';
import useCurrentLocationList from 'Hooks/useCurrentLocationList';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import AirTreatmentCardContainer from '../OverviewPage/DashboardCardContainers/AirTreatmentCardContainer';
import ConnectedDevicesCardContainer from '../OverviewPage/DashboardCardContainers/ConnectedDevicesCardContainer';
import PowerConsumptionCardContainer from '../OverviewPage/DashboardCardContainers/PowerConsumptionCardContainer';
type Props = {
  selectedOrg: SelectedOrgType;
  selectedLocation: SelectedLocationType;
};
export const GET_CONNECTED_DEVICES_QUERY = gql`
  query GetConnectedDevices($accountId: ID!, $locationId: ID!, $deviceTypes: [DeviceType]) {
    report {
      uvangel {
        activelyConnectedDevices(
          accountId: $accountId
          locationId: $locationId
          deviceTypes: $deviceTypes
        ) {
          x
          y
        }
      }
    }
  }
`;
export default function ReportsAirPage({ selectedOrg, selectedLocation }: Props) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [locationId, setLocationId] = useState<string | null | undefined>(
    searchParams.get('location') ?? selectedLocation?.id ?? selectedOrg?.rootLocation?.id ?? ''
  );
  const [islocationSelectorLoading, initLocationList] = useCurrentLocationList(locationId);
  const {
    data: connectedDevices,
    loading: connectedDevicesLoading,
    variables: connectedDevicesVar,
    refetch,
  } = useQuery<GetConnectedDevicesQuery, GetConnectedDevicesQueryVariables>(
    GET_CONNECTED_DEVICES_QUERY,
    {
      variables: {
        accountId: selectedOrg?.id ?? '',
        locationId: locationId ?? '',
        deviceTypes: [DeviceType.Air175, DeviceType.Air20],
      },
      notifyOnNetworkStatusChange: true,
    }
  );
  useEffect(() => {
    const currentLocationId = searchParams.get('location');
    if (!currentLocationId && locationId) {
      setSearchParams((prev) => ({ ...Object.fromEntries(prev.entries()), location: locationId }), {
        replace: true,
      });
    }
  }, [searchParams, setSearchParams, locationId]);
  return (
    <PageContainerFrame pageTitles={['Air Treatment']}>
      <SettingsPanelFrame title='Air Treatment'>
        {!islocationSelectorLoading && (
          <LocationSelectorBreadcrumb
            preLoadedLocationList={initLocationList}
            onLocationSelectCallback={(id) => {
              setLocationId(id);
              setSearchParams((prev) => ({ ...Object.fromEntries(prev.entries()), location: id }), {
                replace: true,
              });
              refetch(Object.assign(connectedDevicesVar ?? {}, { locationId: locationId ?? '' }));
            }}
          />
        )}
        <Grid container spacing={3}>
          <ConnectedDevicesCardContainer
            title='Air Devices'
            data={connectedDevices?.report?.uvangel?.activelyConnectedDevices}
            isLoading={connectedDevicesLoading}
            gridConfig={{
              xs: 12,
              sm: 12,
              md: 6,
              lg: 4,
              xl: 3,
            }}
          />
          <AirTreatmentCardContainer
            selectedLocationID={locationId ?? ''}
            selectedOrgID={selectedOrg?.id ?? ''}
            gridConfig={{
              xs: 12,
              sm: 12,
              md: 6,
              lg: 8,
              xl: 9,
            }}
          />
          <PowerConsumptionCardContainer
            selectedLocationID={locationId ?? ''}
            selectedOrgID={selectedOrg?.id ?? ''}
            gridConfig={{
              xs: 12,
              sm: 12,
              md: 12,
              lg: 12,
              xl: 12,
            }}
          />
        </Grid>
      </SettingsPanelFrame>
    </PageContainerFrame>
  );
}
