import React from 'react';
import { Box, Button, CircularProgress, Typography, useMediaQuery, useTheme } from '@mui/material';
import KeyIcon from '@mui/icons-material/Key';
import { useLocation, useNavigate } from 'react-router-dom';
import { gql } from '__generated__/gql';
import { useQuery } from '@apollo/client';
import { useErrorBoundary } from 'react-error-boundary';

import PageContainerFrame from 'Components/HOC/PageContainerFrame';
import SettingsPanelFrame from 'Components/HOC/SettingsPanelFrame';
import OrgKeyGenView from './PageViews/OrgKeyGenView';
import OrgKeyListView from './PageViews/OrgKeyListView';
import OrgNewKeyView from './PageViews/OrgNewKeyView';
import OrgDisplayAndSwitcher from 'Components/SharedUI/OrgDisplayAndSwitcher';
import { OrgAPISettingsPanelViewEnum } from 'Constants/OrgSettingsEnums';
import RoutePaths from 'Constants/RoutePaths';
import type { GetSelectedOrgDataQuery as SelectedOrgDataType } from '__generated__/graphql';
import type { SelectedOrgType } from 'Apollo/ApolloCache';

const { PATH_ORGANIZATION_API_KEYS } = RoutePaths;

export const GET_SELECTED_ORG_DATA = gql(`
  query getSelectedOrgData($accountId: ID!) {
    account(accountId: $accountId) {
      name
      id
      userAccountScope {
        roles {
          account_write
        }
      }
      deviceTypes
      rootLocation{
        id
      }
      preferences {
        logo_image_url
      }
      accountApiKeys {
        description
        id
        lastUsedTimestamp
        name
        expirationTimestamp
        apiKeyScope {
          location_read
          location_write
          envData_read
          envData_write
          device_read
          device_write
          account_read
          account_write
        }
      }
    }
  }
`);

const { ORG_KEY_LIST_VIEW, ORG_KEY_GEN_VIEW, ORG_NEW_KEY_VIEW } = OrgAPISettingsPanelViewEnum;

type PanelViewRendererProps = {
  panelView: OrgAPISettingsPanelViewEnum;
  selectedOrgData: SelectedOrgDataType | undefined;
  userHasOrgWritePermission: boolean;
};

const CurrentPanelViewRenderer = ({
  panelView,
  selectedOrgData,
  userHasOrgWritePermission,
}: PanelViewRendererProps) => {
  switch (panelView) {
    case ORG_KEY_LIST_VIEW:
      return (
        <OrgKeyListView
          userHasOrgWritePermission={userHasOrgWritePermission}
          selectedOrgData={selectedOrgData}
        />
      );
    case ORG_KEY_GEN_VIEW:
      return <OrgKeyGenView selectedOrgData={selectedOrgData} />;
    case ORG_NEW_KEY_VIEW:
      return <OrgNewKeyView />;
    default:
      return (
        <OrgKeyListView
          userHasOrgWritePermission={userHasOrgWritePermission}
          selectedOrgData={selectedOrgData}
        />
      );
  }
};

type Props = {
  selectedOrg: SelectedOrgType;
};

export default function OrgAPIKeyPage({ selectedOrg }: Props) {
  const userHasOrgWritePermission =
    selectedOrg?.userAccountScope?.roles?.account_write == null
      ? false
      : selectedOrg?.userAccountScope?.roles?.account_write;
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { panelView = ORG_KEY_LIST_VIEW }: { panelView: OrgAPISettingsPanelViewEnum } =
    location.state || {};
  const { error: queryError, data: selectedOrgData } = useQuery(GET_SELECTED_ORG_DATA, {
    variables: { accountId: selectedOrg?.id ?? '' },
  });
  const { showBoundary: showErrorBoundary } = useErrorBoundary();

  if (queryError) {
    showErrorBoundary(queryError);
  }

  return (
    <PageContainerFrame pageTitles={['API Keys', selectedOrg?.name]}>
      <SettingsPanelFrame title='Api Keys'>
        <Box display='flex' flexDirection='row' justifyContent='space-between'>
          <Typography variant='h6'>
            {userHasOrgWritePermission
              ? 'Create and manage API keys associated with the organization you are currently viewing'
              : 'View API keys associated with the organization you are currently viewing'}
          </Typography>
          {userHasOrgWritePermission && panelView !== ORG_KEY_GEN_VIEW ? (
            <Button
              sx={{
                alignSelf: 'flex-end',
                color: theme.palette.success.contrastText,
                backgroundColor: theme.palette.success.main,
                width: 200,
              }}
              endIcon={isSmallScreen ? null : <KeyIcon />}
              onClick={() =>
                navigate(PATH_ORGANIZATION_API_KEYS, {
                  state: { panelView: ORG_KEY_GEN_VIEW },
                })
              }
              variant='contained'
            >
              {isSmallScreen ? 'Gen' : 'Generate'} new key
            </Button>
          ) : null}
        </Box>
        {selectedOrgData ? (
          <>
            <Box>
              <OrgDisplayAndSwitcher />
            </Box>
            <CurrentPanelViewRenderer
              userHasOrgWritePermission={userHasOrgWritePermission}
              panelView={panelView}
              selectedOrgData={selectedOrgData}
            />
          </>
        ) : (
          <CircularProgress sx={{ alignSelf: 'center', marginTop: 10 }} size={100} />
        )}
      </SettingsPanelFrame>
    </PageContainerFrame>
  );
}
