import { Box, Typography } from '@mui/material';
import { Wifi as WifiIcon, ErrorOutline as ErrorIcon } from '@mui/icons-material';
import moment from 'moment';
import type { DeviceConnectivity } from '__generated__/graphql';
interface DeviceConnectivityCellProps {
  connectivity?: DeviceConnectivity | null;
}

const iconProps = {
  sx: {
    height: 20,
    width: 20,
  },
};

export default function DeviceConnectivityCell({ connectivity }: DeviceConnectivityCellProps) {
  return (
    <Box display='flex' flexDirection='column' justifyContent='center' alignItems='flex-start'>
      <Typography fontWeight='bold' color='gray' variant='body2'>
        Connectivity
      </Typography>
      <Box display='flex' justifyContent='center' alignItems='center' gap={1}>
        {connectivity?.connected ? (
          <WifiIcon color='success' {...iconProps} />
        ) : (
          <ErrorIcon color='error' {...iconProps} />
        )}
        <Typography variant='body2' whiteSpace='nowrap'>
          {moment(connectivity?.timestamp).fromNow()}
        </Typography>
      </Box>
    </Box>
  );
}
