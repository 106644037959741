import { Card, CardHeader, Avatar } from '@mui/material';
import { LocationOnOutlined as LocationIcon } from '@mui/icons-material';
interface LocationCardProps {
  location?: string;
}

export default function LocationCard({ location }: LocationCardProps) {
  return (
    <Card sx={{ height: '100%' }}>
      <CardHeader
        avatar={
          <Avatar aria-label='location'>
            <LocationIcon color='inherit' sx={{ height: 28, width: 28 }} />
          </Avatar>
        }
        title='Location'
        titleTypographyProps={{ variant: 'body2', fontWeight: 500 }}
        subheader={location}
      />
    </Card>
  );
}
