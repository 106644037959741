import React, { useState } from 'react';
import {
  Button,
  Typography,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Stack,
  CircularProgress,
  Box,
} from '@mui/material';
import { Close as CloseIcon, ManageAccounts as ManageAccountsIcon } from '@mui/icons-material';

import RoleSelectionInput from 'Components/SharedUI/RoleSelectionInput';
import { useReactiveVar } from '@apollo/client';
import { selectedOrgVar } from 'Apollo/ApolloCache';
import { gql, useMutation } from '@apollo/client';
import { RoleEnum } from 'Constants/OrgSettingsEnums';
import { useToast, ToastNotificationSeverityTypeEnum } from 'Providers/ToastProvider';
import { getPermissionConfig } from 'Utils/permissionConfig';
import useCurrentLocationList from 'Hooks/useCurrentLocationList';
import LocationSelectorBreadcrumb from 'Components/LocationSelectorBreadcrumb/LocationSelectorBreadcrumb';

type Props = {
  onModalClose: () => void;
  modalProps: unknown;
};

const EDIT_USER_DETAILS_MUTATION = gql`
  mutation UpdateUserRolesForAccount(
    $userId: ID!
    $accountId: ID!
    $roles: AccountRolesInput!
    $rootLocationId: ID
  ) {
    updateUserRolesForAccount(
      userId: $userId
      accountId: $accountId
      roles: $roles
      rootLocationId: $rootLocationId
    ) {
      id
      accountScope(accountId: $accountId) {
        roles {
          account_read
          account_write
          location_read
          location_write
          device_read
          device_write
          users_read
          users_write
          report_read
          report_write
        }
        rootLocation {
          id
        }
      }
    }
  }
`;

export default function EditUserDetailsModal({ onModalClose, modalProps }: Props) {
  const { firstName, lastName, roleType, userID, rootLocationId } = modalProps as {
    userID: string;
    firstName: string;
    lastName: string;
    roleType: RoleEnum;
    rootLocationId?: string | null;
  };
  const [editUserDetails, { loading: isMutationInFlight }] = useMutation(
    EDIT_USER_DETAILS_MUTATION
  );
  const [selectedRole, setSelectedRole] = useState<RoleEnum | null>(roleType);
  const selectedOrg = useReactiveVar(selectedOrgVar);
  const [selectedLocationId, setSelectedLocationId] = useState(
    rootLocationId ?? selectedOrg?.rootLocation?.id
  );
  const [isListLoading, initLocationList] = useCurrentLocationList(
    rootLocationId ?? selectedOrg?.rootLocation?.id
  );
  const [isEdited, setIsEdited] = useState<boolean>(false);

  const fullUserName = firstName + ' ' + lastName;
  const { dispatchToast } = useToast();
  const onSubmit = async () => {
    const { id: accountId } = selectedOrg ?? {};
    const roles = getPermissionConfig(selectedRole);
    try {
      const variables = {
        userId: userID,
        accountId,
        rootLocationId: selectedLocationId,
        roles,
      };
      await editUserDetails({
        variables,
      });
      const notifConfig = {
        severity: ToastNotificationSeverityTypeEnum.INFO,
        title: `Edited details for User: ${fullUserName}`,
      };
      onModalClose();
      dispatchToast(notifConfig);
    } catch (error) {
      const notifConfig = {
        severity: ToastNotificationSeverityTypeEnum.ERROR,
        title: 'Error Editing User Details',
        message: 'Please try again',
      };
      dispatchToast(notifConfig);
      console.error('ERROR EDIT_USER_DETAILS_MUTATION: ', error);
    }
  };

  // This component by default renders in an open state. After the user action is completed
  // onModalClose is called, unMounting the parent ModalRenderer. This is necessary otherwise
  // unwanted state will persist.
  return (
    <Dialog open={true} onClose={onModalClose} fullWidth maxWidth='sm'>
      <DialogTitle sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', gap: 2 }}>
        <ManageAccountsIcon color='primary' fontSize='large' />
        <Typography fontWeight='bold' fontSize={24}>
          Edit User: {fullUserName}
        </Typography>
      </DialogTitle>
      <IconButton
        onClick={onModalClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          // eslint-disable-next-line no-magic-numbers
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Stack spacing={3}>
          <Stack spacing={1}>
            <Typography fontSize={16}>Edit this users permissions</Typography>
            <RoleSelectionInput
              setIsEdited={setIsEdited}
              selectedRole={selectedRole}
              setSelectedRole={setSelectedRole}
            />
          </Stack>
          <Stack spacing={1}>
            <Typography fontSize={16}>Edit this users root location</Typography>
            <Box display='flex' mt={2} p={1} bgcolor='background.paper' borderRadius={1}>
              {isListLoading ? (
                <CircularProgress color='inherit' sx={{ alignSelf: 'center' }} />
              ) : (
                <LocationSelectorBreadcrumb
                  onLocationSelectCallback={(id) => {
                    setSelectedLocationId(id);
                    setIsEdited(true);
                  }}
                  dontUpdateGlobal
                  preLoadedLocationList={initLocationList}
                />
              )}
            </Box>
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ mx: 2 }}>
        <Stack direction='row' spacing={2} pb={2}>
          <Button color='error' onClick={onModalClose}>
            Cancel
          </Button>
          <Button
            disabled={!isEdited}
            color='primary'
            onClick={(e) => (isMutationInFlight ? e.preventDefault() : onSubmit())}
            endIcon={isMutationInFlight && <CircularProgress color='inherit' size={16} />}
          >
            Save Changes
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}
