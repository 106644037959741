import { useTheme, Card, CardHeader, Avatar } from '@mui/material';
import { deviceMap } from 'Components/AuthedPages/DevicesListPage/PageViews/DevicesListFilters';
interface DeviceTypeCardProps {
  deviceType?: string | null;
}
export default function DeviceTypeCard({ deviceType }: DeviceTypeCardProps) {
  const theme = useTheme();
  return (
    <Card sx={{ height: '100%' }}>
      <CardHeader
        avatar={
          <Avatar aria-label='device-type' sx={{ bgcolor: 'transparent' }}>
            <img
              src={deviceMap[deviceType ?? ''].icon[theme.palette.mode]}
              width='25'
              height='25'
              alt={deviceMap[deviceType ?? ''].value}
              style={{
                objectFit: 'contain',
              }}
            />
          </Avatar>
        }
        title='Device Type'
        titleTypographyProps={{ variant: 'body2', fontWeight: 500 }}
        subheader={deviceMap[deviceType ?? ''].value ?? 'Unknown'}
      />
    </Card>
  );
}
