/* eslint-disable no-nested-ternary */
import { Alert, AlertTitle, Button, CircularProgress } from '@mui/material';

import { useCallback } from 'react';

import { ModalNotificationTypeEnum, useModal } from 'Providers/ModalProvider';

import { useLazyQuery, useMutation } from '@apollo/client';
import type {
  GetNewFileUploadUrlQuery,
  GetNewFileUploadUrlQueryVariables,
  UpdateLocationFloorPlanImageMutation,
  UpdateLocationFloorPlanImageMutationVariables,
} from '__generated__/graphql';
import { ToastNotificationSeverityTypeEnum, useToast } from 'Providers/ToastProvider';

import {
  GET_FILE_UPLOAD_URL,
  getFormattedFileType,
  uploadImageToS3,
} from 'Components/AuthedPages/OrgDetailsPage/OrgProfilePhotoRendererContainer';
import { UPDATE_FLOOR_PLAN } from 'Components/AuthedPages/OrgLocationEditorPage/LocationDetaillsCard';

export default function MissingFloorPlan({
  selectedOrgId,
  selectedLocationId,
  onUpdateFloorPlan,
}: {
  selectedOrgId: string;
  selectedLocationId: string;
  onUpdateFloorPlan: () => void;
}) {
  const { dispatchModal } = useModal();
  const { dispatchToast } = useToast();
  const [getFileUploadUrl] = useLazyQuery<
    GetNewFileUploadUrlQuery,
    GetNewFileUploadUrlQueryVariables
  >(GET_FILE_UPLOAD_URL);
  const [updateFloorPlanImage, { loading: floorPlanUpdateLoading }] = useMutation<
    UpdateLocationFloorPlanImageMutation,
    UpdateLocationFloorPlanImageMutationVariables
  >(UPDATE_FLOOR_PLAN);
  const uploadAndUpdateLocationFloorPlan = useCallback(
    async (file: File) => {
      try {
        const fileUploadDataRes = await getFileUploadUrl({
          variables: {
            input: {
              accountId: selectedOrgId,
              filesizeBytes: file?.size,
              filetype: getFormattedFileType(file?.type ?? ''),
            },
          },
        });
        const uploadURL = fileUploadDataRes?.data?.getNewFileUploadUrl?.uploadUrl ?? '';
        const newS3FileID = fileUploadDataRes?.data?.getNewFileUploadUrl?.fileId ?? '';
        await uploadImageToS3(uploadURL, file);
        await updateFloorPlanImage({
          variables: {
            accountId: selectedOrgId,
            locationId: selectedLocationId as string,
            floorPlanImageId: newS3FileID,
          },
        });
        dispatchToast({
          severity: ToastNotificationSeverityTypeEnum.SUCCESS,
          title: 'Floor Plan Updated',
          message: 'You have successfully updated your floor plan',
        });
      } catch (error: unknown) {
        dispatchToast({
          severity: ToastNotificationSeverityTypeEnum.ERROR,
          title: 'Error Updating Floor Plan',
          message: 'Please try again',
        });
      } finally {
        onUpdateFloorPlan();
      }
    },
    [
      dispatchToast,
      getFileUploadUrl,
      onUpdateFloorPlan,
      selectedLocationId,
      selectedOrgId,
      updateFloorPlanImage,
    ]
  );
  return (
    <Alert
      severity='warning'
      action={
        <Button
          color='inherit'
          onClick={() =>
            dispatchModal({
              type: ModalNotificationTypeEnum.IMAGE_UPLOAD_MODAL,
              modalProps: {
                title: 'Add Floor Plan',
                imageUploadCallBack: uploadAndUpdateLocationFloorPlan,
              },
            })
          }
          endIcon={floorPlanUpdateLoading && <CircularProgress size={16} />}
        >
          Add floor plan
        </Button>
      }
      sx={{
        '& .MuiAlert-action': {
          alignItems: 'center',
        },
      }}
    >
      <AlertTitle>
        Current location does not have a <b>Floor Plan</b>
      </AlertTitle>
      Please add a <b>Floor Plan</b>
    </Alert>
  );
}
