/* eslint-disable no-magic-numbers */
import { Box, CircularProgress, IconButton, Paper, Stack, Tooltip } from '@mui/material';
import { useState } from 'react';

import { CameraAlt as CameraAltIcon, Delete as DeleteIcon } from '@mui/icons-material';

type Props = {
  imageURL: string | null | undefined;
  callBack: () => void;
  onDelete?: () => void;
  loading?: boolean;
  userHasEditPermission?: boolean;
  size?: 'small' | 'medium' | 'large' | 'xlarge' | '2xlarge';
};

const getImageSize = (size: string): number => {
  switch (size) {
    case 'small':
      return 100;
    case 'medium':
      return 150;
    case 'large':
      return 200;
    case 'xlarge':
      return 300;
    case '2xlarge':
      return 400;
    default:
      return 150;
  }
};

export default function ImageRendererWithUpload({
  imageURL,
  callBack,
  onDelete,
  userHasEditPermission = false,
  loading = false,
  size = 'medium',
}: Props) {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  let toolTipTitle: string;
  if (userHasEditPermission) {
    toolTipTitle = imageURL ? 'Change photo' : 'Upload photo';
  } else {
    toolTipTitle = 'Edit permission required to modify photo';
  }

  const dim = getImageSize(size);

  return (
    <Box
      width={dim}
      height={dim}
      component={Paper}
      display='flex'
      alignItems='center'
      justifyContent='center'
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{ position: 'relative' }}
      bgcolor={isHovered && userHasEditPermission ? 'rgba(0, 0, 0, 0.3)' : ''}
    >
      {imageURL && <Box component='img' height={dim - 20} width={dim - 20} src={imageURL} />}
      <Stack
        direction='row'
        alignItems='center'
        spacing={1}
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        {loading && <CircularProgress size={60} color='primary' />}
        <IconButton
          color='primary'
          sx={{
            display: !loading && userHasEditPermission ? 'block' : 'none',
          }}
          onClick={callBack}
        >
          <Tooltip title={toolTipTitle}>
            <CameraAltIcon fontSize='large' color='inherit' />
          </Tooltip>
        </IconButton>
        <IconButton
          color='error'
          sx={{
            display:
              !loading && imageURL && isHovered && userHasEditPermission && onDelete
                ? 'block'
                : 'none',
          }}
          onClick={onDelete}
        >
          <Tooltip title='Delete Floor Plan'>
            <DeleteIcon fontSize='large' color='inherit' />
          </Tooltip>
        </IconButton>
      </Stack>
    </Box>
  );
}
