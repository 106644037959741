import { useAuthenticator } from '@aws-amplify/ui-react';
import { apolloClient } from 'Apollo/ApolloConfig';
import { cache } from 'Apollo/ApolloCache';
import { useSearchParams } from 'react-router-dom';
import { useEffect, useCallback, useMemo } from 'react';

const BROADCAST_CHANNEL_NAME = 'logout_sync';
interface LogoutMessage {
  type: 'LOGOUT';
  timestamp: number;
}

const clearApolloCache = () => {
  apolloClient.resetStore().then(() => {
    cache.reset();
  });
};

const clearLocalStorage = () => {
  localStorage.clear();
};

const reloadWindow = () => {
  window.location.reload();
};

export function useSignOut() {
  const { signOut } = useAuthenticator((context) => [context.route]);
  const [_, setSearchParams] = useSearchParams();

  const bc = useMemo(() => {
    return new BroadcastChannel(BROADCAST_CHANNEL_NAME); // To logout across browser sessions on the same device
  }, []);

  const handleSignOut = useCallback(() => {
    signOut();
    setSearchParams({}, { replace: true });
    clearLocalStorage();
    clearApolloCache();
    reloadWindow();
    bc?.postMessage({
      type: 'LOGOUT',
      timestamp: Date.now(),
    } as LogoutMessage);
  }, [setSearchParams, signOut, bc]);

  useEffect(() => {
    if (!bc) return;
    function logoutMessageEvent(event: MessageEvent<LogoutMessage>) {
      const isExternal = event.source !== bc;
      if (isExternal && event.data.type === 'LOGOUT') {
        handleSignOut();
        bc.close();
      }
    }
    bc.addEventListener('message', logoutMessageEvent);
    return () => {
      bc.removeEventListener('message', logoutMessageEvent);
    };
  }, [bc, handleSignOut]);

  return handleSignOut;
}
