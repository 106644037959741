import { useTheme, Card, CardHeader, Avatar, CardContent, Typography } from '@mui/material';
import { DeviceHubOutlined } from '@mui/icons-material';
import { blue } from '@mui/material/colors';
import { DeviceQuery } from '__generated__/graphql';
import moment from 'moment';
import { alpha } from '@mui/material/styles';

interface DeviceFirmwareCardProps {
  deviceFirmware?: NonNullable<DeviceQuery['device']>['firmwareVersion'];
}

export default function DeviceFirmwareCard({ deviceFirmware }: DeviceFirmwareCardProps) {
  const theme = useTheme();
  const catalog = {
    '1': {
      ariaLabel: 'upto-date',
      Icon: DeviceHubOutlined,
      color: theme.palette.success.dark,
    },
    '0': {
      ariaLabel: 'update-in-progress',
      Icon: DeviceHubOutlined,
      // eslint-disable-next-line no-magic-numbers
      color: blue[500],
    },
  };
  const state = catalog[deviceFirmware?.pendingValue ? '0' : '1'];

  return (
    <Card
      sx={{
        height: '100%',
        borderLeft: `.5rem solid ${state.color}`,
        // eslint-disable-next-line no-magic-numbers
        bgcolor: alpha(state.color, 0.2),
      }}
    >
      <CardHeader
        avatar={
          <Avatar sx={{ bgcolor: state.color }} aria-label={state.ariaLabel}>
            <state.Icon color='inherit' sx={{ height: 28, width: 28 }} />
          </Avatar>
        }
        title='Firmware'
        titleTypographyProps={{ variant: 'body2', fontWeight: 500 }}
        subheader={`Since ${moment(deviceFirmware?.timestamp).fromNow()}`}
      />
      <CardContent>
        <Typography variant='h5' color={state.color} fontWeight={500}>
          {deviceFirmware?.value}
        </Typography>
        {!!deviceFirmware?.pendingValue ?? (
          <Typography variant='body1' color={state.color}>
            Update in Progress {deviceFirmware?.pendingValue}
          </Typography>
        )}
      </CardContent>
    </Card>
  );
}
