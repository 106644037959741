import { AlertMetric } from '__generated__/graphql';

export const getAlertText = (metric?: AlertMetric): string | undefined => {
  switch (metric) {
    case AlertMetric.Humidity:
      return 'Relative Humidity';
    default:
      return metric?.toString();
  }
};
