import { useState, useCallback, Fragment, useMemo } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Stack,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TableContainer,
  TableHead,
  Table,
  TableRow as TableRowRaw,
  TableCell,
  TableBody,
  Box,
  CircularProgress,
  Tooltip,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  Check as CheckIcon,
  ErrorOutline as ErrorIcon,
  ExpandMore as ExpandMoreIcon,
  ArrowForward as ArrowForwardIcon,
} from '@mui/icons-material';

import { DeviceOtaEvent, Maybe } from '__generated__/graphql';
import { DateRangeEnum } from 'Components/SharedUI/DateRangePicker';
import { ToastNotificationSeverityTypeEnum, useToast } from 'Providers/ToastProvider';
import moment from 'moment-timezone';
import debounce from 'debounce';
const DEBOUNCE_INTERVAL = 700;
const TableRow = styled(TableRowRaw)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },

  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));
interface DeviceConnectivityEventsProps {
  events?: Maybe<Array<Maybe<DeviceOtaEvent>>>;
  getEvents: (interval: DateRangeEnum, include: boolean) => Promise<void>;
}
export default function DeviceConnectivityEvents({
  events,
  getEvents,
}: DeviceConnectivityEventsProps) {
  const [loading, setLoading] = useState(false);
  const [interval, setInterval] = useState(DateRangeEnum.PAST_HOUR);
  const { dispatchToast } = useToast();
  const fetchEventsHandler = useCallback(
    async (interval: DateRangeEnum, include: boolean) => {
      try {
        setLoading(true);
        setInterval(interval);
        await getEvents(interval, include);
      } catch (err: unknown) {
        dispatchToast({
          severity: ToastNotificationSeverityTypeEnum.WARNING,
          title: 'Could not get OTA events',
        });
      } finally {
        setLoading(false);
      }
    },
    [setLoading, getEvents, dispatchToast]
  );
  const debouncedFetchEventsHandler = useMemo(
    () => debounce(fetchEventsHandler, DEBOUNCE_INTERVAL),
    [fetchEventsHandler]
  );
  return (
    <Accordion
      onChange={(e, expanded) =>
        loading ? e.preventDefault() : debouncedFetchEventsHandler(interval, expanded)
      }
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls='ota-events-content'
        id='ota-events-header'
      >
        <Typography variant='body1'>Recent OTA Events</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Stack width='100%' gap={2}>
          <FormControl fullWidth disabled={loading}>
            <InputLabel id='time-period'>Time Period</InputLabel>
            <Select
              label='time-period'
              id='time-period-selector'
              value={interval}
              onChange={(e) => debouncedFetchEventsHandler(e.target.value as DateRangeEnum, true)}
            >
              <MenuItem value={DateRangeEnum.PAST_HOUR}>Past hour</MenuItem>
              <MenuItem value={DateRangeEnum.PAST_DAY}>Past 24 hours</MenuItem>
              <MenuItem value={DateRangeEnum.PAST_THREE_DAYS}>Past 3 days</MenuItem>
              <MenuItem value={DateRangeEnum.ALL_TIME}>All time</MenuItem>
            </Select>
          </FormControl>
          {loading ? (
            <Box width='100%' display='flex' justifyContent='center'>
              <CircularProgress size={18} />
            </Box>
          ) : (
            <TableContainer sx={{ maxHeight: 400, overflowY: 'auto' }}>
              <Table>
                <TableHead>
                  <TableRowRaw>
                    <TableCell>Start</TableCell>
                    <TableCell>End</TableCell>
                    <TableCell>Firmware Update</TableCell>
                  </TableRowRaw>
                </TableHead>
                <TableBody>
                  {events && events.length > 0 ? (
                    <Fragment>
                      {events.map((event) => (
                        <TableRow key={event?._id}>
                          <TableCell>
                            {moment(event?.utcStartTime).format('MMM DD, YYYY, hh:mm:ss A')}
                          </TableCell>
                          <TableCell>
                            {moment(event?.utcEndTime).format('MMM DD, YYYY, hh:mm:ss A')}
                          </TableCell>
                          <TableCell sx={{ '&:hover': { cursor: 'pointer' } }}>
                            <Tooltip
                              sx={{ width: 'min-content' }}
                              title={event?.error ? event.errorMessage : void 0}
                              placement='right'
                            >
                              <Box
                                width='100%'
                                display='flex'
                                justifyContent='center'
                                alignItems='center'
                                gap={2}
                              >
                                <Box whiteSpace='nowrap' component='span'>
                                  {event?.prevFirmwareVersion}
                                </Box>
                                <ArrowForwardIcon fontSize='small' />
                                <Box whiteSpace='nowrap' component='span'>
                                  {event?.newFirmwareVersion}
                                </Box>
                                {!event?.error ? (
                                  <CheckIcon color='success' fontSize='small' />
                                ) : (
                                  <ErrorIcon color='error' fontSize='small' />
                                )}
                              </Box>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      ))}
                    </Fragment>
                  ) : (
                    <TableRowRaw>
                      <TableCell colSpan={3}>
                        <Box width='100%' p={2} textAlign='center'>
                          <Typography
                            sx={{
                              fontWeight: 500,
                            }}
                            variant='body1'
                          >
                            There are no events that match your current filters
                          </Typography>
                          <Typography variant='body2'>
                            Modify the selected filters to find more events
                          </Typography>
                        </Box>
                      </TableCell>
                    </TableRowRaw>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
}
