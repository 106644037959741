import { RoleEnum } from 'Constants/OrgSettingsEnums';
const { ADMIN, READ_ONLY, STANDARD_USER } = RoleEnum;

const permissionConfigMap = {
  [ADMIN]: {
    account_read: true,
    account_write: true,
    location_read: true,
    location_write: true,
    device_read: true,
    device_write: true,
    users_read: true,
    users_write: true,
    report_read: true,
    report_write: true,
  },
  [READ_ONLY]: {
    account_read: true,
    account_write: false,
    location_read: true,
    location_write: false,
    device_read: true,
    device_write: false,
    users_read: true,
    users_write: false,
    report_read: true,
    report_write: false,
  },
  [STANDARD_USER]: {
    account_read: true,
    account_write: false,
    location_read: true,
    location_write: true,
    device_read: true,
    device_write: true,
    users_read: true,
    users_write: false,
    report_read: true,
    report_write: false,
  },
};

export const getPermissionConfig = (type: RoleEnum | null) => {
  if (!type) {
    return null;
  }
  return permissionConfigMap[type];
};
