import React from 'react';
import { Box, Typography, Switch } from '@mui/material';
import { useReactiveVar } from '@apollo/client';
import { appThemeVar, toggleAppThemeVar, darkMode } from 'Apollo/ApolloCache';

export default function PaletteModeCard() {
  const theme = useReactiveVar(appThemeVar);
  const paletteMode = theme.palette.mode;

  return (
    <Box
      sx={{
        display: 'flex',
        direction: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
      width={200}
    >
      <Typography sx={{ fontSize: 16 }}>Dark Theme</Typography>
      <Switch
        id='palleteModeButton'
        onClick={toggleAppThemeVar}
        checked={paletteMode === darkMode}
      />
    </Box>
  );
}
