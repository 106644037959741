import { Box, Typography, Button } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { formatPageTitle } from 'Utils/formatPageTitle';
import RoutePaths from 'Constants/RoutePaths';

const { PATH_HOME } = RoutePaths;
const pageTitle = formatPageTitle(['Page Not Found']);

export default function ErrorPageNotFound() {
  return (
    <Box
      flexDirection='column'
      display='flex'
      justifyContent='center'
      alignItems='center'
      marginTop={10}
    >
      <Helmet defer={false}>
        <title>{pageTitle}</title>
      </Helmet>
      <Typography variant='h1'>404</Typography>
      <Typography variant='h4'>Page not found</Typography>
      <Button
        component={RouterLink}
        to={PATH_HOME}
        color='primary'
        sx={{ marginTop: 3 }}
        variant='contained'
      >
        Go to home Page
      </Button>
    </Box>
  );
}
