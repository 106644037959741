/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useMemo } from 'react';
import { Bar, type ChartProps } from 'react-chartjs-2';
import MediumMetricsCard from 'Components/MetricCards/MediumMetricsCard';
import { metricCardIconColor } from 'Constants/OverviewConsts';
import { useReactiveVar } from '@apollo/client';
import {
  type ConsumableExpirationGroupedDevices,
  GroupByInterval,
  type Maybe,
} from '__generated__/graphql';
import { Chart } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
Chart.register(annotationPlugin);

const CHART_ID = 'device-consumable-expiration-chart';
import AirIcon from '@mui/icons-material/Air';
import { Typography, useMediaQuery, useTheme } from '@mui/material';
import { selectedLocationVar } from 'Apollo/ApolloCache';
import { GridConfigType } from 'Components/MetricCards/QuickMetricsCard';
import moment from 'moment-timezone';

type Props = {
  devicesConsumableExpiration?: Maybe<Array<ConsumableExpirationGroupedDevices>>;
  gridConfig?: GridConfigType;
  groupByInterval: GroupByInterval;
  selectedBar?: number;
  onBarSelect: (bar: number) => void;
  isLoading?: boolean;
};

export default function DeviceMaintenanceBar({
  devicesConsumableExpiration,
  isLoading,
  groupByInterval,
  selectedBar,
  onBarSelect,
  gridConfig,
}: Props) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const selectedLocation = useReactiveVar(selectedLocationVar);
  const chartOptions: ChartProps<'bar'>['options'] | null = useMemo(() => {
    if (!devicesConsumableExpiration) return null;
    return {
      responsive: true,
      maintainAspectRatio: false,
      elements: {
        bar: {
          borderRadius: 5,
        },
      },
      scales: {
        x: {
          type: 'time' as const,
          time: {
            unit:
              groupByInterval === GroupByInterval.Month ? ('month' as const) : ('quarter' as const),
          },
          grid: {
            display: false,
          },
          ticks: {
            display: true,
          },
          bounds: 'ticks',
        },
        y: {
          display: false,
          grid: {
            display: false,
          },
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          callbacks: {
            title: (context) => moment(context.at(0)?.parsed.x).format('MMMM Do YYYY'),
            label: (context) => `${context.formattedValue} Devices`,
          },
        },
      },
      onHover: (e, chartElement) => {
        if (e.native?.target) {
          if (chartElement.length > 0) {
            // @ts-ignore style should always be present
            e.native.target.style.cursor = 'pointer';
            // e.native.target.style.cursor = 'pointer';
          } else {
            // @ts-ignore style should always be present
            e.native.target.style.cursor = '';
          }
        }
      },
      onClick: (_, chartElement) => {
        const firstEl = chartElement.at(0);
        if (!firstEl) {
          return;
        }
        onBarSelect(firstEl.index);
      },
    };
  }, [devicesConsumableExpiration, groupByInterval, onBarSelect]);
  const chartData: ChartProps<'bar'>['data'] | null = useMemo(() => {
    if (!devicesConsumableExpiration) return null;
    const data = devicesConsumableExpiration.map((i) => ({
      x: new Date(i.timestamp).valueOf(),
      y: i.devices ? i.devices.length : 0,
    }));
    const backgroundColors = devicesConsumableExpiration?.map((_, index) =>
      index === selectedBar ? '#51ba38' : '#d7d7d7'
    );
    return {
      labels: data.map((d) => d.x),
      datasets: [
        {
          data: data.map((d) => d.y),
          backgroundColor: backgroundColors,
          hoverBackgroundColor: !isMobile ? '#7f7f7f' : void 0,
          borderColor: 'transparent',
          hoverBorderColor: !isMobile ? '#7f7f7f' : void 0,
          // eslint-disable-next-line no-magic-numbers
          borderWidth: !isMobile ? 3 : void 0,
        },
      ],
    };
  }, [devicesConsumableExpiration, selectedBar, isMobile]);

  return (
    <MediumMetricsCard
      isLoading={isLoading}
      icon={<AirIcon sx={{ color: metricCardIconColor }} />}
      title='Consumable Expiration Forecast'
      infoText='Devices which need a consumable replacement because of expiration'
      gridConfig={gridConfig}
    >
      {chartData && chartOptions ? (
        <Bar id={CHART_ID} plugins={[annotationPlugin]} data={chartData} options={chartOptions} />
      ) : (
        <Typography alignContent='center' color='InactiveCaptionText'>
          No data available for <b>{selectedLocation?.name ?? 'All Locations'}</b>
        </Typography>
      )}
    </MediumMetricsCard>
  );
}
