import InfoIcon from '@mui/icons-material/Info';
import {
  useTheme,
  Box,
  CircularProgress,
  Grid,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { metricCardIconColor } from 'Constants/OverviewConsts';
import { ReactNode } from 'react';
import { GridConfigType } from './QuickMetricsCard';
import { Error as ErrorIcon } from '@mui/icons-material';

const DEFAULT_CARD_HEIGHT = 325;

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  icon?: any;
  title?: string;
  children?: ReactNode;
  isLoading?: boolean;
  infoText?: string;
  additionalText?: string | null | undefined;
  height?: number | string;
  gridConfig?: GridConfigType;
  error?: boolean;
};

export default function MediumMetricsCard({
  additionalText,
  children,
  icon,
  title,
  infoText,
  isLoading = false,
  height = DEFAULT_CARD_HEIGHT,
  gridConfig = {
    xs: 12,
    sm: 12,
    md: 12,
    lg: 6,
    xl: 8,
  },
  error = false,
}: Props) {
  const theme = useTheme();
  return (
    <Grid item {...gridConfig}>
      <Box
        sx={{
          borderWidth: 2,
          borderStyle: 'solid',
          borderColor: error ? theme.palette.error.main : 'transparent',
        }}
        display='flex'
        component={Paper}
        elevation={0}
        flexDirection='column'
        alignItems='space-between'
        height={height}
        width='100%'
        padding={2}
        gap={2}
      >
        <Stack alignItems='center' direction='row' justifyContent='space-between'>
          <Stack alignItems='center' direction='row' gap={1.5}>
            {icon ?? null}
            <Typography fontSize={16} color={error ? theme.palette.error.main : 'inherit'}>
              {title}
            </Typography>
          </Stack>
          {error ? (
            <Tooltip title='An error occurred. Try selecting a new location or requesting data over a smaller range.'>
              <ErrorIcon sx={{ color: theme.palette.error.main }} />
            </Tooltip>
          ) : (
            infoText && (
              <Stack alignItems='center' direction='row' gap={1.5}>
                {additionalText ? (
                  <Typography fontSize={16} fontWeight='bold'>
                    {additionalText}
                  </Typography>
                ) : null}
                <Tooltip title={infoText}>
                  <InfoIcon sx={{ color: metricCardIconColor }} />
                </Tooltip>
              </Stack>
            )
          )}
        </Stack>
        <Stack flexDirection='column' justifyContent='center' alignItems='center' height='90%'>
          {isLoading ? <CircularProgress size={75} /> : children}
        </Stack>
      </Box>
    </Grid>
  );
}
