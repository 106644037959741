/* eslint-disable no-magic-numbers */
import { Close as CloseIcon } from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { ReactNode } from 'react';
import { Link as RouterLink } from 'react-router-dom';
interface Props {
  onModalClose: () => void;
  modalProps?: unknown;
}

interface OverviewInfoProps {
  title: string;
  info: string;
  link: string | undefined;
  icon?: ReactNode;
}

export default function OverviewInfoModal({ onModalClose, modalProps }: Props) {
  const { title, info, link, icon } = modalProps as OverviewInfoProps;

  return (
    <Dialog open={true} onClose={onModalClose} fullWidth maxWidth='sm'>
      <DialogTitle>
        <Stack alignItems='center' direction='row' gap={2}>
          {icon ?? null}
          {title}
        </Stack>
      </DialogTitle>
      <IconButton
        onClick={onModalClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Typography mb={2} variant='body1'>
          {info}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Box width='100%' gap={1} display='flex' justifyContent='flex-end' alignItems='center'>
          {link && (
            <Button
              component={RouterLink}
              to={link}
              color='primary'
              onClick={onModalClose}
            >
              Explore {title} Data
            </Button>
          )}

          <Button color='error' onClick={onModalClose}>
            close
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}
