/* eslint-disable no-magic-numbers */

enum OCCUPANCY_GROUPS {
  MINIMAL = 'MINIMAL',
  LOW = 'LOW',
  MODERATE = 'MODERATE',
  HIGH = 'HIGH',
}

export const GROUP_PERC_THRESHOLD: [string, number][] = [
  ['MINIMAL', 0.25],
  ['LOW', 0.5],
  ['MODERATE', 0.75],
  ['HIGH', 1],
];

export const GROUPS = GROUP_PERC_THRESHOLD.map((v) => v[0].toString());

export function getOccupancyToGroup(occupancyPerc: number) {
  for (let i = 0; i < GROUP_PERC_THRESHOLD.length; ++i) {
    if (GROUP_PERC_THRESHOLD[i][1] >= occupancyPerc) {
      return GROUP_PERC_THRESHOLD[i][0];
    }
  }
  return OCCUPANCY_GROUPS.MINIMAL;
}
