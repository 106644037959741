/* eslint-disable no-magic-numbers */
import { gql, useMutation, useQuery } from '@apollo/client';
import { Close as CloseIcon } from '@mui/icons-material';
import {
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { ToastNotificationSeverityTypeEnum, useToast } from 'Providers/ToastProvider';
import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';

import {
  CreateLocationMutation,
  CreateLocationMutationVariables,
  LocationArchetype,
} from '__generated__/graphql';
import { GET_ALL_SUB_LOCATIONS_QUERY } from 'Components/SharedUI/LocationSelector/LocationSelectorDropdown';
import { capitalize } from 'Utils/stringManipulation';

// Root option is removed as a user option.
// We do this as the root location gets assigned the ROOT archetype upon creation.
// The user should never have a reason to manually assign something as a ROOT archetype.
const archeTypeOptions = Object.values(LocationArchetype).filter(
  (archeType) => archeType !== LocationArchetype.Root
);

type Props = {
  onModalClose: () => void;
  modalProps?: unknown;
};

export const ADD_SUB_LOCATION_MUTATION = gql`
  mutation CreateLocation(
    $accountId: ID!
    $archetype: LocationArchetype
    $type: String!
    $name: String!
    $parentLocationId: ID!
    $tags: [String]
    $description: String
  ) {
    createLocation(
      accountId: $accountId
      archetype: $archetype
      type: $type
      name: $name
      parentLocationId: $parentLocationId
      tags: $tags
      description: $description
    ) {
      archetype
      id
      name
      type
      description
      tags
      numDevices
    }
  }
`;

export default function AddSubLocationModal({ onModalClose, modalProps }: Props) {
  const { accountId, selectedLocationID, onSuccess, allowedLocationArchetypes } = modalProps as {
    accountId: string;
    selectedLocationID: string;
    onSuccess: (newLocation: CreateLocationMutation['createLocation']) => void;
    allowedLocationArchetypes?: Array<LocationArchetype>;
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [addSubLocation, { loading: isLoading }] = useMutation<
    CreateLocationMutation,
    CreateLocationMutationVariables
  >(ADD_SUB_LOCATION_MUTATION);

  const { refetch: refetchGetAllSubLocations } = useQuery(GET_ALL_SUB_LOCATIONS_QUERY, {
    variables: {
      accountId,
      locationId: selectedLocationID,
    },
  });

  const { dispatchToast } = useToast();
  const [tagsList, setTagsList] = useState<Array<string>>([]);
  const [tagInput, setTagInput] = useState<string>('');
  const [selectedArchetype, setSelectedArchetype] = useState<LocationArchetype | string>('');

  const finalAllowedLocationArchetypes = useMemo(() => {
    if (!allowedLocationArchetypes) {
      return archeTypeOptions;
    }
    return allowedLocationArchetypes.filter((i) => i !== LocationArchetype.Root);
  }, [allowedLocationArchetypes]);

  const handleDelete = (targetIndex) => {
    const filteredList = tagsList.filter((tag, index) => targetIndex !== index);
    setTagsList(filteredList);
  };

  const handleSelectArchetype = (event) => {
    setSelectedArchetype(event.target.value);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && tagInput.length) {
      setTagsList([...tagsList, tagInput]);
      setTagInput('');
    }
  };
  const onSubmit = async (data) => {
    const { type, name, description } = data;
    try {
      const variables = {
        accountId,
        parentLocationId: selectedLocationID,
        name,
        type,
      };
      if (selectedArchetype !== '') {
        variables['archetype'] = selectedArchetype;
      }
      if (description && description.length) {
        variables['description'] = description;
      }
      if (tagsList.length) {
        variables['tags'] = tagsList;
      }
      const res = await addSubLocation({
        variables,
      });
      await refetchGetAllSubLocations();
      // await refetchGetLocationEditorData();
      onSuccess(res.data?.createLocation);

      const notifConfig = {
        severity: ToastNotificationSeverityTypeEnum.INFO,
        title: `Added new location: ${name}`,
      };
      onModalClose();
      dispatchToast(notifConfig);
    } catch (error) {
      const notifConfig = {
        severity: ToastNotificationSeverityTypeEnum.ERROR,
        title: 'Error adding sub-location',
        message: 'Please try again',
      };
      dispatchToast(notifConfig);
      console.error('ERROR ADD_SUB_LOCATION_MUTATION: ', error);
    }
  };

  return (
    <Dialog
      open={true}
      onClose={() => {
        onModalClose();
      }}
      fullWidth
      maxWidth='sm'
    >
      <DialogTitle>
        Add Sub-Location <br />{' '}
        <Typography color='text.secondary' variant='body2'>
          Allowed Archetypes{' '}
          <b>
            {finalAllowedLocationArchetypes
              .map((archT) => capitalize(archT.toLowerCase()))
              .join(', ')}
          </b>
        </Typography>
      </DialogTitle>
      <IconButton
        onClick={onModalClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Stack width='100%' gap={2}>
          <TextField
            {...register('name', { required: 'Name is required' })}
            label='Name'
            fullWidth
            error={!!errors.name}
            helperText={errors.name ? errors.name.message?.toString() : void 0}
          />

          <TextField
            label='Location Archetype'
            disabled={false}
            fullWidth
            value={selectedArchetype}
            onChange={handleSelectArchetype}
            select
            helperText='Optional'
          >
            <MenuItem value=''>NONE</MenuItem>
            {finalAllowedLocationArchetypes.map((archetype) => (
              <MenuItem key={archetype} value={archetype}>
                {archetype}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            {...register('type', { required: 'Type is required' })}
            label='Type'
            id='locationTypeInput'
            fullWidth
            error={!!errors.type}
            helperText={errors.type ? errors.type.message?.toString() : void 0}
          />
          <TextField
            {...register('description')}
            label='Description'
            fullWidth
            helperText='Optional'
          />
          <TextField
            label='Tags'
            fullWidth
            placeholder='Type and press enter to add new tag'
            InputProps={{
              startAdornment: tagsList.map((tag, index) => (
                <Chip
                  key={index}
                  label={tag}
                  onDelete={() => handleDelete(index)}
                  sx={{ marginTop: 1, marginLeft: 0.5 }}
                />
              )),
              style: {
                display: 'flex',
                flexWrap: 'wrap',
              },
            }}
            onKeyDown={handleKeyPress}
            onChange={(e) => setTagInput(e.target.value)}
            value={tagInput}
            helperText='Optional'
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack width='100%' direction='row' gap={1} justifyContent='flex-end'>
          <Button color='error' onClick={onModalClose}>
            Cancel
          </Button>
          <Button
            type='submit'
            color='primary'
            sx={{
              cursor: isLoading ? 'not-allowed' : 'inherit',
            }}
            onClick={(e) => (isLoading ? e.preventDefault() : handleSubmit(onSubmit)(e))}
            endIcon={isLoading && <CircularProgress size={16} />}
          >
            Add Location
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}
