import {
  DeviceHub,
  Help as HelpIcon,
  Map as MapIcon,
  MenuOpen as MenuClosedIcon,
  Dashboard as OverviewIcon,
  QueryStats as QueryStatsIcon,
} from '@mui/icons-material';
import {
  Button,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import ContinuumAppPageEnums from 'Constants/ContinuumAppPagesEnums';
import RoutePaths from 'Constants/RoutePaths';
import { ModalNotificationTypeEnum, useModal } from 'Providers/ModalProvider';
import { NavLink } from 'react-router-dom';
import OrganizationListItem from './Items/OrganizationListItem';

import { useReactiveVar } from '@apollo/client';
import { DeviceType } from '__generated__/graphql';
import { currentUserDataVar, selectedOrgVar } from 'Apollo/ApolloCache';
import UVAngelLogo from 'assets/UVAngel-wordmark-reversed.svg';
import ContinuumAppPagesEnum from 'Constants/ContinuumAppPagesEnums';
import AdminMenuListItem from './Items/AdminListItems';
import AnalysisListItem from './Items/AnalysisListItems';
import { canViewFeature, FeatureFlagFeatures } from 'Utils/FeatureFlags';

const { PATH_OVERVIEW, PATH_REPORTS, PATH_DEVICES, PATH_FLOORPLANS } = RoutePaths;

type SideBarItemConfig = {
  primaryText: string;
  handleClick: () => void;
  icon: JSX.Element;
  viewType: ContinuumAppPageEnums;
  path: string;
  exact?: boolean;
  disabled?: boolean;
  applicableDeviceTypes?: Array<DeviceType>;
};

type SideBarItemsProps = {
  config: SideBarItemConfig;
  currentPage: ContinuumAppPageEnums;
};

const { OVERVIEW_PAGE, REPORTS_PAGE, DEVICES_PAGE } = ContinuumAppPageEnums;

type SideBarProps = {
  currentPage: ContinuumAppPageEnums;
  width: number;
  handleToggleSideBar: () => void;
  isSideBarOpen: boolean;
  isMobileView: boolean;
};

export default function SideBar({
  width,
  currentPage,
  isSideBarOpen,
  handleToggleSideBar,
  isMobileView,
}: SideBarProps) {
  const theme = useTheme();
  const { dispatchModal } = useModal();
  const selectedOrg = useReactiveVar(selectedOrgVar);
  const currentUser = useReactiveVar(currentUserDataVar);
  const sideBarBackgroundColor = theme.palette.sidebar.main;

  const SidebarItem = ({ config }: SideBarItemsProps) => {
    const { primaryText, handleClick, icon, path } = config;
    return (
      <NavLink to={path} style={{ color: 'inherit', textDecoration: 'none' }} end>
        {({ isActive }) => (
          <ListItem
            disablePadding
            onClick={(e) => {
              if (config.disabled) {
                e.preventDefault();
              }
            }}
            sx={{
              cursor: config.disabled ? 'not-allowed' : 'pointer',
            }}
          >
            <ListItemButton disabled={config.disabled} selected={isActive} onClick={handleClick}>
              <ListItemIcon
                sx={{
                  color: 'white',
                }}
              >
                {icon}
              </ListItemIcon>
              <ListItemText
                primary={primaryText}
                sx={{
                  color: 'white',
                }}
                secondary={config.disabled ? 'Coming Soon' : null}
                secondaryTypographyProps={{
                  color: 'inherit',
                }}
              />
            </ListItemButton>
          </ListItem>
        )}
      </NavLink>
    );
  };
  const sideBarConfig: Array<SideBarItemConfig> = [
    {
      primaryText: 'Overview',
      handleClick: () => {
        handleToggleSideBar();
      },
      icon: <OverviewIcon />,
      viewType: OVERVIEW_PAGE,
      path: PATH_OVERVIEW,
      exact: true,
    },
    {
      primaryText: 'Data Explorer',
      handleClick: () => {
        handleToggleSideBar();
      },
      icon: <QueryStatsIcon />,
      viewType: REPORTS_PAGE,
      path: PATH_REPORTS,
      exact: true,
      applicableDeviceTypes: [DeviceType.Air20],
    },
    {
      primaryText: 'Devices',
      handleClick: () => {
        handleToggleSideBar();
      },
      icon: <DeviceHub />,
      viewType: DEVICES_PAGE,
      path: PATH_DEVICES,
      exact: false,
    },
    {
      primaryText: 'Floorplans',
      handleClick: () => {
        handleToggleSideBar();
      },
      icon: <MapIcon />,
      viewType: ContinuumAppPagesEnum.PAGE_NOT_FOUND,
      path: PATH_FLOORPLANS,
      disabled: !canViewFeature(FeatureFlagFeatures.FLOORPLANS, currentUser, selectedOrg),
      exact: true,
    },
  ]
    .filter((config) =>
      config.applicableDeviceTypes
        ? config.applicableDeviceTypes.some((i) => selectedOrg?.deviceTypes?.includes(i))
        : true
    );

  return (
    <Drawer
      ModalProps={{
        BackdropProps: {
          invisible: true,
        },
      }}
      open={isSideBarOpen}
      anchor='left'
      variant={!isMobileView ? 'permanent' : undefined}
    >
      <List
        sx={{
          width,
          height: 'auto',
          backgroundColor: sideBarBackgroundColor,
        }}
      >
        <ListItem disablePadding>
          <Stack gap={1} padding={2} direction='column' justifyContent='space-between' width='100%'>
            <img src={UVAngelLogo} alt='Image Alt Text' style={{ width: 150 }} />
            <Typography color={'white'} fontSize={14} fontWeight='bold'>
              UV Angel Cloud™
            </Typography>
            {isMobileView ? (
              <Button sx={{ color: 'white' }} onClick={handleToggleSideBar}>
                <MenuClosedIcon />
              </Button>
            ) : null}
          </Stack>
        </ListItem>
        <Divider
          sx={{
            backgroundColor: 'white',
            marginLeft: 2,
            marginRight: 2,
          }}
        />
      </List>
      <List
        sx={{
          width,
          height: '100%',
          backgroundColor: sideBarBackgroundColor,
          position: 'relative',
          overflow: 'auto',
        }}
      >
        {sideBarConfig.map((config, index) => {
          return <SidebarItem key={index} config={config} currentPage={currentPage} />;
        })}
        <AnalysisListItem handleToggleSideBar={handleToggleSideBar} currentPage={currentPage} />
        <OrganizationListItem handleToggleSideBar={handleToggleSideBar} currentPage={currentPage} />
      </List>
      <List
        sx={{
          width,
          height: 'auto',
          backgroundColor: sideBarBackgroundColor,
        }}
      >
        <Divider
          sx={{
            backgroundColor: 'white',
            marginLeft: 2,
            marginRight: 2,
          }}
        />
        <AdminMenuListItem handleToggleSideBar={handleToggleSideBar} currentPage={currentPage} />
        <ListItem sx={{ marginTop: 'auto' }} disablePadding>
          <ListItemButton
            onClick={() =>
              dispatchModal({
                type: ModalNotificationTypeEnum.EMAIL_SUPPORT_MODAL,
              })
            }
          >
            <ListItemIcon
              sx={{
                color: 'white',
              }}
            >
              <HelpIcon />
            </ListItemIcon>
            <ListItemText
              sx={{
                color: 'white',
              }}
              primary='Support'
            />
          </ListItemButton>
        </ListItem>
      </List>
    </Drawer>
  );
}
