/* eslint-disable no-magic-numbers */
import { useMutation } from '@apollo/client';
import { Close as CloseIcon } from '@mui/icons-material';
import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { useCallback, useState } from 'react';

import { ToastNotificationSeverityTypeEnum, useToast } from 'Providers/ToastProvider';

import {
  StopOutbreakResponseModeMutation,
  StopOutbreakResponseModeMutationVariables,
} from '__generated__/graphql';
import { OUTBREAK_STOP } from './graphql';
import { LOCATION_OUTBREAK_FRAGMENT } from 'fragments';

type ManageOutbreakModalProps = {
  onModalClose: () => void;
  modalProps: unknown;
};

export default function OutbreakStopModal({ modalProps, onModalClose }: ManageOutbreakModalProps) {
  const [isChecked, setIsChecked] = useState<boolean>(false);

  const { accountId, locationId, name, onActionSuccessful } = modalProps as {
    accountId: string;
    locationId: string;
    name: string;
    onActionSuccessful: () => void;
  };

  const [stopOutbreak, { loading: isStopMutationInFlight }] = useMutation<
    StopOutbreakResponseModeMutation,
    StopOutbreakResponseModeMutationVariables
  >(OUTBREAK_STOP, {
    update: (cache, _) => {
      cache.writeFragment({
        id: `Location:${locationId}`,
        fragment: LOCATION_OUTBREAK_FRAGMENT,
        data: {
          activeOutbreakMode: null,
        },
      });
    },
  });
  const { dispatchToast } = useToast();

  const handleOutbreakAction = useCallback(async () => {
    if (isStopMutationInFlight) {
      return;
    }

    try {
      const variables = {
        accountId,
        locationId,
      };

      await stopOutbreak({
        variables,
      });
      dispatchToast({
        severity: ToastNotificationSeverityTypeEnum.INFO,
        title: `Stopped Outbreak response in Location: ${name}`,
      });

      onModalClose();
      onActionSuccessful();
    } catch (error) {
      console.error('OutbreakStopModal -> handleOutbreakAction', error);
      dispatchToast({
        severity: ToastNotificationSeverityTypeEnum.ERROR,
        title: 'Error Stopping Outbreak Response',
        message: 'Please try again',
      });
    }
  }, [
    accountId,
    dispatchToast,
    isStopMutationInFlight,
    locationId,
    name,
    onActionSuccessful,
    onModalClose,
    stopOutbreak,
  ]);

  return (
    <Dialog
      open={true}
      onClose={() => {
        onModalClose();
      }}
      fullWidth
      maxWidth='sm'
    >
      <DialogTitle>
        Outbreak Response{' '}
        <Typography variant='body1' color='text.secondary' fontWeight={500}>
          Deactivate
        </Typography>
      </DialogTitle>
      <IconButton
        onClick={onModalClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Stack spacing={2}>
          <Typography>
            You are about to deactivate the outbreak response in location <b>{name}</b>.
          </Typography>
          <Typography>
            This action will cause all devices in this area to revert to their default settings. Are
            you sure you want to proceed?
          </Typography>
        </Stack>

        <FormControlLabel
          control={<Checkbox checked={isChecked} onChange={() => setIsChecked(!isChecked)} />}
          label='Yes, Deactivate'
        />
      </DialogContent>
      <Divider style={{ width: '100%' }} />
      <DialogActions>
        <Stack alignItems='center' justifyContent='flex-end' direction='row' gap={1}>
          <Button onClick={onModalClose} color='info'>
            Cancel
          </Button>
          <Button
            disabled={!isChecked || isStopMutationInFlight}
            onClick={handleOutbreakAction}
            color='error'
            endIcon={isStopMutationInFlight && <CircularProgress size={16} />}
          >
            Stop
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}
