/* eslint-disable @typescript-eslint/no-namespace */
import React from 'react';
import { Box, Grid, Divider, Typography } from '@mui/material';
import { useReactiveVar } from '@apollo/client';
import { currentUserDataVar } from 'Apollo/ApolloCache';
import { useNavigate } from 'react-router-dom';
import RoutePaths from 'Constants/RoutePaths';
import PageContainerFrame from 'Components/HOC/PageContainerFrame';
import OrgDisplayAndSwitcher from 'Components/SharedUI/OrgDisplayAndSwitcher';
import type { SelectedOrgType } from 'Apollo/ApolloCache';

const { PATH_USER_ACCESS_DENIED } = RoutePaths;

// Type definition for stripe-pricing-table component
// Otherwise typescript will not recognize it
declare global {
  namespace JSX {
    interface IntrinsicElements {
      'stripe-pricing-table': React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
      >;
    }
  }
}
type Props = {
  selectedOrg: SelectedOrgType;
};

export default function OrgSubscriptionPricingPage({ selectedOrg }: Props) {
  const hasWriteAccess = selectedOrg?.userAccountScope?.roles?.account_write;
  const navigate = useNavigate();

  if (!hasWriteAccess) {
    navigate(PATH_USER_ACCESS_DENIED);
  }
  const selectedOrgID = selectedOrg?.id;
  const currentUser = useReactiveVar(currentUserDataVar);
  const { email } = currentUser?.currentUser ?? {};

  return (
    <PageContainerFrame pageTitles={['Subscription Pricing', selectedOrg?.name]}>
      <Grid item>
        <Box display='flex' flexDirection='column' gap={2}>
          <Typography color='inherit' variant='h4'>
            Organization Subscription
          </Typography>
          <Divider />
          <Typography variant='h6'>
            {hasWriteAccess
              ? 'View and manage subscription settings'
              : 'View subscription settings for a particular organization'}
          </Typography>
          <Box>
            <OrgDisplayAndSwitcher />
          </Box>
        </Box>
      </Grid>
      <stripe-pricing-table
        pricing-table-id='prctbl_1NOQmTFTn8YrcX5NxZ3EwaH0'
        publishable-key='pk_test_51J4tObFTn8YrcX5N8jVA5bXp3YFr52vUvwO2nYO4xg2FhTtHM5OaxD0KIj8ahUT303unkpuXP1HDhyPft2cdrwrJ00MdPHz7Sg'
        client-reference-id={selectedOrgID}
        customer-email={email}
        allow-top-navigation
      />
    </PageContainerFrame>
  );
}
