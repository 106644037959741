/* eslint-disable no-magic-numbers */
import { useState, MouseEvent, useMemo, useCallback, Fragment } from 'react';
import {
  useTheme,
  Card,
  CardContent,
  CardHeader,
  Avatar,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Slider,
  CardActions,
  Button,
  Box,
  Tooltip,
  CircularProgress,
} from '@mui/material';
import { Air20_Device_Modes as Air20DeviceMode, IntShadowValue } from '__generated__/graphql';
import { SpeedOutlined as SpeedIcon, MoreVert as MoreVertIcon } from '@mui/icons-material';
import moment from 'moment';
import { useToast, ToastNotificationSeverityTypeEnum } from 'Providers/ToastProvider';

interface DeviceModeCardProps {
  deviceMode?: IntShadowValue | null;
  update: (mode: Air20DeviceMode) => Promise<void>;
}

const LABELS = ['Standby', 'Speed 1', 'Speed 2', 'Speed 3', 'Speed 4', 'Speed 5'];
const ALL_MODES = [
  Air20DeviceMode.AirsysStandby,
  Air20DeviceMode.AirsysSpeed_1,
  Air20DeviceMode.AirsysSpeed_2,
  Air20DeviceMode.AirsysSpeed_3,
  Air20DeviceMode.AirsysSpeed_4,
  Air20DeviceMode.AirsysSpeed_5,
  Air20DeviceMode.AirsysDynamic,
];
export default function DeviceModeCard({ deviceMode, update }: DeviceModeCardProps) {
  const theme = useTheme();
  const toast = useToast();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedAction, setSelectedAction] = useState<string>();
  const [deviceModeState, setDeviceModeState] = useState<number>(deviceMode?.value ?? 0);
  const [updateLoading, setUpdateLoading] = useState(false);
  const open = useMemo(() => Boolean(anchorEl), [anchorEl]);
  const deviceModeText = useMemo(() => {
    let text = '';
    if (deviceMode?.value !== undefined && deviceMode?.value !== null) {
      if (deviceMode?.value === 0) {
        text = 'Standby';
      } else if (deviceMode?.value > 0) {
        text = `Speed ${deviceMode?.value}`;
      }
    }
    if (deviceMode?.pendingValue !== undefined && deviceMode?.pendingValue !== null) {
      if (deviceMode?.pendingValue === 0) {
        text += '\t(Pending: Standby)';
      } else if (deviceMode?.pendingValue > 0) {
        text += `\t(Pending: Speed ${deviceMode?.pendingValue})`;
      }
    }
    return text;
  }, [deviceMode]);

  const handleClickListItem = useCallback((event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);
  const handleMenuItemClick = useCallback((_: MouseEvent<HTMLElement>, action: string) => {
    setSelectedAction(action);
    setAnchorEl(null);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleUpdateDeviceMode = useCallback(() => {
    setUpdateLoading(true);
    let operationMode: Air20DeviceMode | null = null;

    if (deviceModeState >= 0 && deviceModeState < ALL_MODES.length) {
      operationMode = ALL_MODES[deviceModeState];
    }
    if (!operationMode) {
      setUpdateLoading(false);
      setSelectedAction(void 0);
      toast.dispatchToast({
        severity: ToastNotificationSeverityTypeEnum.WARNING,
        title: 'Unable to update Device Mode',
        message: 'Please Try again',
      });
      return;
    }
    update(operationMode)
      .then(() => {
        toast.dispatchToast({
          severity: ToastNotificationSeverityTypeEnum.SUCCESS,
          title: `Updated Device Mode to ${LABELS[deviceModeState]}`,
          message: 'Please allow up to 1 minute for changes to be reflected',
        });
      })
      .catch(() => {
        toast.dispatchToast({
          severity: ToastNotificationSeverityTypeEnum.ERROR,
          title: 'Failed to Update Device Mode',
        });
      })
      .finally(() => {
        setUpdateLoading(false);
        setSelectedAction(void 0);
      });
  }, [deviceModeState, update, toast]);

  return (
    <Card>
      <Tooltip
        placement='top'
        title={`As of ${moment(deviceMode?.timestamp ?? deviceMode?.pendingTimestamp).fromNow()}`}
      >
        <Fragment>
          <CardHeader
            avatar={
              <Avatar sx={{ backgroundColor: theme.palette.info.main }} aria-label='device-mode'>
                <SpeedIcon color='inherit' sx={{ height: 28, width: 28 }} />
              </Avatar>
            }
            title='Device Mode'
            titleTypographyProps={{ variant: 'body2', fontWeight: 500 }}
            subheader={deviceModeText}
            action={
              updateLoading ? (
                <CircularProgress thickness={2} />
              ) : (
                <IconButton aria-label='settings' onClick={handleClickListItem}>
                  <MoreVertIcon />
                </IconButton>
              )
            }
          />
        </Fragment>
      </Tooltip>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem onClick={(event) => handleMenuItemClick(event, 'update-mode')}>
          <Typography variant='subtitle2'>Update Mode</Typography>
        </MenuItem>
        <MenuItem onClick={(event) => handleMenuItemClick(event, 'apply-all')} disabled>
          <Typography variant='subtitle2'>Apply Changes to All</Typography>
        </MenuItem>
      </Menu>
      {selectedAction === 'update-mode' && (
        <>
          <CardContent sx={{ paddingX: 6, paddingTop: 4 }}>
            <Slider
              valueLabelDisplay='on'
              min={0}
              step={1}
              max={LABELS.length - 1}
              valueLabelFormat={valueLabel}
              sx={{
                '& .MuiSlider-mark': {
                  height: 10,
                  width: 10,
                  borderRadius: '50%',
                  backgroundColor: theme.palette.primary.main
                },
              }}
              marks={[
                {
                  value: 0,
                  label: LABELS[0],
                },
                {
                  value: 1,
                },
                {
                  value: 2,
                },
                {
                  value: 3,
                },
                {
                  value: 4,
                },
                {
                  value: LABELS.length - 1,
                  label: LABELS[LABELS.length - 1],
                },
              ]}
              aria-label='update-device-mode'
              value={deviceModeState}
              onChange={(_, val) => setDeviceModeState(val as number)}
              disabled={updateLoading}
            />
          </CardContent>
          <CardActions>
            <Box display='flex' flexGrow={1} justifyContent='end' alignItems='center' gap={0.5}>
              <Button
                variant='text'
                color='primary'
                onClick={() => {
                  setSelectedAction(void 0);
                  setDeviceModeState(deviceMode?.value ?? 0);
                }}
              >
                Cancel
              </Button>
              <Button
                variant='text'
                color='error'
                onClick={() => {
                  handleUpdateDeviceMode();
                }}
                disabled={updateLoading || deviceModeState === (deviceMode?.value ?? 0)}
              >
                Ok
              </Button>
            </Box>
          </CardActions>
        </>
      )}
    </Card>
  );
}

function valueLabel(value: number) {
  return <Typography variant='subtitle2'>{LABELS[value]}</Typography>;
}
