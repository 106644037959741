import { Button, Typography, Stack, Tooltip, Box } from '@mui/material';
import { SwapHoriz as SwapHorizIcon, HelpOutlineOutlined as HelpIcon } from '@mui/icons-material';
import { useModal, ModalNotificationTypeEnum } from 'Providers/ModalProvider';
import { useReactiveVar } from '@apollo/client';
import { selectedOrgVar, currentUserDataVar, defaultOrgVar } from 'Apollo/ApolloCache';

interface OrgDisplayAndSwitcherProps {
  withDefaultOrg?: boolean;
}

export default function OrgDisplayAndSwitcher({ withDefaultOrg }: OrgDisplayAndSwitcherProps) {
  const selectedOrg = useReactiveVar(selectedOrgVar);
  const defaultOrg = useReactiveVar(defaultOrgVar);
  const userData = useReactiveVar(currentUserDataVar);
  const orgList = userData?.currentUser?.memberAccounts ?? [];
  const hasMultipleOrgs = orgList.length > 1;

  const { dispatchModal } = useModal();
  const handleSwitchOrgClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    const modalConfig = {
      type: ModalNotificationTypeEnum.SWITCH_ORG_MODAL,
      modalProps: {
        origin: 'switch-session-org',
      },
    };
    if (withDefaultOrg) {
      modalConfig.modalProps.origin = 'switch-user-org';
    }
    dispatchModal(modalConfig);
  };
  if (!hasMultipleOrgs) return null;
  return (
    <Stack gap={1} alignItems='flex-start' marginBottom={2}>
      <Stack>
        <Box display='flex' gap={0.5}>
          <Typography sx={{ fontSize: 14 }}>Current Organization</Typography>
          <Tooltip title='This value will revert back to default if you log out or close the tab.'>
            <HelpIcon sx={{ height: 14, width: 14, color: 'grey' }} />
          </Tooltip>
        </Box>
        <Typography sx={{ fontSize: 16, fontWeight: 'medium' }}>{selectedOrg?.name}</Typography>
      </Stack>
      {withDefaultOrg && (
        <Stack>
          <Box display='flex' gap={0.5}>
            <Typography sx={{ fontSize: 14 }}>Default Organization</Typography>
            <Tooltip title='This value is permanent across different sessions even if you log out.'>
              <HelpIcon sx={{ height: 14, width: 14, color: 'grey' }} />
            </Tooltip>
          </Box>
          <Typography sx={{ fontSize: 16, fontWeight: 'medium' }}>{defaultOrg?.name}</Typography>
        </Stack>
      )}
      <Button
        startIcon={<SwapHorizIcon />}
        onClick={handleSwitchOrgClick}
        sx={{ fontSize: 14, textTransform: 'lowercase', paddingLeft: 0.4 }}
      >
        {withDefaultOrg ? 'Switch Default Organization' : 'Switch Organization'}
      </Button>
    </Stack>
  );
}
