/* eslint-disable no-magic-numbers */
/* eslint-disable indent */
import { Line, ChartProps } from 'react-chartjs-2';
import { Chart } from 'chart.js';
import { Maybe, TimeInterval, HistoricalLocationMoldRisk } from '__generated__/graphql';
import { Fragment, useMemo } from 'react';
import { CrosshairPlugin } from 'chartjs-plugin-crosshair';
import zoomPlugin from 'chartjs-plugin-zoom';
import annotationPlugin from 'chartjs-plugin-annotation';
import {
  useTheme,
  Box,
  Card,
  CardContent,
  CardHeader,
  useMediaQuery,
  Typography,
  Avatar,
  Tooltip,
  IconButton,
} from '@mui/material';
import { SelectedLocationType, SelectedOrgType } from 'Apollo/ApolloCache';
import CSVDownloadButton from 'Components/SharedUI/CSVDownloadButton';
import moment from 'moment';
import { useSearchParams } from 'react-router-dom';
import { formatTimestamp } from 'Components/AuthedPages/ReportsPage/MetricLineChartContainer';
import { InfoOutlined as InfoIcon, ZoomOutMap as ZoomIcon } from '@mui/icons-material';
import { replaceConfigDataIfSkipped } from 'Utils/chartHelpers';
import {
  convertTemperature,
  convertUnitOfMeasurePreferenceToTempScaleEnum,
} from 'Utils/conversionTools';
import { TempScaleEnum } from 'Constants/ConversionEnums';

interface MoldSpreadViewProps {
  report?: Maybe<Array<Maybe<HistoricalLocationMoldRisk>>>;
  selectedLocation: SelectedLocationType;
  selectedOrg: SelectedOrgType;
  selectedInterval: TimeInterval;
}

Chart.register();
const CHART_ID = 'infection-risk';

export default function MoldSpreadView({
  report,
  selectedLocation,
  selectedOrg,
  selectedInterval,
}: MoldSpreadViewProps) {
  const [params] = useSearchParams();
  const theme = useTheme();
  const isSmallerScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const suggestedMinX = useMemo(() => params.get('start-date') as string, [params]);
  const suggestedMaxX = useMemo(() => params.get('end-date') as string, [params]);
  const tempScalePref = convertUnitOfMeasurePreferenceToTempScaleEnum(
    selectedOrg?.preferences?.unit_of_measure
  );
  const tempScaleSuffix = tempScalePref === TempScaleEnum.CELSIUS ? '°C' : '°F';
  const chartOptions: ChartProps<'line'>['options'] | null = useMemo(() => {
    if (!report) return null;
    const xLimits = {
      min: Math.min(...report.map((reportItem) => new Date(reportItem?.timestamp).valueOf())),
      max: Math.max(...report.map((reportItem) => new Date(reportItem?.timestamp).valueOf())),
    };
    return {
      aspectRatio: isSmallerScreen ? 0.8 : 1.5,
      interaction: {
        intersect: false,
        mode: 'nearest' as const,
        axis: 'x' as const,
      },
      plugins: {
        legend: {
          display: true,
        },
        tooltip: {
          callbacks: {
            title: (context) => {
              const timeStamp = context[0].label;
              return formatTimestamp(timeStamp, selectedInterval, true);
            },
            label: (context) => {
              const value = context?.raw as { x: Date; y: number };
              const roundedValue = value.y.toFixed(2);
              return `${context.dataset.label}: ${roundedValue}`;
            },
          },
        },
        crosshair: {
          line: {
            color: 'gray',
            dashPattern: [8, 8],
            width: 1,
          },
          sync: {
            enabled: true, // enable trace line syncing with other charts
            group: 1, // chart group
            suppressTooltips: false, // suppress tooltips when showing a synced tracer
          },
          zoom: {
            enabled: false, // we use the other zoom plugin for zooming, so this is to be disabled
          },
        },
        zoom: {
          limits: {
            x: xLimits,
          },
          pan: {
            enabled: true,
            mode: 'x' as const,
            modifierKey: 'shift' as const,
            scaleMode: 'x' as const,
            onPan: (chart) => {
              chart.chart.canvas.style.cursor = 'grabbing';
            },
            onPanComplete: (chart) => {
              chart.chart.canvas.style.cursor = 'grab';
            },
          },
          zoom: {
            pinch: {
              enabled: true,
            },
            drag: {
              enabled: true,
              backgroundColor: 'rgba(128,128,128,0.2)',
              borderColor: 'gray',
              borderWidth: 1,
              threshold: 1,
            },
            onZoom: (chart) => {
              chart.chart.canvas.style.cursor = 'grabbing';
            },
            onZoomComplete: (chart) => {
              chart.chart.canvas.style.cursor = 'grab';
            },
            mode: 'x' as const,
          },
        },
      },
      scales: {
        x: {
          suggestedMin: suggestedMinX,
          suggestedMax: suggestedMaxX,
          type: 'time' as const,
          time: {
            unit:
              selectedInterval === TimeInterval.Weekly ||
              selectedInterval === TimeInterval.Monthly ||
              selectedInterval === TimeInterval.Yearly
                ? ('day' as const)
                : ('hour' as const),
          },
          grid: {
            color: (context) => {
              const lineWidth =
                context.tick && context.tick.major
                  ? Chart.defaults.borderColor.toString()
                  : 'transparent';
              return lineWidth;
            },
          },
          ticks: {
            major: {
              enabled: true,
            },
            font: function (context) {
              if (context.tick && context.tick.major) {
                return {
                  size: 14, // 12 is 'normal'
                  weight: 'bold',
                };
              }
            },
            display: true,
          },
        },
        y_mold_categories: {
          title: { display: true, text: 'Mold Spread Risk' },
          type: 'category',
          labels: ['High', 'Moderate', 'Low'],
          offset: true,
          grid: {
            offset: true,
          },
        },
        y_mold: {
          display: false,
          beginAtZero: true,
          min: 0,
          max: 100,
          ticks: {
            callback: function (val) {
              return `${val}`;
            },
          },
        },
        y_temp_humidity: {
          title: { display: true, text: `Temperature ${tempScaleSuffix} / Humidity %` },
          display: true,
          suggestedMin: 0,
          suggestedMax: 100,
          position: 'right',
          grid: {
            display: false,
          },
          ticks: {
            callback: function (val) {
              return `${val}`;
            },
          },
        },
      },
    };
  }, [report, isSmallerScreen, suggestedMinX, suggestedMaxX, selectedInterval, tempScaleSuffix]);
  const chartData: ChartProps<'line'>['data'] | null = useMemo(() => {
    if (!report) return null;
    return {
      datasets: [
        {
          label: 'Mold Spread Risk (%)',
          data: report?.map((reportItem) => ({
            x: new Date(reportItem?.timestamp).valueOf(),
            y: reportItem?.normalizedMoldRisk as number,
          })),
          yAxisID: 'y_mold',
          fill: true,
          borderColor: '#0B84A5',
          backgroundColor: '#0B84A533',
          tension: 0.2,
          pointRadius: 0,
          segment: {
            borderColor: (context) => replaceConfigDataIfSkipped(context, 'gray', selectedInterval),
            borderDash: (context) => replaceConfigDataIfSkipped(context, [6, 6], selectedInterval),
          },
          spanGaps: true,
        },
        {
          label: `Temperature (${tempScaleSuffix})`,
          data: report?.map((reportItem) => ({
            x: new Date(reportItem?.timestamp).valueOf(),
            y:
              tempScalePref === TempScaleEnum.CELSIUS
                ? (reportItem?.avgTempCelsius as number)
                : convertTemperature(
                    reportItem?.avgTempCelsius as number,
                    TempScaleEnum.FAHRENHEIT
                  ),
          })),
          yAxisID: 'y_temp_humidity',
          fill: false,
          borderColor: 'lightgray',
          backgroundColor: 'lightgray',
          tension: 0.2,
          pointRadius: 0,
          segment: {
            borderColor: (context) => replaceConfigDataIfSkipped(context, 'gray', selectedInterval),
            borderDash: (context) => replaceConfigDataIfSkipped(context, [6, 6], selectedInterval),
          },
          spanGaps: true,
        },
        {
          label: 'Relative Humidity (%)',
          data: report?.map((reportItem) => ({
            x: new Date(reportItem?.timestamp).valueOf(),
            y: reportItem?.avgHumidityPercentage as number,
          })),
          yAxisID: 'y_temp_humidity',
          fill: false,
          borderColor: '#B2BEB5',
          backgroundColor: '#B2BEB5',
          tension: 0.2,
          pointRadius: 0,
          segment: {
            borderColor: (context) => replaceConfigDataIfSkipped(context, 'gray', selectedInterval),
            borderDash: (context) => replaceConfigDataIfSkipped(context, [6, 6], selectedInterval),
          },
          spanGaps: true,
        },
      ],
    };
  }, [report, selectedInterval, tempScalePref, tempScaleSuffix]);
  return (
    <Card sx={{ width: '100%' }}>
      {!report || report.length === 0 || !chartData || !chartOptions ? (
        <CardHeader
          sx={{ p: 3 }}
          title='There is no Mold Spread Risk Report'
          subheader='Modify the selected filters to find a report'
        />
      ) : (
        <Fragment>
          <CardHeader
            sx={{ pb: 0 }}
            avatar={
              <Tooltip
                title={
                  <Fragment>
                    <Typography variant='subtitle2'>Click & Drag to Zoom</Typography>
                    <Typography variant='subtitle2'>Hold Shift + Click & Drag to Pan</Typography>
                  </Fragment>
                }
              >
                <Avatar>
                  <InfoIcon />
                </Avatar>
              </Tooltip>
            }
            action={
              <Box display='flex' gap={1} alignItems='center'>
                <Tooltip title='Reset Zoom'>
                  <IconButton
                    sx={{
                      borderColor: (theme) => theme.palette.primary.main,
                      borderRadius: 1,
                      borderWidth: 1,
                      borderStyle: 'solid',
                    }}
                    color='primary'
                    onClick={() => Chart.getChart(CHART_ID)?.resetZoom()}
                  >
                    <ZoomIcon />
                  </IconButton>
                </Tooltip>
                <CSVDownloadButton
                  data={report ?? []}
                  fileName={`mold-risk-${moment().format('MMM-DD-YYYY')}`}
                  formatDataCallback={(raw: Array<Record<string, unknown>>) => {
                    return raw.map((riskItem) => ({
                      location: selectedLocation?.name ?? 'All Locations',
                      timestamp: riskItem?.timestamp,
                      normalizedMoldRisk: riskItem?.normalizedMoldRisk,
                      avgHumidityPercentage: riskItem?.avgHumidityPercentage,
                      avgTempCelsius: riskItem?.avgTempCelsius,
                    }));
                  }}
                />
              </Box>
            }
          />
          <CardContent>
            <Line
              id={CHART_ID}
              data={chartData}
              options={chartOptions}
              plugins={[CrosshairPlugin, annotationPlugin, zoomPlugin]}
              style={{
                cursor: 'grab',
              }}
            />
          </CardContent>
        </Fragment>
      )}
    </Card>
  );
}
