/* eslint-disable no-magic-numbers */
import { useReactiveVar } from '@apollo/client';
import { Search } from '@mui/icons-material';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from '@mui/material';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import {
  GetHistoricalLocationMoldRiskQueryVariables,
  Maybe,
  TimeInterval,
} from '__generated__/graphql';
import { selectedOrgVar } from 'Apollo/ApolloCache';
import { intervalLabels } from 'Components/AuthedPages/ReportsPage/ReportsSideBarPanel/ReportsSideBarPanel';
import { DateRangeEnum } from 'Components/SharedUI/DateRangePicker';
import moment from 'moment';
import { ToastNotificationSeverityTypeEnum, useToast } from 'Providers/ToastProvider';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { getPastDateTimeRange } from 'Utils/getPastDateTimeRange';
import {
  autoSelectedInterval,
  manualIntervalOptions as generateManualIntervalOptions,
} from 'Utils/manageIntervals';

interface InfectionFiltersProps {
  locationId?: Maybe<string>;
  timeInterval?: Maybe<TimeInterval>;
  filterHandler: (args: GetHistoricalLocationMoldRiskQueryVariables) => void;
}

export const hoursMap = {
  [DateRangeEnum.PAST_HOUR]: 1,
  [DateRangeEnum.PAST_SIX_HOURS]: 6,
  [DateRangeEnum.PAST_DAY]: 24,
  [DateRangeEnum.PAST_THREE_DAYS]: 24 * 3,
  [DateRangeEnum.PAST_WEEK]: 24 * 7,
  [DateRangeEnum.PAST_MONTH]: 24 * 30,
};

export default function InfectionFilters({
  locationId,
  timeInterval,
  filterHandler,
}: InfectionFiltersProps) {
  const [params, setSearchParams] = useSearchParams();
  const { dispatchToast } = useToast();
  const selectedOrg = useReactiveVar(selectedOrgVar);

  const startDate = params.get('startDate') as string;
  const endDate = params.get('endDate') as string;

  const [selectedTimeInterval, setSelectedTimeInterval] = useState<TimeInterval | 'Auto'>(
    (params.get('interval') as TimeInterval | 'Auto') ?? timeInterval ?? TimeInterval.Hourly
  );
  const [selectedTimePeriod, setSelectedTimePeriod] = useState(
    (params.get('period') as DateRangeEnum) ?? DateRangeEnum.PAST_DAY
  );
  const getDateRangeFromCurrent = useCallback((selectedTimePeriod: DateRangeEnum) => {
    if (selectedTimePeriod === DateRangeEnum.CUSTOM) {
      selectedTimePeriod = DateRangeEnum.PAST_DAY;
    }
    const currDateTime = moment().set('minutes', 0).set('seconds', 0);
    const endDate = currDateTime.toISOString();
    const startDate = currDateTime.subtract(hoursMap[selectedTimePeriod], 'hours').toISOString();
    const utcOffsetMinutes = moment().utcOffset();
    const offsetHours = Math.floor(Math.abs(utcOffsetMinutes) / 60);
    const offsetMinutes = Math.abs(utcOffsetMinutes) % 60;
    const offsetSign = utcOffsetMinutes >= 0 ? '+' : '-';
    return [
      startDate,
      endDate,
      `${offsetSign}${String(offsetHours).padStart(2, '0')}:${String(offsetMinutes).padStart(
        2,
        '0'
      )}`,
    ];
  }, []);
  const [customDates, setCustomDates] = useState<
    [moment.Moment | null | undefined, moment.Moment | null | undefined]
  >(() => {
    if (selectedTimePeriod === DateRangeEnum.CUSTOM) {
      return [moment(startDate), moment(endDate)];
    }
    return [void 0, void 0];
  });

  const manualIntervalOptions = useMemo(() => {
    if (selectedTimePeriod === DateRangeEnum.CUSTOM) {
      return generateManualIntervalOptions(
        customDates[0]?.toDate() ?? startDate,
        customDates[1]?.toDate() ?? endDate
      );
    } else {
      const hoursDiff = hoursMap[selectedTimePeriod];
      const [strt, end] = getPastDateTimeRange(hoursDiff, 'hours');
      return generateManualIntervalOptions(strt, end);
    }
  }, [selectedTimePeriod, customDates, startDate, endDate]);

  useEffect(() => {
    // Make sure daily cannot be selected when end date - start date less than or equal to 24 hours
    if (
      selectedTimePeriod !== DateRangeEnum.CUSTOM &&
      hoursMap[selectedTimePeriod] <= 24 &&
      selectedTimeInterval === TimeInterval.Daily
    ) {
      dispatchToast({
        severity: ToastNotificationSeverityTypeEnum.WARNING,
        title: 'Daily Interval is only available for above 24 hours',
      });
      setSelectedTimeInterval(TimeInterval.Hourly);
    }
    if (
      selectedTimePeriod === DateRangeEnum.CUSTOM &&
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      customDates[0] &&
      customDates[1] &&
      customDates[1].diff(customDates[0], 'hours') <= 24 &&
      selectedTimeInterval === TimeInterval.Daily
    ) {
      dispatchToast({
        severity: ToastNotificationSeverityTypeEnum.WARNING,
        title: 'Daily Interval is only available for a range above 24 hours',
      });
      setSelectedTimeInterval(TimeInterval.Hourly);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTimeInterval, selectedTimePeriod]);

  const applyFilters = useCallback(() => {
    // eslint-disable-next-line prefer-const
    let [startDate, endDate, utcOffset] = getDateRangeFromCurrent(selectedTimePeriod);
    if (selectedTimePeriod === DateRangeEnum.CUSTOM) {
      startDate = customDates[0]?.toISOString() ?? '';
      endDate = customDates[1]?.toISOString() ?? '';
    }
    setSearchParams(
      (params) => {
        const prev = Object.fromEntries(params.entries());
        prev['location'] = locationId ?? '';
        prev['interval'] = selectedTimeInterval;
        prev['period'] = selectedTimePeriod;
        prev['start-date'] = startDate;
        prev['end-date'] = endDate;
        return prev;
      },
      { replace: true }
    );

    filterHandler({
      input: {
        accountId: selectedOrg?.id ?? '',
        locationId: locationId ?? '',
        timeFrame: {
          startDate: startDate,
          endDate: endDate,
          timeInterval:
            selectedTimeInterval === 'Auto'
              ? autoSelectedInterval(startDate, endDate)
              : selectedTimeInterval,
          utcOffset: utcOffset,
        },
      },
    });
  }, [
    selectedTimeInterval,
    selectedTimePeriod,
    locationId,
    selectedOrg,
    customDates,
    filterHandler,
    getDateRangeFromCurrent,
    setSearchParams,
  ]);

  useEffect(() => {
    applyFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card>
      <CardHeader subheader='Filters' />
      <CardContent>
        <Stack width='100%' gap={2}>
          <FormControl fullWidth>
            <InputLabel id='time-period'>Time Period</InputLabel>
            <Select
              label='time-period'
              id='time-period-selector'
              value={selectedTimePeriod}
              onChange={(e) => setSelectedTimePeriod(e.target.value as DateRangeEnum)}
            >
              <MenuItem value={DateRangeEnum.PAST_HOUR}>Past hour</MenuItem>
              <MenuItem value={DateRangeEnum.PAST_SIX_HOURS}>Past 6 hours</MenuItem>
              <MenuItem value={DateRangeEnum.PAST_DAY}>Past 24 hours</MenuItem>
              <MenuItem value={DateRangeEnum.PAST_THREE_DAYS}>Past 3 days</MenuItem>
              <MenuItem value={DateRangeEnum.PAST_WEEK}>Past week</MenuItem>
              <MenuItem value={DateRangeEnum.PAST_MONTH}>Past month</MenuItem>
              <MenuItem value={DateRangeEnum.CUSTOM}>Custom</MenuItem>
            </Select>
          </FormControl>
          {selectedTimePeriod === DateRangeEnum.CUSTOM && (
            <Fragment>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DateTimePicker
                  label='Start Date'
                  value={customDates[0]}
                  maxDateTime={customDates[1]}
                  onChange={(date) => setCustomDates((prev) => [date as moment.Moment, prev[1]])}
                  views={['year', 'day', 'hours', 'minutes']}
                />
                <DateTimePicker
                  label='End Date'
                  value={customDates[1]}
                  minDateTime={customDates[0]}
                  onChange={(date) => setCustomDates((prev) => [prev[0], date as moment.Moment])}
                  views={['year', 'day', 'hours', 'minutes']}
                />
              </LocalizationProvider>
            </Fragment>
          )}
          <FormControl fullWidth>
            <InputLabel id='time-interval'>Time Interval</InputLabel>
            <Select
              label='time-interval'
              id='time-interval-selector'
              value={selectedTimeInterval}
              onChange={(e) => setSelectedTimeInterval(e.target.value as TimeInterval)}
            >
              <MenuItem value='Auto' sx={{ fontStyle: 'italic' }}>
                Automatic
              </MenuItem>
              <Divider />
              {manualIntervalOptions?.map((interval) => (
                <MenuItem key={interval} value={interval}>
                  {intervalLabels[interval]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
        <CardActions sx={{ mt: 2, px: 0 }}>
          <Button fullWidth variant='contained' startIcon={<Search />} onClick={applyFilters}>
            Apply Filters
          </Button>
        </CardActions>
      </CardContent>
    </Card>
  );
}
