export function compareFwVersions(
  fwVersion1: string | undefined,
  fwVersion2: string | undefined
): number {
  // removes last part of a version, encrypted alphanumberic part if exists
  function removeEncrypted(fwVersion: string): string {
    const fwSplitted = fwVersion.split('.');
    const temp = fwSplitted.pop();
    if (!temp) {
      return fwVersion;
    }

    if (!/^[a-zA-Z]+$/.test(temp)) {
      fwSplitted.push(temp);
    }

    return fwSplitted.join('.');
  }

  if (!fwVersion1 || !fwVersion2) {
    throw new Error('Error comparing firmware versions');
  }

  fwVersion1 = removeEncrypted(fwVersion1);
  fwVersion2 = removeEncrypted(fwVersion2);

  const fw1Splitted = fwVersion1.split('.').map((e) => parseInt(e));
  const fw2Splitted = fwVersion2.split('.').map((e) => parseInt(e));

  for (let i = 0; i < fw1Splitted.length; i++) {
    if (fw1Splitted[i] > fw2Splitted[i]) {
      return 1;
    }
    if (fw2Splitted[i] > fw1Splitted[i]) {
      return -1;
    }
  }

  return 0;
}
