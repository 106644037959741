/* eslint-disable @typescript-eslint/no-explicit-any */
import { IconButton, Tooltip } from '@mui/material';
import { Parser } from '@json2csv/plainjs';

import DownloadIcon from '@mui/icons-material/Download';

export const formatLocationMetricData = (locationDataRaw): Array<Record<string, unknown>> => {
  const formattedData = locationDataRaw.flatMap((report) => {
    const locationName = report?.label;
    return report.data.map((dataPoint) => {
      const { timeStamp, value, metricType } = dataPoint;
      return {
        location: locationName,
        metricType,
        metricValue: value,
        timeStamp,
      };
    });
  });
  return formattedData;
};

export const convertJSONtoCSV = (data): string => {
  const fields = Object.keys(data[0] ?? {});
  try {
    const opts = {
      fields,
    };
    const parser = new Parser(opts);
    const csv = parser.parse(data);
    return csv;
  } catch (err) {
    console.error(err);
  }
  return '';
};

type Props = {
  data: any;
  fileName: string;
  formatDataCallback: (arg: any) => Array<Record<string, unknown>>;
};

export default function CSVDownloadButton({ data, fileName, formatDataCallback }: Props) {
  // formatDataCallback returns an array of objects. Each of these objects should contain
  // the same fields and data type on the values. The .csv column headers will be dynamically
  // generated from the collection of fields found on the first object in the array.
  const formattedData = formatDataCallback(data);
  const csvData = convertJSONtoCSV(formattedData);
  const handleDownload = () => {
    // Create a Blob object containing the CSV data
    const blob = new Blob([csvData], { type: 'text/csv' });

    // Create a temporary anchor element
    const a = document.createElement('a');
    a.href = window.URL.createObjectURL(blob);

    // Set the file name for the download
    a.download = fileName;

    // Trigger a click event on the anchor element to start the download
    a.click();

    // Clean up
    window.URL.revokeObjectURL(a.href);
  };

  return (
    <Tooltip title='Download chart data as .csv'>
      <IconButton
        sx={{
          borderColor: (theme) => theme.palette.primary.main,
          borderRadius: 1,
          borderWidth: 1,
          borderStyle: 'solid',
        }}
        color='primary'
        onClick={handleDownload}
      >
        <DownloadIcon />
      </IconButton>
    </Tooltip>
  );
}
