import { useEffect, useState } from 'react';
import QuickMetricsCard from 'Components/MetricCards/QuickMetricsCard';
import { gaugeColors, metricCardIconColor } from 'Constants/OverviewConsts';
import Co2Icon from '@mui/icons-material/Co2';
import RoutePaths from 'Constants/RoutePaths';
import { MetricReportTypeEnum } from 'Constants/FloorsViewEnums';
import MetricGauge from 'Components/MetricCards/ChartComponents/MetricGauge';
import type { GridConfigType } from 'Components/MetricCards/QuickMetricsCard';

import type { GaugeConfigType } from 'Constants/OverViewTypes';
import { useTheme, Typography } from '@mui/material';
import moment from 'moment-timezone';
import { cache } from 'Apollo/ApolloCache';
import { LOCATION_CORE_FRAGMENT } from 'fragments';
import { LocationCoreFragment } from '__generated__/graphql';

export type CO2SmallCardContainerProps = {
  co2DataToAverage?: (number | null | undefined)[];
  isLoading: boolean;
  startDate: string;
  endDate: string;
  selectedLocationID: string | undefined;
  selectedOrgID: string | undefined;
  gridConfig: GridConfigType;
  error?: boolean;
};
const TITLE = 'CO2';
const INFO_TEXT =
  'CO₂, or carbon dioxide, is a gas that we naturally breathe out, and it can build up indoors, especially in poorly ventilated spaces. High CO₂ levels indoors can make the air feel stale and lead to tiredness, headaches, and difficulty focusing. Keeping CO₂ levels low by allowing fresh air in (like opening windows or using ventilation systems) helps maintain a fresher indoor environment that feels more energizing and comfortable.';

export default function CO2SmallCardContainer({
  co2DataToAverage,
  isLoading,
  startDate,
  endDate,
  selectedLocationID,
  gridConfig,
  error,
}: CO2SmallCardContainerProps) {
  const theme = useTheme();
  const [gaugeConfig, setGaugeConfig] = useState<GaugeConfigType>();

  const selectedLocation = cache.readFragment<LocationCoreFragment>({
    id: `Location:${selectedLocationID}`,
    fragment: LOCATION_CORE_FRAGMENT
  });

  useEffect(() => {
    const report = co2DataToAverage ?? [];
    const co2Ppm = report.length === 0 ? null : Math.floor(report[report.length - 1] ?? 0);
    const selectedToolTipText = `${co2Ppm} ppm`;
    const mainGaugeLabel = `${co2Ppm} ppm`;
    const { red, yellow, green } = gaugeColors;

    const rangeConfig = [
      {
        defaultColor: green.default,
        selectedColor: green.selected,
        limit: 600,
        tooltip: {
          defaultText: 'Healthy CO2 levels',
          selectedText: selectedToolTipText,
        },
      },
      {
        defaultColor: yellow.default,
        selectedColor: yellow.selected,
        limit: 1500,
        tooltip: {
          defaultText: 'Moderate CO2 levels',
          selectedText: selectedToolTipText,
        },
      },
      {
        defaultColor: red.default,
        selectedColor: red.selected,
        limit: 2500,
        tooltip: {
          defaultText: 'Unhealthy CO2 levels',
          selectedText: selectedToolTipText,
        },
      },
    ];

    setGaugeConfig({
      rangeConfig,
      mainGaugeLabel,
      value: co2Ppm,
      minValue: 0,
      maxValue: 3000,
    });
  }, [co2DataToAverage]);
  const numHoursDataRange = moment(endDate).diff(startDate, 'hour');
  return (
    <QuickMetricsCard
      isLoading={isLoading}
      icon={<Co2Icon sx={{ color: error ? theme.palette.error.main : metricCardIconColor }} />}
      title={TITLE}
      infoText={INFO_TEXT}
      link={`${RoutePaths.PATH_REPORTS}?locationID=${selectedLocationID}&selectedMetric=${MetricReportTypeEnum.CO2}`}
      gridConfig={gridConfig}
      error={error}
      showInfoInModal
    >
      {gaugeConfig ? <MetricGauge gaugeConfig={gaugeConfig} /> : null}
      {(!gaugeConfig ||
        (gaugeConfig && (gaugeConfig.value === null || gaugeConfig.value === undefined))) && (
        <>
          <Typography alignContent={'center'} color={'InactiveCaptionText'}>
            No data available for <b>{selectedLocation?.name}</b>
          </Typography>
          <Typography alignContent={'center'} fontStyle={'italic'} color={'InactiveCaptionText'}>
            in the last{' '}
            <b>
              {numHoursDataRange} {numHoursDataRange > 1 ? 'hours' : 'hour'}
            </b>
          </Typography>
        </>
      )}
    </QuickMetricsCard>
  );
}
