import { Box, Typography } from '@mui/material';
interface DeviceLocationCellProps {
  fullLocation: string;
}

export default function DeviceLocationCell({ fullLocation }: DeviceLocationCellProps) {
  return (
    <Box display='flex' flexDirection='column' justifyContent='center' alignItems='flex-start'>
      <Typography fontWeight='bold' color='gray' variant='body2'>
        Location
      </Typography>
      <Typography variant='body2'>{fullLocation}</Typography>
    </Box>
  );
}
