import { useReactiveVar } from '@apollo/client';
import {
  Check as CheckIcon,
  PriorityHigh as ErrorIcon,
  ExpandMore as ExpandMoreIcon,
} from '@mui/icons-material';
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Button,
  Chip,
  CircularProgress,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { LocationFloorPlanQuery } from '__generated__/graphql';
import { selectedLocationVar, selectedOrgVar } from 'Apollo/ApolloCache';
import { ModalNotificationTypeEnum, useModal } from 'Providers/ModalProvider';
import { Fragment, useCallback, useState } from 'react';
interface FloorPlanEditRoomsProps {
  rooms: NonNullable<LocationFloorPlanQuery['location']>['immediateSublocations'];
  drawInProgress?: boolean;
  selectedRoomId?: string;
  onRoomChange: (roomId?: string) => void;
  onRoomDelete: (id: string) => void;
  onDeleteGeometry: (roomId: string) => Promise<void>;
  onSaveGeometry: (roomId: string) => Promise<void>;
}
export default function FloorPlanEditRooms({
  rooms,
  selectedRoomId,
  onRoomChange,
  onRoomDelete,
  onDeleteGeometry,
  onSaveGeometry,
}: FloorPlanEditRoomsProps) {
  const selectedOrg = useReactiveVar(selectedOrgVar);
  const selectedLocation = useReactiveVar(selectedLocationVar);
  const { dispatchModal } = useModal();
  const [loading, setLoading] = useState<string>();
  const handleChange = useCallback(
    (panel: string) => (_: React.SyntheticEvent, isExpanded: boolean) => {
      onRoomChange(isExpanded ? panel : void 0);
    },
    [onRoomChange]
  );
  return (
    <Fragment>
      {(!rooms || rooms.length === 0) && (
        <Typography variant='body2'>
          <b>{selectedLocation?.name ?? 'All Locations'}</b> does not have any sublocations with
          Archetype <b>Room</b>
        </Typography>
      )}
      {rooms?.map((loc) => {
        if (loc?.archetype?.toLocaleLowerCase() !== 'room') return null;
        return (
          <Accordion
            sx={{ width: '100%' }}
            key={loc.id}
            expanded={selectedRoomId === loc.id}
            onChange={handleChange(loc.id)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`${loc.id}-content`}
              id={`${loc.id}-header`}
            >
              <Stack
                width='100%'
                direction='row'
                justifyContent='space-between'
                alignItems='center'
              >
                <Stack spacing={1} direction='row' alignItems='center'>
                  {loc.mapFeatures ? (
                    <Avatar
                      sx={{
                        bgcolor: (theme) => theme.palette.success.light,
                        height: 25,
                        width: 25,
                      }}
                    >
                      <CheckIcon fontSize='small' />
                    </Avatar>
                  ) : (
                    <Avatar
                      sx={{
                        bgcolor: (theme) => theme.palette.warning.light,
                        height: 25,
                        width: 25,
                      }}
                    >
                      <ErrorIcon fontSize='small' />
                    </Avatar>
                  )}

                  <Typography>{loc.name}</Typography>
                </Stack>
                {loading === loc.id && <CircularProgress size={16} />}
              </Stack>
            </AccordionSummary>
            <AccordionDetails>
              {loc.mapFeatures ? (
                <Chip color='success' variant='outlined' size='small' label='Geometry Configured' />
              ) : (
                <Fragment>
                  <Chip color='warning' variant='outlined' size='small' label='Missing Geometry' />

                  <Typography variant='subtitle2' sx={{ mt: 2 }}>
                    Define location outline on map, finish by closing the drawn polygon on the map
                    and clicking <b>Save Geometry</b>
                  </Typography>
                </Fragment>
              )}
              {loc.description && (
                <Typography variant='body2' color='text.secondary' sx={{ mt: 2 }}>
                  {loc.description}
                </Typography>
              )}
            </AccordionDetails>

            <AccordionActions>
              <Tooltip title='Delete Room' arrow>
                <Button
                  variant='contained'
                  color='error'
                  disableElevation
                  onClick={() =>
                    dispatchModal({
                      type: ModalNotificationTypeEnum.REMOVE_LOCATION_CONFIRMATION_MODAL,
                      modalProps: {
                        accountId: selectedOrg?.id,
                        locationId: loc.id,
                        name: loc.name,
                        onRemoveLocation: onRoomDelete,
                        parentLocationId: selectedLocation?.id,
                      },
                    })
                  }
                >
                  Delete
                </Button>
              </Tooltip>
              {loc.mapFeatures ? (
                <Button
                  color='error'
                  onClick={() => {
                    setLoading(loc.id);
                    onDeleteGeometry(loc.id)
                      .catch(console.error)
                      .finally(() => setLoading(void 0));
                  }}
                >
                  Remove Geometry
                </Button>
              ) : (
                <Button
                  color='success'
                  onClick={() => {
                    setLoading(loc.id);
                    onSaveGeometry(loc.id)
                      .catch(console.error)
                      .finally(() => setLoading(void 0));
                  }}
                >
                  Save Geometry
                </Button>
              )}
            </AccordionActions>
          </Accordion>
        );
      })}
    </Fragment>
  );
}
