import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { SelectedLocationType, SelectedOrgType } from 'Apollo/ApolloCache';
import PageContainerFrame from 'Components/HOC/PageContainerFrame';
import SettingsPanelFrame from 'Components/HOC/SettingsPanelFrame';
import { useSearchParams } from 'react-router-dom';
import { useErrorBoundary } from 'react-error-boundary';
import useCurrentLocationList from 'Hooks/useCurrentLocationList';
import {
  ConsumableExpirationGroupedDevices,
  GetDevicesConsumableExpirationQuery,
  GetDevicesConsumableExpirationQueryVariables,
  GroupByInterval,
  Maybe,
} from '__generated__/graphql';

import LocationSelectorBreadcrumb from 'Components/LocationSelectorBreadcrumb/LocationSelectorBreadcrumb';
import {
  useTheme,
  Grid,
  Stack,
  ButtonGroup,
  Button,
  Typography,
  useMediaQuery,
  CircularProgress,
} from '@mui/material';
import DeviceMaintenanceBar from './PageViews/DeviceMaintenanceBar';
import DevicesListView from '../DevicesListPage/PageViews/DevicesListView';
import DevicesListMobileView from '../DevicesListPage/PageViews/DeviceListMobileView';
const GET_DEVICES_CONSUMABLE_EXPIRATION_QUERY = gql`
  query GetDevicesConsumableExpiration(
    $accountId: ID!
    $locationId: ID!
    $groupByInterval: GroupByInterval!
  ) {
    report {
      uvangel {
        getDevicesConsumableExpiration(
          accountId: $accountId
          locationId: $locationId
          groupByInterval: $groupByInterval
        ) {
          timestamp
          devices {
            serialNumber
            type
            state {
              state
              timestamp
              __typename
            }
            connectivity {
              timestamp
              connected
              __typename
            }
            firmwareVersion {
              value
              timestamp
              pendingValue
              pendingTimestamp
              __typename
            }
            fullLocationPath {
              id
              name
              __typename
            }
            firstConnection
            nickname
            installationDate
            shadowReported
            account {
              id
              name
              __typename
            }
            __typename
          }
        }
      }
    }
  }
`;
type Props = {
  selectedOrg?: SelectedOrgType;
  selectedLocation?: SelectedLocationType;
};

export default function DeviceMaintenancePlanningPage({ selectedOrg, selectedLocation }: Props) {
  const [searchParams, setSearchParams] = useSearchParams();
  const { showBoundary } = useErrorBoundary();

  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('lg'));
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [groupByInterval, setGroupByInterval] = useState(
    (searchParams.get('interval') as GroupByInterval) ?? GroupByInterval.Quarter
  );
  const [locationId, setLocationId] = useState<string | null | undefined>(
    searchParams.get('location') ?? selectedLocation?.id ?? selectedOrg?.rootLocation?.id ?? ''
  );
  const [selectedBar, setSelectedBar] = useState<number>();

  const [islocationSelectorLoading, initLocationList] = useCurrentLocationList(locationId);

  const {
    data: expirationForecast,
    variables: expirationForecastVar,
    loading: expirationForecastLoading,
    refetch,
    error,
  } = useQuery<GetDevicesConsumableExpirationQuery, GetDevicesConsumableExpirationQueryVariables>(
    GET_DEVICES_CONSUMABLE_EXPIRATION_QUERY,
    {
      variables: {
        accountId: selectedOrg?.id ?? '',
        locationId: locationId ?? '',
        groupByInterval: groupByInterval,
      },
      notifyOnNetworkStatusChange: true,
    }
  );
  const selectedDevices = useMemo(() => {
    if (selectedBar === void 0) return null;
    const currentDevices =
      expirationForecast?.report?.uvangel?.getDevicesConsumableExpiration?.at(selectedBar)?.devices;
    if (!currentDevices) return null;

    return {
      currentDevices: currentDevices,
      totalDevices: currentDevices.length,
      totalLocations: new Set(
        currentDevices.map((d) => d.fullLocationPath?.at(d.fullLocationPath.length - 1)?.id)
      ).size,
    };
  }, [expirationForecast, selectedBar]);
  useEffect(() => {
    const currentGroupByInterval = searchParams.get('interval');
    if (!currentGroupByInterval && groupByInterval) {
      setSearchParams(
        (prev) => ({ ...Object.fromEntries(prev.entries()), interval: groupByInterval }),
        {
          replace: true,
        }
      );
    }
    const currentLocationId = searchParams.get('location');
    if (!currentLocationId && locationId) {
      setSearchParams((prev) => ({ ...Object.fromEntries(prev.entries()), location: locationId }), {
        replace: true,
      });
    }
  }, [searchParams, setSearchParams, locationId, groupByInterval]);
  const changeIntervalHandler = useCallback(
    async (interval: GroupByInterval) => {
      if (groupByInterval === interval) return;
      setGroupByInterval(interval);
      setSearchParams((prev) => ({ ...Object.fromEntries(prev.entries()), interval: interval }), {
        replace: true,
      });
      setSelectedBar(void 0);
      await refetch(Object.assign(expirationForecastVar ?? {}, { groupByInterval: interval }));
    },
    [groupByInterval, setSearchParams, refetch, expirationForecastVar]
  );
  if (error) {
    showBoundary(error);
  }
  return (
    <PageContainerFrame pageTitles={['Device Maintenance Planning']}>
      <SettingsPanelFrame title='Device Maintenance Planning'>
        <Stack direction={isMobile ? 'column' : 'row'} justifyContent='space-between' gap={2}>
          {islocationSelectorLoading ? (
            <CircularProgress size={16} />
          ) : (
            <LocationSelectorBreadcrumb
              preLoadedLocationList={initLocationList}
              onLocationSelectCallback={(id) => {
                setLocationId(id);
                setSearchParams(
                  (prev) => ({ ...Object.fromEntries(prev.entries()), location: id }),
                  {
                    replace: true,
                  }
                );
                setSelectedBar(void 0);
                refetch(
                  Object.assign(expirationForecastVar ?? {}, { locationId: locationId ?? '' })
                );
              }}
            />
          )}

          <ButtonGroup disableElevation variant='outlined'>
            <Button
              variant={groupByInterval === GroupByInterval.Month ? 'contained' : void 0}
              onClick={() => changeIntervalHandler(GroupByInterval.Month)}
            >
              Month
            </Button>
            <Button
              variant={groupByInterval === GroupByInterval.Quarter ? 'contained' : void 0}
              onClick={() => changeIntervalHandler(GroupByInterval.Quarter)}
            >
              Quarter
            </Button>
          </ButtonGroup>
        </Stack>
        <Grid container spacing={4}>
          <DeviceMaintenanceBar
            isLoading={expirationForecastLoading}
            devicesConsumableExpiration={
              expirationForecast?.report?.uvangel?.getDevicesConsumableExpiration as Maybe<
                Array<ConsumableExpirationGroupedDevices>
              >
            }
            groupByInterval={groupByInterval}
            selectedBar={selectedBar}
            onBarSelect={setSelectedBar}
            gridConfig={{
              xs: 12,
              sm: 12,
              md: 12,
              lg: 12,
              xl: 12,
            }}
          />
          {selectedDevices ? (
            <Fragment>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography variant='h6'>
                  {selectedDevices.totalDevices}{' '}
                  {selectedDevices.totalDevices > 1 ? 'Devices' : 'Device'} &mdash;{' '}
                  {selectedDevices.totalLocations}{' '}
                  {selectedDevices.totalLocations > 1 ? 'Locations' : 'Location'}
                </Typography>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                {isTablet ? (
                  <DevicesListMobileView
                    selectedOrg={selectedOrg}
                    totalCount={selectedDevices.totalDevices}
                    simple
                    devices={selectedDevices.currentDevices}
                  />
                ) : (
                  <DevicesListView
                    selectedOrg={selectedOrg}
                    totalCount={selectedDevices.totalDevices}
                    simple
                    devices={selectedDevices.currentDevices}
                  />
                )}
              </Grid>
            </Fragment>
          ) : (
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Typography variant='body1' align='center'>
                Select a a time range bar above to display devices needing replacements during that
                period
              </Typography>
            </Grid>
          )}
        </Grid>
      </SettingsPanelFrame>
    </PageContainerFrame>
  );
}
