import React from 'react';
import ToastProvider from './ToastProvider';
import ModalProvider from './ModalProvider';

type Props = { children: JSX.Element | JSX.Element[] };

export enum NotificationTypeEnum {
  TOAST = 'Toast',
  MODAL = 'Modal',
}

// This is a HOC that we use to render the global notification providers
// for both toasts and modals. Splitting these into sub providers simplifies
// the code, allowing us to keep both notification states separate
export default function NotificationProvider({ children }: Props) {
  return (
    <ToastProvider>
      <ModalProvider>{children}</ModalProvider>
    </ToastProvider>
  );
}
