import React from 'react';
import { Box, Typography } from '@mui/material';
import UserAvatar from 'Components/SharedUI/UserAvatar';
import { RoleEnum } from 'Constants/OrgSettingsEnums';

type Props = {
  firstName: string;
  lastName: string;
  email: string;
  roleType: RoleEnum;
};

const { ADMIN, STANDARD_USER, READ_ONLY } = RoleEnum;
const getBackgroundColor = (roleType: RoleEnum): string | undefined => {
  // we can standardize these colors later
  if (roleType === ADMIN) {
    return '#E9D783';
  }
  if (roleType === STANDARD_USER) {
    return '#b8e6ab';
  }
  if (roleType === READ_ONLY) {
    return '#c5d9ed';
  }
  return;
};

export default function UserNameCellCard({ firstName, lastName, email, roleType }: Props) {
  const backgroundColor = getBackgroundColor(roleType);
  return (
    <Box display='flex' alignItems='center' gap={2} minWidth={250}>
      <UserAvatar
        backgroundColor={backgroundColor}
        firstName={firstName}
        lastName={lastName}
        height={50}
        width={50}
        fontSize={15}
      />
      <Box
        display='flex'
        flexDirection='column'
        justifyContent='center'
        alignItems='flex-start'
        gap={0.5}
      >
        <Typography lineHeight={1} noWrap fontWeight='bold'>
          {firstName + ' ' + lastName}
        </Typography>
        <Typography lineHeight={1}>{email}</Typography>
      </Box>
    </Box>
  );
}
