import { useMemo, Fragment } from 'react';
import { useTheme, Card, CardHeader, Avatar, Tooltip } from '@mui/material';
import { FluorescentOutlined as FluorescentIcon } from '@mui/icons-material';
import moment from 'moment';
import { IntShadowValue } from '__generated__/graphql';
interface DeviceUVTimerProps {
  deviceUvTimer?: IntShadowValue | null;
}
export default function DeviceUVTimer({ deviceUvTimer }: DeviceUVTimerProps) {
  const theme = useTheme();
  const uvTimer = useMemo(() => {
    const timer: number = deviceUvTimer?.value || 0;
    return `${(timer / (60 * 60 * 24)).toFixed(1)} Days`;
  }, [deviceUvTimer]);
  return (
    <Tooltip placement='top' title={`As of ${moment(deviceUvTimer?.timestamp).fromNow()}`}>
      <Fragment>
        <Card>
          <CardHeader
            avatar={
              <Avatar sx={{ backgroundColor: theme.palette.info.main }} aria-label='device-type'>
                <FluorescentIcon color='inherit' sx={{ height: 28, width: 28 }} />
              </Avatar>
            }
            title='UV On Time'
            titleTypographyProps={{ variant: 'body2', fontWeight: 500 }}
            subheader={uvTimer}
          />
        </Card>
      </Fragment>
    </Tooltip>
  );
}
