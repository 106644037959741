import { Card, CardHeader, Avatar } from '@mui/material';
import { CalendarTodayOutlined as CalendarIcon } from '@mui/icons-material';
import moment from 'moment';
interface DeviceLastServiceCardProps {
  lastServiceDate?: string;
}

export default function DeviceLastServiceCard({ lastServiceDate }: DeviceLastServiceCardProps) {
  return (
    <Card sx={{ height: '100%' }}>
      <CardHeader
        avatar={
          <Avatar aria-label='device-kit-repl'>
            <CalendarIcon color='inherit' sx={{ height: 28, width: 28 }} />
          </Avatar>
        }
        title='Last Serviced Date'
        titleTypographyProps={{ variant: 'body2', fontWeight: 500 }}
        subheader={moment(lastServiceDate).format('MMM DD, YYYY')}
      />
    </Card>
  );
}
