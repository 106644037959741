import { useMemo } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import { SelectedOrgType } from 'Apollo/ApolloCache';
import { useErrorBoundary } from 'react-error-boundary';
import PageContainerFrame from 'Components/HOC/PageContainerFrame';
import SettingsPanelFrame from 'Components/HOC/SettingsPanelFrame';
import { CircularProgress, Box } from '@mui/material';
import { AlertMetric } from '__generated__/graphql';
import { MetricReportTypeEnum } from 'Constants/FloorsViewEnums';
import RoutePaths from 'Constants/RoutePaths';
import moment from 'moment';
import { LOCATION_CORE_FRAGMENT } from 'fragments';
const GET_ALERT = gql`
  ${LOCATION_CORE_FRAGMENT}
  query Alert($accountId: ID!, $alertId: ID!) {
    alert(accountId: $accountId, alertId: $alertId) {
      id
      location {
        ...LocationCore
      }
      metric
      startTime
      endTime
    }
  }
`;
interface AlertDetailsProps {
  selectedOrg: SelectedOrgType;
}
const ALERT_HOURS_OFFSET = 1;
export default function AlertDetails({ selectedOrg }: AlertDetailsProps) {
  const params = useParams();
  const { showBoundary: showErrorBoundary } = useErrorBoundary();
  const { data, loading, error } = useQuery(GET_ALERT, {
    variables: {
      accountId: selectedOrg?.id ?? '',
      alertId: params.alert_id ?? '',
    },
  });

  const reportPath = useMemo(() => {
    if (!data?.alert) return RoutePaths.PATH_REPORTS;
    const alert = data.alert;
    const locationId = alert.location?.id ?? selectedOrg?.rootLocation?.id;
    const metric = alert.metric;
    const startDate = new Date(alert.startTime);
    const endDate = alert.endTime ? new Date(alert.endTime) : void 0;
    let selectedMetric: MetricReportTypeEnum = MetricReportTypeEnum.CO2;
    switch (metric) {
      case AlertMetric.Humidity:
        selectedMetric = MetricReportTypeEnum.HUMIDITY;
        break;
      case AlertMetric.Temp:
        selectedMetric = MetricReportTypeEnum.TEMP;
        break;
      case AlertMetric.Co2:
        selectedMetric = MetricReportTypeEnum.CO2;
        break;
      case AlertMetric.Voc:
        selectedMetric = MetricReportTypeEnum.VOC;
        break;
      case AlertMetric.Iaq:
        selectedMetric = MetricReportTypeEnum.IAQ;
        break;
    }
    const offsetStartDate = moment(startDate).subtract(ALERT_HOURS_OFFSET, 'hours');
    const alertHighlightStartDate = moment(startDate);
    let alertHighlightEndDate: moment.Moment | null = null;
    let paddedEndDate = moment();
    if (endDate) {
      alertHighlightEndDate = moment(endDate);
      paddedEndDate = moment(endDate).add(ALERT_HOURS_OFFSET, 'hours');
      if (paddedEndDate.isAfter(moment())) {
        paddedEndDate = moment();
      }
    }

    let path = `${RoutePaths.PATH_REPORTS}?locationID=${
      locationId ?? selectedOrg?.rootLocation?.id
    }&selectedMetric=${selectedMetric}&startDate=${offsetStartDate.toISOString()}&endDate=${paddedEndDate.toISOString()}&selectedInterval=PerMinute&selectedTimePeriod=Custom&alertHighlightStartDate=${alertHighlightStartDate.toISOString()}`;
    if (alertHighlightEndDate) {
      path += `&alertHighlightEndDate=${alertHighlightEndDate.toISOString()}`;
    }
    return path;
  }, [data, selectedOrg]);

  if (error) {
    showErrorBoundary(error);
  }

  if (loading) {
    return (
      <PageContainerFrame pageTitles={['Alert Details']}>
        <SettingsPanelFrame title='Alert Details'>
          <Box display='flex' justifyContent='center' width='100%'>
            <CircularProgress />
          </Box>
        </SettingsPanelFrame>
      </PageContainerFrame>
    );
  }
  return <Navigate to={reportPath} replace={true} />;
}
