import React from 'react';
import { Button, Box, Stack, Typography } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';

import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';
import { sideBarWidth } from 'ContinuumApp';

type ErrorBoundaryProps = {
  isMobileView?: boolean;
  children: JSX.Element | JSX.Element[];
};

// This HOC ErrorBoundary component can be placed anywhere within the React tree.
// It can be triggered by one of two ways.
// 1. It automatically will be triggered errors originating from a components render method.
// 2. It is explicitly called via the useErrorBoundary hook.
//    NOTE: Errors originating from origins such as async calls or in-client data processing
//    must use this hook to explicitly render the ErrorBoundary.
export default function ErrorBoundary({ children, isMobileView = false }: ErrorBoundaryProps) {
  function CustomFallback({ error, resetErrorBoundary }) {
    console.error(error);
    // Calling resetErrorBoundary() reloads the child component(s) of the ErrorBoundary that produced the error
    const widthSetting = isMobileView ? '100%' : `calc(100% - ${sideBarWidth}px)`;
    const fallbackPositionOffset = isMobileView ? 0 : sideBarWidth;
    return (
      <Box
        flexDirection='column'
        width={widthSetting}
        height='50%'
        display='flex'
        position='absolute'
        left={fallbackPositionOffset}
        justifyContent='center'
        alignItems='center'
        margin={2}
      >
        <ErrorIcon color='error' sx={{ fontSize: 50 }} />
        <Typography fontWeight='bold' variant='h4'>
          Something went wrong
        </Typography>
        <Stack marginTop={1} alignItems='center'>
          <Typography variant='h6'>Press the button below or try refreshing the page.</Typography>
          <Typography lineHeight={0.75} variant='h6'>
            If the issue continues, please contact your administrator
          </Typography>
        </Stack>
        <Button sx={{ marginTop: 4 }} variant='contained' onClick={resetErrorBoundary}>
          Try again{' '}
        </Button>
      </Box>
    );
  }

  return <ReactErrorBoundary FallbackComponent={CustomFallback}>{children}</ReactErrorBoundary>;
}
