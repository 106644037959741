import {
  Box,
  Button,
  CircularProgress,
  Tooltip,
  Typography,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { useModal, ModalNotificationTypeEnum } from 'Providers/ModalProvider';
import { gql } from '__generated__/gql';
import { useQuery } from '@apollo/client';
import { useErrorBoundary } from 'react-error-boundary';

import PageContainerFrame from 'Components/HOC/PageContainerFrame';
import SettingsPanelFrame from 'Components/HOC/SettingsPanelFrame';
import OrgDisplayAndSwitcher from 'Components/SharedUI/OrgDisplayAndSwitcher';
import OrgUserManagementTable from './OrgUserManagementTable';
import OrgUserManagementCardList from './OrgUserManagementCardList';
import type { SelectedOrgType } from 'Apollo/ApolloCache';

export const GET_CURRENT_ORG_USER_LIST_QUERY = gql(`
query getCurrentOrgUserList($accountId: ID!) {
  account(accountId: $accountId) {
    id
    users {
      id
      firstName
      lastName
      email
      status
      lastLogin
      createdAt
      accountScope(accountId: $accountId) {
        roles {
          account_read
          account_write
          location_read
          location_write
          device_read
          device_write
          users_read
          users_write
          report_read
          report_write
        }
        rootLocation {
          id
        }
      }
    }
  }
}
`);

type Props = {
  selectedOrg: SelectedOrgType;
};

export default function OrgUsersPage({ selectedOrg }: Props) {
  const { showBoundary: showErrorBoundary } = useErrorBoundary();
  const theme = useTheme();
  const isViewport1100pxOrBelow = useMediaQuery('(max-width: 1100px)');
  const userHasOrgWritePermission =
    selectedOrg?.userAccountScope?.roles?.account_write == null
      ? false
      : selectedOrg?.userAccountScope?.roles?.account_write;
  const { dispatchModal } = useModal();
  const {
    data: userListdata,
    error: queryError,
    loading: isLoading,
  } = useQuery(GET_CURRENT_ORG_USER_LIST_QUERY, {
    variables: { accountId: selectedOrg?.id ?? '' },
  });

  if (queryError) {
    showErrorBoundary(queryError);
  }

  const renderUserManagementComponent = () => {
    // we render a different component for smaller viewports.
    if (isViewport1100pxOrBelow) {
      return <OrgUserManagementCardList userListdata={userListdata} selectedOrg={selectedOrg} />;
    }
    return <OrgUserManagementTable userListdata={userListdata} selectedOrg={selectedOrg} />;
  };

  return (
    <PageContainerFrame pageTitles={['Users', selectedOrg?.name]}>
      <SettingsPanelFrame title='Users'>
        <Box display='flex' flexDirection='column' gap={2}>
          <Box display='flex' flexDirection='row' justifyContent='space-between'>
            <Typography variant='h6'>
              {userHasOrgWritePermission ? 'View and manage org members' : 'View org members'}
            </Typography>
            <Tooltip title='Add users to participate in your organization'>
              <Button
                variant='contained'
                sx={{
                  color: theme.palette.success.contrastText,
                  alignSelf: 'flex-end',
                  backgroundColor: theme.palette.success.main,
                  width: 200,
                }}
                onClick={() =>
                  dispatchModal({
                    type: ModalNotificationTypeEnum.ADD_USER_TO_ORG_MODAL,
                  })
                }
              >
                Add user
              </Button>
            </Tooltip>
          </Box>
          <Box>
            <OrgDisplayAndSwitcher />
          </Box>
        </Box>
        {isLoading ? (
          <CircularProgress sx={{ alignSelf: 'center', marginTop: 10 }} size={100} />
        ) : (
          renderUserManagementComponent()
        )}
      </SettingsPanelFrame>
    </PageContainerFrame>
  );
}
