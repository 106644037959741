import React from 'react';
import { Box, Typography } from '@mui/material';
import { formatStatus } from 'Utils/orgUserManagementUtils';
type Props = { status: string | null | undefined };

const getStatusBackroundColor = (status: string | null | undefined): string => {
  if (!status) {
    return 'white';
  }
  // we can customize these colors if we start utilizing more status types
  const statusColorMap = {
    UNCONFIRMED: 'white',
    CONFIRMED: '#48DFA6',
    EXTERNAL_PROVIDER: 'white',
    ARCHIVED: 'white',
    UNKNOWN: 'white',
    RESET_REQUIRED: 'white',
    FORCE_CHANGE_PASSWORD: '#FFE51E',
  };
  return statusColorMap[status] ?? 'white';
};

export default function StatusCellCard({ status }: Props) {
  const statusFormatted = formatStatus(status);
  const backgroundColor = getStatusBackroundColor(status);
  return (
    <Box
      display='flex'
      justifyContent='center'
      width={75}
      padding={0.5}
      borderRadius={10}
      boxShadow={'0 2px 4px rgba(0, 0, 0, 0.2)'}
      sx={{ color: 'black', background: backgroundColor }}
    >
      <Typography fontWeight='semi-bold'>{statusFormatted}</Typography>
    </Box>
  );
}
