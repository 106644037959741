import React from 'react';
import { useReactiveVar } from '@apollo/client';
import { currentUserDataVar } from 'Apollo/ApolloCache';
import DesktopHeaderBar from './DesktopHeaderBar';

type Props = {
  handleToggleSideBar: () => void;
};

export default function ContinuumHeaderContainer({ handleToggleSideBar }: Props) {
  const userData = useReactiveVar(currentUserDataVar);
  const { firstName, lastName, email } = userData?.currentUser ?? {};

  return <DesktopHeaderBar
    firstName={firstName ?? ''}
    lastName={lastName ?? ''}
    email={email ?? ''}
    userData={userData}
    handleToggleSideBar={handleToggleSideBar}
  />;
}
