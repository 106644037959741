/* eslint-disable no-nested-ternary */
/* eslint-disable no-magic-numbers */
import {
  Box,
  Collapse,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { Fragment, useMemo, useState } from 'react';

import { useReactiveVar } from '@apollo/client';
import {
  AdminPanelSettings,
  AutoAwesome as AutoAwesomeIcon,
  Dashboard,
  DeviceHub,
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
} from '@mui/icons-material';
import { currentUserDataVar, SelectedOrgType } from 'Apollo/ApolloCache';
import ContinuumAppPageEnums from 'Constants/ContinuumAppPagesEnums';
import RoutePaths from 'Constants/RoutePaths';
import { NavLink } from 'react-router-dom';

type AdminMenuItemConfigType = {
  primaryText: string;
  icon: JSX.Element;
  path?: string;
  handleClick?: () => void;
  pageType?: ContinuumAppPageEnums;
  disabled?: boolean;
  beta?: boolean;
};

type Props = {
  handleToggleSideBar: () => void;
  currentPage: ContinuumAppPageEnums;
  selectedOrg?: SelectedOrgType;
};

export default function AdminMenuListItem({ handleToggleSideBar }: Props) {
  const currentUser = useReactiveVar(currentUserDataVar);
  const sideBarTextColor = 'white';

  const canAdminSettings = useMemo(() => {
    return (
      !!currentUser?.currentUser?.globalRoles?.account_read &&
      !!currentUser?.currentUser?.globalRoles?.device_read
    );
  }, [currentUser]);

  const adminMenuItemsConfig: Array<AdminMenuItemConfigType> = [];

  adminMenuItemsConfig.push({
    primaryText: 'Dashboard',
    icon: <Dashboard />,
    disabled: true,
    path: '...',
  });
  adminMenuItemsConfig.push({
    primaryText: 'Devices',
    icon: <DeviceHub />,
    path: RoutePaths.PATH_ADMIN_DEVICES,
  });

  const [isAnalysisSubMenuOpen, setIsAnalysisSubMenuOpen] = useState<boolean>(() => {
    // TODO: check for any matching pages, then auto-open this menu, otherwise it is to be closed by default
    return false;
  });

  const AnalysisMenuItem = ({ config }: { config: AdminMenuItemConfigType }): JSX.Element => {
    const { primaryText, path, icon, disabled, beta } = config;
    return (
      <NavLink
        to={path ?? RoutePaths.PATH_PAGE_NOT_FOUND}
        style={{ color: 'inherit', textDecoration: 'none' }}
        end
      >
        {({ isActive }) => (
          <ListItem
            disablePadding
            onClick={(e) => {
              handleToggleSideBar();
              if (disabled) {
                e.preventDefault();
              }
            }}
            sx={{
              pl: 4,
              cursor: disabled ? 'not-allowed' : 'pointer',
            }}
          >
            <ListItemButton disabled={disabled} selected={isActive}>
              <ListItemIcon
                sx={{
                  color: sideBarTextColor,
                }}
              >
                {icon}
              </ListItemIcon>
              <ListItemText
                sx={{
                  color: sideBarTextColor,
                }}
                primary={primaryText}
                secondary={
                  disabled ? (
                    'Coming Soon'
                  ) : beta ? (
                    <Box component='span' display='flex' alignItems='center' gap={1}>
                      Beta <AutoAwesomeIcon fontSize='inherit' />
                    </Box>
                  ) : null
                }
                secondaryTypographyProps={{
                  color: 'inherit',
                }}
              />
            </ListItemButton>
          </ListItem>
        )}
      </NavLink>
    );
  };

  if (!canAdminSettings) return null;

  return (
    <Fragment>
      <ListItem disablePadding>
        <ListItemButton onClick={() => setIsAnalysisSubMenuOpen(!isAnalysisSubMenuOpen)}>
          <ListItemIcon
            sx={{
              color: sideBarTextColor,
            }}
          >
            {<AdminPanelSettings />}
          </ListItemIcon>
          <ListItemText
            primary='UV Angel Admin'
            sx={{
              color: sideBarTextColor,
            }}
          />
          {isAnalysisSubMenuOpen ? (
            <ExpandLessIcon
              sx={{
                color: sideBarTextColor,
              }}
            />
          ) : (
            <ExpandMoreIcon
              sx={{
                color: sideBarTextColor,
              }}
            />
          )}
        </ListItemButton>
      </ListItem>
      <Collapse in={isAnalysisSubMenuOpen}>
        <List disablePadding>
          {adminMenuItemsConfig.map((config, index) => {
            return <AnalysisMenuItem key={index} config={config} />;
          })}
        </List>
      </Collapse>
    </Fragment>
  );
}
