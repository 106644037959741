import { gql } from '@apollo/client';

export const SURFACE_TOTAL_TOUCHES = gql`
  query TotalTouches(
    $accountId: ID!
    $locationId: ID!
    $startDate: Date!
    $endDate: Date!
    $timeUnit: TimeInterval!
    $utcOffset: UtcOffset
  ) {
    report {
      uvangel {
        totalTouchesByTimeframe(
          accountId: $accountId
          locationId: $locationId
          timeFrame: {
            startDate: $startDate
            endDate: $endDate
            timeInterval: $timeUnit
            utcOffset: $utcOffset
          }
        ) {
          locationReport {
            data {
              x
              y
            }
          }
        }
      }
    }
  }
`;

export const SURFACE_TOTAL_DISINFECTION_DURATION = gql`
  query TotalDisinfection($accountId: ID!, $locationId: ID!, $timeFrame: TimeFrameInput!) {
    report {
      uvangel {
        totalDisinfectionSecondsByTimeframe(
          accountId: $accountId
          locationId: $locationId
          timeFrame: $timeFrame
        ) {
          locationReport {
            data {
              x
              y
            }
          }
        }
      }
    }
  }
`;

export const AVERAGE_TOUCH_DURATION = gql`
  query AverageTouchDurationByTimeframe(
    $accountId: ID!
    $locationId: ID!
    $timeFrame: TimeFrameInput!
  ) {
    report {
      uvangel {
        averageTouchDurationByTimeframe(
          accountId: $accountId
          locationId: $locationId
          timeFrame: $timeFrame
        ) {
          locationReport {
            data {
              x
              y
            }
          }
        }
      }
    }
  }
`;

export const AVERAGE_DISINFECTION_PERCENTAGE = gql`
  query AvgDisinfectionPercentageByTimeframe(
    $accountId: ID!
    $locationId: ID!
    $timeFrame: TimeFrameInput!
  ) {
    report {
      uvangel {
        avgDisinfectionPercentageByTimeframe(
          accountId: $accountId
          locationId: $locationId
          timeFrame: $timeFrame
        ) {
          locationReport {
            data {
              x
              y
            }
          }
        }
      }
    }
  }
`;
export const SURFACE_INTERACTIONS_HEATMAP = gql`
  query SurfaceInteractionHeatMap($accountId: ID!, $timeRange: TimeRangeInput!, $locationId: ID) {
    report {
      uvangel {
        surfaceInteractionHeatMap(
          accountId: $accountId
          timeRange: $timeRange
          locationId: $locationId
        ) {
          dayOfWeek
          value
          hour
        }
      }
    }
  }
`;

export const DISINFECTION_HEATMAP = gql`
  query DisinfectionHeatMap($accountId: ID!, $timeRange: TimeRangeInput!, $locationId: ID) {
    report {
      uvangel {
        disinfectionHeatMap(accountId: $accountId, timeRange: $timeRange, locationId: $locationId) {
          dayOfWeek
          hour
          value
        }
      }
    }
  }
`;
