import React, { useState } from 'react';
import { gql } from '__generated__/gql';
import { useErrorBoundary } from 'react-error-boundary';
import Fuse from 'fuse.js';
import {
  CircularProgress,
  Stack,
  Checkbox,
  FormGroup,
  FormControlLabel,
  TextField,
} from '@mui/material';
import { useQuery } from '@apollo/client';

type Props = {
  selectedLocationID: string;
  selectedOrgID: string;
  handleUpdateSelectedSubLocations: (arg: Array<string>) => void;
  selectedSubLocations: string[];
  isDisabled: boolean;
};

export const GET_ALL_SUB_LOCATIONS_QUERY = gql(`
  query SubLocationFiltersQuery($accountId: ID!, $locationId: ID!) {
    location(accountId: $accountId, locationId: $locationId) {
      id
      immediateSublocations {
          name
          id
      }
    }
  }
`);

const maxSelectionLimit = 5;

const searchOptionsConfig = {
  // Specifies which fields to search
  keys: ['name'],
};

type SubLocationType = {
  id: string;
  name: string;
};

export default function SubLocationFilterOptionsContainer({
  selectedLocationID,
  selectedOrgID,
  handleUpdateSelectedSubLocations,
  selectedSubLocations = [],
  isDisabled,
}: Props) {
  const [subLocationsList, setSubLocationsList] = useState<SubLocationType[]>([]);
  const [unfilteredList, setUnfilteredList] = useState<SubLocationType[]>([]);
  const { showBoundary: showErrorBoundary } = useErrorBoundary();
  const numberOfBoxsChecked = selectedSubLocations.length;

  const { loading: isLoading, error: queryError } = useQuery(GET_ALL_SUB_LOCATIONS_QUERY, {
    onCompleted: (data) => {
      const immediateSublocations = data?.location?.immediateSublocations as SubLocationType[];
      setUnfilteredList(immediateSublocations);
      setSubLocationsList(immediateSublocations);
    },
    skip: !selectedLocationID,
    variables: {
      accountId: selectedOrgID,
      locationId: selectedLocationID,
    },
  });
  if (queryError) {
    showErrorBoundary(queryError);
  }

  const handleCheckboxChange = (id) => {
    let updatedSelectedIDs: Array<string>;
    if (selectedSubLocations.includes(id)) {
      updatedSelectedIDs = selectedSubLocations.filter((selectedId) => selectedId !== id);
    } else {
      if (numberOfBoxsChecked === maxSelectionLimit) {
        return;
      }
      updatedSelectedIDs = [...selectedSubLocations, id];
    }
    handleUpdateSelectedSubLocations(updatedSelectedIDs);
  };

  const fuse = new Fuse(unfilteredList, searchOptionsConfig);
  const handleFilterList = (event: React.ChangeEvent<HTMLInputElement>) => {
    const targetString = event.target.value;
    if (targetString === '') {
      // resets to unfiltered list
      setSubLocationsList(unfilteredList);
    } else {
      const rawResults = fuse.search(targetString);
      const updatedList = rawResults.map((res) => res.item);
      setSubLocationsList(updatedList);
    }
  };

  return (
    <Stack padding={2} overflow='auto' maxHeight={300}>
      {isLoading ? (
        <CircularProgress size={20} />
      ) : (
        <>
          <TextField label='Filter available sub-locations by name' onChange={handleFilterList} />
          <FormGroup>
            {subLocationsList.map((location) => {
              const isChecked = selectedSubLocations.includes(location.id);
              const isCheckboxDisabled =
                (!isChecked && numberOfBoxsChecked === maxSelectionLimit) || isDisabled;
              return (
                <FormControlLabel
                  key={location.id}
                  control={
                    <Checkbox
                      checked={isChecked}
                      disabled={isCheckboxDisabled}
                      onChange={() => handleCheckboxChange(location.id)}
                      sx={{ color: '#BDBDBD' }}
                    />
                  }
                  label={location.name}
                />
              );
            })}
          </FormGroup>
        </>
      )}
    </Stack>
  );
}
