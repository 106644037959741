import { gql } from '@apollo/client';
import { LOCATION_CORE_FRAGMENT, LOCATION_MODE_FRAGMENT } from 'fragments';

export const LOCATION_MODE = gql`
  ${LOCATION_MODE_FRAGMENT}
  ${LOCATION_CORE_FRAGMENT}
  query GetLocationResponseModeStatus(
    $accountId: ID!
    $locationId: ID
    $locationResponseStatus: LocationResponseStatus
  ) {
    getLocationResponseModeStatus(
      accountId: $accountId
      locationId: $locationId
      locationResponseStatus: $locationResponseStatus
    ) {
      locationResponseModes {
        ...LocationMode
        location {
          ...LocationCore
        }
      }
    }
  }
`;
