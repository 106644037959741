import { useMemo } from 'react';
import { useTheme, Card, CardHeader, Avatar, Tooltip } from '@mui/material';
import { FluorescentOutlined as FluorescentIcon } from '@mui/icons-material';
import moment from 'moment';
import { FloatShadowValue } from '__generated__/graphql';
interface DeviceBulbCyclesProps {
  deviceBulbCycles?: FloatShadowValue | null;
}
export default function DeviceBulbCycles({ deviceBulbCycles }: DeviceBulbCyclesProps) {
  const theme = useTheme();
  const uvTimer = useMemo(() => {
    const cycles: number = deviceBulbCycles?.value || 0;
    return cycles;
  }, [deviceBulbCycles]);
  return (
    <Tooltip placement='top' title={`As of ${moment(deviceBulbCycles?.timestamp).fromNow()}`}>
      <Card>
        <CardHeader
          avatar={
            <Avatar sx={{ backgroundColor: theme.palette.info.main }} aria-label='device-type'>
              <FluorescentIcon color='inherit' sx={{ height: 28, width: 28 }} />
            </Avatar>
          }
          title='UV Bulb Cycles'
          titleTypographyProps={{ variant: 'body2', fontWeight: 500 }}
          subheader={uvTimer.toLocaleString()}
        />
      </Card>
    </Tooltip>
  );
}
