/* eslint-disable indent */
/* eslint-disable no-nested-ternary */
import { gql, useLazyQuery, useMutation, useQuery, useReactiveVar } from '@apollo/client';
import { Button, CircularProgress, Grid, Stack } from '@mui/material';
import {
  GetAllEnvOverviewDataQuery,
  GetAllEnvOverviewDataQueryVariables,
  LocationFloorPlanQuery,
  LocationFloorPlanQueryVariables,
  TimeInterval,
  UpdateLocationMapFeaturesMutation,
  UpdateLocationMapFeaturesMutationVariables,
} from '__generated__/graphql';
import { currentUserDataVar, SelectedLocationType, SelectedOrgType } from 'Apollo/ApolloCache';
import PageContainerFrame from 'Components/HOC/PageContainerFrame';
import SettingsPanelFrame from 'Components/HOC/SettingsPanelFrame';
import LocationSelectorBreadcrumb from 'Components/LocationSelectorBreadcrumb/LocationSelectorBreadcrumb';
import useCurrentLocationList from 'Hooks/useCurrentLocationList';
import { ToastNotificationSeverityTypeEnum, useToast } from 'Providers/ToastProvider';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useErrorBoundary } from 'react-error-boundary';
import { useSearchParams } from 'react-router-dom';
import { getPastDateTimeRange } from 'Utils/getPastDateTimeRange';
import { ImdfFeature } from 'Utils/map';
import { GET_ENV_DATA_QUERY } from '../OverviewPage/OverviewPage';
import FloorPlanEditMapboxView, { EditRoomOp } from './PageViews/FloorPlanEditMapboxView';
import FloorPlanMapboxView from './PageViews/FloorPlanMapboxView';
import MissingFloorPlan from './PageViews/MissingFloorPlan';
import NotAFloor from './PageViews/NotAFloor';

const LOCATION_FLOORPLAN = gql`
  query LocationFloorPlan($accountId: ID!, $locationId: ID!) {
    location(accountId: $accountId, locationId: $locationId) {
      id
      name
      description
      numDevices
      archetype
      floorPlanImageUrl
      tags
      immediateSublocations {
        id
        name
        description
        archetype
        tags
        numDevices
        mapFeatures {
          feature {
            geometry
            properties
            type
          }
        }
      }
    }
  }
`;

export const LOCATION_UPDATE_MAPFEATURES = gql`
  mutation UpdateLocationMapFeatures(
    $accountId: ID!
    $locationId: ID!
    $mapFeatures: [LocationMapFeatureInput]
  ) {
    updateLocation(accountId: $accountId, locationId: $locationId, mapFeatures: $mapFeatures) {
      id
      mapFeatures {
        feature {
          geometry
          properties
          type
        }
      }
    }
  }
`;
type Props = {
  selectedOrg?: SelectedOrgType;
  selectedLocation?: SelectedLocationType;
};

enum FloorPlanMode {
  VIEW,
  EDIT,
}

export default function FloorPlanPage({ selectedLocation, selectedOrg }: Props) {
  const currentUser = useReactiveVar(currentUserDataVar);
  const [searchParams, setSearchParams] = useSearchParams();
  const { showBoundary } = useErrorBoundary();
  const { dispatchToast } = useToast();

  const [mode, setMode] = useState<FloorPlanMode>(
    searchParams.get('edit') === '1' ? FloorPlanMode.EDIT : FloorPlanMode.VIEW
  );
  const [locationId, setLocationId] = useState<string | null | undefined>(
    searchParams.get('location') ?? selectedLocation?.id ?? selectedOrg?.rootLocation?.id ?? ''
  );
  const [reportData, setReportData] =
    useState<Record<string, NonNullable<GetAllEnvOverviewDataQuery['report']>['uvangel']>>();
  const [isLocationSelectorLoading, initLocationList] = useCurrentLocationList(locationId);

  const {
    data: location,
    loading: isLocationLoading,
    error: locationError,
    refetch: refetchLocation,
  } = useQuery<LocationFloorPlanQuery, LocationFloorPlanQueryVariables>(LOCATION_FLOORPLAN, {
    variables: {
      accountId: selectedOrg?.id ?? '',
      locationId: locationId ?? '',
    },
    notifyOnNetworkStatusChange: true,
  });
  const [fetchReport] = useLazyQuery<
    GetAllEnvOverviewDataQuery,
    GetAllEnvOverviewDataQueryVariables
  >(GET_ENV_DATA_QUERY);
  const [updateMapFeatures] = useMutation<
    UpdateLocationMapFeaturesMutation,
    UpdateLocationMapFeaturesMutationVariables
  >(LOCATION_UPDATE_MAPFEATURES);
  if (locationError) {
    showBoundary(locationError);
  }

  const editOption = useMemo(() => searchParams.get('edit') === '1', [searchParams]);
  const hasEditPermission = useMemo(
    () => !!selectedOrg?.userAccountScope?.roles?.location_write,
    [selectedOrg]
  );

  const changeModeHandler = useCallback(
    (newMode: FloorPlanMode) => {
      setMode(newMode);
      setSearchParams(
        (prev) => {
          const prevParams = {
            ...Object.fromEntries(prev.entries()),
          };
          if (newMode === FloorPlanMode.EDIT) {
            prevParams.edit = '1';
          } else {
            delete prevParams['edit'];
          }
          return prevParams;
        },
        {
          replace: true,
        }
      );
    },
    [setSearchParams]
  );
  const editRoomHandler = useCallback(
    async (roomId: string, op: EditRoomOp, features?: ImdfFeature<GeoJSON.Polygon>[]) => {
      try {
        const variables: UpdateLocationMapFeaturesMutationVariables = {
          accountId: selectedOrg?.id as string,
          locationId: roomId,
          mapFeatures: null,
        };

        if ((op === 'ADD' || op === 'UPDATE') && features) {
          variables.mapFeatures = features.map((v) => ({
            feature: {
              type: v.type,
              geometry: v.geometry,
              properties: v.properties,
            },
            baseLocationId: locationId as string,
          }));
        }
        await updateMapFeatures({ variables });
        await refetchLocation();
        dispatchToast({
          severity: ToastNotificationSeverityTypeEnum.SUCCESS,
          title: 'Floor Plan Updated Successfully',
        });
      } catch (err: unknown) {
        dispatchToast({
          severity: ToastNotificationSeverityTypeEnum.ERROR,
          title: 'Could not update Floor Plan',
        });
      }
    },
    [dispatchToast, locationId, refetchLocation, selectedOrg?.id, updateMapFeatures]
  );

  useEffect(() => {
    if (!location?.location?.immediateSublocations) return;
    let _active = true;
    loadReports();
    return () => {
      _active = false;
    };
    async function loadReports() {
      if (!location?.location?.immediateSublocations) return Promise.resolve();
      const [startDate, endDate] = getPastDateTimeRange(12, 'hours');
      const responses = await Promise.all(
        location.location.immediateSublocations
          .filter((loc) => loc?.archetype?.toLocaleLowerCase() === 'room' && loc?.numDevices)
          .map((loc) =>
            fetchReport({
              variables: {
                accountId: selectedOrg?.id as string,
                locationId: loc?.id as string,
                timeFrame: {
                  startDate: startDate,
                  endDate: endDate,
                  timeInterval: TimeInterval.Yearly,
                },
              },
            }).then((result) => ({ locationId: loc?.id, report: result.data?.report?.uvangel }))
          )
      );
      if (!_active) return Promise.resolve();
      responses.forEach((res) => {
        setReportData((prev) => ({ ...prev, [res.locationId as string]: res.report }));
      });
    }
  }, [location, fetchReport, selectedOrg]);

  useEffect(() => {
    if (!location) return;
    if (
      location?.location?.archetype?.toLocaleLowerCase() === 'floor' &&
      location?.location?.floorPlanImageUrl &&
      (hasEditPermission || mode !== FloorPlanMode.EDIT)
    )
      return;
    changeModeHandler(FloorPlanMode.VIEW);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser, mode, location]);

  return (
    <PageContainerFrame pageTitles={['Floorplans']}>
      <SettingsPanelFrame title='Floor Plan'>
        <Stack direction='row' justifyContent='space-between'>
          {isLocationSelectorLoading ? (
            <CircularProgress size={24} />
          ) : (
            <LocationSelectorBreadcrumb
              preLoadedLocationList={initLocationList}
              onLocationSelectCallback={(id) => {
                setLocationId(id);
                setSearchParams(
                  (prev) => ({ ...Object.fromEntries(prev.entries()), location: id }),
                  {
                    replace: true,
                  }
                );
                refetchLocation({ accountId: selectedOrg?.id ?? '', locationId: locationId ?? '' });
              }}
            />
          )}
          {mode === FloorPlanMode.VIEW ? (
            <Button
              variant='outlined'
              sx={{ display: hasEditPermission ? 'block' : 'none' }}
              onClick={() => changeModeHandler(FloorPlanMode.EDIT)}
            >
              Edit
            </Button>
          ) : (
            <Button
              disableElevation
              variant='contained'
              onClick={() => changeModeHandler(FloorPlanMode.VIEW)}
            >
              Done Editing
            </Button>
          )}
        </Stack>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            {isLocationLoading ? (
              <Stack
                width='100%'
                height='40vh'
                display='flex'
                justifyContent='center'
                alignItems='center'
              >
                <CircularProgress size={32} />
              </Stack>
            ) : location?.location?.archetype?.toLocaleLowerCase() === 'floor' ? (
              location.location.floorPlanImageUrl ? (
                editOption ? (
                  <FloorPlanEditMapboxView
                    {...(location?.location as NonNullable<LocationFloorPlanQuery['location']>)}
                    onEditRoom={editRoomHandler}
                    refresh={refetchLocation}
                  />
                ) : (
                  <FloorPlanMapboxView
                    {...(location?.location as NonNullable<LocationFloorPlanQuery['location']>)}
                    report={reportData}
                  />
                )
              ) : (
                <MissingFloorPlan
                  selectedLocationId={locationId as string}
                  selectedOrgId={selectedOrg?.id as string}
                  onUpdateFloorPlan={() => refetchLocation()}
                />
              )
            ) : (
              <NotAFloor />
            )}
          </Grid>
        </Grid>
      </SettingsPanelFrame>
    </PageContainerFrame>
  );
}
