/* eslint-disable no-magic-numbers */
import { Box, IconButton, Typography, Tooltip } from '@mui/material';
import { ContentCopy as CopyIcon, Check as CheckIcon } from '@mui/icons-material';
import useCopy from 'Hooks/useCopy';

interface DeviceTitleCellProps {
  serialNumber: string;
  fullLocation: string;
  nickname?: string | null;
}

export default function DeviceTitleCell({
  serialNumber,
  fullLocation,
  nickname,
}: DeviceTitleCellProps) {
  const [copied, copyText] = useCopy();
  return (
    <Box
      width='100%'
      display='flex'
      flexDirection='column'
      justifyContent='center'
      alignItems='flex-start'
    >
      <Box width='100%' display='flex'>
        <Box flex='1 1 100%' minWidth={0} display='flex' justifyContent='flex-start'>
          <Typography
            sx={{
              direction: 'rtl',
            }}
            noWrap
            fontWeight='bold'
            color='gray'
            variant='body2'
          >
            {fullLocation}
          </Typography>
        </Box>
      </Box>
      <Box display='flex' flexGrow={1} gap={0.5} alignItems='center'>
        <Tooltip title={copied ? 'Copied' : 'Copy Serial Number'}>
          {copied ? (
            <IconButton onClick={(e) => e.preventDefault} size='small' sx={{ pl: 0, ml: 0 }}>
              <CheckIcon color='success' fontSize='small' sx={{ pl: 0, ml: 0 }} />
            </IconButton>
          ) : (
            <IconButton
              onClick={(e) => copyText(e, serialNumber)}
              size='small'
              sx={{ pl: 0, ml: 0 }}
            >
              <CopyIcon fontSize='small' sx={{ pl: 0, ml: 0 }} />
            </IconButton>
          )}
        </Tooltip>
        {nickname ? (
          <Box display='flex' justifyContent='center' alignItems='flex-start' gap={1}>
            <Typography variant='body2' noWrap>
              {nickname}
            </Typography>
            <Typography variant='body2' component='span' sx={{ display: 'flex' }}>
              <span>(</span>
              <Typography
                sx={{ maxWidth: 100, direction: 'rtl', '&::after': '\\2768' }}
                noWrap
                variant='body2'
              >
                {serialNumber}
              </Typography>
              <span>)</span>
            </Typography>
          </Box>
        ) : (
          <Typography variant='body2'>{serialNumber}</Typography>
        )}
      </Box>
    </Box>
  );
}
