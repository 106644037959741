import { gql } from '@apollo/client';

export const LOCATION_MODE_FRAGMENT = gql`
  fragment LocationMode on LocationResponseMode {
    _id
    activatedAt
    createdAt
    inactiveAt
    mode
    status
    type
  }
`;
