import { OrgCoreFragment } from '__generated__/graphql';
import { setDefaultOrgVar, setSelectedLocationVar, setSelectedOrgVar } from 'Apollo/ApolloCache';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

export default function useSwitchOrg() {
  const navigate = useNavigate();
  const switchOrg = useCallback(
    (org: OrgCoreFragment) => {
      navigate({ pathname: window.location.pathname });
      localStorage.setItem('SELECTED_ORGANIZATION_ID', org.id);
      setSelectedOrgVar({
        ...org,
        id: org.id,
      });
      setSelectedLocationVar({
        id: org.rootLocation?.id as string,
        name: org.rootLocation?.name as string,
        type: org.rootLocation?.type as string
      });
    },
    [navigate]
  );
  const switchDefaultOrg = useCallback(
    (org: OrgCoreFragment) => {
      navigate({ pathname: window.location.pathname });
      localStorage.setItem('SELECTED_ORGANIZATION_ID', org.id);
      localStorage.setItem('SELECTED_USER_ORGANIZATION_ID', org.id);
      setSelectedOrgVar({
        ...org,
        id: org.id,
      });
      setSelectedLocationVar({
        id: org.rootLocation?.id as string,
        name: org.rootLocation?.name as string,
        type: org.rootLocation?.type as string,
      });
      setDefaultOrgVar({ ...org, id: org.id });
    },
    [navigate]
  );
  return [switchOrg, switchDefaultOrg] as const;
}
