import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';

const Footer = () => {
  const theme = useTheme();
  return (
    <Box
      component='footer'
      sx={{
        py: 2,
        px: 3,
        textAlign: 'center',
        mt: 'auto',
      }}
      color={theme.palette.text.secondary}
    >
      <Typography variant='body1' sx={{ mb: 1 }}>
        © {new Date().getFullYear()} UV Angel. All Rights Reserved.
      </Typography>
      <Typography variant='body2'>
        UV Angel does not make health claims. UV Angel Air and Surface treatment systems are not
        medical devices and do not claim to prevent infections.
      </Typography>
    </Box>
  );
};

export default Footer;
