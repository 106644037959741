/* eslint-disable no-magic-numbers */
import moment from 'moment';
import { BinInterval, TimeInterval } from '__generated__/graphql';

const AUTO_HOURS_LIMIT_PER_MINUTE = 24 * 2;
const AUTO_HOURS_LIMIT_HOURLY = 24 * 14;
const AUTO_HOURS_LIMIT_DAILY = 24 * 31;
const AUTO_HOURS_LIMIT_WEEKLY = 24 * 90;

export function autoSelectedInterval(startDate: Date | string, endDate: Date | string) {
  const hours = moment(endDate).diff(startDate, 'hours', true);
  if (hours <= AUTO_HOURS_LIMIT_PER_MINUTE) {
    return TimeInterval.PerMinute;
  } else if (hours <= AUTO_HOURS_LIMIT_HOURLY) {
    return TimeInterval.Hourly;
  } else if (hours <= AUTO_HOURS_LIMIT_DAILY) {
    return TimeInterval.Daily;
  } else if (hours <= AUTO_HOURS_LIMIT_WEEKLY) {
    return TimeInterval.Weekly;
  } else {
    return TimeInterval.Monthly;
  }
}

export function manualIntervalOptions(startDate: Date | string, endDate: Date | string) {
  const hours = moment(endDate).diff(startDate, 'hours', true);
  if (hours <= AUTO_HOURS_LIMIT_HOURLY) {
    return [TimeInterval.PerMinute, TimeInterval.Hourly, TimeInterval.Daily];
  } else if (hours <= AUTO_HOURS_LIMIT_DAILY) {
    return [TimeInterval.Hourly, TimeInterval.Daily, TimeInterval.Weekly];
  } else {
    return [TimeInterval.Weekly, TimeInterval.Monthly];
  }
}

// Show at least 30 data points at all times
const MIN_DATA_POINTS = 30;
const AUTO_MINUTES_LIMIT_PER_ONE_MINUTE = MIN_DATA_POINTS;
const AUTO_MINUTES_LIMIT_PER_FIVE_MINUTES = MIN_DATA_POINTS * 5;
const AUTO_MINUTES_LIMIT_PER_TEN_MINUTES = MIN_DATA_POINTS * 10;
const AUTO_MINUTES_LIMIT_PER_FIFTEEN_MINUTES = MIN_DATA_POINTS * 15;
const AUTO_MINUTES_LIMIT_PER_THIRTY_MINUTES = MIN_DATA_POINTS * 30;

export function generateAutoSelectedBinInterval(startDate: Date | string, endDate: Date | string) {
  const minutes = moment(endDate).diff(startDate, 'minutes', true);
  console.log('Total Minutes', minutes);
  if (minutes <= AUTO_MINUTES_LIMIT_PER_ONE_MINUTE) {
    return BinInterval.OneMinute;
  } else if (minutes <= AUTO_MINUTES_LIMIT_PER_FIVE_MINUTES) {
    return BinInterval.FiveMinutes;
  } else if (minutes <= AUTO_MINUTES_LIMIT_PER_TEN_MINUTES) {
    return BinInterval.TenMinutes;
  } else if (minutes <= AUTO_MINUTES_LIMIT_PER_FIFTEEN_MINUTES) {
    return BinInterval.FifteenMinutes;
  } else if (minutes <= AUTO_MINUTES_LIMIT_PER_THIRTY_MINUTES) {
    return BinInterval.ThirtyMinutes;
  } else {
    return BinInterval.OneHour;
  }
}

export function generateManualBinIntervalOptions(startDate: Date | string, endDate: Date | string) {
  const minutes = moment(endDate).diff(startDate, 'minutes', true);
  if (minutes <= AUTO_MINUTES_LIMIT_PER_FIVE_MINUTES) {
    return [BinInterval.OneMinute, BinInterval.FiveMinutes, BinInterval.TenMinutes];
  } else if (minutes <= AUTO_MINUTES_LIMIT_PER_TEN_MINUTES) {
    return [BinInterval.FiveMinutes, BinInterval.TenMinutes, BinInterval.FifteenMinutes];
  } else if (minutes <= AUTO_MINUTES_LIMIT_PER_FIFTEEN_MINUTES) {
    return [BinInterval.TenMinutes, BinInterval.FifteenMinutes, BinInterval.ThirtyMinutes];
  } else if (minutes <= AUTO_MINUTES_LIMIT_PER_THIRTY_MINUTES) {
    return [BinInterval.FifteenMinutes, BinInterval.ThirtyMinutes, BinInterval.OneHour];
  } else {
    return [BinInterval.ThirtyMinutes, BinInterval.OneHour];
  }
}

export const BIN_INTERVAL_LABELS = {
  [BinInterval.OneMinute]: 'One Minute',
  [BinInterval.FiveMinutes]: '5 Minutes',
  [BinInterval.TenMinutes]: '10 Minutes',
  [BinInterval.FifteenMinutes]: '15 Minutes',
  [BinInterval.ThirtyMinutes]: '30 Minutes',
  [BinInterval.OneHour]: 'One Hour',
};
