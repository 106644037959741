/* eslint-disable no-magic-numbers */
import { gql, useMutation, useReactiveVar } from '@apollo/client';
import { Close as CloseIcon } from '@mui/icons-material';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  TextField,
} from '@mui/material';
import { currentUserDataVar } from 'Apollo/ApolloCache';
import { ToastNotificationSeverityTypeEnum, useToast } from 'Providers/ToastProvider';
import {
  CreateOrganizationMutation,
  CreateOrganizationMutationVariables,
} from '__generated__/graphql';
import { useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';

interface Props {
  onModalClose: () => void;
  modalProps?: unknown;
}
interface CreateOrgForm {
  name: string;
  adminNotAddedToAccount?: boolean;
}
const CREATE_ORGANIZATION = gql`
  mutation CreateOrganization(
    $name: String!
    $locationTypes: [String]
    $adminNotAddedToAccount: Boolean
  ) {
    createAccount(
      name: $name
      locationTypes: $locationTypes
      adminNotAddedToAccount: $adminNotAddedToAccount
    ) {
      id
      name
    }
  }
`;
export default function CreateOrganization({ onModalClose }: Props) {
  const { dispatchToast } = useToast();
  const currentUser = useReactiveVar(currentUserDataVar);
  const {
    control,
    handleSubmit,
    formState: { isDirty },
  } = useForm<CreateOrgForm>({ defaultValues: { name: '' } });
  const [createOrganization, { loading }] = useMutation<
    CreateOrganizationMutation,
    CreateOrganizationMutationVariables
  >(CREATE_ORGANIZATION);
  const handleClearURLParams = useCallback(() => {
    window.location.href = window.location.pathname;
  }, []);
  const saveHandler = useCallback(
    (d: CreateOrgForm) => {
      if (!isDirty) {
        onModalClose();
        return;
      }
      createOrganization({
        variables: {
          name: d.name,
          adminNotAddedToAccount: d.adminNotAddedToAccount ?? void 0,
        },
      })
        .then((data) => {
          if (data.errors) {
            throw new Error('Create Organization Failed');
          }
          dispatchToast({
            severity: ToastNotificationSeverityTypeEnum.SUCCESS,
            title: 'Organization Created',
            message: `Redirecting to Organization: ${d.name}`,
          });
          const org = data.data?.createAccount;
          localStorage.setItem('SELECTED_ORGANIZATION_ID', org?.id ?? '');
          handleClearURLParams();
        })
        .catch((e) => {
          dispatchToast({
            severity: ToastNotificationSeverityTypeEnum.ERROR,
            title: 'Could not Create Organization',
            message: e.message,
          });
        })
        .finally(() => {
          onModalClose();
        });
    },
    [dispatchToast, createOrganization, onModalClose, isDirty, handleClearURLParams]
  );
  return (
    <Dialog
      open={true}
      onClose={() => {
        onModalClose();
      }}
      fullWidth
      maxWidth='sm'
    >
      <DialogTitle>Create Organization</DialogTitle>
      <IconButton
        onClick={onModalClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Controller
          name='name'
          control={control}
          rules={{
            required: {
              value: true,
              message: 'Name is required',
            },
          }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              fullWidth
              label='Name'
              {...field}
              error={!!error}
              helperText={error ? error.message : void 0}
              disabled={loading}
            />
          )}
        />
        {currentUser?.currentUser?.globalRoles?.account_read && (
          <Controller
            name='adminNotAddedToAccount'
            control={control}
            render={({ field }) => (
              <FormControlLabel
                control={<Checkbox {...field} />}
                label='This Organization is for a customer - do not add me as a member'
              />
            )}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Box width='100%' gap={1} display='flex' justifyContent='flex-end' alignItems='center'>
          <Button
            color='primary'
            sx={{ fontWeight: 500 }}
            onClick={onModalClose}
            disabled={loading}
          >
            Cancel
          </Button>
          <Button
            disabled={loading}
            color='error'
            sx={{ fontWeight: 500 }}
            onClick={handleSubmit(saveHandler)}
            endIcon={loading && <CircularProgress color='inherit' size={16} />}
          >
            Save
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}
