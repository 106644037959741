import React from 'react';
import { Box, Button, Divider, Typography, LinearProgress, useTheme, Stack } from '@mui/material';
import { SubscriptionStatus } from '__generated__/graphql';
import LensIcon from '@mui/icons-material/Lens';
import TransferWithinAStationIcon from '@mui/icons-material/TransferWithinAStation';

const { Active, Incomplete, IncompleteExpired, Trialing, PastDue, Canceled, Unpaid } =
  SubscriptionStatus;

type Props = {
  isRedirecting: boolean;
  handleGetPortalSession: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  subscription: any;
  isLoading: boolean;
};

const formatTimeStamp = (inputDate) => {
  const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' };
  const date = new Date(inputDate);
  return date.toLocaleDateString('en-US', options);
};

export default function SubscriptionDetails({
  subscription,
  isRedirecting,
  isLoading,
  handleGetPortalSession,
}: Props) {
  const theme = useTheme();

  const getSubscriptionStatusBackroundColor = (status: string | null | undefined): string => {
    const neutralColor = '#E0E0E0';
    if (!status) {
      return neutralColor;
    }
    const nominalColor = theme.palette.success.main;
    const warningColor = theme.palette.warning.main;
    const errorColor = theme.palette.error.main;
    // we can customize these colors if we start utilizing more status types
    const statusColorMap = {
      [Active]: nominalColor,
      [Trialing]: nominalColor,
      [Incomplete]: warningColor,
      [IncompleteExpired]: warningColor,
      [PastDue]: errorColor,
      [Unpaid]: errorColor,
      [Canceled]: neutralColor,
    };
    return statusColorMap[status] ?? neutralColor;
  };

  const { currentPeriodStart, currentPeriodEnd, stripeProductName, subscriptionStatus } =
    subscription;
  const formattedStartDate = formatTimeStamp(currentPeriodStart);
  const formattedEndDate = formatTimeStamp(currentPeriodEnd);
  const backgroundColor = getSubscriptionStatusBackroundColor(subscriptionStatus);

  return (
    <Box display='flex' flexDirection='column' height='100%'>
      <Box display='flex' gap={1} alignItems='center' justifyContent='space-between' padding={2}>
        <Stack direction='row' gap={2}>
          <LensIcon
            sx={{
              borderRadius: 5,
              border: 1,
              color: backgroundColor,
              width: 22,
              height: 22,
            }}
          />
          <Typography fontWeight='bold'>{stripeProductName}</Typography>
        </Stack>
        <Button
          disabled={isRedirecting || isLoading}
          variant='contained'
          onClick={handleGetPortalSession}
        >
          Edit subscription
        </Button>
      </Box>
      <Divider />
      {isRedirecting ? (
        <Box
          display='flex'
          flexDirection='column'
          justifyContent='center'
          alignItems='center'
          gap={1}
          padding={2}
          height='100%'
        >
          <TransferWithinAStationIcon sx={{ fontSize: 100 }} />
          <LinearProgress sx={{ width: '100%' }} />
          <Typography>Please wait</Typography>
          <Typography>Redirecting to Subscription Page</Typography>
        </Box>
      ) : (
        <Stack padding={2} gap={2}>
          <Stack direction='row' gap={1}>
            <Typography fontWeight='bold'>Status:</Typography>
            <Typography>{subscriptionStatus}</Typography>
          </Stack>
          <Stack direction='row' gap={1}>
            <Typography fontWeight='bold'>Current Period:</Typography>
            <Typography>{`${formattedStartDate} - ${formattedEndDate}`}</Typography>
          </Stack>
        </Stack>
      )}
    </Box>
  );
}
