import { useReactiveVar } from '@apollo/client';
import { Alert } from '@mui/material';
import { LocationCoreFragment, LocationOutbreakFragment } from '__generated__/graphql';
import { selectedLocationVar } from 'Apollo/ApolloCache';
import { formatDistanceToNow } from 'date-fns';
import { LOCATION_CORE_FRAGMENT, LOCATION_OUTBREAK_FRAGMENT } from 'fragments';
import useFetchLocationWithFragments from 'Hooks/useFetchLocationWithFragments';
import { useEffect, useState } from 'react';

const UPDATE_INTERVAL = 1000;

export default function OutbreakStatus() {
  const selectedLocation = useReactiveVar(selectedLocationVar);

  const [formattedTimeDistance, setFormattedTimeDistance] = useState('Unknown');

  const { data: activeModeCurrentLocation } = useFetchLocationWithFragments<
    LocationCoreFragment & LocationOutbreakFragment
  >(selectedLocation?.id, LOCATION_CORE_FRAGMENT, LOCATION_OUTBREAK_FRAGMENT);

  useEffect(() => {
    const timedSettingFormattedTimeDistance = setInterval(() => {
      const activatedAt = activeModeCurrentLocation?.location?.activeOutbreakMode?.activatedAt;
      if (!activatedAt) return;
      setFormattedTimeDistance(formatDistanceToNow(new Date(activatedAt), { addSuffix: true }));
    }, UPDATE_INTERVAL);

    return () => {
      clearInterval(timedSettingFormattedTimeDistance);
    };
  }, [activeModeCurrentLocation]);
  if (!activeModeCurrentLocation?.location?.activeOutbreakMode?.activatedAt) return null;
  return (
    <Alert severity='warning' variant='outlined' sx={{ width: '100%' }}>
      Outbreak Active in <b>{selectedLocation?.name ?? 'All Locations'}</b> since{' '}
      {formattedTimeDistance}
    </Alert>
  );
}
