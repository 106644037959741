import { FocusEventHandler, KeyboardEventHandler, useState } from 'react';
import {
  Button,
  Chip,
  Typography,
  IconButton,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Stack,
  CircularProgress,
  Box,
} from '@mui/material';
import { useReactiveVar } from '@apollo/client';
import { selectedOrgVar } from 'Apollo/ApolloCache';
import { gql, useMutation } from '@apollo/client';
import * as EmailValidator from 'email-validator';
import { Close as CloseIcon, PersonAddAlt1 as PersonAddAlt1Icon } from '@mui/icons-material';

import { useToast, ToastNotificationSeverityTypeEnum } from 'Providers/ToastProvider';
import RoleSelectionInput from 'Components/SharedUI/RoleSelectionInput';
import { GET_CURRENT_ORG_USER_LIST_QUERY } from 'Components/AuthedPages/OrgUsersPage/OrgUsersPage';
import { RoleEnum } from 'Constants/OrgSettingsEnums';
import { getPermissionConfig } from 'Utils/permissionConfig';
import LocationSelectorBreadcrumb from 'Components/LocationSelectorBreadcrumb/LocationSelectorBreadcrumb';

const ADD_USER_TO_ORG_MUTATION = gql(`
  mutation InviteUsersToAccount(
    $emails: [String]!
    $roles: GlobalRolesInput!
    $accountId: ID
    $rootLocationId: ID
  ) {
    inviteUsersToAccount(
      emails: $emails
      roles: $roles
      accountId: $accountId
      rootLocationId: $rootLocationId
    ) {
      id
    }
  }
`);

type Props = {
  onModalClose: () => void;
};

export default function AddUserToOrgModal({ onModalClose }: Props) {
  const selectedOrg = useReactiveVar(selectedOrgVar);
  const [inviteUserToOrg, { loading: isMutationInFlight }] = useMutation(ADD_USER_TO_ORG_MUTATION);
  const [emailList, setEmailList] = useState<Array<string>>([]);
  const [hasInvalidEmail, setHasInvalidEmail] = useState<boolean>(false);
  const [emailInput, setEmailInput] = useState<string>('');
  const [selectedRole, setSelectedRole] = useState<RoleEnum | null>(null);
  const [selectedLocationId, setSelectedLocationId] = useState(selectedOrg?.rootLocation?.id);

  const { dispatchToast } = useToast();

  const handleFlushEmail = () => {
    const sanitizedEmailInput = emailInput.trim();
    if (EmailValidator.validate(sanitizedEmailInput)) {
      setEmailList([...emailList, sanitizedEmailInput]);
      setEmailInput('');
    } else {
      setHasInvalidEmail(true);
    }
  };
  const handleKeyPress: KeyboardEventHandler<HTMLDivElement> = (event) => {
    if (event.key === 'Enter' || event.key === ' ') {
      handleFlushEmail();
    } else if (hasInvalidEmail) {
      setHasInvalidEmail(false);
    }
  };

  const handleBlur: FocusEventHandler<HTMLInputElement> = () => {
    if (emailInput === '') return;
    handleFlushEmail();
  };
  const handleDeleteEmail = (target: string): void => {
    const filteredEmailList = emailList.filter((email) => email !== target);
    setEmailList(filteredEmailList);
  };

  const onSubmit = async () => {
    const { id } = selectedOrg ?? {};
    const roles = getPermissionConfig(selectedRole);

    try {
      const variables = {
        emails: emailList,
        accountId: id,
        rootLocationId: selectedLocationId,
        roles,
      };
      await inviteUserToOrg({
        variables,
        refetchQueries: [GET_CURRENT_ORG_USER_LIST_QUERY],
      });
      const notifConfig = {
        severity: ToastNotificationSeverityTypeEnum.INFO,
        title: 'New User(s) Added',
      };
      onModalClose();
      dispatchToast(notifConfig);
    } catch (error) {
      const notifConfig = {
        severity: ToastNotificationSeverityTypeEnum.ERROR,
        title: 'Error Adding New User(s)',
        message: 'Please try again',
      };
      dispatchToast(notifConfig);
      console.error('ERROR ADD_USER_TO_ORG_MUTATION: ', error);
    }
  };
  const maxEmailInput = 4;
  // This component by default renders in an open state. After the user action is completed
  // onModalClose is called, unMounting the parent ModalRenderer. This is necessary otherwise
  // unwanted state will persist.
  return (
    <Dialog open={true} onClose={onModalClose} fullWidth maxWidth='sm'>
      <DialogTitle sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', gap: 2 }}>
        <PersonAddAlt1Icon color='primary' fontSize='large' />
        <Typography fontWeight='bold' fontSize={24}>
          Add User(s)
        </Typography>
      </DialogTitle>
      <IconButton
        onClick={onModalClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          // eslint-disable-next-line no-magic-numbers
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Stack spacing={3}>
          <Typography fontSize={16}>
            Add existing users to this organization or invite new ones. Select a permission level
            and enter the email addresses of the users you wish to add to your organization. (4 max)
          </Typography>
          <RoleSelectionInput selectedRole={selectedRole} setSelectedRole={setSelectedRole} />
          <Box display='flex' mt={2} p={1} bgcolor='background.paper' borderRadius={1}>
            <LocationSelectorBreadcrumb
              onLocationSelectCallback={(id) => setSelectedLocationId(id)}
              dontUpdateGlobal
            />
          </Box>
          <TextField
            disabled={emailList.length === maxEmailInput}
            placeholder={
              emailList.length < maxEmailInput
                ? 'Press enter to confirm email selection'
                : 'Max limit of email addresses (4) reached.'
            }
            helperText={
              hasInvalidEmail ? 'Please enter a valid email format ex: yourname@uvangel.com' : null
            }
            InputProps={{
              startAdornment: emailList.map((emailAddress, index) => (
                <Chip
                  key={emailAddress + index}
                  tabIndex={-1}
                  label={emailAddress}
                  onDelete={() => handleDeleteEmail(emailAddress)}
                  sx={{ marginTop: 1, marginLeft: 0.5 }}
                />
              )),
              style: {
                height: 'auto',
                display: 'flex',
                flexWrap: 'wrap',
              },
            }}
            fullWidth
            onKeyDown={handleKeyPress}
            onBlur={handleBlur}
            value={emailInput}
            onChange={(e) => setEmailInput(e.target.value)}
          />
        </Stack>
      </DialogContent>
      <DialogActions sx={{ mx: 2 }}>
        <Stack direction='row' spacing={2} pb={2}>
          <Button color='error' onClick={onModalClose}>
            Cancel
          </Button>
          <Button
            disabled={emailList.length === 0 || !selectedRole}
            color='primary'
            onClick={(e) => (isMutationInFlight ? e.preventDefault() : onSubmit())}
            endIcon={isMutationInFlight && <CircularProgress color='inherit' size={16} />}
          >
            Add Users
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}
