import { Box, Modal, Typography, Stack } from '@mui/material';

type Props = {
  onModalClose: () => void;
  modalProps?: unknown;
};

const modalStyle = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: 500,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
};

export default function AddDeviceModal({ onModalClose }: Props) {
  return (
    <Modal open={true} onClose={onModalClose}>
      <Box
        display='flex'
        flexDirection='column'
        justifyContent='flex-start'
        minHeight={650}
        maxHeight={750}
        sx={modalStyle}
        padding={4}
        gap={2}
      >
        <Typography
          sx={{
            marginBottom: 2,
          }}
          variant='h5'
        >
          Add Devices
        </Typography>
        <Typography>
          Register devices to your organization using our mobile app. Simply download, sign in, and
          follow the on-screen prompts!
        </Typography>
        <Box display='flex' justifyContent='center' alignItems='flex-start' gap={4}>
          <Stack spacing={2} alignItems='center' justifyContent='center'>
            <img src='/assets/qrcode-google-play.png' height='300' width='300' />
            <a
              href='https://play.google.com/store/apps/details?id=com.uva.ally'
              target='_blank'
              rel='noopener noreferrer'
            >
              <img src='/assets/google-play-badge.png' height='100' width='auto' />
            </a>
          </Stack>
          <Stack spacing={2} alignItems='center' justifyContent='center'>
            <img src='/assets/qrcode-app-store.png' height='300' width='300' />
            <a
              href='https://apps.apple.com/us/app/uv-angel-ally/id1490621637'
              target='_blank'
              rel='noopener noreferrer'
            >
              <Box sx={{ marginTop: '-62px' }}>
                <img src='/assets/app-store-badge.svg' height='225' width='auto' />
              </Box>
            </a>
          </Stack>
        </Box>
      </Box>
    </Modal>
  );
}
