import { SelectedLocationType, SelectedOrgType } from 'Apollo/ApolloCache';
import {
  BinInterval,
  LocationCoreFragment,
  LocationMetadataFragment,
  OccupancyReportQuery,
  OccupancyReportQueryVariables,
} from '__generated__/graphql';
import { ApolloError } from '@apollo/client';

export enum OccupancyView {
  INVALID_LOCATION,
  CALIBRATE,
  OCCUPANCY_CHART,
  LOADING,
}

export type OccupancyPageProps = {
  selectedOrg?: SelectedOrgType;
  selectedLocation?: SelectedLocationType;
};

export type OccupancyContextType = {
  occupancyGraphLoading: boolean;
  occupancyGraphData?: OccupancyReportQuery;
  occupancyGraphError?: ApolloError;
  locationCalibrationDetailsForOccupancy?: LocationCoreFragment & LocationMetadataFragment;
  filterHandler: (args: OccupancyReportQueryVariables) => Promise<void>;
  locationId?: string | null;
  onLocationChange: (locationId: string) => void;
  view: OccupancyView;
  currentInterval: BinInterval;
  isRecalibrating: boolean;
  triggerCalibrationOnCurrentLocation: () => void;
  resetPage: () => void;
};
