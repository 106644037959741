/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-magic-numbers */
import {
  Card,
  CardContent,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from '@mui/material';
import { GetAllEnvOverviewDataQuery, LocationFloorPlanQuery } from '__generated__/graphql';
import { useEffect, useMemo, useRef, useState } from 'react';
import MapGL, {
  FullscreenControl,
  Layer,
  LngLatBoundsLike,
  MapRef,
  NavigationControl,
  Source,
} from 'react-map-gl';
import { downloadImageAndConvertToBase64 } from 'Utils/file';
import { findBounds, getImageDimensions, ImdfFeature } from 'Utils/map';
import CustomOverlay from './CustomOverlay';
import {
  BACKGROUND_LAYER_ID,
  DASH_ARRAY_SEQUENCE,
  DEFAULT_PITCH,
  DEFAULT_ZOOM,
  FLOOR_PLAN_LAYER_ID,
  getColors,
  LAT_MAX,
  LNG_MAX,
  MAP_RATIO,
  MAPBOX_AUTH_TOKEN,
  METRIC_CONFIG,
  Metrics,
  ROOM_INFO_LAYER_ID,
  ROOM_PAINT,
  ROOMS_LAYER_ID,
  SELECTED_OUTLINE_PAINT,
  SELECTED_PAINT,
  SELECTED_ROOMS_LAYER_ID,
  SELECTED_ROOMS_OUTLINE_BACKGROUND_LAYER_ID,
  SELECTED_ROOMS_OUTLINE_LAYER_ID,
} from './FloorPlanConstants';

interface FloorPlanMapboxViewProps extends NonNullable<LocationFloorPlanQuery['location']> {
  report?: Record<string, NonNullable<GetAllEnvOverviewDataQuery['report']>['uvangel']>;
}

export default function FloorPlanMapboxView(props: FloorPlanMapboxViewProps) {
  const { floorPlanImageUrl, immediateSublocations, report } = props;
  const mapRef = useRef<MapRef>(null);

  const [viewState, setViewState] = useState({ pitch: DEFAULT_PITCH, zoom: DEFAULT_ZOOM });
  const [floorPlanRaw, setFloorPlanRaw] = useState<string>();
  const [floorPlanDim, setFloorPlanDim] = useState({ height: 0, width: 0 });
  const [selectedRoomId, setSelectedRoomId] = useState<string>('');

  const [metric, setMetric] = useState<Metrics>(Metrics.TEMP);
  const selectedRoomIdRef = useRef<string>();

  const [animationStep, setAnimationStep] = useState(0);
  const animationStepRef = useRef(animationStep);

  const [widthLng, heightLat, floorPlanBounds] = useMemo(() => {
    // translate floor plan dimensions into longitude(x)/latitude(y) while keeping aspect ratio
    const origRatio = floorPlanDim.width / (floorPlanDim.height || 1);
    const widthLng = origRatio > MAP_RATIO ? LNG_MAX : origRatio * LAT_MAX;
    const heightLat = origRatio > MAP_RATIO ? (1 / origRatio) * LNG_MAX : LAT_MAX;
    // map bounds allow panning around the floor plan for half the map width/height
    const mapBounds = [
      [0 - widthLng / 2, 0 - heightLat / 2],
      [widthLng * 1.5, heightLat * 1.5],
    ] as LngLatBoundsLike;
    return [widthLng, heightLat, mapBounds] as const;
  }, [floorPlanDim]);
  const selectedRoomFilter = useMemo(() => ['in', 'roomId', selectedRoomId], [selectedRoomId]);

  const roomFeatures = useMemo(() => {
    const mapFeatures: ImdfFeature<GeoJSON.Polygon>[] = [];
    immediateSublocations
      ?.filter((subLoc) => subLoc?.mapFeatures && subLoc?.mapFeatures.length > 0)
      .flatMap((subLoc) => {
        return subLoc?.mapFeatures?.map((feature) => ({
          ...feature?.feature,
          roomName: subLoc.name,
          roomId: subLoc.id,
          hasDevices: subLoc.numDevices && subLoc.numDevices > 0,
        }));
      })
      .forEach((feature) => {
        if (!feature || !feature.geometry || !feature.properties) return;
        let reportValue: number | undefined;
        if (report) {
          reportValue = report[feature.roomId]?.environmentalMetricsReportByTimeframe
            ?.at(0)
            ?.y![metric]?.toFixed(2) as number | undefined;
        }
        let value: string | undefined;
        if (reportValue) {
          value = `${Number(reportValue)} ${METRIC_CONFIG[metric].unit}`;
        }
        mapFeatures.push({
          geometry: feature.geometry as GeoJSON.Polygon,
          properties: {
            ...feature.properties,
            roomName: feature.roomName,
            roomId: feature.roomId,
            value: feature.hasDevices ? value : 'No Devices',
            ...getColors(METRIC_CONFIG[metric].colors, reportValue),
          },
          type: 'Feature',
        });
      });
    return mapFeatures;
  }, [immediateSublocations, report, metric]);

  const initialBounds = useMemo(() => {
    const centerTopAndBottomAnchors: ImdfFeature<GeoJSON.Point>[] = [
      {
        geometry: {
          type: 'Point',
          coordinates: [widthLng / 2, 0],
        },
        properties: {
          roomId: '',
        },
        type: 'Feature',
      },
      {
        geometry: {
          type: 'Point',
          coordinates: [widthLng / 2, heightLat],
        },
        properties: {
          roomId: '',
        },
        type: 'Feature',
      },
    ];
    return findBounds(floorPlanBounds, centerTopAndBottomAnchors);
  }, [floorPlanBounds, widthLng, heightLat]);

  useEffect(() => {
    if (floorPlanRaw || !floorPlanImageUrl) return;
    downloadImageAndConvertToBase64(floorPlanImageUrl)
      .then((base64Image) => {
        setFloorPlanRaw(base64Image);
        return getImageDimensions(base64Image);
      })
      .then((value) => {
        setFloorPlanDim(value);
      })
      .catch(console.error);
  }, [floorPlanImageUrl, floorPlanRaw]);

  useEffect(() => {
    if (!mapRef.current) return;
  }, [mapRef, selectedRoomId]);

  useEffect(() => {
    function animateDashArray(timestamp: number) {
      // Update line-dasharray using the next value in DASH_ARRAY_SEQUENCE. The
      // divisor in the expression `timestamp / 50` controls the animation speed.
      const newStep = Math.floor((timestamp / 50) % DASH_ARRAY_SEQUENCE.length);

      if (newStep !== animationStepRef.current) {
        setAnimationStep(newStep);
        animationStepRef.current = newStep;
      }

      // Request the next frame of the animation.
      requestAnimationFrame(animateDashArray);
    }

    animateDashArray(0);
  }, []);
  if (!floorPlanRaw || !floorPlanImageUrl) return null;
  return (
    <Card>
      <CardContent>
        <MapGL
          ref={mapRef}
          mapboxAccessToken={MAPBOX_AUTH_TOKEN}
          initialViewState={{
            bounds: initialBounds,
          }}
          {...viewState}
          onMove={(evt) => setViewState({ pitch: evt.viewState.pitch, zoom: evt.viewState.zoom })}
          maxBounds={floorPlanBounds}
          style={{
            width: '100%',
            height: 700,
          }}
          cursor='hand'
          mapStyle='mapbox://styles/mapbox/empty-v8'
          interactiveLayerIds={[ROOMS_LAYER_ID, SELECTED_ROOMS_LAYER_ID]}
          onClick={(event) => {
            const roomId = event.features?.at(0)?.properties?.roomId as string | undefined;
            if (roomId) {
              setSelectedRoomId(roomId);
            }
          }}
        >
          <Source
            id='floor-plan-source'
            type='image'
            // top left, top right, bottom right, bottom left
            coordinates={[
              // We put the bottom left corner of the floor plan at the position of [0, 0]
              [0, heightLat],
              [widthLng, heightLat],
              [widthLng, 0],
              [0, 0],
            ]}
            url={floorPlanRaw as string}
          >
            <Layer
              id={FLOOR_PLAN_LAYER_ID}
              source='floor-plan-source'
              type='raster'
              paint={{
                'raster-fade-duration': 0,
              }}
            />
            <Layer
              id={BACKGROUND_LAYER_ID}
              type='background'
              beforeId={FLOOR_PLAN_LAYER_ID}
              paint={{
                'background-color': 'transparent',
              }}
            />
          </Source>
          <Source
            id='room-source'
            promoteId='roomId'
            type='geojson'
            data={{
              type: 'FeatureCollection',
              features: roomFeatures,
            }}
          >
            <Layer
              // Layer with all the rooms
              id={ROOMS_LAYER_ID}
              source='room-source'
              type='fill'
              paint={ROOM_PAINT}
            />
            <Layer
              // Layer with room info
              id={ROOM_INFO_LAYER_ID}
              source='room-source'
              type='symbol'
              filter={['!=', 'hideValue', true]}
              // beforeId={SELECTED_ROOMS_OUTLINE_LAYER_ID}
              layout={{
                'text-field': [
                  'format',
                  ['get', 'roomName'],
                  {
                    'font-scale': 1,
                    'text-font': ['literal', ['DIN Offc Pro Bold', 'Arial Unicode MS Regular']],
                  },
                  '\n',
                  {},
                  ['get', 'value'],
                  {
                    'font-scale': 1.1,
                    'text-font': ['literal', ['DIN Offc Pro Italic', 'Arial Unicode MS Regular']],
                  },
                ],
                'text-justify': 'auto',
              }}
            />
            <Layer
              // Layer for highlighting the selected room(s)
              id={SELECTED_ROOMS_LAYER_ID}
              source='room-source'
              type='fill'
              beforeId={ROOM_INFO_LAYER_ID}
              filter={selectedRoomFilter}
              paint={SELECTED_PAINT}
            />
            <Layer
              // Fill layers can not specify width of their border outline so I introduced extra line layer just for the
              // ability to specify width of selected room border width
              id={SELECTED_ROOMS_OUTLINE_LAYER_ID}
              source='room-source'
              type='line'
              beforeId={SELECTED_ROOMS_LAYER_ID}
              filter={selectedRoomFilter}
              paint={{
                ...SELECTED_OUTLINE_PAINT,
                'line-dasharray': DASH_ARRAY_SEQUENCE[animationStep],
              }}
            />
            <Layer
              // This layer combined with SELECTED_ROOMS_OUTLINE_LAYER_ID make an animated dashed outline
              id={SELECTED_ROOMS_OUTLINE_BACKGROUND_LAYER_ID}
              source='room-source'
              type='line'
              beforeId={SELECTED_ROOMS_LAYER_ID}
              filter={selectedRoomFilter}
              paint={{
                ...SELECTED_OUTLINE_PAINT,
                'line-opacity': 0.6,
              }}
            />
          </Source>
          <FullscreenControl position='bottom-right' />
          <NavigationControl position='top-right' />
          <CustomOverlay position='top-left'>
            <Stack
              width={300}
              alignItems='flex-end'
              p={1.5}
              gap={2}
              sx={{
                pointerEvents: 'all',
                borderRadius: 1,
                bgcolor: (theme) => theme.palette.background.default,
              }}
            >
              <FormControl
                fullWidth
                disabled={
                  immediateSublocations?.filter(
                    (loc) => loc?.archetype?.toLocaleLowerCase() === 'room'
                  ).length === 0
                }
              >
                <InputLabel id='select-metric-label'>Metric</InputLabel>
                <Select
                  labelId='select-metric-label'
                  id='select-metric'
                  label='Metric'
                  value={metric}
                  onChange={(e) => {
                    setMetric(e.target.value as Metrics);
                  }}
                >
                  {Object.entries(METRIC_CONFIG).map(([metric, config]) => {
                    return (
                      <MenuItem key={metric} value={metric}>
                        {config.copy}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <FormControl
                fullWidth
                disabled={
                  immediateSublocations?.filter(
                    (loc) => loc?.archetype?.toLocaleLowerCase() === 'room'
                  ).length === 0
                }
              >
                <InputLabel id='select-room-label'>Room</InputLabel>
                <Select
                  labelId='select-room-label'
                  id='select-room'
                  label='Room'
                  value={selectedRoomId}
                  onChange={(e) => {
                    setSelectedRoomId(e.target.value);
                    selectedRoomIdRef.current = e.target.value;
                  }}
                >
                  {immediateSublocations?.map((loc) => {
                    if (loc?.archetype?.toLocaleLowerCase() !== 'room') return null;

                    return (
                      <MenuItem key={loc.id} value={loc.id}>
                        {loc.name}
                      </MenuItem>
                    );
                  })}
                </Select>
                {immediateSublocations?.filter(
                  (loc) => loc?.archetype?.toLocaleLowerCase() === 'room'
                ).length === 0 && (
                  <FormHelperText>
                    <b>{props.name ?? 'All Locations'}</b> does not have any sublocations with
                    Archetype <b>Room</b>{' '}
                  </FormHelperText>
                )}
              </FormControl>
            </Stack>
          </CustomOverlay>
        </MapGL>
      </CardContent>
    </Card>
  );
}
