import { Fragment, ReactNode } from 'react';
import { Box, Typography, Stack, List, ListItem, ListItemText } from '@mui/material';
import { useReactiveVar } from '@apollo/client';
import { selectedOrgVar } from 'Apollo/ApolloCache';
import PageContainerFrame from 'Components/HOC/PageContainerFrame';
import ContinuumAppPagesEnum from 'Constants/ContinuumAppPagesEnums';
import SettingsPanelFrame from 'Components/HOC/SettingsPanelFrame';

const APPLICABLE_ROUTES = new Set([ContinuumAppPagesEnum.OVERVIEW_PAGE]);
export default function AddDeviceGuard({
  currentPath,
  children,
}: {
  currentPath: ContinuumAppPagesEnum;
  children: ReactNode;
}) {
  const selectedOrg = useReactiveVar(selectedOrgVar);

  if (!APPLICABLE_ROUTES.has(currentPath) || selectedOrg?.numDevices) {
    /**
     * if not
     *      in applicable pages
     *      and does not have devices
     */
    return <Fragment>{children}</Fragment>;
  }
  return (
    <PageContainerFrame pageTitles={['Add Devices']}>
      <SettingsPanelFrame title='Welcome to UV Angel Cloud™'>
        <Typography variant='h6'>UV Angel Cloud™ gives you the power to...</Typography>
        <List sx={{ listStyle: 'disc', pl: 4 }} disablePadding>
          <ListItem disablePadding sx={{ display: 'list-item', py: 0 }}>
            <ListItemText primary='Manage all of your UV Angel Devices' />
          </ListItem>
          <ListItem disablePadding sx={{ display: 'list-item', py: 0 }}>
            <ListItemText primary='Utilize powerful reporting and analytics tools to improve your indoor environments' />
          </ListItem>
          <ListItem disablePadding sx={{ display: 'list-item', py: 0 }}>
            <ListItemText primary='Create alerts to monitor your indoor environments automatically' />
          </ListItem>
        </List>
        <Typography variant='h6'>Getting Started</Typography>
        <Typography>
          Register devices to your account using our mobile app. Simply download, sign in, and
          follow the on-screen prompts!
        </Typography>
        <Box display='flex' alignItems='flex-start' gap={4}>
          {/* Ally App on Android is currently unavailable, leaving commented out until it is ready again */}
          {/* <Stack spacing={2} alignItems='center' justifyContent='center'>
            <img src='/assets/qrcode-google-play.png' height='300' width='300' />
            <a
              href='https://play.google.com/store/apps/details?id=com.uva.ally'
              target='_blank'
              rel='noopener noreferrer'
            >
              <img src='/assets/google-play-badge.png' height='100' width='auto' />
            </a>
          </Stack> */}
          <Stack spacing={2} alignItems='center' justifyContent='center'>
            <img src='/assets/qrcode-app-store.png' height='300' width='300' />
            <a
              href='https://apps.apple.com/us/app/uv-angel-ally/id1490621637'
              target='_blank'
              rel='noopener noreferrer'
            >
              <Box sx={{ marginTop: '-62px' }}>
                <img src='/assets/app-store-badge.svg' height='225' width='auto' />
              </Box>
            </a>
          </Stack>
        </Box>
        <Typography>
          Once you have successfully registered a device, its data will automatically show up here!
        </Typography>
      </SettingsPanelFrame>
    </PageContainerFrame>
  );
}
