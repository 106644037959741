enum RoutePaths {
  PATH_HOME = '/',
  PATH_LOGIN = '/login',
  PATH_PRICING = '/pricing',
  PATH_DOCS = '/docs',
  PATH_USER = '/user',
  PATH_USER_SETTINGS = '/user/settings',
  PATH_ALERT_DETAILS = '/alerts/:alert_id',
  PATH_USER_ACCESS_DENIED = '/access_denied',
  PATH_PAGE_NOT_FOUND = '/page_not_found',
  PATH_FLOORPLANS = '/floorplans',
  PATH_OCCUPANCY = '/occupancy',
  PATH_MOLD = '/mold',
  PATH_INFECTION = '/infection',
  PATH_OVERVIEW = '/overview',
  PATH_REPORTS = '/reports',
  PATH_REPORTS_AIR = '/reports/air',
  PATH_REPORTS_SURFACE = '/reports/surface',
  PATH_DEVICES = '/devices',
  PATH_DEVICES_MAINT = '/devices/maintenance',
  PATH_DEVICE_DETAILS = '/devices/:device_sr',
  PATH_DEVICE_DETAILS_OLD = '/account/:account_id/devices/:device_type/:device_sr',
  PATH_ORGANIZATION = '/organization',
  PATH_ORGANIZATION_DETAILS = '/organization/details',
  PATH_ORGANIZATION_API_KEYS = '/organization/api_keys',
  PATH_ORGANIZATION_ALERT_CONFIGS = '/organization/alert_configs',
  PATH_ORGANIZATION_INTEGRATIONS = '/organization/integrations',
  PATH_ORGANIZATION_LOCATION_EDITOR = '/organization/location_editor',
  PATH_ORGANIZATION_USERS = '/organization/users',
  PATH_ORGANIZATION_SUBSCRIPTION = '/organization/subscription',
  PATH_ORGANIZATION_SUBSCRIPTION_PRICING = '/organization/subscription/pricing',
  PATH_DEVICE_INFO = '/2d/dl',
  PATH_ADMIN_DEVICES = '/admin/devices',
}

export default RoutePaths;
