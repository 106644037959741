import React from 'react';
import { Box, Typography } from '@mui/material';

function getLastLoginAmount(timestamp: string): string {
  const currentDate = new Date().getTime();
  const lastLoginDate = new Date(timestamp).getTime();

  const diffInMilliseconds = currentDate - lastLoginDate;
  const diffInSeconds = Math.floor(diffInMilliseconds / 1000);
  const diffInMinutes = Math.floor(diffInSeconds / 60);
  const diffInHours = Math.floor(diffInMinutes / 60);
  const diffInDays = Math.floor(diffInHours / 24);
  const diffInMonths = Math.floor(diffInDays / 30);

  if (diffInMonths > 0) {
    return `${diffInMonths} month(s) ago`;
  } else if (diffInDays > 0) {
    return `${diffInDays} day(s) ago`;
  } else if (diffInHours > 0) {
    return `${diffInHours} hour(s) ago`;
  } else if (diffInMinutes > 0) {
    return `${diffInMinutes} minute(s) ago`;
  } else {
    return 'just now';
  }
}

type Props = {
  lastLogin: string;
};

export default function LastLoginCellCard({ lastLogin }: Props) {
  const lastLoginTime = getLastLoginAmount(lastLogin);
  return (
    <Box display='flex' flexDirection='column' justifyContent='center'>
      {lastLogin == null ? (
        <Typography>No Login Activity</Typography>
      ) : (
        <>
          <Typography>Last Login:</Typography>
          <Typography>{lastLoginTime}</Typography>
        </>
      )}
    </Box>
  );
}
