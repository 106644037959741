import { useReactiveVar } from '@apollo/client';
import { Button } from '@mui/material';
import { CreateLocationMutation, LocationArchetype } from '__generated__/graphql';
import { selectedLocationVar, selectedOrgVar } from 'Apollo/ApolloCache';
import { ModalNotificationTypeEnum, useModal } from 'Providers/ModalProvider';

interface FloorPlanAddRoomProps {
  onRoomAdd: (newLocation: CreateLocationMutation['createLocation']) => void;
}

export default function FloorPlanAddRoom({ onRoomAdd }: FloorPlanAddRoomProps) {
  const { dispatchModal } = useModal();
  const selectedLocation = useReactiveVar(selectedLocationVar);
  const selectedOrg = useReactiveVar(selectedOrgVar);
  return (
    <Button
      variant='outlined'
      color='primary'
      onClick={() => {
        dispatchModal({
          type: ModalNotificationTypeEnum.ADD_SUB_LOCATION_MODAL,
          modalProps: {
            accountId: selectedOrg?.id,
            selectedLocationID: selectedLocation?.id,
            allowedLocationArchetypes: [LocationArchetype.Room],
            onSuccess: onRoomAdd,
          },
        });
      }}
    >
      Add Room
    </Button>
  );
}
