/* eslint-disable no-magic-numbers */
import { useState, useEffect } from 'react';
import { useTimer } from 'react-timer-hook';

const PERIODIC_POLL_INTERVAL = 15000; // 15 seconds
const LIVE_POLL_INTERVAL = 1000; // 1 second
const MAX_LIVE_POLL_DURATION = 1 * 60 * 1000; // 1 minute
const MAX_PERIODIC_POLL_DURATION = 20 * 60 * 1000; // 20 minutes
const LAST_UPDATE_EMIT_INTERVAL = 1000; // 1 second

export enum POLL_STATES {
  LIVE = 'live',
  PERIODIC = 'periodic',
  DISABLED = 'disabled',
}

interface PollingProps {
  defaultPollState?: POLL_STATES;
  startPolling: (pollInterval: number) => void;
  stopPolling: () => void;
}
export default function usePolling({
  startPolling,
  stopPolling,
  defaultPollState,
}: PollingProps): [{ lastUpdated: Date }, POLL_STATES, (state: POLL_STATES) => void] {
  const [pollState, setPollState] = useState<POLL_STATES>(defaultPollState ?? POLL_STATES.LIVE);
  const [lastUpdated, setLastUpdated] = useState(new Date());
  const { pause, restart } = useTimer({
    expiryTimestamp: new Date(Date.now() + MAX_LIVE_POLL_DURATION),
    autoStart: false,
    onExpire: () => {
      if (pollState === POLL_STATES.LIVE) {
        setPollState(POLL_STATES.PERIODIC);
      } else {
        setPollState(POLL_STATES.DISABLED);
      }
    },
  });

  // when pollState changes, start/stop polling and restart of stop overall timer
  useEffect(() => {
    switch (pollState) {
      case POLL_STATES.LIVE:
        startPolling(LIVE_POLL_INTERVAL);
        restart(new Date(Date.now() + MAX_LIVE_POLL_DURATION));
        break;
      case POLL_STATES.PERIODIC:
        startPolling(PERIODIC_POLL_INTERVAL);
        restart(new Date(Date.now() + MAX_PERIODIC_POLL_DURATION));
        break;
      case POLL_STATES.DISABLED:
        stopPolling();
        pause();
        break;
      default:
        stopPolling();
        pause();
        break;
    }
  }, [pollState, startPolling, stopPolling, restart, pause]);

  useEffect(() => {
    // When poll state is live, emit a new date every second if elapsed seconds is equal LIVE_POLL_INTERVAL otherwise emit the most recent date
    // when poll state is periodic emit a new date every second if elapsed seconds is equal to PERIODIC_POLL_INTERVAL otherwise emit the most recent date
    // when poll state is disabled, emit the old date every second
    let _elapsedSeconds = 0;
    const interval = setInterval(() => {
      switch (pollState) {
        case POLL_STATES.LIVE:
          setLastUpdated(new Date()); // because it is LIVE is updated every second and this function is also run every second
          break;
        case POLL_STATES.PERIODIC:
          setLastUpdated((prev) => {
            if (_elapsedSeconds === PERIODIC_POLL_INTERVAL) {
              _elapsedSeconds = 0;
              return new Date();
            }
            _elapsedSeconds += 1000;
            return new Date(prev);
          });
          break;
        default: // But it doesn't matter
          setLastUpdated((prev) => new Date(prev));
          _elapsedSeconds += 1000;
          break;
      }
    }, LAST_UPDATE_EMIT_INTERVAL);
    return () => {
      _elapsedSeconds = 0;
      clearInterval(interval);
    };
  }, [pollState]);
  return [{ lastUpdated }, pollState, setPollState];
}
