export enum MetricReportTypeEnum {
  CO2 = 'co2',
  HUMIDITY = 'humidity',
  IAQ = 'iaq',
  TEMP = 'temp',
  VOC = 'voc',
}

export enum EnvironmentalDataField {
  INCLUDE_CO2 = 'includeCO2',
  INCLUDE_TEMP = 'includeTemp',
  INCLUDE_HUMIDITY = 'includeHumidity',
  INCLUDE_VOC = 'includeVOC',
  INCLUDE_IAQ = 'includeIAQ',
}
