import { useState, useEffect } from 'react';
import { CircularProgress, Select, MenuItem, FormControl } from '@mui/material';
import { useQuery, gql } from '@apollo/client';
import { useErrorBoundary } from 'react-error-boundary';

export const GET_ALL_SUB_LOCATIONS_QUERY = gql`
  query LocationSelectorDropDownQuery($accountId: ID!, $locationId: ID!) {
    location(accountId: $accountId, locationId: $locationId) {
      id
      immediateSublocations {
        name
        id
      }
    }
  }
`;

type Props = {
  locationID: string;
  onSelectLocation: (id: string, name: string, parentLocationIndex: number) => void;
  selectedOrgID: string;
  getSelectedOption: () => string;
  parentLocationIndex: number;
  rootLocationName: string;
};

export default function LocationSelectorDropdown({
  locationID,
  selectedOrgID,
  getSelectedOption,
  onSelectLocation,
  parentLocationIndex,
  rootLocationName,
}: Props) {
  const {
    loading: isLoading,
    data,
    error: queryError,
  } = useQuery(GET_ALL_SUB_LOCATIONS_QUERY, {
    skip: !locationID,
    variables: {
      accountId: selectedOrgID,
      locationId: locationID,
    },
  });

  const { immediateSublocations } = data?.location ?? {};

  const [selectedLocationOptionID, setSelectedLocationOptionId] = useState<string>(
    getSelectedOption()
  );

  useEffect(() => {
    setSelectedLocationOptionId(getSelectedOption()); // Update the state when the function changes
  }, [getSelectedOption]);

  const handleChange = (id: string, name: string): void => {
    onSelectLocation(id, name, parentLocationIndex);
    setSelectedLocationOptionId(id);
  };

  const { showBoundary: showErrorBoundary } = useErrorBoundary();

  if (queryError) {
    showErrorBoundary(queryError);
  }

  if (isLoading) {
    return <CircularProgress size={20} />;
  }

  if (immediateSublocations?.length === 0) {
    return null;
  }
  return (
    <FormControl>
      <Select displayEmpty disabled={isLoading} value={selectedLocationOptionID}>
        <MenuItem
          value={locationID}
          onClick={(e) => {
            e.stopPropagation();
            handleChange(locationID, 'All Locations');
          }}
        >
          {parentLocationIndex === 0 ? rootLocationName : 'All Locations'}
        </MenuItem>
        {immediateSublocations?.map((location) => {
          const { id, name = 'null' } = location ?? {};
          if (!id) {
            return null;
          }
          return (
            <MenuItem
              onClick={(e) => {
                e.stopPropagation();
                handleChange(id, name);
              }}
              value={id}
              key={id}
            >
              {name}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}
