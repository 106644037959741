export enum RoleEnum {
  ADMIN = 'Admin',
  READ_ONLY = 'Read Only',
  STANDARD_USER = 'Standard User',
  CUSTOM = 'Custom',
}

export enum SettingsViewEnum {
  PROFILE,
  ORG_API_KEYS,
  ORG_DETAILS,
  ORG_INTEGRATIONS,
  ORG_USERS,
}

export enum OrgAPISettingsPanelViewEnum {
  ORG_KEY_LIST_VIEW,
  ORG_KEY_GEN_VIEW,
  ORG_NEW_KEY_VIEW,
}

export enum OrgAlertConfigurationsSettingsPanelViewEnum {
  ORG_ALERT_CONFIG_LIST_VIEW,
  ORG_ALERT_CONFIG_NEW_VIEW,
}

export enum OrgIntegrationsPanelViewEnum {
  INTEGRATIONS_LIST_VIEW,
  ADD_INTEGRATIONS_VIEW,
  UPDATE_INTEGRATIONS_VIEW,
}
