import { useMemo } from 'react';
import { useParams, Navigate } from 'react-router-dom';
import RoutePaths from 'Constants/RoutePaths';

const { PATH_PAGE_NOT_FOUND } = RoutePaths;
interface RedirectProps {
  to: string;
  redirectComponents?: Record<string, string>;
}

export default function Redirect(props: RedirectProps) {
  const params = useParams();

  const newPathname = useMemo(() => {
    let newPathname = props.to;
    let isErr = false;
    Object.entries(props.redirectComponents ?? {}).forEach(([key, value]) => {
      const paramVal = params[key] as string;
      if (!paramVal) {
        isErr = true;
        console.error(`redirectComponents.${key} is not present in the path`);
      }
      if (newPathname.indexOf(value) === -1) {
        isErr = true;
        console.error(`${value} is not present in the ${newPathname}`);
      }
      newPathname = newPathname.replace(`:${value}`, paramVal);
    });
    if (isErr) {
      return PATH_PAGE_NOT_FOUND;
    }

    return newPathname;
  }, [params, props]);

  return <Navigate to={newPathname} />;
}
