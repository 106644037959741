import { ReactNode, useMemo } from 'react';
import { currentUserDataVar, selectedOrgVar } from 'Apollo/ApolloCache';
import { canViewFeature, FeatureFlagFeatures } from 'Utils/FeatureFlags';
import { useReactiveVar } from '@apollo/client';

/**
 * uses defined rules set to determine if the current user/organization can access a certain feature.
 * @usage wrap any component to enable/disable it based on the defined feature flag ruleset.
 *
 *   `<FeatureEnabled featureFlag={FeatureFlagFeatures.FLOORPLANS}>
 *     {children}
 *   </FeatureEnabled>`
 */
export function FeatureEnabled({
  featureFlag,
  children,
}: {
  featureFlag: FeatureFlagFeatures,
  children: ReactNode
}) {
  const currentUser = useReactiveVar(currentUserDataVar);
  const selectedOrg = useReactiveVar(selectedOrgVar);

  // Memoize the feature check to prevent unnecessary re-renders
  const isFeatureEnabled = useMemo(() => {
    return canViewFeature(featureFlag, currentUser, selectedOrg);
  }, [featureFlag, currentUser, selectedOrg]);

  return isFeatureEnabled ? <>{children}</> : <></>;
}