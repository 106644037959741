import { Alert, AlertTitle, Box, CircularProgress } from '@mui/material';
import { useOccupancyContext } from '../context';
import { OccupancyView } from '../occupancy.types';
import { OccupancyGraphAndFiltersView } from './OccupancyGraphAndFiltersView';
import OccupancyNeedsCalibrationView from './OccupancyNeedsCalibrationView';

export default function OccupancyViewRouter() {
  const occupancyContext = useOccupancyContext();
  switch (occupancyContext.view) {
    case OccupancyView.LOADING: {
      return <OccupancyViewLoading />;
    }
    case OccupancyView.OCCUPANCY_CHART: {
      return <OccupancyGraphAndFiltersView />;
    }
    case OccupancyView.INVALID_LOCATION: {
      return <OccupancyInvalidLocationView />;
    }
    case OccupancyView.CALIBRATE: {
      return <OccupancyNeedsCalibrationView />;
    }
    default:
      return <OccupancyDefaultView />;
  }
}

function OccupancyViewLoading() {
  return (
    <Box display='flex' justifyContent='center' my={10}>
      <CircularProgress size={50} />
    </Box>
  );
}

function OccupancyInvalidLocationView() {
  return (
    <Alert severity='info'>
      <AlertTitle>Location must be a <b>room</b></AlertTitle>
      Occupancy report can only be retrieved for <b>room</b> locations. Select a <b>room</b> to view its occupancy report.
    </Alert>
  );
}

function OccupancyDefaultView() {
  return (
    <Alert severity='error'>
      <AlertTitle>There is no Occupancy Report</AlertTitle>
      Please contact support
    </Alert>
  );
}
