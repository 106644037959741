/* eslint-disable no-magic-numbers */
import { selectedOrgVar } from 'Apollo/ApolloCache';
import { TempScaleEnum } from 'Constants/ConversionEnums';
import { FillPaint, LinePaint } from 'mapbox-gl';
import { convertUnitOfMeasurePreferenceToTempScaleEnum } from 'Utils/conversionTools';
import { ImdfProps } from 'Utils/map';
export const MAPBOX_AUTH_TOKEN = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
// max "width"
export const LNG_MAX = 90;
// max "height"
export const LAT_MAX = 45;
export const MAP_RATIO = LNG_MAX / LAT_MAX;

export const DEFAULT_PITCH = 45;
export const DEFAULT_ZOOM = 3.6;

// technique based on https://jsfiddle.net/2mws8y3q/
// an array of valid line-dasharray values, specifying the lengths of the alternating dashes and gaps that form the dash pattern
export const DASH_ARRAY_SEQUENCE = [
  [0, 4, 3],
  [0.5, 4, 2.5],
  [1, 4, 2],
  [1.5, 4, 1.5],
  [2, 4, 1],
  [2.5, 4, 0.5],
  [3, 4, 0],
  [0, 0.5, 3, 3.5],
  [0, 1, 3, 3],
  [0, 1.5, 3, 2.5],
  [0, 2, 3, 2],
  [0, 2.5, 3, 1.5],
  [0, 3, 3, 1],
];

export const OPACITY_SETTING = 0.6;
export const SELECTED_OPACITY_SETTING = 0.8;
export const METRIC_COLORS_PALETTE = {
  red: {
    default: `rgba(244, 39, 39, ${OPACITY_SETTING})`,
    selected: `rgba(244, 39, 39, ${SELECTED_OPACITY_SETTING})`,
  },
  yellow: {
    default: `rgba(244, 211, 39, ${OPACITY_SETTING})`,
    selected: `rgba(244, 211, 39, ${SELECTED_OPACITY_SETTING})`,
  },
  green: {
    default: `rgba(31, 199, 47, ${OPACITY_SETTING})`,
    selected: `rgba(31, 199, 47, ${SELECTED_OPACITY_SETTING})`,
  },
  lightBlue: {
    default: `rgba(22, 190, 240, ${OPACITY_SETTING})`,
    selected: `rgba(22, 190, 240, ${SELECTED_OPACITY_SETTING})`,
  },
  darkBlue: {
    default: `rgba(1, 68, 138, ${OPACITY_SETTING})`,
    selected: `rgba(1, 68, 138, ${SELECTED_OPACITY_SETTING})`,
  },
};

export enum Metrics {
  TEMP = 'temp',
  CO2 = 'co2',
  HUMIDITY = 'humidity',
  VOC = 'voc',
  IAQ = 'iaq',
}
export type MetricColors = Array<{ lo: number; hi: number; default: string; selected: string }>;
export const METRIC_CONFIG: Record<
  Metrics,
  {
    copy: string;
    unit: string;
    colors?: MetricColors;
  }
> = {
  [Metrics.TEMP]: {
    copy: 'Temperature',
    unit: (() => {
      const selectedOrg = selectedOrgVar();
      const tempScalePref = convertUnitOfMeasurePreferenceToTempScaleEnum(
        selectedOrg?.preferences?.unit_of_measure
      );
      if (tempScalePref === TempScaleEnum.CELSIUS) {
        return '°C';
      } else {
        return '°F';
      }
    })(),
    colors: (() => {
      const selectedOrg = selectedOrgVar();
      const tempScalePref = convertUnitOfMeasurePreferenceToTempScaleEnum(
        selectedOrg?.preferences?.unit_of_measure
      );
      if (tempScalePref === TempScaleEnum.CELSIUS) {
        return [
          {
            lo: 0,
            hi: 15,
            ...METRIC_COLORS_PALETTE.darkBlue,
          },
          {
            lo: 15,
            hi: 19,
            ...METRIC_COLORS_PALETTE.lightBlue,
          },
          {
            lo: 19,
            hi: 24,
            ...METRIC_COLORS_PALETTE.green,
          },
          {
            lo: 24,
            hi: 29,
            ...METRIC_COLORS_PALETTE.yellow,
          },
          {
            lo: 29,
            hi: 1e7,
            ...METRIC_COLORS_PALETTE.red,
          },
        ];
      } else {
        return [
          {
            lo: 0,
            hi: 55,
            ...METRIC_COLORS_PALETTE.darkBlue,
          },
          {
            lo: 55,
            hi: 67,
            ...METRIC_COLORS_PALETTE.lightBlue,
          },
          {
            lo: 67,
            hi: 76,
            ...METRIC_COLORS_PALETTE.green,
          },
          {
            lo: 76,
            hi: 86,
            ...METRIC_COLORS_PALETTE.yellow,
          },
          {
            lo: 86,
            hi: 1e7,
            ...METRIC_COLORS_PALETTE.red,
          },
        ];
      }
    })(),
  },
  [Metrics.CO2]: {
    copy: 'Co2',
    unit: 'ppm',
    colors: [
      {
        lo: 0,
        hi: 600,
        ...METRIC_COLORS_PALETTE.green,
      },
      {
        lo: 600,
        hi: 1500,
        ...METRIC_COLORS_PALETTE.yellow,
      },
      {
        lo: 1500,
        hi: 1e7,
        ...METRIC_COLORS_PALETTE.red,
      },
    ],
  },
  [Metrics.HUMIDITY]: {
    copy: 'Relative Humidity',
    unit: '%',
    colors: [
      {
        lo: 0,
        hi: 10,
        ...METRIC_COLORS_PALETTE.red,
      },
      {
        lo: 10,
        hi: 19,
        ...METRIC_COLORS_PALETTE.yellow,
      },
      {
        lo: 19,
        hi: 60,
        ...METRIC_COLORS_PALETTE.green,
      },
      {
        lo: 60,
        hi: 70,
        ...METRIC_COLORS_PALETTE.yellow,
      },
      {
        lo: 70,
        hi: 1e7,
        ...METRIC_COLORS_PALETTE.red,
      },
    ],
  },
  [Metrics.IAQ]: {
    copy: 'IAQ',
    unit: 'iaq',
    colors: [
      {
        lo: 0,
        hi: 100,
        ...METRIC_COLORS_PALETTE.green,
      },
      {
        lo: 100,
        hi: 200,
        ...METRIC_COLORS_PALETTE.yellow,
      },
      {
        lo: 200,
        hi: 1e7,
        ...METRIC_COLORS_PALETTE.red,
      },
    ],
  },
  [Metrics.VOC]: {
    copy: 'VOC',
    unit: 'ppm',
    colors: [
      {
        lo: 0,
        hi: 50,
        ...METRIC_COLORS_PALETTE.green,
      },
      {
        lo: 50,
        hi: 100,
        ...METRIC_COLORS_PALETTE.yellow,
      },
      {
        lo: 100,
        hi: 1e7,
        ...METRIC_COLORS_PALETTE.red,
      },
    ],
  },
};

export const ROOM_PAINT = {
  'fill-color': ['case', ['has', 'fillColor'], ['get', 'fillColor'], 'rgba(70, 113, 138, 0.4)'],
  'fill-outline-color': [
    'case',
    ['has', 'fillOutlineColor'],
    ['get', 'fillOutlineColor'],
    '#627BC1',
  ],
  'fill-opacity': ['case', ['boolean', ['feature-state', 'hover'], false], 1, 0.4],
} as FillPaint;

export const SELECTED_PAINT = {
  'fill-color': [
    'case',
    ['has', 'selectedFillColor'],
    ['get', 'selectedFillColor'],
    'rgba(0, 147, 199, 0.5)',
  ],
  'fill-outline-color': [
    'case',
    ['has', 'selectedFillColor'],
    ['get', 'selectedFillColor'],
    'rgba(0, 147, 199, 1)',
  ],
} as FillPaint;

export const SELECTED_OUTLINE_PAINT = {
  'line-color': [
    'case',
    ['has', 'selectedFillOutlineColor'],
    ['get', 'selectedFillOutlineColor'],
    'rgba(0, 147, 199, 1)',
  ],
  'line-width': [
    'case',
    ['has', 'selectedFillOutlineWidth'],
    ['get', 'selectedFillOutlineWidth'],
    1,
  ],
} as LinePaint;

export function getColors(metricColors?: MetricColors, value?: number) {
  const colors: Pick<
    ImdfProps,
    | 'fillColor'
    | 'fillOutlineColor'
    | 'selectedFillColor'
    | 'selectedFillOutlineColor'
    | 'selectedFillOutlineWidth'
  > = {};
  if (!value || !metricColors) return colors;

  for (const mc of metricColors) {
    if (mc.lo <= value && mc.hi >= value) {
      colors.fillColor = mc.default;
      colors.fillOutlineColor = mc.selected;
      colors.selectedFillColor = mc.selected;
      return colors;
    }
  }
  return colors;
}

export const BACKGROUND_LAYER_ID = 'background-layer';
export const ROOMS_LAYER_ID = 'rooms-layer';
export const SELECTED_ROOMS_LAYER_ID = 'selected-rooms-layer';
export const SELECTED_ROOMS_OUTLINE_LAYER_ID = 'selected-rooms-outline-layer';
export const SELECTED_ROOMS_OUTLINE_BACKGROUND_LAYER_ID = 'selected-rooms-outline-background-layer';
export const FLOOR_PLAN_LAYER_ID = 'floor-plan-layer';
export const ROOM_INFO_LAYER_ID = 'room-info-layer';
