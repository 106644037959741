import { gql } from '@apollo/client';
import { LOCATION_CORE_FRAGMENT } from './locationFragments.gql';

export const ORG_CORE_FRAGMENT = gql`
  ${LOCATION_CORE_FRAGMENT}
  fragment OrgCore on Account {
    id
    name
    numMembers
    numLocations
    numDevices
    deviceTypes
    userAccountScope {
      roles {
        account_read
        account_write
        device_read
        device_write
        location_read
        location_write
        report_read
        report_write
        users_read
        users_write
      }
    }
    rootLocation {
      ...LocationCore
    }
    preferences {
      unit_of_measure
      logo_image_url
    }
  }
`;

export const ORG_CORE_LIGHT_FRAGMENT = gql`
  ${LOCATION_CORE_FRAGMENT}
  fragment OrgCoreLight on Account {
    id
    name
    numMembers
    numLocations
    numDevices
    createdAt
    rootLocation {
      ...LocationCore
    }
  }
`;