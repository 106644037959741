import { Button, Grid } from '@mui/material';
import LinkIcon from '@mui/icons-material/Link';

export default function DocumentationPage() {
  return (
    <Grid
      container
      spacing={0}
      direction='column'
      alignItems='center'
      justifyContent='center'
      style={{ minHeight: '60vh' }}
    >
      <Grid item xs={3}>
        <Button
          variant='contained'
          href='https://uvangel-cloud-api.readme.io/'
          target='_blank'
          rel='noopener noreferrer'
          endIcon={<LinkIcon />}
        >
          Explore Developer Documentation
        </Button>
      </Grid>
    </Grid>
  );
}
