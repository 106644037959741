/* eslint-disable no-magic-numbers */
import { useMemo } from 'react';
import { Box, Button, Stack, Typography, Tooltip, IconButton, useTheme } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { DeviceType } from '__generated__/graphql';
import { deviceMap } from 'Components/AuthedPages/DevicesListPage/PageViews/DevicesListFilters';
import {
  LinkOutlined as LinkIcon,
  ContentCopy as CopyIcon,
  Check as CheckIcon,
} from '@mui/icons-material';
import useCopy from 'Hooks/useCopy';
import { Helmet } from 'react-helmet';
import { formatPageTitle } from 'Utils/formatPageTitle';

export default function DeviceInformationPage() {
  const { hash } = useLocation();
  const [copied, copyText] = useCopy();
  const theme = useTheme();
  const params = useMemo(() => {
    const searchFormat = new URLSearchParams('?' + hash.slice(1));
    const wM = searchFormat.get('wM');
    if (wM) {
      searchFormat.set('wM', wM.replace(/(..)(?!$)/g, '$1:'));
    }
    return Object.fromEntries(searchFormat.entries()) as Record<
      'iSN' | 'wM' | 'pSN',
      string | undefined
    >;
  }, [hash]);
  const deviceType = useMemo(() => {
    const iSN = params.iSN;
    if (iSN?.startsWith('101')) {
      return DeviceType.Uva20;
    } else if (iSN?.startsWith('102') || iSN?.startsWith('1001240')) {
      return DeviceType.Air175;
    } else if (iSN?.startsWith('202') || iSN?.startsWith('2020015')) {
      return DeviceType.Air20;
    }
  }, [params]);
  const pageTitle = formatPageTitle([params.iSN, 'Device Info']);
  return (
    <Box
      position='absolute'
      top='50%'
      left='50%'
      sx={{
        transform: 'translate(-50%, -50%)',
        width: 'max-content',
        [theme.breakpoints.down('sm')]: { width: 'auto' },
      }}
    >
      <Helmet defer={false}>
        <title>{pageTitle}</title>
      </Helmet>
      <Stack direction='row' gap={4}>
        <Stack
          alignItems='center'
          justifyContent='center'
          sx={{ [theme.breakpoints.down('md')]: { display: 'none' } }}
        >
          <img
            src={
              theme.palette.mode === 'dark'
                ? '/assets/UVAngel_Icon_Reversed.svg'
                : '/assets/UVAngel_Icon_Full-Color.svg'
            }
            alt='BIGL'
            height='200'
            width='200'
          />
          <img
            src={
              theme.palette.mode === 'dark'
                ? '/assets/UVAngel-wordmark-reversed.svg'
                : '/assets/UVAngel-wordmark-full-color.svg'
            }
            alt='BIGL'
            width='200'
          />
        </Stack>
        <Stack gap={2} position='relative'>
          <Typography variant='h4'>Device Info</Typography>{' '}
          {deviceType && (
            <Stack gap={0.5}>
              <Typography fontWeight={600}>Device Type</Typography>
              <Stack direction='row' gap={2} alignItems='center'>
                <Typography>{deviceMap[deviceType ?? ''].value}</Typography>
                <img src={deviceMap[deviceType ?? ''].icon.dark} alt='devicon' width='30' />
              </Stack>
            </Stack>
          )}
          {params.iSN && (
            <Stack gap={0.5}>
              <Typography fontWeight={600}>IoT Serial Number</Typography>
              <Stack direction='row' gap={2} alignItems='center'>
                <Typography>{params.iSN}</Typography>
                <Tooltip title={copied ? 'Copied' : 'Copy Serial Number'}>
                  {copied === params.iSN ? (
                    <IconButton onClick={(e) => e.preventDefault} size='small'>
                      <CheckIcon color='success' fontSize='small' />
                    </IconButton>
                  ) : (
                    <IconButton
                      onClick={(e) => copyText(e, params.iSN ?? '')}
                      size='small'
                    >
                      <CopyIcon fontSize='small' />
                    </IconButton>
                  )}
                </Tooltip>
              </Stack>
            </Stack>
          )}
          {params.wM && (
            <Stack gap={0.5}>
              <Typography fontWeight={600}>Wifi Mac Address</Typography>
              <Stack direction='row' gap={2} alignItems='center'>
                <Typography>{params.wM}</Typography>
                <Tooltip title={copied ? 'Copied' : 'Copy Mac Address'}>
                  {copied === params.wM ? (
                    <IconButton onClick={(e) => e.preventDefault} size='small'>
                      <CheckIcon color='success' fontSize='small' />
                    </IconButton>
                  ) : (
                    <IconButton
                      onClick={(e) => copyText(e, params.wM ?? '')}
                      size='small'
                    >
                      <CopyIcon fontSize='small'  />
                    </IconButton>
                  )}
                </Tooltip>
              </Stack>
            </Stack>
          )}
          {params.pSN && (
            <Stack gap={0.5}>
              <Typography fontWeight={600}>Product Serial Number</Typography>
              <Stack direction='row' gap={2} alignItems='center'>
                <Typography>{params.pSN}</Typography>
                <Tooltip title={copied ? 'Copied' : 'Copy Product Serial Number'}>
                  {copied === params.pSN ? (
                    <IconButton onClick={(e) => e.preventDefault} size='small'>
                      <CheckIcon color='success' fontSize='small'/>
                    </IconButton>
                  ) : (
                    <IconButton
                      onClick={(e) => copyText(e, params.pSN ?? '')}
                      size='small'
                    >
                      <CopyIcon fontSize='small' />
                    </IconButton>
                  )}
                </Tooltip>
              </Stack>
            </Stack>
          )}
          {deviceType && (
            <Button
              component='a'
              href={deviceMap[deviceType ?? ''].docs.link}
              target='_blank'
              rel='noopener noreferrer'
              color='primary'
              sx={{ textTransform: 'unset', fontSize: 18, maxWidth: 'max-content' }}
              variant='outlined'
              endIcon={<LinkIcon />}
            >
              {deviceMap[deviceType ?? ''].docs.copy}
            </Button>
          )}
        </Stack>
      </Stack>
    </Box>
  );
}
