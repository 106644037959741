/* eslint-disable no-magic-numbers */
import { Close as CloseIcon, CloudUpload as CloudUploadIcon } from '@mui/icons-material';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { ChangeEvent, DragEvent, useState } from 'react';

type Props = {
  onModalClose: () => void;
  modalProps: unknown;
};

export default function ImageUploadModal({ onModalClose, modalProps }: Props) {
  const { imageUploadCallBack, title, fileTypes } = modalProps as {
    title: string;
    imageUploadCallBack: (file: File) => void;
    fileTypes?: string[];
  };

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [file, setFile] = useState<File | null>(null);

  const handleUploadImage = async () => {
    if (!file || !imageUploadCallBack) {
      return;
    }
    setIsLoading(true);
    try {
      await imageUploadCallBack(file);
    } catch (error) {
      console.error('Error uploading image:', error);
    } finally {
      setIsLoading(false);
      onModalClose();
    }
  };

  return (
    <Dialog
      open={true}
      onClose={() => {
        onModalClose();
      }}
      fullWidth
      maxWidth='sm'
    >
      <DialogTitle>{title}</DialogTitle>
      <IconButton
        onClick={onModalClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <UploadImageSection
          fileTypes={fileTypes}
          isLoading={isLoading}
          file={file}
          setFile={setFile}
        />
      </DialogContent>
      <DialogActions>
        <Box width='100%' gap={1} display='flex' justifyContent='flex-end' alignItems='center'>
          <Button color='error' onClick={onModalClose} variant='contained'>
            Cancel
          </Button>
          <Button
            disabled={!file || isLoading}
            color='primary'
            onClick={handleUploadImage}
            variant='contained'
          >
            Upload Image
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}

const validateFileType = (file: File | null | undefined): boolean => {
  if (!file) {
    return false;
  }

  const allowedExtensions = ['jpeg', 'jpg', 'png'];
  const fileType = file.type.split('/').pop() ?? '';

  return allowedExtensions.includes(fileType);
};

type UploadImageSectionProps = {
  isLoading: boolean;
  file: File | null;
  fileTypes?: string[];
  setFile: (file: File) => void;
};

function UploadImageSection({ isLoading, file, setFile, fileTypes }: UploadImageSectionProps) {
  const [hasIncorrectFileType, setHasIncorrectFileType] = useState<boolean>(false);
  const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const handleDrop = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const droppedFile = event.dataTransfer.files[0];
    const isValidFileType = validateFileType(droppedFile);
    if (isValidFileType && droppedFile) {
      setFile(droppedFile);
    } else {
      setHasIncorrectFileType(true);
    }
  };

  const handleBrowseButtonClick = () => {
    const element = document.getElementById('logoImageUploadInput');
    element?.click();
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event?.target?.files ? event.target.files[0] : null;
    const isValidFileType = validateFileType(selectedFile);
    if (isValidFileType && selectedFile) {
      setFile(selectedFile);
    } else {
      setHasIncorrectFileType(true);
    }
  };

  return (
    <Box
      display='flex'
      flexDirection='column'
      height={300}
      width='100%'
      justifyContent='center'
      alignItems='center'
      border={1}
      sx={{
        border: '2px dashed #0093c8',
      }}
    >
      {isLoading ? (
        <Stack direction='column' alignItems='center' gap={2}>
          <CircularProgress size={50} />
          <Typography fontWeight='bold'>Image uploading</Typography>
        </Stack>
      ) : (
        <div onDragOver={handleDragOver} onDrop={handleDrop}>
          {file ? (
            <Box component='img' height={275} width={275} src={URL.createObjectURL(file)} />
          ) : (
            <Stack alignItems='center' direction='column' gap={1}>
              <CloudUploadIcon fontSize='large' color='primary' />
              {hasIncorrectFileType && (
                <Stack alignItems='center'>
                  <Typography fontSize={20} lineHeight={1.25} fontWeight='bold' color='error'>
                    Invalid file type
                  </Typography>
                  <Typography lineHeight={1.25} color='error'>
                    Images must be: jpeg jpg png
                  </Typography>
                  <Typography lineHeight={1.25} color='error'>
                    Please try again
                  </Typography>
                </Stack>
              )}
              <Typography fontWeight='bold' fontSize={24}>
                Drag files to upload
              </Typography>
              <Typography fontWeight='bold' fontSize={24}>
                or
              </Typography>
              <Button onClick={handleBrowseButtonClick}>Browse Files</Button>
              <input
                accept={fileTypes ? fileTypes.join(',') : '.jpg, .jpeg, .png'}
                id='logoImageUploadInput'
                type='file'
                onChange={handleFileChange}
                style={{ visibility: 'hidden', display: 'none' }}
              />
            </Stack>
          )}
        </div>
      )}
    </Box>
  );
}
