import RoutePaths from './RoutePaths';

const {
  PATH_HOME,
  PATH_OVERVIEW,
  PATH_ORGANIZATION_DETAILS,
  PATH_ORGANIZATION_API_KEYS,
  PATH_ORGANIZATION_ALERT_CONFIGS,
  PATH_ORGANIZATION_INTEGRATIONS,
  PATH_ORGANIZATION_LOCATION_EDITOR,
  PATH_ORGANIZATION_SUBSCRIPTION,
  PATH_ORGANIZATION_SUBSCRIPTION_PRICING,
  PATH_ORGANIZATION_USERS,
  PATH_USER_SETTINGS,
  PATH_REPORTS,
  PATH_REPORTS_AIR,
  PATH_REPORTS_SURFACE,
  PATH_DEVICES,
  PATH_DEVICE_DETAILS,
  PATH_DEVICES_MAINT,
  PATH_ALERT_DETAILS,
  PATH_INFECTION,
  PATH_OCCUPANCY,
  PATH_MOLD,
  PATH_FLOORPLANS,
  PATH_ADMIN_DEVICES,
} = RoutePaths;

enum ContinuumAppPagesEnum {
  HOME_PAGE,
  OVERVIEW_PAGE,
  REPORTS_PAGE,
  REPORTS_AIR_PAGE,
  REPORTS_SURFACE_PAGE,
  INFECTION_PAGE,
  OCCUPANCY_PAGE,
  MOLD_PAGE,
  DEVICES_PAGE,
  FLOORPLAN_PAGE,
  DEVICES_MAINT_PAGE,
  DEVICE_DETAILS_PAGE,
  USER_SETTINGS_PAGE,
  ORG_DETAILS_PAGE,
  ORG_API_KEY_LIST_PAGE,
  ORG_ALERT_CONFIGURATION_LIST_PAGE,
  ORG_INTEGRATIONS_PAGE,
  ORG_LOCATION_EDITOR_PAGE,
  ORG_LOCATION_SUBSCRIPTION_PAGE,
  ORG_LOCATION_SUBSCRIPTION_PRICING_PAGE,
  ORG_USERS_PAGE,
  ALERT_DETAILS_PAGE,
  PAGE_NOT_FOUND,
  ADMIN_DEVICES_PAGE,
}

const {
  HOME_PAGE,
  OVERVIEW_PAGE,
  ORG_DETAILS_PAGE,
  OCCUPANCY_PAGE,
  FLOORPLAN_PAGE,
  ORG_API_KEY_LIST_PAGE,
  ORG_ALERT_CONFIGURATION_LIST_PAGE,
  ORG_INTEGRATIONS_PAGE,
  ORG_LOCATION_EDITOR_PAGE,
  ORG_LOCATION_SUBSCRIPTION_PAGE,
  ORG_LOCATION_SUBSCRIPTION_PRICING_PAGE,
  ORG_USERS_PAGE,
  USER_SETTINGS_PAGE,
  REPORTS_PAGE,
  REPORTS_AIR_PAGE,
  REPORTS_SURFACE_PAGE,
  INFECTION_PAGE,
  DEVICES_PAGE,
  DEVICES_MAINT_PAGE,
  DEVICE_DETAILS_PAGE,
  ALERT_DETAILS_PAGE,
  ADMIN_DEVICES_PAGE,
  MOLD_PAGE,
} = ContinuumAppPagesEnum;

export const continuumAppPathToPageMap = {
  [PATH_HOME]: HOME_PAGE,
  [PATH_OVERVIEW]: OVERVIEW_PAGE,
  [PATH_REPORTS]: REPORTS_PAGE,
  [PATH_REPORTS_AIR]: REPORTS_AIR_PAGE,
  [PATH_REPORTS_SURFACE]: REPORTS_SURFACE_PAGE,
  [PATH_DEVICES]: DEVICES_PAGE,
  [PATH_DEVICES_MAINT]: DEVICES_MAINT_PAGE,
  [PATH_DEVICE_DETAILS]: DEVICE_DETAILS_PAGE,
  [PATH_ORGANIZATION_DETAILS]: ORG_DETAILS_PAGE,
  [PATH_ORGANIZATION_API_KEYS]: ORG_API_KEY_LIST_PAGE,
  [PATH_ORGANIZATION_ALERT_CONFIGS]: ORG_ALERT_CONFIGURATION_LIST_PAGE,
  [PATH_ORGANIZATION_INTEGRATIONS]: ORG_INTEGRATIONS_PAGE,
  [PATH_ORGANIZATION_LOCATION_EDITOR]: ORG_LOCATION_EDITOR_PAGE,
  [PATH_ORGANIZATION_SUBSCRIPTION]: ORG_LOCATION_SUBSCRIPTION_PAGE,
  [PATH_ORGANIZATION_SUBSCRIPTION_PRICING]: ORG_LOCATION_SUBSCRIPTION_PRICING_PAGE,
  [PATH_ORGANIZATION_USERS]: ORG_USERS_PAGE,
  [PATH_USER_SETTINGS]: USER_SETTINGS_PAGE,
  [PATH_ALERT_DETAILS]: ALERT_DETAILS_PAGE,
  [PATH_INFECTION]: INFECTION_PAGE,
  [PATH_FLOORPLANS]: FLOORPLAN_PAGE,
  [PATH_OCCUPANCY]: OCCUPANCY_PAGE,
  [PATH_ADMIN_DEVICES]: ADMIN_DEVICES_PAGE,
  [PATH_MOLD]: MOLD_PAGE,
};

export default ContinuumAppPagesEnum;
