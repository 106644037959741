import React from 'react';
import { Box, Button, Modal, Typography, Paper, Tooltip, IconButton, Stack } from '@mui/material';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import CancelIcon from '@mui/icons-material/Cancel';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const modalStyle = {
  position: 'absolute' as const,
  top: '40%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
};

type Props = {
  onModalClose: () => void;
};

export default function EmailSupportModal({ onModalClose }: Props) {
  const supportEmailAddress = 'support@uvangel.com';

  return (
    <Modal open={true} onClose={onModalClose}>
      <Box
        display='flex'
        flexDirection='column'
        justifyContent='center'
        alignItems='center'
        component={Paper}
        width={300}
        height={200}
        sx={modalStyle}
        padding={3}
        gap={1}
        position='relative'
      >
        <IconButton
          onClick={onModalClose}
          sx={{ alignSelf: 'flex-end', position: 'absolute', top: 4, right: 4 }}
        >
          <CancelIcon />
        </IconButton>
        <SupportAgentIcon fontSize='large' color='primary' />
        <Stack alignItems='center'>
          <Typography fontSize={20} fontWeight='bold'>
            Questions?
          </Typography>
          <Typography lineHeight={1} fontSize={20} fontWeight='bold'>
            We&apos;re here to help
          </Typography>
        </Stack>
        <Typography>
          Contact us at
          <CopyToClipboard text={supportEmailAddress}>
            <Tooltip title='Click to copy'>
              <Button sx={{ textTransform: 'lowercase' }}>{supportEmailAddress}</Button>
            </Tooltip>
          </CopyToClipboard>
        </Typography>
      </Box>
    </Modal>
  );
}
