/* eslint-disable no-magic-numbers */
import { useCallback } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Box,
  Button,
  Typography,
  CircularProgress,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { gql, useMutation, useReactiveVar } from '@apollo/client';
import { useToast, ToastNotificationSeverityTypeEnum } from 'Providers/ToastProvider';
import { selectedOrgVar } from 'Apollo/ApolloCache';
import { deviceMap } from 'Components/AuthedPages/DevicesListPage/PageViews/DevicesListFilters';
interface Props {
  onModalClose: () => void;
  modalProps?: unknown;
}

type RecalibrateDeviceModalProps = {
  serialNumber?: string | null;
  type?: string | null;
  onSuccess: () => Promise<void>;
};

const RECALIBRATE_DEVICE = gql`
  mutation RecalibrateDeviceGyroCalibration($accountId: ID!, $serialNumber: String!) {
    recalibrateDeviceGyroCalibration(accountId: $accountId, serialNumber: $serialNumber) {
      serialNumber
    }
  }
`;

export default function RecalibrateDeviceModal({ onModalClose, modalProps }: Props) {
  const { serialNumber, onSuccess, type } = modalProps as RecalibrateDeviceModalProps;
  const { dispatchToast } = useToast();
  const selectedOrg = useReactiveVar(selectedOrgVar);

  const [recalibrateDevice, { loading }] = useMutation(RECALIBRATE_DEVICE);
  const recalibrateHandler = useCallback(() => {
    if (!selectedOrg?.id || !serialNumber) {
      dispatchToast({
        severity: ToastNotificationSeverityTypeEnum.WARNING,
        title: 'Unexpected error occurred',
        message: 'Please contact support',
      });
      return;
    }
    recalibrateDevice({
      variables: {
        accountId: selectedOrg?.id,
        serialNumber,
      },
    })
      .then((d) => {
        if (d.errors) {
          throw new Error('Device Recalibration Failed');
        }
        dispatchToast({
          severity: ToastNotificationSeverityTypeEnum.SUCCESS,
          title: 'Device  Recalibrated',
        });
        onSuccess();
      })
      .catch((e) => {
        dispatchToast({
          severity: ToastNotificationSeverityTypeEnum.ERROR,
          title: 'Could not recibrate device',
          message: e.message,
        });
      })
      .finally(() => {
        onModalClose();
      });
  }, [serialNumber, selectedOrg, dispatchToast, onSuccess, recalibrateDevice, onModalClose]);
  return (
    <Dialog
      open={true}
      onClose={() => {
        onModalClose();
      }}
      fullWidth
      maxWidth='sm'
    >
      <DialogTitle>Recalibrate Device</DialogTitle>
      <IconButton
        onClick={onModalClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Typography variant='body1'>
          Please ensure that the following <b>{deviceMap[type ?? '']?.value ?? ''}</b> device is
          properly oriented and secured in a safe operating position.
        </Typography>
        <Typography variant='subtitle1'>{serialNumber}</Typography>
      </DialogContent>
      <DialogActions>
        <Box width='100%' gap={1} display='flex' justifyContent='flex-end' alignItems='center'>
          <Button
            color='primary'
            sx={{ fontWeight: 500 }}
            onClick={onModalClose}
            disabled={loading}
          >
            Cancel
          </Button>
          <Button
            disabled={loading}
            color='error'
            sx={{ fontWeight: 500 }}
            onClick={recalibrateHandler}
            endIcon={loading && <CircularProgress color='inherit' size={16} />}
          >
            Recalibrate
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}
