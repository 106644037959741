import { Card, CardHeader, Avatar } from '@mui/material';
import { QueryBuilderOutlined as QueryBuilderIcon } from '@mui/icons-material';
import moment from 'moment';
interface FirstConnectedCardProps {
  firstConnected?: string;
}

export default function FirstConnectedCard({ firstConnected }: FirstConnectedCardProps) {
  return (
    <Card sx={{ height: '100%' }}>
      <CardHeader
        avatar={
          <Avatar aria-label='device-type'>
            <QueryBuilderIcon color='inherit' sx={{ height: 28, width: 28 }} />
          </Avatar>
        }
        title='First Connected'
        titleTypographyProps={{ variant: 'body2', fontWeight: 500 }}
        subheader={moment(firstConnected).format('MMM DD, YYYY, h:mm A')}
      />
    </Card>
  );
}
