import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import UserNameCellCard from './TableCells/UserNameCellCard';
import LastLoginCellCard from './TableCells/LastLoginCellCard';
import UserActionCellCard from './TableCells/UserActionCellCard';
import StatusCellCard from './TableCells/StatusCellCard';
import { getRoleType, formatTimestamp } from 'Utils/orgUserManagementUtils';

import type { SelectedOrgType } from 'Apollo/ApolloCache';
import type { GetCurrentOrgUserListQuery as OrgUserListType } from '__generated__/graphql';

type OrgUserManagementTableProps = {
  userListdata: OrgUserListType | undefined;
  selectedOrg: SelectedOrgType;
};

export default function OrgUserManagementTable({
  userListdata,
  selectedOrg,
}: OrgUserManagementTableProps) {
  const { users } = userListdata?.account ?? {};
  const userList = users ?? [];
  const [isRemovingUser, setIsRemovingUser] = useState<boolean>(false);
  return (
    <TableContainer component={Paper}>
      <Table aria-label='user management table'>
        <TableHead>
          <TableRow>
            <TableCell>Username</TableCell>
            <TableCell>Role</TableCell>
            <TableCell>Date registered</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Last Login</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {userList.map((user) => {
            const {
              id: userID,
              accountScope,
              firstName,
              lastName,
              email,
              status,
              createdAt,
              lastLogin,
            } = user ?? {};
            const { roles, rootLocation } = accountScope ?? {};
            const roleType = getRoleType(roles);
            const dateRegistered = formatTimestamp(createdAt);
            return (
              <TableRow key={user?.id}>
                <TableCell width={250}>
                  <UserNameCellCard
                    firstName={firstName ?? 'null'}
                    lastName={lastName ?? 'null'}
                    email={email ?? ''}
                    roleType={roleType}
                  />
                </TableCell>
                <TableCell width={50}>{roleType}</TableCell>
                <TableCell width={125}>{dateRegistered}</TableCell>
                <TableCell width={100}>
                  <StatusCellCard status={status} />
                </TableCell>
                <TableCell width={150}>
                  <LastLoginCellCard lastLogin={lastLogin} />
                </TableCell>
                <TableCell width={80}>
                  <UserActionCellCard
                    firstName={firstName ?? ''}
                    lastName={lastName ?? ''}
                    userID={userID ?? ''}
                    rootLocationId={rootLocation?.id}
                    selectedOrg={selectedOrg}
                    isRemovingUser={isRemovingUser}
                    roleType={roleType}
                    setIsRemovingUser={setIsRemovingUser}
                  />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
