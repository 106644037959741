type TimeRange = 'hours' | 'days' | 'weeks' | 'months';

const hoursInDay = 24;
const daysInWeek = 7;
const weeksInMonth = 4;
const milliSecondsInHour = 3600000;
const milliSecondsInDay = milliSecondsInHour * hoursInDay;
const milliSecondsInWeek = milliSecondsInDay * daysInWeek;
const milliSecondsInMonth = milliSecondsInWeek * weeksInMonth;

const timeRangeMap = {
  hours: milliSecondsInHour,
  days: milliSecondsInDay,
  weeks: milliSecondsInWeek,
  months: milliSecondsInMonth,
};
// Util class for generating ISO formatted TS
export class TimeStampGenerator {
  now(): string {
    const now = new Date();
    return now.toISOString();
  }
  previous(multiplier: number, timeRangeOption: TimeRange): string {
    const now = new Date();
    const milliSecondsInTimeRange = timeRangeMap[timeRangeOption];
    const totalMilliSeconds = milliSecondsInTimeRange * multiplier;
    return new Date(now.getTime() - totalMilliSeconds).toISOString();
  }
}
