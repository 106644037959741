import { Box, Button, CircularProgress, useMediaQuery, useTheme } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { TypedDocumentNode, gql, useQuery } from '@apollo/client';
import { useErrorBoundary } from 'react-error-boundary';

import PageContainerFrame from 'Components/HOC/PageContainerFrame';
import SettingsPanelFrame from 'Components/HOC/SettingsPanelFrame';
import OrgAlertConfigurationCreateView from './PageViews/OrgAlertConfigCreateView';
import OrgAlertConfigurationListView from './PageViews/OrgAlertConfigListView';
import OrgDisplayAndSwitcher from 'Components/SharedUI/OrgDisplayAndSwitcher';
import { OrgAlertConfigurationsSettingsPanelViewEnum } from 'Constants/OrgSettingsEnums';
import RoutePaths from 'Constants/RoutePaths';
import type { AlertConfiguration, QueryAlertConfigurationsArgs } from '__generated__/graphql';
import type { SelectedOrgType } from 'Apollo/ApolloCache';
import { NotificationAdd } from '@mui/icons-material';

export const GET_ALERT_CONFIGURATIONS_QUERY: TypedDocumentNode<
  { alertConfigurations: AlertConfiguration[] },
  QueryAlertConfigurationsArgs
> = gql`
  query AlertConfigurations($accountId: ID!) {
    alertConfigurations(accountId: $accountId) {
      createdAt
      emailRecipients
      exceedingDurationMinutes
      id
      metric
      metricThreshold {
        high
        low
      }
      parentLocation {
        id
        name
      }
      totalAlerts
    }
  }
`;

type PanelViewRendererProps = {
  panelView: OrgAlertConfigurationsSettingsPanelViewEnum;
  alertConfigurations?: AlertConfiguration[];
  userHasOrgWritePermission: boolean;
  selectedOrg: SelectedOrgType;
};

const CurrentPanelViewRenderer = ({
  panelView,
  alertConfigurations,
  userHasOrgWritePermission,
  selectedOrg,
}: PanelViewRendererProps) => {
  switch (panelView) {
    case OrgAlertConfigurationsSettingsPanelViewEnum.ORG_ALERT_CONFIG_LIST_VIEW:
      return (
        <OrgAlertConfigurationListView
          userHasOrgWritePermission={userHasOrgWritePermission}
          alertConfigurations={alertConfigurations}
          selectedOrg={selectedOrg}
        />
      );
    case OrgAlertConfigurationsSettingsPanelViewEnum.ORG_ALERT_CONFIG_NEW_VIEW:
      return <OrgAlertConfigurationCreateView selectedOrg={selectedOrg} />;
    default:
      return (
        <OrgAlertConfigurationListView
          userHasOrgWritePermission={userHasOrgWritePermission}
          alertConfigurations={alertConfigurations}
          selectedOrg={selectedOrg}
        />
      );
  }
};

type Props = {
  selectedOrg: SelectedOrgType;
};

export default function OrgAlertConfigPage({ selectedOrg }: Props) {
  const userHasOrgWritePermission =
    selectedOrg?.userAccountScope?.roles?.account_write == null
      ? false
      : selectedOrg?.userAccountScope?.roles?.account_write;
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const {
    panelView = OrgAlertConfigurationsSettingsPanelViewEnum.ORG_ALERT_CONFIG_LIST_VIEW,
  }: { panelView: OrgAlertConfigurationsSettingsPanelViewEnum } = location.state || {};
  const { error: queryError, data: alertConfigurationsData } = useQuery(
    GET_ALERT_CONFIGURATIONS_QUERY,
    {
      variables: { accountId: selectedOrg?.id ?? '' },
    }
  );
  const { showBoundary: showErrorBoundary } = useErrorBoundary();

  if (queryError) {
    showErrorBoundary(queryError);
  }

  return (
    <PageContainerFrame pageTitles={['Alert Configurations', selectedOrg?.name]}>
      <SettingsPanelFrame
        title='Alert Configurations'
        description={
          userHasOrgWritePermission
            ? 'Create and manage Alert Configurations to be notified when a location\'s selected metric goes outside of your configured parameters.'
            : 'View Alert Configurations currently defined by your organization, which send an alert when a location\'s metric goes outside of the configured parameters.'
        }
      >
        <Box display='flex' flexDirection='row' justifyContent='space-between'>
          {userHasOrgWritePermission &&
          panelView !== OrgAlertConfigurationsSettingsPanelViewEnum.ORG_ALERT_CONFIG_NEW_VIEW && (
            <Button
              color="success"
              endIcon={!isSmallScreen && <NotificationAdd />}
              onClick={() =>
                navigate(RoutePaths.PATH_ORGANIZATION_ALERT_CONFIGS, {
                  state: {
                    panelView:
                      OrgAlertConfigurationsSettingsPanelViewEnum.ORG_ALERT_CONFIG_NEW_VIEW,
                  },
                })
              }
              variant='contained'
            >
              New Alert Config
            </Button>
          )}
        </Box>
        {alertConfigurationsData ? (
          <>
            <Box>
              <OrgDisplayAndSwitcher />
            </Box>
            <CurrentPanelViewRenderer
              userHasOrgWritePermission={userHasOrgWritePermission}
              panelView={panelView}
              alertConfigurations={alertConfigurationsData.alertConfigurations}
              selectedOrg={selectedOrg}
            />
          </>
        ) : (
          <CircularProgress sx={{ alignSelf: 'center', marginTop: 10 }} size={100} />
        )}
      </SettingsPanelFrame>
    </PageContainerFrame>
  );
}
