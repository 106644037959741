import { gql } from '@apollo/client';

export const LOCATION_CORE_FRAGMENT = gql`
  fragment LocationCore on Location {
    id
    name
    description
    type
    tags
    archetype
  }
`;

export const LOCATION_PARENTS_FRAGMENT = gql`
  fragment LocationParents on Location {
    parentLocation {
      ...LocationCore
    }
    fullLocationPath {
      ...LocationCore
    }
  }
`;

export const LOCATION_STATS_FRAGMENT = gql`
  fragment LocationStats on Location {
    numImmediateSubLocations
    numDevices
  }
`;

export const LOCATION_IMMEDIATE_SUBLOCATIONS_FRAGMENT = gql`
  ${LOCATION_CORE_FRAGMENT}
  fragment LocationImmediateSublocations on Location {
    immediateSublocations {
      ...LocationCore
    }
  }
`;

export const LOCATION_ALL_SUBLOCATIONS_FRAGMENT = gql`
  ${LOCATION_CORE_FRAGMENT}
  fragment LocationAllSublocations on Location {
    allSublocations {
      ...LocationCore
    }
  }
`;

export const LOCATION_METADATA_FRAGMENT = gql`
  fragment LocationMetadata on Location {
    metadata {
      airVolumeFt3
      assumedAch
      averagePerPersonCO2EmissionRate
      co2BaselinePPM
      desiredQuantaThresholdCm3
      maximumOccupancy
      buildingId
      floorId
    }
  }
`;

// TODO: use fragment for users
export const LOCATION_DETAILS_FRAGMENT = gql`
  fragment LocationDetails on Location {
    createdAt
    createdBy {
      id
      email
    }
    updatedAt
    updatedBy {
      id
      email
    }
  }
`;

export const LOCATION_MAP_FRAGMENT = gql`
  ${LOCATION_CORE_FRAGMENT}
  fragment LocationMap on Location {
    mapFeatures {
      baseLocation {
        ...LocationCore
      }
      feature {
        type
        geometry
        properties
      }
    }
    floorPlanImageUrl
  }
`;

export const LOCATION_OUTBREAK_FRAGMENT = gql`
  fragment LocationOutbreak on Location {
    activeOutbreakMode {
      _id
      activatedAt
      createdAt
      inactiveAt
      mode
      status
      type
    }
  }
`;
