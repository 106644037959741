import { Box, Typography, Tooltip } from '@mui/material';
import { DeviceHubOutlined } from '@mui/icons-material';
import moment from 'moment';
import type { StringShadowValue as FirmwareVersion } from '__generated__/graphql';
interface DeviceFirmwareCellProps {
  firmware?: FirmwareVersion | null;
}

export default function DeviceFirmwareCell({ firmware }: DeviceFirmwareCellProps) {
  return (
    <Box display='flex' flexDirection='column' justifyContent='center' alignItems='flex-start'>
      <Typography fontWeight='bold' color='gray' variant='body2'>
        Firmware
      </Typography>
      <Box display='flex' justifyContent='center' alignItems='center' gap={1}>
        <DeviceHubOutlined
          sx={{
            height: 16,
            width: 16,
          }}
        />
        <Tooltip title={moment(firmware?.timestamp).fromNow()}>
          <Typography variant='body2'>{firmware?.value}</Typography>
        </Tooltip>
      </Box>
    </Box>
  );
}
