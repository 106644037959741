import ExternalAppViewsEnums, { externalAppPathToViewMap } from 'Constants/ExternalAppPageEnum';
import DeviceInformationPage from 'UnAuthedPages/DeviceInformationPage';
import DocumentationPage from 'UnAuthedPages/DocumentationPage';
import LandingSplashPage from 'UnAuthedPages/LandingSplashPage';
import LoginPage from 'UnAuthedPages/LoginPage';
import { Fragment } from 'react';
import { useLocation } from 'react-router-dom';
import NavBar from './Components/NavBar/NavBar';

const { LANDING_PAGE, LOGIN_PAGE, DOC_PAGE, DEVICE_INFO_PAGE } = ExternalAppViewsEnums;

function ExternalPageRenderer(): JSX.Element {
  const location = useLocation();
  const currentPathName = location.pathname;
  const selectedView = externalAppPathToViewMap[currentPathName];

  switch (selectedView) {
    case LANDING_PAGE:
      return <LandingSplashPage />;
    case LOGIN_PAGE:
      return <LoginPage />;
    case DOC_PAGE:
      return <DocumentationPage />;
    case DEVICE_INFO_PAGE:
      return <DeviceInformationPage />;
    default:
      return <LandingSplashPage />;
  }
}

export default function ExternalApp() {
  return (
    <Fragment>
      <NavBar />
      <ExternalPageRenderer />
    </Fragment>
  );
}
