/* eslint-disable no-nested-ternary */
import { useQuery, useReactiveVar } from '@apollo/client';
import { PlayCircleOutline, StopCircleOutlined } from '@mui/icons-material';
import CrisisAlert from '@mui/icons-material/CrisisAlert';
import {
  Badge,
  CircularProgress,
  Divider,
  Hidden,
  IconButton,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Tooltip,
} from '@mui/material';
import {
  GetLocationResponseModeStatusQuery,
  GetLocationResponseModeStatusQueryVariables,
  LocationResponseStatus,
} from '__generated__/graphql';
import { selectedLocationVar, selectedOrgVar } from 'Apollo/ApolloCache';
import moment from 'moment';
import { ModalNotificationTypeEnum, useModal } from 'Providers/ModalProvider';
import { useMemo, useState } from 'react';
import { LOCATION_MODE } from './LocationMode.gql';
import OutbreakStatus from './OutbreakStatus';
import { useNavigate } from 'react-router-dom';
import RoutePaths from 'Constants/RoutePaths';

const MAX_MENU_HEIGHT = 500;

export default function OutbreakLocations() {
  const { dispatchModal } = useModal();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const handleMenuClick = (event: React.MouseEvent<HTMLElement>): void => {
    if (anchorEl) {
      setAnchorEl(null);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };
  const selectedOrg = useReactiveVar(selectedOrgVar);
  const selectedLocation = useReactiveVar(selectedLocationVar);
  const {
    data: activeModeLocations,
    loading: activeModeLocationsLoading,
    refetch,
  } = useQuery<GetLocationResponseModeStatusQuery, GetLocationResponseModeStatusQueryVariables>(
    LOCATION_MODE,
    {
      skip: !selectedLocation?.id || !selectedOrg?.id,
      variables: {
        accountId: selectedOrg?.id ?? '',
        locationResponseStatus: LocationResponseStatus.Active,
      },
    }
  );
  const locationsWithOutbreakMode = useMemo(() => {
    const locs = activeModeLocations?.getLocationResponseModeStatus?.locationResponseModes;
    if (!locs) return [];
    return locs?.filter((i) => !!i);
  }, [activeModeLocations]);
  return (
    <Stack width='100%' direction='row' alignItems='center' spacing={2}>
      <Hidden lgDown>
        <OutbreakStatus />
      </Hidden>
      <Stack direction='row' flexGrow={1} justifyContent='flex-end'>
        <Tooltip title='Manage Outbreaks'>
          {activeModeLocationsLoading ? (
            <CircularProgress color='primary' size={16} />
          ) : (
            <IconButton
              aria-controls='menu-appbar'
              aria-haspopup='true'
              aria-label='Alerts Menu'
              onClick={handleMenuClick}
              size='large'
            >
              <Badge badgeContent={locationsWithOutbreakMode.length ?? 0} color='error'>
                <CrisisAlert />
              </Badge>
            </IconButton>
          )}
        </Tooltip>
        <Menu
          anchorEl={anchorEl}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          open={!!anchorEl}
          onClose={() => setAnchorEl(null)}
          sx={{
            maxHeight: MAX_MENU_HEIGHT,
            width: 300,
          }}
          slotProps={{
            paper: {
              sx: {
                minWidth: 300,
              },
            }
          }}
        >
          <MenuItem
            onClick={() => {
              dispatchModal({
                type: ModalNotificationTypeEnum.LOCATION_MODE_OUTBREAK_START,
                modalProps: {
                  accountId: selectedOrg?.id ?? '',
                  locationId: selectedLocation?.id ?? '',
                  onActionSuccessful: () => refetch(),
                },
              });
            }}
          >
            <ListItemText
              primaryTypographyProps={{
                sx: { color: (theme) => theme.palette.warning.main },
              }}
            >
              New Outbreak
            </ListItemText>
            <PlayCircleOutline color='warning' />
          </MenuItem>
          <Divider />
          {locationsWithOutbreakMode.length === 0 ? (
            <MenuItem>No Active Outbreaks</MenuItem>
          ) : (
            locationsWithOutbreakMode.map((i) => (
              <MenuItem key={i?._id}
                onClick={(e) => {
                  e.preventDefault();
                  if (i?.location?.id) {
                    navigate(`${RoutePaths.PATH_DEVICES}?location=${i?.location.id}`);
                  }
                  setAnchorEl(null);
                }}
              >
                <ListItemText
                  primary={i?.location?.name ?? 'All Locations'}
                  secondary={`Since ${moment(i?.activatedAt).fromNow()}`}
                />
                <Tooltip title={`Stop Outbreak in ${i?.location?.name ?? 'Unknown'}`}>
                  <IconButton edge="end"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      dispatchModal({
                        type: ModalNotificationTypeEnum.LOCATION_MODE_OUTBREAK_STOP,
                        modalProps: {
                          action: 'stop',
                          accountId: selectedOrg?.id ?? '',
                          locationId: i?.location?.id ?? '',
                          name: i?.location?.name ?? 'Unknown',
                          onActionSuccessful: () => refetch(),
                        },
                      });
                    }}
                  >
                    <StopCircleOutlined />
                  </IconButton>
                </Tooltip>
              </MenuItem>
            ))
          )}
        </Menu>
      </Stack>
    </Stack>
  );
}
