/* eslint-disable no-magic-numbers */
import { useMutation } from '@apollo/client';
import { Close as CloseIcon } from '@mui/icons-material';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { useCallback, useState } from 'react';

import { ToastNotificationSeverityTypeEnum, useToast } from 'Providers/ToastProvider';

import {
  StartOutbreakResponseModeMutation,
  StartOutbreakResponseModeMutationVariables,
} from '__generated__/graphql';
import LocationSelectorBreadcrumb from 'Components/LocationSelectorBreadcrumb/LocationSelectorBreadcrumb';
import useCurrentLocationList from 'Hooks/useCurrentLocationList';
import { OUTBREAK_START } from './graphql';
import { LOCATION_OUTBREAK_FRAGMENT } from 'fragments';

type OutbreakStartModalProps = {
  onModalClose: () => void;
  modalProps: unknown;
};

export default function OutbreakStartModal({ modalProps, onModalClose }: OutbreakStartModalProps) {
  const [isChecked, setIsChecked] = useState<boolean>(false);

  const { accountId, locationId, onActionSuccessful } = modalProps as {
    accountId: string;
    locationId: string;
    onActionSuccessful: () => void;
  };

  const [selectedLocationId, setSelectedLocationId] = useState(locationId);

  const [isLoading, initLocationList] = useCurrentLocationList(selectedLocationId);

  const [startOutbreak, { loading: isStartMutationInFlight }] = useMutation<
    StartOutbreakResponseModeMutation,
    StartOutbreakResponseModeMutationVariables
  >(OUTBREAK_START, {
    update: (cache, result) => {
      cache.writeFragment({
        id: `Location:${locationId}`,
        fragment: LOCATION_OUTBREAK_FRAGMENT,
        data: {
          activeOutbreakMode: result.data?.startOutbreakResponseMode.locationResponseMode,
        },
      });
    },
  });
  const { dispatchToast } = useToast();

  const handleOutbreakAction = useCallback(async () => {
    if (isStartMutationInFlight) {
      return;
    }

    try {
      const variables = {
        accountId,
        locationId: selectedLocationId,
      };

      await startOutbreak({
        variables,
      });
      dispatchToast({
        severity: ToastNotificationSeverityTypeEnum.INFO,
        title: 'Started Outbreak response',
      });

      onModalClose();
      onActionSuccessful();
    } catch (error) {
      console.error('OutbreakStartModal -> handleOutbreakAction', error);
      dispatchToast({
        severity: ToastNotificationSeverityTypeEnum.ERROR,
        title: 'Error Starting Outbreak Response',
        message: 'Please try again',
      });
    }
  }, [
    accountId,
    dispatchToast,
    isStartMutationInFlight,
    onActionSuccessful,
    onModalClose,
    selectedLocationId,
    startOutbreak,
  ]);
  return (
    <Dialog
      open={true}
      onClose={() => {
        onModalClose();
      }}
      fullWidth
      maxWidth='sm'
    >
      <DialogTitle>
        Outbreak Response{' '}
        <Typography variant='body1' color='text.secondary' fontWeight={500}>
          Activate
        </Typography>
      </DialogTitle>
      <IconButton
        onClick={onModalClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Stack spacing={2}>
          <Typography>You are about to trigger an outbreak in the following location</Typography>
          <Box display='flex' mt={2} p={1} bgcolor='background.paper' borderRadius={1}>
            {isLoading ? (
              <CircularProgress color='inherit' sx={{ alignSelf: 'center' }} />
            ) : (
              <LocationSelectorBreadcrumb
                onLocationSelectCallback={setSelectedLocationId}
                dontUpdateGlobal
                preLoadedLocationList={initLocationList}
              />
            )}
          </Box>
          <Typography>
            Would you like to initiate this outbreak and cause all devices in the following area to
            go to max speed?
          </Typography>
        </Stack>
        <FormControlLabel
          control={<Checkbox checked={isChecked} onChange={() => setIsChecked(!isChecked)} />}
          label='Yes, Initiate'
        />
      </DialogContent>
      <Divider style={{ width: '100%' }} />
      <DialogActions>
        <Stack alignItems='center' justifyContent='flex-end' direction='row' gap={1}>
          <Button onClick={onModalClose} color='info'>
            Cancel
          </Button>
          <Button
            disabled={!isChecked || isStartMutationInFlight}
            onClick={handleOutbreakAction}
            color='error'
            endIcon={isStartMutationInFlight && <CircularProgress size={16} />}
          >
            Start
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}
