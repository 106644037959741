/* eslint-disable indent */
/* eslint-disable no-magic-numbers */
import { Alert, AlertTitle, List, ListItem, ListItemText, Stack, Typography } from '@mui/material';
import { useOccupancyContext } from '../context';
import OccupancyCalibrationHelper from './components/OccupancyCalibrationHelper';

export default function OccupancyNeedsCalibrationView() {
  const { isRecalibrating } = useOccupancyContext();
  return (
    <Stack spacing={2}>
      {!isRecalibrating && (
        <Alert severity='warning'>
          <AlertTitle>Calibration Required</AlertTitle>
          Occupancy details can only be viewed once calibration is complete
        </Alert>
      )}
      <Typography>A location needs the following parameters to be calibrated</Typography>
      <List sx={{ listStyle: 'decimal', pl: 4, py: 0 }}>
        <ListItem sx={{ display: 'list-item', py: 0 }}>
          <ListItemText
            primary={
              <span>
                Room Size{' '}
                <code>
                  (ft<sup>3</sup>)
                </code>
              </span>
            }
          />
        </ListItem>
        <ListItem sx={{ display: 'list-item', py: 0 }}>
          <ListItemText primary='Max Occupant Capacity' />
        </ListItem>
        <ListItem sx={{ display: 'list-item', py: 0 }}>
          <ListItemText primary='An Occupancy Sample' />
          <List sx={{ listStyle: 'lower-alpha', pl: 4, py: 0 }}>
            <ListItem sx={{ display: 'list-item', py: 0 }}>
              <ListItemText primary='Time-series occupancy data with at least moderate room usage. Ideally spanning at least 1 full day.' />
            </ListItem>
          </List>
        </ListItem>
      </List>
      <Typography>Please follow the instructions to calibrate this location</Typography>
      <OccupancyCalibrationHelper />
    </Stack>
  );
}
