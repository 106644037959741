import React from 'react';
import { Box, Typography, Paper, Stack, useTheme } from '@mui/material';

import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import PeopleIcon from '@mui/icons-material/People';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { RoleEnum } from 'Constants/OrgSettingsEnums';

const { ADMIN, READ_ONLY, STANDARD_USER } = RoleEnum;

type RolePermissionCardProps = {
  title: RoleEnum;
  description: string;
  icon: React.ElementType;
  selectedRole: RoleEnum | null;
  setSelectedRole: (arg: RoleEnum | null) => void;
  setIsEdited?: (arg: boolean) => void;
};

function RolePermissionCard({
  title,
  description,
  icon: Icon,
  selectedRole,
  setSelectedRole,
  setIsEdited,
}: RolePermissionCardProps) {
  const theme = useTheme();
  const isSelected = selectedRole === title;
  const handleClick = () => {
    setSelectedRole(title);
    if (setIsEdited) {
      setIsEdited(true);
    }
  };
  return (
    <Box
      component={Paper}
      display='flex'
      flexDirection='row'
      alignItems='center'
      justifyContent='space-between'
      border={3}
      borderRadius={2}
      borderColor={isSelected ? theme.palette.primary.main : theme.palette.secondary.light}
      height={70}
      padding={2}
      gap={2}
      onClick={handleClick}
    >
      <Stack alignItems='center' direction='row' gap={1.5}>
        <Icon fontSize='medium' />
        <Stack>
          <Typography fontSize={16} fontWeight='bold'>
            {title}
          </Typography>
          <Typography fontSize={14}>{description}</Typography>
        </Stack>
      </Stack>
      {isSelected ? <CheckCircleIcon color='primary' /> : null}
    </Box>
  );
}

type RoleSelectionContainerProps = {
  selectedRole: RoleEnum | null;
  setSelectedRole: (arg: RoleEnum | null) => void;
  setIsEdited?: (arg: boolean) => void;
};

const roleOptionsConfigList = [
  {
    title: ADMIN,
    description: 'Admin level access to org data & operations',
    icon: ManageAccountsIcon,
  },
  {
    title: STANDARD_USER,
    description: 'Standard access to org data & operations',
    icon: PeopleIcon,
  },
  {
    title: READ_ONLY,
    description: 'Read only access to org data & devices',
    icon: AutoStoriesIcon,
  },
];
export default function RoleSelectionInput({
  selectedRole,
  setSelectedRole,
  setIsEdited,
}: RoleSelectionContainerProps) {
  return (
    <Stack gap={1} width='100%'>
      {roleOptionsConfigList.map((config) => {
        const { title, description, icon } = config;
        return (
          <RolePermissionCard
            key={title}
            title={title}
            description={description}
            icon={icon}
            selectedRole={selectedRole}
            setSelectedRole={setSelectedRole}
            setIsEdited={setIsEdited}
          />
        );
      })}
    </Stack>
  );
}
