/* eslint-disable indent */
/* eslint-disable no-magic-numbers */
import { Box, Checkbox, FormControlLabel, Typography, useTheme } from '@mui/material';
import { GridConfigType } from 'Components/MetricCards/QuickMetricsCard';
import { Fragment, useMemo, useState } from 'react';

import { Apps as AppsIcon } from '@mui/icons-material';
import { alpha } from '@mui/material';
import { ChartData, ChartOptions } from 'chart.js';
import { MatrixDataPoint } from 'chartjs-matrix-chart';
import MediumMetricsCard from 'Components/MetricCards/MediumMetricsCard';
import { metricCardIconColor } from 'Constants/OverviewConsts';
import { Chart as Chartjs } from 'react-chartjs-2';

import { BinInterval } from '__generated__/graphql';
import moment from 'moment-timezone';
import { useSearchParams } from 'react-router-dom';
import { capitalize } from 'Utils/stringManipulation';
import { useOccupancyContext } from '../../context';
import { getOccupancyToGroup } from '../../utils';

const CHART_ID = 'occupancy-heatmap';

type Props = {
  gridConfig?: GridConfigType;
};
const TITLE = 'Occupancy Heatmap';
const INITIAL_BOX = 350;
export default function OccupancyHeatmap({ gridConfig }: Props) {
  const [params] = useSearchParams();
  const theme = useTheme();
  const {
    currentInterval,
    occupancyGraphLoading,
    occupancyGraphData,
    occupancyGraphError,
    locationCalibrationDetailsForOccupancy,
  } = useOccupancyContext();

  const [show9To5, setShow9To5] = useState(true);

  const oneWeekBeforeEndDate = useMemo(() => {
    const endDate = params.get('end-date');
    const startDate = params.get('start-date');
    if (!endDate || !startDate) return;
    const endDateM = moment(endDate);
    const oneWeekPreEndDate = endDateM.clone().subtract(7, 'days');
    const startDateM = moment(startDate);
    // if startDate is more than 1 week behind end date send end-date - 1 week
    // other wise send start date
    if (startDateM.valueOf() < oneWeekPreEndDate.valueOf()) {
      return [oneWeekPreEndDate, endDateM];
    } else {
      return [startDateM, endDateM];
    }
  }, [params]);

  const heatmapData = useMemo(() => {
    const report = occupancyGraphData?.report?.uvangel?.getLocationOccupancyForDuration;
    if (!report || report.length === 0 || !oneWeekBeforeEndDate) return null;
    const finalReport = report
      .filter((r) => {
        const isWithinOneWeek =
          oneWeekBeforeEndDate[0].valueOf() < new Date(r?.timestamp).valueOf();
        const hourOfTheDay = moment(r?.timestamp).hours();
        const minuteOfTheHour = moment(r?.timestamp).minutes();
        const minuteOfTheDay = hourOfTheDay * 60 + minuteOfTheHour;
        if (show9To5) return isWithinOneWeek && hourOfTheDay >= 9 && minuteOfTheDay <= 17 * 60;
        return isWithinOneWeek;
      })
      .map((item) => {
        const timestamp = moment(item?.timestamp);
        return {
          x: moment(item?.timestamp).weekday() as number,
          y: timestamp.hours() * 60 + timestamp.minutes(),
          v: item?.estimatedOccupancyPercentage as number,
          t: timestamp.toDate(),
        };
      });
    if (finalReport.every((i) => i.v === 0)) {
      return null;
    }

    return finalReport;
  }, [occupancyGraphData, oneWeekBeforeEndDate, show9To5]);

  const chartOptions: ChartOptions<'matrix'> = useMemo(() => {
    return {
      responsive: true,
      maintainAspectRatio: false,
      aspectRatio: 5,
      scales: {
        y: {
          offset: true,
          grid: {
            display: false,
          },
          border: {
            display: false,
          },
          min: Math.min(...(heatmapData?.map((i) => i?.y) ?? [])),
          max: Math.max(...(heatmapData?.map((i) => i?.y) ?? [])),
          ticks: {
            stepSize: INTERVAL_STEPS[currentInterval],
            autoSkip: false,
            align: 'center',
            display: true,
            callback: (value) => {
              const time = moment().startOf('day').add(value, 'minutes');
              if (time.minute() === 0) {
                return time.format('h a');
              }
              return '';
            },
          },
        },
        x: {
          offset: true,
          position: 'top',
          grid: {
            display: false,
          },
          border: {
            display: false,
          },
          type: 'category',
          ticks: {
            display: true,
          },
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: true,
          callbacks: {
            title(context) {
              const raw = context.at(0)?.raw as MatrixDataPoint;
              const val = raw.v;
              return `Level: ${capitalize(getOccupancyToGroup(val / 100).toLocaleLowerCase())}`;
            },
            label(context) {
              const time = heatmapData?.at(context.dataIndex)?.t;
              return moment(time).format('ddd, MMM DD, h:mm A');
            },
          },
        },
      },
    };
  }, [heatmapData, currentInterval]);

  const chartData: ChartData<'matrix', MatrixDataPoint[]> | null = useMemo(() => {
    if (!heatmapData || heatmapData.length === 0) return null;
    return {
      datasets: [
        {
          data: heatmapData.map((i) => ({
            x: moment(i.t).format('dd M/D'),
            y: i.y.toString(),
            v: i.v,
          })),
          backgroundColor(c) {
            const point = c.dataset.data[c.dataIndex] as MatrixDataPoint;
            const opacity = Math.max(0.1, point.v / 100);
            return alpha(theme.palette.primary.main, opacity);
          },
          borderWidth: 1,
          hoverBorderColor: 'grey',
          width(c) {
            const a = c.chart.chartArea || {};
            return (a.right - a.left) / 7 - 6;
          },
          height(c) {
            const a = c.chart.chartArea || {};
            return (a.bottom - a.top) / new Set(heatmapData.map((i) => i.y)).size - 4;
          },
        },
      ],
    };
  }, [heatmapData, theme]);

  const chartHeight = useMemo(() => {
    if (!heatmapData) return INITIAL_BOX;
    const labels = new Set(heatmapData.map((i) => i.y));
    // Give each label at least 15px height
    return labels.size * 25;
  }, [heatmapData]);

  return (
    <MediumMetricsCard
      isLoading={occupancyGraphLoading}
      icon={
        <AppsIcon
          sx={{ color: occupancyGraphError ? theme.palette.error.main : metricCardIconColor }}
        />
      }
      title={TITLE}
      infoText={`${TITLE}${
        oneWeekBeforeEndDate
          ? ` from ${oneWeekBeforeEndDate[0].format('MMM DD')} to ${oneWeekBeforeEndDate[1].format(
              'MMM DD'
            )}`
          : 'for one week'
      }`}
      gridConfig={gridConfig}
      error={!!occupancyGraphError}
      height={'auto'}
    >
      <Box width='100%' display='flex' justifyContent='flex-end'>
        <FormControlLabel
          control={<Checkbox checked={show9To5} onChange={(e) => setShow9To5(e.target.checked)} />}
          label='Show 9 AM to 5 PM'
        />
      </Box>

      {chartData && chartOptions ? (
        <Fragment>
          <Box sx={{ height: chartHeight, width: '100%' }}>
            <Chartjs id={CHART_ID} type='matrix' data={chartData} options={chartOptions} />
          </Box>
        </Fragment>
      ) : (
        <Box pt={4} pb={8}>
          <Typography alignContent='center' color='InactiveCaptionText'>
            No data available for <b>{locationCalibrationDetailsForOccupancy?.name}</b>
          </Typography>
          <Typography alignContent='center' fontStyle='italic' color='InactiveCaptionText'>
            in the given time range
          </Typography>
        </Box>
      )}
    </MediumMetricsCard>
  );
}

const INTERVAL_STEPS: Record<BinInterval, number> = {
  [BinInterval.OneMinute]: 1,
  [BinInterval.FiveMinutes]: 5,
  [BinInterval.FifteenMinutes]: 15,
  [BinInterval.TenMinutes]: 10,
  [BinInterval.ThirtyMinutes]: 30,
  [BinInterval.OneHour]: 60,
};
