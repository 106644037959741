/* eslint-disable no-nested-ternary */
import { Alert, AlertTitle } from '@mui/material';
export default function NotAFloor() {
  return (
    <Alert severity='warning'>
      <AlertTitle>
        Current location is not a <b>Floor</b>
      </AlertTitle>
      Please choose a <b>Floor</b> as location to see the floor plan
    </Alert>
  );
}
