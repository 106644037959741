import { Fragment, useState, useMemo, useCallback } from 'react';
import { Tooltip, IconButton, Menu, MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import {
  Settings as SettingsIcon,
  LightOutlined as LightIcon,
  SpeedOutlined as SpeedIcon,
  Download as DownloadIcon,
} from '@mui/icons-material';
import { useSearchParams } from 'react-router-dom';
import {
  Exact,
  ConnectionStatus,
  DeviceSearchOptions,
  DeviceType,
  DeviceSearchSortColumn,
  OrderEnum,
} from '__generated__/graphql';
import { useModal, ModalNotificationTypeEnum } from 'Providers/ModalProvider';
import useCSVDownload from 'Hooks/useCSVDownload';
import { useLazyQuery, useReactiveVar } from '@apollo/client';
import { deviceMap } from './DevicesListFilters';
import { GET_CURRENT_ORG_DEVICES } from '../DevicesListPage';
import { useErrorBoundary } from 'react-error-boundary';
import { selectedLocationVar, selectedOrgVar } from 'Apollo/ApolloCache';
import { useToast, ToastNotificationSeverityTypeEnum } from 'Providers/ToastProvider';
/**
 * Heavily dependent on the query search parameters to determmine the device filters
 */
export default function DeviceSettingsTrigger() {
  const { showBoundary: showErrorBoundary } = useErrorBoundary();
  const [params] = useSearchParams();
  const download = useCSVDownload();
  const { dispatchToast } = useToast();
  const selectedOrg = useReactiveVar(selectedOrgVar);
  const selectedLocation = useReactiveVar(selectedLocationVar);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const { dispatchModal } = useModal();
  const [fetchDevices, { error }] = useLazyQuery(GET_CURRENT_ORG_DEVICES);
  const currentSearchOptions: Exact<DeviceSearchOptions> = useMemo(() => {
    return {
      searchQuery: params.get('searchQuery'),
      connectivity: params.get('connectivity') as ConnectionStatus,
      state: params.get('state'),
      types: params.get('type') ? [params.get('type') as DeviceType] : void 0,
      activeFaults: params.get('activeFault') ? [params.get('activeFault') ?? ''] : void 0,
    };
  }, [params]);
  const downloadDevicesHandler = useCallback(() => {
    dispatchToast({
      severity: ToastNotificationSeverityTypeEnum.INFO,
      title: 'Downloading devices, please wait a few seconds',
    });
    fetchDevices({
      variables: {
        deviceSearchOptions: {
          ...currentSearchOptions,
          accountId: selectedOrg?.id,
          locationId: selectedLocation?.id,
        },
        sort: [
          {
            column: (params.get('sortKey') ??
              DeviceSearchSortColumn.ConnectivityDate) as DeviceSearchSortColumn,
            order: (params.get('sortOrder') ?? OrderEnum.Desc) as OrderEnum,
          },
        ],
      },
    })
      .then((data) => {
        if (data.error) {
          dispatchToast({
            severity: ToastNotificationSeverityTypeEnum.ERROR,
            title: 'Unexpected error',
            message: 'Could not download device list',
          });
          return;
        }
        const jsonData =
          data?.data?.deviceSearch?.devices?.map((device) => ({
            'Serial Number': device?.serialNumber as string,
            'Device Type': /[a-zA-z0-9- ]+/.exec(deviceMap[device?.type ?? ''].value ?? '-')?.at(0),
            Account: device?.account?.name,
            'Full Location Path':
              device?.fullLocationPath?.map((loc) => loc?.name)?.join(' / ') ?? '',
            Firmware: device?.firmwareVersion?.value,
            'Connectivity Status': device?.connectivity?.connected ? 'Connected' : 'Disconnected',
            'Connectivity Date': device?.connectivity?.timestamp ?? '',
            'Last Status': device?.state?.state,
            'Last Status Date': device?.state?.timestamp ?? '',
          })) ?? [];
        download(jsonData, `devices-${Date.now()}.csv`);
      })
      .catch(() => {
        dispatchToast({
          severity: ToastNotificationSeverityTypeEnum.ERROR,
          title: 'Unexpected error',
          message: 'Could not download device list',
        });
      });
  }, [
    fetchDevices,
    params,
    currentSearchOptions,
    download,
    selectedOrg,
    selectedLocation,
    dispatchToast,
  ]);
  if (error) {
    showErrorBoundary(error);
  }
  return (
    <Fragment>
      <Tooltip title='Settings'>
        <IconButton
          aria-label='device-settings-menu'
          id='device-settings-menu-trigger'
          aria-haspopup='true'
          onClick={(e) => setAnchorEl(e.currentTarget)}
        >
          <SettingsIcon sx={{ fontSize: 20 }} />
        </IconButton>
      </Tooltip>
      <Menu
        id='device-settings-menu'
        MenuListProps={{
          'aria-labelledby': 'device-settings-menu-trigger',
        }}
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem
          key='led-downlight-brightness'
          onClick={() => {
            dispatchModal({
              type: ModalNotificationTypeEnum.DEVICE_BULK_UPDATE_LED_DOWNLIGHT,
              modalProps: currentSearchOptions,
            });
            setAnchorEl(null);
          }}
        >
          <ListItemIcon>
            <LightIcon />
          </ListItemIcon>
          <ListItemText>Bulk change LED Downlight Brightness</ListItemText>
        </MenuItem>
        <MenuItem
          key='treatment-mode'
          onClick={() => {
            dispatchModal({
              type: ModalNotificationTypeEnum.DEVICE_BULK_UPDATE_DEVICE_MODE,
              modalProps: currentSearchOptions,
            });
            setAnchorEl(null);
          }}
        >
          <ListItemIcon>
            <SpeedIcon />
          </ListItemIcon>
          <ListItemText>Bulk change Treatment Mode</ListItemText>
        </MenuItem>
        <MenuItem
          key='download'
          onClick={() => {
            downloadDevicesHandler();
            setAnchorEl(null);
          }}
        >
          <ListItemIcon>
            <DownloadIcon />
          </ListItemIcon>
          <ListItemText>Export device list to CSV</ListItemText>
        </MenuItem>
      </Menu>
    </Fragment>
  );
}
