import { useState, useCallback, useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  useTheme,
  Paper,
  Grid,
  TextField,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  ListSubheader,
  SelectChangeEvent,
  IconButton,
  ListItemIcon,
  Tooltip,
  Box,
} from '@mui/material';
import { Sort as SortIcon } from '@mui/icons-material';
import debounce from 'debounce';
import {
  DeviceType,
  DeviceSearchSortColumn,
  DeviceSearchSortInput,
  OrderEnum,
  ConnectionStatus,
  Exact,
  QueryDeviceSearchArgs,
} from '__generated__/graphql';

interface DeviceListFiltersProps {
  filterHandler: (args: Exact<QueryDeviceSearchArgs>) => void;
  selectedLocationId?: string | null;
}

export const deviceMap = {
  [DeviceType.Air20]: {
    value: 'UV Angel Clean Air Active™',
    icon: {
      light: '/assets/uv-angel-clean-air-active.svg',
      dark: '/assets/uv-angel-clean-air-active.svg',
    },
    docs: {
      copy: 'Installation and Operating Instructions Manual',
      link: 'https://uvangel.com/wp-content/uploads/2023/12/Clean-Air-Active-Installation-Manual.pdf',
    },
  },
  [DeviceType.Air175]: {
    value: 'UV Angel Clean Air™',
    icon: {
      light: '/assets/uv-angel-clean-air.svg',
      dark: '/assets/uv-angel-clean-air.svg',
    },
    docs: {
      copy: 'UV Angel Clean Air™ - Product Manual',
      link: 'https://uvangel.com/wp-content/uploads/2020/12/UV-Angel-Air-Installation-and-Operation-Manual-11-18-20-v4.pdf',
    },
  },
  [DeviceType.Uva20]: {
    value: 'UV Angel Adapt™',
    icon: {
      light: '/assets/uv-angel-adapt.svg',
      dark: '/assets/uv-angel-adapt.svg',
    },
    docs: {
      copy: 'UV Angel Adapt™ - Product Manual',
      link: 'https://uvangel.com/wp-content/uploads/2020/12/UV-Angel-Adapt-Operations-Manual-v11.pdf',
    },
  },
};

export const activeFaults = {
  lamp_failure: 'Lamp Fault',
  control_board_switch: 'Door Fault',
  fan_current: 'Fan Fault',
};

export const deviceSearchSortColumns = {
  [DeviceSearchSortColumn.ConnectivityDate]: 'Connectivity Date',
  [DeviceSearchSortColumn.Location]: 'Location',
  [DeviceSearchSortColumn.Nickname]: 'Nickname',
  [DeviceSearchSortColumn.SerialNumber]: 'Serial Number',
  [DeviceSearchSortColumn.Status]: 'Status',
  [DeviceSearchSortColumn.Type]: 'Type',
};

export const deviceStates = {
  NORMAL: 'Normal',
  NOT_NORMAL: 'Not Normal',
  ERROR: 'Error',
  UNPROVISIONED: 'Not Provisioned',
  MAINT: 'Maintenance Lock',
  GYRO_LOCK: 'Orientation Lock',
};

type DeviceFilters = {
  connectivity: ConnectionStatus | 'None';
  state: keyof typeof deviceStates | 'None';
  activeFault: keyof typeof activeFaults | 'None';
  type: keyof typeof deviceMap | 'None';
};

const DEBOUNCE_WAIT = 500;

export default function DevicesListFilters({
  filterHandler,
  selectedLocationId,
}: DeviceListFiltersProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const theme = useTheme();

  const [searchQuery, setSearchQuery] = useState(searchParams.get('searchQuery') ?? '');
  const [filter, setFilters] = useState<DeviceFilters>({
    connectivity: (searchParams.get('connectivity') ?? 'None') as DeviceFilters['connectivity'],
    type: (searchParams.get('type') ?? 'None') as DeviceFilters['type'],
    state: (searchParams.get('state') ?? 'None') as DeviceFilters['state'],
    activeFault: (searchParams.get('activeFault') ?? 'None') as DeviceFilters['activeFault'],
  });
  const [sort, setSort] = useState<DeviceSearchSortInput>({
    column: (searchParams.get('sortKey') ??
      DeviceSearchSortColumn.ConnectivityDate) as DeviceSearchSortColumn,
    order: (searchParams.get('sortOrder') ?? OrderEnum.Desc) as OrderEnum,
  });

  const refetchDevices = useCallback(
    (f: typeof filter, s: typeof sort, search: string, locationId?: string | null) => {
      setSearchParams(
        (prev) => {
          let params: Record<string, string> = {};
          if (locationId) {
            params.location = locationId;
          }
          params = Object.entries({
            ...Object.fromEntries(prev),
            ...f,
          })
            .filter(([_, v]) => v !== 'None')
            .reduce((acc, curr) => {
              acc[curr[0]] = curr[1];
              return acc;
            }, params);
          params.sortKey = s.column as string;
          params.sortOrder = s.order as string;
          if (search) {
            params.searchQuery = search;
          }
          return params;
        },
        { replace: true }
      );

      filterHandler({
        deviceSearchOptions: {
          searchQuery: search === '' ? void 0 : search,
          connectivity: f.connectivity === 'None' ? void 0 : f.connectivity,
          state: f.state === 'None' ? void 0 : f.state,
          types: f.type === 'None' ? void 0 : [f.type],
          activeFaults: f.activeFault === 'None' ? void 0 : [f.activeFault],
        },
        sort: [s],
      });
    },
    // eslint-disable-next-line
    [filterHandler]
  );

  // eslint-disable-next-line
  const debouncedRefetchDevices = useMemo(() => debounce(refetchDevices, DEBOUNCE_WAIT), []);

  useEffect(() => {
    const sortKey = searchParams.get('sortKey');
    const sortOrder = searchParams.get('sortOrder');
    setSort((prev) => {
      const _prev = { ...prev };
      if (sortKey) {
        _prev.column = sortKey as typeof _prev.column;
      }
      if (sortOrder) {
        _prev.order = sortOrder as typeof _prev.order;
      }
      return _prev;
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    refetchDevices(filter, sort, searchQuery, selectedLocationId);

    // eslint-disable-next-line
  }, [filter, sort]);

  useEffect(() => {
    debouncedRefetchDevices(filter, sort, searchQuery, selectedLocationId);
    // eslint-disable-next-line
  }, [searchQuery]);

  const handleSelectFilterChange = useCallback((event: SelectChangeEvent) => {
    const keyMap = {
      'device-type': 'type',
      'fault-type': 'activeFault',
    };
    const elemName = event.target.name;
    const elemVal = event.target.value;
    setFilters((prev) => {
      const _prev = { ...prev };
      _prev[keyMap[elemName] ?? elemName] = elemVal;
      return _prev;
    });
  }, []);
  return (
    <Paper sx={{ padding: 3 }}>
      <Grid
        container
        spacing={2}
        sx={{
          width: '100%',
        }}
      >
        <Grid item xs={12} md={6} lg={3}>
          <TextField
            fullWidth
            label='Serial Number'
            name='searchQuery'
            value={searchQuery}
            placeholder='Search By Serial Number'
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <FormControl fullWidth>
            <InputLabel id='connectivity'>Connectivity</InputLabel>
            <Select
              labelId='connectivity'
              id='connectivity-selector'
              label='Connectivity'
              name='connectivity'
              value={filter.connectivity}
              onChange={handleSelectFilterChange}
            >
              <MenuItem value='None'>Any</MenuItem>
              <MenuItem value='Connected'>Connected</MenuItem>
              <MenuItem value='Disconnected'>Disconnected</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <FormControl fullWidth>
            <InputLabel id='state'>State</InputLabel>
            <Select
              labelId='state'
              id='state-selector'
              label='State'
              name='state'
              value={filter.state}
              onChange={handleSelectFilterChange}
            >
              <ListSubheader>Generic Device States</ListSubheader>
              <MenuItem value='None'>Any</MenuItem>
              <MenuItem value='NORMAL'>{deviceStates.NORMAL}</MenuItem>
              <MenuItem value='NOT_NORMAL'>{deviceStates.NOT_NORMAL}</MenuItem>
              <MenuItem value='ERROR'>{deviceStates.ERROR}</MenuItem>
              <MenuItem value='UNPROVISIONED'>{deviceStates.UNPROVISIONED}</MenuItem>
              <ListSubheader>UV Angel Clean Air™ States</ListSubheader>
              <MenuItem value='MAINT'>{deviceStates.MAINT}</MenuItem>
              <ListSubheader>UV Angel Adapt™ States</ListSubheader>
              <MenuItem value='GYRO_LOCK'>{deviceStates.GYRO_LOCK}</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <FormControl fullWidth>
            <InputLabel id='device-type'>Device Type</InputLabel>
            <Select
              labelId='device-type'
              id='device-type-selector'
              label='device-type'
              name='device-type'
              value={filter.type}
              onChange={handleSelectFilterChange}
            >
              <MenuItem value='None'>Any</MenuItem>
              {Object.entries(deviceMap).map(([key, val]) => (
                <MenuItem key={key} value={key}>
                  <Box display='flex' alignItems='center' gap={1}>
                    <ListItemIcon sx={{ minWidth: 20 }}>
                      <img
                        src={val.icon[theme.palette.mode]}
                        width='20'
                        height='20'
                        alt={val.value}
                        style={{
                          objectFit: 'contain',
                        }}
                      />
                    </ListItemIcon>
                    {val.value}
                  </Box>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <FormControl fullWidth>
            <InputLabel id='fault-type'>Active Fault</InputLabel>
            <Select
              labelId='fault-type'
              id='fault-type-selector'
              label='fault-type'
              name='fault-type'
              value={filter.activeFault}
              onChange={handleSelectFilterChange}
            >
              <MenuItem value='None'>Any</MenuItem>
              <MenuItem value='lamp_failure'>{activeFaults.lamp_failure}</MenuItem>
              <MenuItem value='control_board_switch'>{activeFaults.control_board_switch}</MenuItem>
              <MenuItem value='fan_current'>{activeFaults.fan_current}</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <FormControl fullWidth>
            <InputLabel id='sort-by'>Sort By</InputLabel>
            <Select
              labelId='sort-by'
              id='sort-by-selector'
              label='sort-by'
              name='sort-by'
              value={sort.column}
              onChange={(e) =>
                setSort((prev) => ({ ...prev, column: e.target.value as DeviceSearchSortColumn }))
              }
              startAdornment={
                <Tooltip
                  title={`Make ${sort.order === OrderEnum.Desc ? 'Ascending' : 'Descending'}`}
                >
                  <IconButton
                    onClick={() =>
                      setSort((prev) => ({
                        ...prev,
                        order: prev.order === OrderEnum.Asc ? OrderEnum.Desc : OrderEnum.Asc,
                      }))
                    }
                  >
                    <SortIcon
                      sx={{
                        transform:
                          sort.order === OrderEnum.Desc
                            ? 'rotate(0deg) scaleX(1)'
                            : 'rotate(180deg) scaleX(-1)',
                        transition: 'transform',
                        height: 20,
                        width: 20,
                      }}
                    />
                  </IconButton>
                </Tooltip>
              }
            >
              {Object.entries(deviceSearchSortColumns).map(([key, val]) => (
                <MenuItem key={key} value={key}>
                  {val}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </Paper>
  );
}
