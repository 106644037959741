import { ReactNode, Fragment } from 'react';
import { Box, Divider, Typography } from '@mui/material';

type Props = {
  title: ReactNode;
  description?: ReactNode;
  children: ReactNode;
};

// this HOC is used provide consistent css framing of org settings panels
export default function SettingsPanelFrame({ children, description, title }: Props) {
  return (
    <Box
      display='flex'
      flexDirection='column'
      alignItems='center'
      gap={2}
      width='100%'
      paddingTop={2}
      paddingBottom={2}
      paddingLeft={1}
      paddingRight={1}
    >
      <Box display='flex' flexDirection='column' gap={2} width='100%' maxWidth={1500}>
        {typeof title === 'string' ? (
          <Typography color='inherit' variant='h4'>
            {title}
          </Typography>
        ) : (
          title
        )}

        <Divider />
        {description && (
          <Fragment>
            {typeof description === 'string' ? (
              <Typography variant='h6'>{description}</Typography>
            ) : (
              description
            )}
          </Fragment>
        )}
        {children}
      </Box>
    </Box>
  );
}
