import React, { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { useReactiveVar } from '@apollo/client';
import {
  Box,
  Button,
  Grid,
  Divider,
  FormGroup,
  FormControl,
  FormLabel,
  CircularProgress,
  TextField,
  Typography,
  Tooltip,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import OrgDisplayAndSwitcher from 'Components/SharedUI/OrgDisplayAndSwitcher';
import { useToast, ToastNotificationSeverityTypeEnum } from 'Providers/ToastProvider';
import { currentUserDataVar } from 'Apollo/ApolloCache';
import PageContainerFrame from 'Components/HOC/PageContainerFrame';

const UPDATE_USER_PROFILE_MUTATION = gql`
  mutation UpdateUser($userId: ID!, $firstName: String, $lastName: String) {
    updateUser(userId: $userId, firstName: $firstName, lastName: $lastName) {
      id
      firstName
      lastName
    }
  }
`;

export default function UserSettingsPage() {
  const userData = useReactiveVar(currentUserDataVar);
  const { firstName, lastName, id, email } = userData?.currentUser ?? {};
  const [isEdited, setIsEdited] = useState<boolean>(false);
  const navigate = useNavigate();
  const handleNavigateBack = () => navigate(-1);

  const { register, handleSubmit } = useForm();
  const { dispatchToast } = useToast();
  const [updateUserProfile, { loading: isMutationInFlight }] = useMutation(
    UPDATE_USER_PROFILE_MUTATION
  );

  const onSubmit = async (data) => {
    const { firstName, lastName } = data ?? {};
    try {
      const variables = {
        userId: id,
        firstName,
        lastName,
      };
      await updateUserProfile({
        variables,
      });
      setIsEdited(false);
      const notifConfig = {
        severity: ToastNotificationSeverityTypeEnum.INFO,
        title: 'User Profile Updated',
        message: 'You have successfully updated your user profile',
      };
      dispatchToast(notifConfig);
    } catch (error) {
      const notifConfig = {
        severity: ToastNotificationSeverityTypeEnum.ERROR,
        title: 'Error Updating User Profile',
        message: 'Please try again',
      };
      dispatchToast(notifConfig);
      console.error('ERROR UPDATE_USER_PROFILE_MUTATION: ', error);
    }
  };

  return (
    <PageContainerFrame pageTitles={['User Settings']}>
      <Box>
        <Grid item>
          <Box display='flex' flexDirection='column' gap={2}>
            <Typography color='inherit' variant='h4'>
              User Settings
            </Typography>
            <Divider />
            <Typography variant='h6'>Manage personal information and settings</Typography>
            <Box>
              <OrgDisplayAndSwitcher withDefaultOrg />
            </Box>
          </Box>
        </Grid>
        <Grid item container>
          <FormControl>
            <Box display='flex' flexDirection='column' gap={2} width={425} marginBottom={4}>
              <Box display='flex' flexDirection='column'>
                <FormLabel htmlFor='profileSettingFirstNameInput'>First Name:</FormLabel>
                <TextField
                  {...register('firstName')}
                  onChange={() => setIsEdited(true)}
                  defaultValue={firstName ?? ''}
                  id='profileSettingFirstNameInput'
                  variant='outlined'
                  size='small'
                />
              </Box>
              <Box display='flex' flexDirection='column'>
                <FormLabel htmlFor='profileSettingLastNameInput'>Last Name:</FormLabel>
                <TextField
                  {...register('lastName')}
                  onChange={() => setIsEdited(true)}
                  defaultValue={lastName ?? ''}
                  id='profileSettingLastNameInput'
                  variant='outlined'
                  size='small'
                />
              </Box>
              <Box display='flex' flexDirection='column'>
                <FormLabel htmlFor='profileSettingEmailInput'>Email:</FormLabel>
                <Tooltip title='Please contact your administrator to update this email'>
                  <TextField
                    disabled
                    defaultValue={email ?? ''}
                    id='profileSettingEmailInput'
                    size='small'
                    sx={{ background: '#E0E0E0' }}
                  />
                </Tooltip>
              </Box>
            </Box>
            <FormGroup row>
              <Button
                disabled={isMutationInFlight}
                color='secondary'
                sx={{
                  height: 50,
                  width: 200,
                  marginRight: 2,
                }}
                onClick={handleNavigateBack}
                variant='contained'
              >
                Go Back
              </Button>
              <Button
                type='submit'
                disabled={!isEdited || isMutationInFlight}
                color='primary'
                sx={{ height: 50, width: 200 }}
                onClick={handleSubmit(onSubmit)}
                variant='contained'
                startIcon={isMutationInFlight ? <CircularProgress size={20} /> : null}
              >
                Update
              </Button>
            </FormGroup>
          </FormControl>
        </Grid>
      </Box>
    </PageContainerFrame>
  );
}
