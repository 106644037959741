import { Card, CardHeader, Avatar } from '@mui/material';
import { CalendarTodayOutlined as CalendarIcon } from '@mui/icons-material';
import moment from 'moment';
interface DeviceReplacementKitCardProps {
  replacement?: string;
}

export default function DeviceReplacementKitCard({ replacement }: DeviceReplacementKitCardProps) {
  return (
    <Card sx={{ height: '100%' }}>
      <CardHeader
        avatar={
          <Avatar aria-label='device-kit-repl'>
            <CalendarIcon color='inherit' sx={{ height: 28, width: 28 }} />
          </Avatar>
        }
        title='Estimated Replacement Kit Due Date'
        titleTypographyProps={{ variant: 'body2', fontWeight: 500 }}
        subheader={moment(replacement).format('MMM DD, YYYY')}
      />
    </Card>
  );
}
