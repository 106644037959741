import React, { useState, useEffect, createContext, useContext } from 'react';
import { Alert, AlertTitle, Snackbar } from '@mui/material';
import { useReactiveVar } from '@apollo/client';
import {
  toastNotificationQueueVar,
  removeToastFromQueue,
  addToastToQueue,
} from 'Apollo/ApolloCache';

export enum ToastNotificationSeverityTypeEnum {
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info',
  SUCCESS = 'success',
}
export type ToastNotificationConfigType = {
  timeout?: number;
  severity: ToastNotificationSeverityTypeEnum;
  variant?: 'filled' | 'outlined' | 'standard';
  title: string;
  message?: string;
};

type ToastNotificationContextType = {
  dispatchToast: (arg: ToastNotificationConfigType) => void;
};

const ToastNotificationContext = createContext<ToastNotificationContextType>({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  dispatchToast: () => {},
});

export const useToast = () => {
  return useContext(ToastNotificationContext);
};

type NotificationRendererPropsType = {
  currentToast: ToastNotificationConfigType;
  displayNextToast: () => void;
};

function ToastNotificationRenderer({
  currentToast,
  displayNextToast,
}: NotificationRendererPropsType) {
  const { title, message, severity, variant } = currentToast;
  // For toast notifications we render on the bottom right
  // we can customize this more if needed.
  const vertical = 'bottom';
  const horizontal = 'center';
  const [isOpen, setIsOpen] = useState<boolean>(true);

  const handleCloseToast = () => {
    setIsOpen(false);
    displayNextToast();
  };

  return (
    <Snackbar anchorOrigin={{ vertical, horizontal }} open={isOpen} onClose={handleCloseToast}>
      <Alert
        onClose={handleCloseToast}
        variant={variant}
        severity={severity}
        sx={{ width: '100%' }}
      >
        <AlertTitle>{title}</AlertTitle>
        {message}
      </Alert>
    </Snackbar>
  );
}

type Props = { children: JSX.Element | JSX.Element[] };

export default function ToastProvider({ children }: Props) {
  const toastQueue = useReactiveVar(toastNotificationQueueVar);
  const displayNextToast = () => {
    removeToastFromQueue();
  };
  const dispatchToast = (config: ToastNotificationConfigType) => {
    addToastToQueue(config);
  };
  useEffect(() => {
    if (toastQueue.length === 0) {
      return;
    }
    const fiveSeconds = 5000;
    const timeout = toastQueue[0].timeout ?? fiveSeconds;
    const timer = setTimeout(() => {
      displayNextToast();
    }, timeout);

    return () => {
      clearTimeout(timer);
    };
  }, [toastQueue]);

  const currentToast = toastQueue[0];

  return (
    <ToastNotificationContext.Provider value={{ dispatchToast }}>
      {children}{' '}
      {toastQueue.length > 0 && (
        <ToastNotificationRenderer
          currentToast={currentToast}
          displayNextToast={displayNextToast}
        />
      )}
    </ToastNotificationContext.Provider>
  );
}
