import React from 'react';
import { Box, Typography, LinearProgress, Link, Stack, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import RoutePaths from 'Constants/RoutePaths';

import TransferWithinAStationIcon from '@mui/icons-material/TransferWithinAStation';
import GroupAddIcon from '@mui/icons-material/GroupAdd';

const { PATH_ORGANIZATION_SUBSCRIPTION_PRICING } = RoutePaths;

type Props = {
  userHasOrgWritePermission: boolean;
  isRedirecting: boolean;
  isStripeCustomer: boolean;
  handleGetPortalSession: () => void;
};

export default function NoActiveSubscriptions({
  userHasOrgWritePermission,
  isRedirecting,
  isStripeCustomer,
  handleGetPortalSession,
}: Props) {
  const theme = useTheme();
  const navigate = useNavigate();

  let content;

  if (!userHasOrgWritePermission) {
    content = (
      <>
        <GroupAddIcon
          sx={{
            color: theme.palette.primary.main,
            width: 100,
            height: 100,
          }}
        />
        <Typography fontSize={20} fontWeight='bold'>
          This organization has no active subscriptions
        </Typography>
      </>
    );
  } else if (isRedirecting) {
    content = (
      <Box
        display='flex'
        flexDirection='column'
        justifyContent='center'
        alignItems='center'
        gap={1}
        padding={2}
        height='100%'
      >
        <TransferWithinAStationIcon sx={{ fontSize: 100 }} />
        <LinearProgress sx={{ width: '100%' }} />
        <Typography>Please wait</Typography>
        <Typography>Redirecting to Subscription Page</Typography>
      </Box>
    );
  } else {
    content = (
      <>
        <GroupAddIcon
          sx={{
            color: theme.palette.primary.main,
            width: 100,
            height: 100,
          }}
        />
        <Typography fontSize={20} fontWeight='bold'>
          This organization has no active subscriptions
        </Typography>
        <Link
          fontSize={20}
          color={theme.palette.primary.main}
          underline='hover'
          onClick={() => {
            if (isStripeCustomer) {
              handleGetPortalSession();
            } else {
              navigate(PATH_ORGANIZATION_SUBSCRIPTION_PRICING);
            }
          }}
        >
          Click here to subscribe
        </Link>
      </>
    );
  }

  return (
    <Stack
      direction='column'
      justifyContent='center'
      alignItems='center'
      width='100%'
      height='100%'
      gap={1}
    >
      {content}
    </Stack>
  );
}
