import { useTheme, Card, CardHeader, Avatar, CardContent, Typography } from '@mui/material';
import { Wifi as WifiIcon, ErrorOutline as ErrorIcon } from '@mui/icons-material';
import { alpha } from '@mui/material/styles';
import { DeviceQuery } from '__generated__/graphql';
import moment from 'moment';

interface DeviceConnectivityCardProps {
  deviceConnectivity?: NonNullable<DeviceQuery['device']>['connectivity'];
}

export default function DeviceConnectivityCard({
  deviceConnectivity,
}: DeviceConnectivityCardProps) {
  const theme = useTheme();
  const catalog = {
    '1': {
      ariaLabel: 'connected',
      Icon: WifiIcon,
      color: theme.palette.success.dark,
    },
    '0': {
      ariaLabel: 'disconnected',
      Icon: ErrorIcon,
      color: theme.palette.error.dark,
      bgColor: theme.palette.error.light,
    },
  };
  const state = catalog[deviceConnectivity?.connected ? '1' : '0'];

  return (
    <Card
      sx={{
        height: '100%',
        borderLeft: `.5rem solid ${state.color}`,
        // eslint-disable-next-line no-magic-numbers
        bgcolor: alpha(state.color, 0.2),
      }}
    >
      <CardHeader
        avatar={
          <Avatar sx={{ bgcolor: state.color }} aria-label={state.ariaLabel}>
            <state.Icon color='inherit' sx={{ height: 28, width: 28 }} />
          </Avatar>
        }
        title='Connectivity'
        titleTypographyProps={{ variant: 'body2', fontWeight: 500 }}
        subheader={`Since ${moment(deviceConnectivity?.timestamp).fromNow()}`}
      />
      <CardContent>
        <Typography variant='h5' color={state.color} fontWeight={500}>
          {deviceConnectivity?.connected ? 'Connected' : 'Disconnected'}
        </Typography>
      </CardContent>
    </Card>
  );
}
