import React from 'react';
import { CircularProgress, Stack, useTheme, useMediaQuery } from '@mui/material';
import { useQuery, gql } from '@apollo/client';
import { useErrorBoundary } from 'react-error-boundary';

import PageContainerFrame from 'Components/HOC/PageContainerFrame';
import SettingsPanelFrame from 'Components/HOC/SettingsPanelFrame';
import LocationSelectorPanel from 'Components/SharedUI/LocationSelector/LocationSelectorPanel';
import LocationDetaillsCard from './LocationDetaillsCard';
import useLinkedLocationList from 'Hooks/useLinkedLocationList';

import type { SelectedOrgType } from 'Apollo/ApolloCache';
import {
  GetLocationEditorDataQuery,
  GetLocationEditorDataQueryVariables,
} from '__generated__/graphql';

export const GET_LOCATION_EDITOR_DATA = gql`
  query getLocationEditorData($accountId: ID!, $locationId: ID!) {
    location(accountId: $accountId, locationId: $locationId) {
      archetype
      id
      name
      type
      description
      tags
      parentLocation {
        id
      }
      floorPlanImageUrl
      numDevices
      numImmediateSubLocations
      metadata {
        airVolumeFt3
        assumedAch
        desiredQuantaThresholdCm3
      }
    }
  }
`;

type Props = {
  selectedOrg: SelectedOrgType;
};

export default function OrgLocationEditorPage({ selectedOrg }: Props) {
  const selectedOrgID = selectedOrg?.id ?? '';
  const rootLocation = selectedOrg?.rootLocation;

  const rootLocationName = selectedOrg?.rootLocation?.name ?? '';
  const { linkedLocationList, addLocation, removeLocation, selectedLocation } =
    useLinkedLocationList(rootLocation ?? { name: '', id: '', type: '' });
  const onUpdateLinkedLocationList = (id: string, name: string, parentLocationIndex: number) => {
    addLocation(id, name, parentLocationIndex);
  };
  const onRemoveLocation = (id: string) => {
    removeLocation(id);
  };

  const selectedLocationID = selectedLocation?.id ?? '';

  const {
    data,
    error: queryError,
    loading: isLoading,
    refetch: reloadLocation,
  } = useQuery<GetLocationEditorDataQuery, GetLocationEditorDataQueryVariables>(
    GET_LOCATION_EDITOR_DATA,
    {
      variables: { accountId: selectedOrgID, locationId: selectedLocationID },
    }
  );
  const { showBoundary: showErrorBoundary } = useErrorBoundary();
  const theme = useTheme();
  if (queryError) {
    showErrorBoundary(queryError);
  }

  const isMediumScreenOrBelow = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <PageContainerFrame pageTitles={['Location Editor', selectedOrg?.name]}>
      <SettingsPanelFrame
        title='Location Editor'
        description='Add, edit, and remove locations within your organization'
      >
        <Stack
          alignItems={isMediumScreenOrBelow ? 'center' : ''}
          direction={isMediumScreenOrBelow ? 'column' : 'row'}
          gap={2}
        >
          {rootLocation && selectedOrgID ? (
            <LocationSelectorPanel
              linkedLocationList={linkedLocationList}
              selectedOrgID={selectedOrgID}
              onUpdateLinkedLocationList={addLocation}
              rootLocationName={rootLocationName}
            />
          ) : null}
          {isLoading ? (
            <CircularProgress size={75} />
          ) : (
            <LocationDetaillsCard
              locationData={data?.location}
              selectedOrgID={selectedOrgID}
              onRemoveLocation={onRemoveLocation}
              onUpdateLinkedLocationList={onUpdateLinkedLocationList}
              onUpdateFloorPlan={() => reloadLocation()}
              linkedLocationList={linkedLocationList}
            />
          )}
        </Stack>
      </SettingsPanelFrame>
    </PageContainerFrame>
  );
}
