import React from 'react';
import { Stack } from '@mui/material';
import LocationSelectorDropdown from './LocationSelectorDropdown';

import type { LinkedLocationListType } from 'Hooks/useLinkedLocationList';

type Props = {
  selectedOrgID: string;
  linkedLocationList: LinkedLocationListType;
  onUpdateLinkedLocationList: (id: string, name: string, parentLocationIndex: number) => void;
  removeLocation?: (id: string) => void;
  rootLocationName: string;
};

export default function LocationSelectorPanel({
  selectedOrgID,
  onUpdateLinkedLocationList,
  linkedLocationList,
  rootLocationName,
}: Props) {
  const handleSelectLocation = (id: string, name: string, parentLocationIndex: number) => {
    onUpdateLinkedLocationList(id, name, parentLocationIndex);
  };

  const selectorComponentsList = linkedLocationList.map((location, index) => (
    <LocationSelectorDropdown
      key={`${location?.id} + ${index}`}
      locationID={location.id ?? ''}
      onSelectLocation={handleSelectLocation}
      rootLocationName={rootLocationName}
      selectedOrgID={selectedOrgID}
      parentLocationIndex={index}
      getSelectedOption={() => {
        if (index < linkedLocationList.length - 1) {
          return linkedLocationList[index + 1].id;
        } else {
          return location.id;
        }
      }}
    />
  ));
  return (
    <Stack spacing={2} width={300}>
      {selectorComponentsList}
    </Stack>
  );
}
