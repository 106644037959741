import React from 'react';
import {
  Box,
  Button,
  Divider,
  FormGroup,
  FormControl,
  FormLabel,
  CircularProgress,
  TextField,
  Typography,
} from '@mui/material';
import { gql, useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import { useToast, ToastNotificationSeverityTypeEnum } from 'Providers/ToastProvider';
import RoutePaths from 'Constants/RoutePaths';
import { OrgIntegrationsPanelViewEnum } from 'Constants/OrgSettingsEnums';
import type { AccountIntegrationType } from '../OrgIntegrationsPage';

const { PATH_ORGANIZATION_INTEGRATIONS } = RoutePaths;

const UPDATE_ACCOUNT_INTEGRATION_MUTATION = gql`
  mutation UpdateAccountIntegration(
    $accountIntegrationId: ID!
    $params: UpdateAccountIntegrationParams!
  ) {
    updateAccountIntegration(accountIntegrationId: $accountIntegrationId, params: $params) {
      id
      account {
        accountIntegrations {
          id
          description
        }
      }
    }
  }
`;

type Props = {
  setTargetIntegration: (arg: AccountIntegrationType) => void;
  targetIntegration: AccountIntegrationType;
};

export default function UpdateIntegrationView({ targetIntegration }: Props) {
  const navigate = useNavigate();
  const { register, handleSubmit, formState } = useForm();
  const { dispatchToast } = useToast();
  const [updateAccountIntegration, { loading: isMutationInFlight, error: updateAccountError }] =
    useMutation(UPDATE_ACCOUNT_INTEGRATION_MUTATION);

  if (updateAccountError) {
    console.error('UPDATE_ACCOUNT_INTEGRATION_MUTATION ERROR: ', updateAccountError);
  }
  const { id: targetID, description } = targetIntegration ?? {};
  const onSubmit = async (data) => {
    try {
      const variables = {
        accountIntegrationId: targetID,
        params: {
          description: data.description,
        },
      };
      await updateAccountIntegration({
        variables,
      });
      const notifConfig = {
        severity: ToastNotificationSeverityTypeEnum.INFO,
        title: 'Integration Updated',
        message: 'You\'ve successfully updated an integration for your organization',
      };
      dispatchToast(notifConfig);
      navigate(PATH_ORGANIZATION_INTEGRATIONS, {
        state: { panelView: OrgIntegrationsPanelViewEnum.INTEGRATIONS_LIST_VIEW },
      });
    } catch (error) {
      const notifConfig = {
        severity: ToastNotificationSeverityTypeEnum.ERROR,
        title: 'Error Updating Integration',
        message: 'Error updating integration. Please try again',
      };
      dispatchToast(notifConfig);
      console.error('ERROR: UPDATE_ACCOUNT_INTEGRATION_MUTATION - ', error);
    }
  };

  return (
    <Box width={600}>
      <Divider />
      <Box display='flex' flexDirection='column' marginTop={2} gap={2}>
        <Typography variant='h6'>
          The following fields can be updated on your selected integration
        </Typography>
        <FormControl>
          <Box display='flex' flexDirection='column' gap={0.5} width={425} marginBottom={2}>
            <FormLabel htmlFor='descriptionInput'>Description</FormLabel>
            <TextField
              {...register('description', { required: true })}
              defaultValue={description ?? ''}
              id='descriptionInput'
              variant='outlined'
              size='small'
            />
          </Box>
          <FormGroup row>
            {updateAccountError && <p>Error updating account</p>}
            <Button
              disabled={isMutationInFlight}
              color='secondary'
              sx={{
                height: 50,
                width: 200,
                marginRight: 2,
              }}
              onClick={() =>
                navigate(PATH_ORGANIZATION_INTEGRATIONS, {
                  state: { panelView: OrgIntegrationsPanelViewEnum.INTEGRATIONS_LIST_VIEW },
                })
              }
              variant='contained'
            >
              Go Back
            </Button>
            <Button
              type='submit'
              disabled={!formState.isValid || isMutationInFlight}
              color='primary'
              sx={{ height: 50, width: 200 }}
              onClick={handleSubmit(onSubmit)}
              variant='contained'
              startIcon={isMutationInFlight ? <CircularProgress size={20} /> : null}
            >
              Update
            </Button>
          </FormGroup>
        </FormControl>
      </Box>
    </Box>
  );
}
