/* eslint-disable no-magic-numbers */
import React, { useState } from 'react';
import { Box, Button, Typography, useTheme } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { gql } from '__generated__/gql';
import { useQuery } from '@apollo/client';
import { useErrorBoundary } from 'react-error-boundary';

import PageContainerFrame from 'Components/HOC/PageContainerFrame';
import SettingsPanelFrame from 'Components/HOC/SettingsPanelFrame';
import IntegrationsListView from './PageViews/IntegrationsListView';
import AddIntegrationsView from './PageViews/AddIntegrationsView';
import UpdateIntegrationView from './PageViews/UpdateIntegrationView';
import OrgDisplayAndSwitcher from 'Components/SharedUI/OrgDisplayAndSwitcher';
import { OrgIntegrationsPanelViewEnum } from 'Constants/OrgSettingsEnums';
import RoutePaths from 'Constants/RoutePaths';

import type { SelectedOrgType } from 'Apollo/ApolloCache';
import type { GetSelectedOrgIntegrationsDataQuery as IntegrationsDataType } from '__generated__/graphql';

export type AccountIntegrationType = NonNullable<
  NonNullable<IntegrationsDataType['account']>['accountIntegrations']
>[number];

const { PATH_ORGANIZATION_INTEGRATIONS } = RoutePaths;

export const SELECTED_ORG_INTEGRATIONS_DATA_QUERY = gql(`
  query getSelectedOrgIntegrationsData($accountId: ID!) {
    account(accountId: $accountId) {
      id
      accountIntegrations {
        id
        description
        expirationTimestamp
        provider
      }
    }
  }
`);

const { INTEGRATIONS_LIST_VIEW, ADD_INTEGRATIONS_VIEW, UPDATE_INTEGRATIONS_VIEW } =
  OrgIntegrationsPanelViewEnum;

type PanelViewRendererProps = {
  panelView: OrgIntegrationsPanelViewEnum;
  selectedOrg: SelectedOrgType | null;
  data: IntegrationsDataType | undefined;
  setTargetIntegration: (arg: AccountIntegrationType) => void;
  targetIntegration: AccountIntegrationType;
  userHasOrgWritePermission: boolean;
};

const CurrentPanelViewRenderer = ({
  panelView,
  selectedOrg,
  data,
  targetIntegration,
  setTargetIntegration,
  userHasOrgWritePermission,
}: PanelViewRendererProps) => {
  switch (panelView) {
    case INTEGRATIONS_LIST_VIEW:
      return (
        <IntegrationsListView
          data={data}
          selectedOrg={selectedOrg}
          panelView={panelView}
          setTargetIntegration={setTargetIntegration}
          userHasOrgWritePermission={userHasOrgWritePermission}
        />
      );
    case ADD_INTEGRATIONS_VIEW:
      return <AddIntegrationsView data={data} selectedOrg={selectedOrg} />;
    case UPDATE_INTEGRATIONS_VIEW:
      return (
        <UpdateIntegrationView
          targetIntegration={targetIntegration}
          setTargetIntegration={setTargetIntegration}
        />
      );
    default:
      return (
        <IntegrationsListView
          data={data}
          selectedOrg={selectedOrg}
          panelView={panelView}
          setTargetIntegration={setTargetIntegration}
          userHasOrgWritePermission={userHasOrgWritePermission}
        />
      );
  }
};

type Props = {
  selectedOrg: SelectedOrgType;
};

export default function OrgIntegrationsPage({ selectedOrg }: Props) {
  const userHasOrgWritePermission =
    selectedOrg?.userAccountScope?.roles?.account_write == null
      ? false
      : selectedOrg?.userAccountScope?.roles?.account_write;
  const location = useLocation();
  const { panelView = INTEGRATIONS_LIST_VIEW }: { panelView: OrgIntegrationsPanelViewEnum } =
    location.state || {};
  const theme = useTheme();
  const navigate = useNavigate();
  const { data, error: queryError } = useQuery(SELECTED_ORG_INTEGRATIONS_DATA_QUERY, {
    variables: { accountId: selectedOrg?.id ?? '' },
  });
  const { showBoundary: showErrorBoundary } = useErrorBoundary();
  if (queryError) {
    showErrorBoundary(queryError);
  }
  const [targetIntegration, setTargetIntegration] = useState<AccountIntegrationType>(null);
  return (
    <PageContainerFrame pageTitles={['Integrations', selectedOrg?.name]}>
      <SettingsPanelFrame title='Integrations'>
        <Box display='flex' flexDirection='row' justifyContent='space-between'>
          <Typography variant='h6'>
            {userHasOrgWritePermission
              ? ' Manage and submit API keys granting Continuum access to your organizations data'
              : 'View existing API keys granting Continuum access to your organizations data'}
          </Typography>
          {panelView === INTEGRATIONS_LIST_VIEW && userHasOrgWritePermission && (
            <Button
              sx={{
                color: theme.palette.success.contrastText,
                alignSelf: 'flex-end',
                backgroundColor: theme.palette.success.main,
                width: 200,
              }}
              onClick={() =>
                navigate(PATH_ORGANIZATION_INTEGRATIONS, {
                  state: { panelView: OrgIntegrationsPanelViewEnum.ADD_INTEGRATIONS_VIEW },
                })
              }
              variant='contained'
            >
              Add Integration
            </Button>
          )}
        </Box>
        <Box>
          <OrgDisplayAndSwitcher />
        </Box>
        <CurrentPanelViewRenderer
          data={data}
          panelView={panelView}
          selectedOrg={selectedOrg}
          setTargetIntegration={setTargetIntegration}
          targetIntegration={targetIntegration}
          userHasOrgWritePermission={userHasOrgWritePermission}
        />
      </SettingsPanelFrame>
    </PageContainerFrame>
  );
}
