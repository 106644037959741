import React, { useState } from 'react';
import { gql } from '__generated__/gql';
import { useErrorBoundary } from 'react-error-boundary';
import Fuse from 'fuse.js';
import {
  CircularProgress,
  Stack,
  Checkbox,
  FormGroup,
  FormControlLabel,
  TextField,
  List,
  ListItemButton,
  ListItem,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import { DeviceType } from '__generated__/graphql';
import DeviceTitleCell from 'Components/AuthedPages/DevicesListPage/PageViews/DeviceTitleCell';

type Props = {
  selectedLocationID: string;
  selectedOrgID: string;
  updateSelectedDevices: (arg: Array<string>) => void;
  selectedDevices: Array<string>;
  isDisabled: boolean;
  isReadOnly: boolean;
};

export const GET_ALL_DEVICES_ON_LOCATION_QUERY = gql(`
  query getAllDevicesOnLocationQuery($deviceSearchOptions: DeviceSearchOptions) {
    deviceSearch(deviceSearchOptions: $deviceSearchOptions) {
      devices {
        nickname
        serialNumber
        fullLocationPath {
          id
          name
        }
      }
    }
  }
`);

const searchOptionsConfig = {
  // Specifies which fields to search
  keys: ['serialNumber', 'nickname'],
};

const maxSelectionLimit = 5;

type DeviceListItemType = {
  serialNumber: string;
  nickname: string;
  fullLocationPath: {
    id: string;
    name: string;
  }[];
};

export default function LocationDevicesOptionsContainer({
  selectedLocationID,
  selectedOrgID,
  updateSelectedDevices,
  selectedDevices,
  isDisabled,
  isReadOnly,
}: Props) {
  const [unfilteredList, setUnfilteredList] = useState<DeviceListItemType[]>([]);
  const [devicesList, setDevicesList] = useState<DeviceListItemType[]>([]);
  const { showBoundary: showErrorBoundary } = useErrorBoundary();
  const numberOfBoxsChecked = selectedDevices.length;

  const { loading: isLoading, error: queryError } = useQuery(GET_ALL_DEVICES_ON_LOCATION_QUERY, {
    onCompleted: (data) => {
      const devicesList = data?.deviceSearch?.devices as DeviceListItemType[];
      setUnfilteredList(devicesList);
      setDevicesList(devicesList);
    },
    skip: !selectedLocationID,
    variables: {
      deviceSearchOptions: {
        accountId: selectedOrgID,
        locationId: selectedLocationID,
        types: [DeviceType.Air20],
      },
    },
  });

  if (queryError) {
    showErrorBoundary(queryError);
  }

  const handleCheckboxChange = (id) => {
    let updatedSelectedDevices: Array<string>;
    if (selectedDevices.includes(id)) {
      updatedSelectedDevices = selectedDevices.filter((selectedId) => selectedId !== id);
    } else {
      if (numberOfBoxsChecked === maxSelectionLimit) {
        return;
      }
      updatedSelectedDevices = [...selectedDevices, id];
    }
    updateSelectedDevices(updatedSelectedDevices);
  };

  const fuse = new Fuse(unfilteredList, searchOptionsConfig);
  const handleFilterList = (event: React.ChangeEvent<HTMLInputElement>) => {
    const targetString = event.target.value;
    if (targetString === '') {
      // resets to unfiltered list
      setDevicesList(unfilteredList);
    } else {
      const rawResults = fuse.search(targetString);
      const updatedList = rawResults.map((res) => res.item);
      setDevicesList(updatedList);
    }
  };

  const readOnlyDevicesFragment = (
    <List>
      {devicesList?.map((device) => {
        return (
          <ListItemButton key={device.serialNumber} component={RouterLink} to={`/devices/${device?.serialNumber}`}>
            <DeviceTitleCell serialNumber={device.serialNumber} nickname={device.nickname} fullLocation={device.fullLocationPath.map(location => location.name).join('/')} />
          </ListItemButton>
        );
      })}
    </List>
  );

  const fullAccessDevicesFragment = (
    <>
      <TextField
        label='Filter devices by nickname or serial number'
        onChange={handleFilterList}
      />
      <FormGroup>
        {devicesList?.map((device) => {
          const isChecked = selectedDevices?.includes(device.serialNumber ?? '');
          const isCheckboxDisabled =
            (!isChecked && numberOfBoxsChecked === maxSelectionLimit) || isDisabled;
          return (
            <FormControlLabel
              key={device.serialNumber}
              control={
                <Checkbox
                  checked={isChecked}
                  disabled={isCheckboxDisabled}
                  onChange={() => handleCheckboxChange(device.serialNumber)}
                  sx={{ color: '#BDBDBD' }}
                />
              }
              label={
                <ListItem key={device.serialNumber}>
                  <DeviceTitleCell serialNumber={device.serialNumber} nickname={device.nickname} fullLocation={device.fullLocationPath.map(location => location.name).join('/')} />
                </ListItem>
              }
            />
          );
        })}
      </FormGroup>
    </>
  );

  return (
    <Stack padding={2} overflow='auto' maxHeight={300}>
      {isLoading && (
        <CircularProgress size={20} />
      )}
      {!isLoading && isReadOnly && readOnlyDevicesFragment}
      {!isLoading && !isReadOnly && fullAccessDevicesFragment}
    </Stack>
  );
}
