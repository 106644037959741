/* eslint-disable no-magic-numbers */
export function truncateString(inputString: string, maxLength: number): string {
  const ellipsisOffest = 3;
  if (inputString.length <= maxLength) {
    return inputString;
  } else {
    return inputString.substring(0, maxLength - ellipsisOffest) + '...';
  }
}

export function capitalize(inputString: string | null | undefined) {
  if (!inputString) {
    return '';
  }
  if (inputString.length === 1) {
    return inputString.toUpperCase();
  }
  return inputString.charAt(0).toUpperCase() + inputString.slice(1);
}
export function snakeCaseToCamelWithSpaces(input: string) {
  // Replace underscores with spaces
  const stringWithSpaces = input.replace(/_/g, ' ').toLowerCase();

  // Capitalize the first letter of each word
  const camelCaseWithSpaces = stringWithSpaces.replace(/\b\w/g, (match) => match.toUpperCase());

  return camelCaseWithSpaces;
}

export function toKebabCase(input: string | null | undefined): string {
  if (!input) {
    return '';
  }
  return input
    .replace(/[^a-zA-Z0-9\s-]/g, '') // Keep hyphens
    .toLocaleLowerCase()
    .split(' ')
    .filter(Boolean) // Remove empty segments
    .join('-')
    .replace(/-+/g, '-'); // Replace multiple hyphens with single hyphen
}
