import { ArrowDownward as ArrowDownwardIcon, Link } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';
import { ReactNode } from 'react';

const SOURCES = [
  {
    link: 'https://pmc.ncbi.nlm.nih.gov/articles/PMC9319059/',
    text: 'Temperature versus Relative Humidity: Which Is More Important for Indoor Mold Prevention?',
  },
  {
    link: 'https://pubmed.ncbi.nlm.nih.gov/32180231/',
    text: 'Current challenges for shaping the sustainable and mold-free hygienic indoor environment in humid regions.',
  },
  {
    link: 'https://pubmed.ncbi.nlm.nih.gov/36107023/',
    text: 'Phthalate migration and its effects on poly(vinyl chloride)-based footwear: pathways, influence of environmental conditions, and the possibility of human exposure.',
  },
  {
    link: 'https://www.semanticscholar.org/paper/22f6f2e5af05865f2dc6c4fe4eb3b73e4f6f7b3f',
    text: 'A Predictive Model for the Growth Diameter of Mold under Different Temperatures and Relative Humidities in Indoor Environments.',
  },
  {
    link: 'https://www.semanticscholar.org/paper/6909a256a202307d01513cd2f81f9f3274cb0074',
    text: 'The Effect of Air leakage through the Air Cavities of Building Walls on Mold Growth Risks.',
  },
  {
    link: 'https://www.ashrae.org/file library/about/position documents/pd_limiting-indoor-mold-and-dampness-in-buildings-2021.pdf',
    text: 'Limiting Indoor Mold and Dampness in Buildings.',
  },
];

const accordionDetails: {
  key: string;
  title: string;
  description: ReactNode;
}[] = [
  {
    key: 'what-is-mold-risk',
    title: 'What is Mold Risk',
    description:
      'Mold growth in indoor environments is significantly influenced by two primary factors: temperature and relative humidity (RH). This algorithm aims to predict the likelihood of mold growth in a specific room based on these two parameters. The algorithm is built upon the findings from various academic studies that have explored the relationship between mold growth, temperature, and humidity.',
  },
  {
    key: 'sources',
    title: 'Academic Sources',
    description: (
      <List sx={{ listStyle: 'decimal', pl: 2, py: 0 }}>
        {SOURCES.map((src) => (
          <ListItem key={src.link} sx={{ display: 'list-item', py: 0 }} disableGutters>
            <ListItem
              component='a'
              href={src.link}
              target='_blank'
              rel='noopener noreferrer'
              disablePadding
            >
              <ListItemText>
                {src.text} <Link sx={{ position: 'relative', top: 6 }} />
              </ListItemText>
            </ListItem>
          </ListItem>
        ))}
      </List>
    ),
  },
];

export default function MoldSpreadSource() {
  return (
    <Stack width='100%'>
      {accordionDetails.map((det) => (
        <Accordion key={det.key}>
          <AccordionSummary
            expandIcon={<ArrowDownwardIcon fontSize='small' />}
            aria-controls={`${det.key}-content`}
            id={`${det.key}-header`}
          >
            <Typography color='textSecondary'>{det.title}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {typeof det.description === 'string' ? (
              <Typography>{det.description}</Typography>
            ) : (
              det.description
            )}
          </AccordionDetails>
        </Accordion>
      ))}
    </Stack>
  );
}
