/* eslint-disable indent */
/* eslint-disable no-magic-numbers */
import { useCallback, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Box,
  Button,
  Typography,
  CircularProgress,
  FormControlLabel,
  Checkbox,
  Slider,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { gql, useMutation, useQuery, useReactiveVar } from '@apollo/client';
import { useToast, ToastNotificationSeverityTypeEnum } from 'Providers/ToastProvider';
import { selectedLocationVar, selectedOrgVar } from 'Apollo/ApolloCache';
import useCurrentLocationList from 'Hooks/useCurrentLocationList';
import LocationSelectorBreadcrumb from 'Components/LocationSelectorBreadcrumb/LocationSelectorBreadcrumb';
import { DeviceSearchOptions, DeviceType } from '__generated__/graphql';
import { GET_CURRENT_ORG_DEVICES_COUNT } from 'Components/AuthedPages/DevicesListPage/DevicesListPage';

export const BULK_CHANGE_DOWNLIGHT_BRIGHTNESS = gql`
  mutation BulkChangeDownlightBrightness(
    $deviceSearchOptions: DeviceSearchOptions!
    $brightnessLevel: Int!
  ) {
    bulkChangeDownlightBrightness(
      deviceSearchOptions: $deviceSearchOptions
      brightnessLevel: $brightnessLevel
    )
  }
`;
interface Props {
  onModalClose: () => void;
  modalProps?: unknown;
}
const ALLOWED_TYPES = [DeviceType.Air20, DeviceType.Air175];

export default function BulkUpdateLEDDownlightBrightness({ onModalClose, modalProps }: Props) {
  const { searchQuery, connectivity, state, types, activeFaults } =
    modalProps as DeviceSearchOptions;
  const { dispatchToast } = useToast();
  const selectedOrg = useReactiveVar(selectedOrgVar);
  const selectedLocation = useReactiveVar(selectedLocationVar);

  const [brightnessLevel, setBrightnessLevel] = useState(50);

  const [isListLoading, initLocationList] = useCurrentLocationList(selectedLocation?.id);
  const {
    data: devices,
    loading: devicesLoading,
    variables: devicesVars,
    refetch,
  } = useQuery(GET_CURRENT_ORG_DEVICES_COUNT, {
    variables: {
      deviceSearchOptions: {
        accountId: selectedOrg?.id,
        locationId: selectedLocation?.id,
        searchQuery,
        connectivity,
        state,
        types: types
          ? types.filter((type) => ALLOWED_TYPES.indexOf(type as DeviceType) !== -1) ??
            ALLOWED_TYPES
          : ALLOWED_TYPES,
        activeFaults,
      },
    },
    notifyOnNetworkStatusChange: true,
  });
  const [bulkChangeLEDDownlightBrightness, { loading: bulkChangeLoading }] = useMutation(
    BULK_CHANGE_DOWNLIGHT_BRIGHTNESS
  );
  const bulkChangeLEDDownlightBrightnessHandler = useCallback(() => {
    if (devices?.deviceSearch?.totalCount === 0) {
      dispatchToast({
        severity: ToastNotificationSeverityTypeEnum.WARNING,
        title: 'No devices found with current criteria',
        message: 'Cannot apply Bulk Change for LED Downlight Brightness',
      });
      return;
    }
    bulkChangeLEDDownlightBrightness({
      variables: {
        deviceSearchOptions: devicesVars?.deviceSearchOptions,
        brightnessLevel,
      },
    })
      .then((d) => {
        if (d.errors) {
          throw new Error('Bulk Update LED Downlight Brightness Failed');
        }
        dispatchToast({
          severity: ToastNotificationSeverityTypeEnum.SUCCESS,
          title: 'Bulk Update LED Downlight Brightness Successful',
        });
      })
      .catch((e) => {
        dispatchToast({
          severity: ToastNotificationSeverityTypeEnum.ERROR,
          title: 'Failure',
          message: e.message,
        });
      })
      .finally(() => {
        onModalClose();
      });
  }, [
    devices,
    devicesVars,
    brightnessLevel,
    dispatchToast,
    bulkChangeLEDDownlightBrightness,
    onModalClose,
  ]);
  return (
    <Dialog
      open={true}
      onClose={() => {
        onModalClose();
      }}
      fullWidth
      maxWidth='sm'
    >
      <DialogTitle>
        Bulk Change LED Downlight Brightness <br />{' '}
        <Typography variant='body1'>{selectedOrg?.name}</Typography>
      </DialogTitle>
      <IconButton
        onClick={onModalClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Box display='flex' p={1} bgcolor='background.paper' borderRadius={1}>
          {isListLoading ? (
            <CircularProgress color='inherit' sx={{ alignSelf: 'center' }} />
          ) : (
            <LocationSelectorBreadcrumb
              onLocationSelectCallback={(id) =>
                refetch({
                  deviceSearchOptions: { ...devicesVars?.deviceSearchOptions, locationId: id },
                })
              }
              dontUpdateGlobal
              preLoadedLocationList={initLocationList}
            />
          )}
        </Box>
        <FormControlLabel
          control={
            <Checkbox
              defaultChecked
              onChange={(e) =>
                refetch({
                  deviceSearchOptions: {
                    ...devicesVars?.deviceSearchOptions,
                    includeSublocations: e.target.checked,
                  },
                })
              }
            />
          }
          label='Include all devices in sublocations'
        />
        <Box display='flex' alignItems='center' gap={2} mb={5}>
          <Typography variant='body1'>Number of devices to update</Typography>
          {devicesLoading ? (
            <CircularProgress size={16} />
          ) : (
            <Typography variant='body1' fontWeight='bold'>
              {devices?.deviceSearch?.totalCount}
            </Typography>
          )}
        </Box>
        <Box px={4}>
          <Slider
            valueLabelDisplay='on'
            min={0}
            step={1}
            max={100}
            marks={[
              {
                value: 0,
                label: '0%',
              },
              {
                value: 100,
                label: '100%',
              },
            ]}
            valueLabelFormat={valueLabel}
            value={brightnessLevel}
            onChange={(_, val) => setBrightnessLevel(val as number)}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Box width='100%' gap={1} display='flex' justifyContent='flex-end' alignItems='center'>
          <Button
            color='primary'
            sx={{ fontWeight: 500 }}
            onClick={(e) => (bulkChangeLoading ? e.preventDefault() : onModalClose())}
          >
            Cancel
          </Button>
          <Button
            color='error'
            sx={{ fontWeight: 500 }}
            endIcon={bulkChangeLoading && <CircularProgress color='inherit' size={16} />}
            onClick={(e) =>
              bulkChangeLoading ? e.preventDefault() : bulkChangeLEDDownlightBrightnessHandler()
            }
          >
            Save
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}
function valueLabel(value: number) {
  return <Typography variant='subtitle2'>{value}%</Typography>;
}
