import React, { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import {
  Autocomplete,
  Box,
  Button,
  ButtonGroup,
  Grid,
  FormGroup,
  FormControl,
  FormLabel,
  CircularProgress,
  TextField,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import moment from 'moment-timezone';
import { useQuery } from '@apollo/client';
import { useErrorBoundary } from 'react-error-boundary';

import OrgDisplayAndSwitcher from 'Components/SharedUI/OrgDisplayAndSwitcher';
import PageContainerFrame from 'Components/HOC/PageContainerFrame';
import SettingsPanelFrame from 'Components/HOC/SettingsPanelFrame';
import { useToast, ToastNotificationSeverityTypeEnum } from 'Providers/ToastProvider';

import OrgProfilePhotoRendererContainer from 'Components/AuthedPages/OrgDetailsPage/OrgProfilePhotoRendererContainer';
import type { AdditionalSelectedOrgaDataQuery as OrgDetailsDataType } from '__generated__/graphql';
import type { SelectedOrgType } from 'Apollo/ApolloCache';

const timeZonesList = moment.tz.names();

const UPDATE_ACCOUNT_DETAILS_MUTATION = gql`
  mutation UpdateAccount($accountId: ID!, $attributesToUpdate: AccountUpdateInput!) {
    updateAccount(accountId: $accountId, attributesToUpdate: $attributesToUpdate) {
      name
      id
    }
  }
`;

const ADDITIONAL_SELECTED_ORG_DATA_QUERY = gql`
  query additionalSelectedOrgaData($accountId: ID!) {
    account(accountId: $accountId) {
      name
      id
      preferences {
        preferred_timezone
        unit_of_measure
        logo_image_url
      }
    }
  }
`;

type DetailsInputPanelProps = {
  orgDetailsData: OrgDetailsDataType;
  userHasOrgWritePermission: boolean;
  refetchOrgData: () => void;
};

function DetailsInputPanel({
  orgDetailsData,
  userHasOrgWritePermission,
  refetchOrgData,
}: DetailsInputPanelProps) {
  const { name, id, preferences } = orgDetailsData.account ?? {};
  const { unit_of_measure: existingMeasurePref, logo_image_url: logoImageURL } = preferences ?? {};
  const [preferredTimezone, setPreferredTimezone] = useState<string | null>(
    preferences?.preferred_timezone ?? null
  );
  const [measurementUnit, setMeasurementUnit] = useState<'imperial' | 'metric' | null>(
    existingMeasurePref ?? null
  );

  const [isEdited, setIsEdited] = useState<boolean>(false);
  const navigate = useNavigate();
  const handleNavigateBack = () => navigate(-1);

  const { register, handleSubmit } = useForm({
    defaultValues: {
      name,
      preferredTimezone: preferences?.preferred_timezone,
    },
  });
  const { dispatchToast } = useToast();
  const [updateAccountDetails, { loading: isMutationInFlight }] = useMutation(
    UPDATE_ACCOUNT_DETAILS_MUTATION
  );

  const onSubmit = async (data) => {
    const { name, preferredTimezone } = data ?? {};
    try {
      const variables = {
        accountId: id,
        attributesToUpdate: {
          name: name,
          preferences: {
            unit_of_measure: measurementUnit,
            preferred_timezone: preferredTimezone,
          },
        },
      };
      await updateAccountDetails({
        variables,
      });
      // we refetch to ensure that the updated org data is propagated in the App.
      refetchOrgData();
      setIsEdited(false);
      const notifConfig = {
        severity: ToastNotificationSeverityTypeEnum.INFO,
        title: 'Account Details Updated',
        message: 'You\'ve successfully updated details for your organization',
      };
      dispatchToast(notifConfig);
    } catch (error) {
      const notifConfig = {
        severity: ToastNotificationSeverityTypeEnum.ERROR,
        title: 'Error Updating Account Details',
        message: 'Please try again',
      };
      dispatchToast(notifConfig);
      console.error('ERROR UPDATE_ACCOUNT_DETAILS_MUTATION: ', error);
    }
  };
  return (
    <Grid item container>
      <FormControl>
        <Box display='flex' flexDirection='column' gap={2} width={425} marginBottom={4}>
          <Box display='flex' flexDirection='column' gap={1}>
            <FormLabel>Organization Profile Photo:</FormLabel>
            <OrgProfilePhotoRendererContainer
              accountId={id ?? ''}
              imageURL={logoImageURL}
              userHasEditPermission={userHasOrgWritePermission}
              size='medium'
            />
          </Box>
          <Box display='flex' flexDirection='column' gap={1}>
            <FormLabel htmlFor='orgDescNameInput'>Organization Name:</FormLabel>
            <TextField
              {...register('name')}
              onChange={() => setIsEdited(true)}
              disabled={!userHasOrgWritePermission}
              id='orgDescNameInput'
              variant='outlined'
              size='small'
            />
          </Box>
          <Box display='flex' flexDirection='column' gap={1}>
            <FormLabel htmlFor='orgDescTimezoneInput'>Preferred Timezone:</FormLabel>
            <Autocomplete
              disablePortal
              onChange={(e, value) => {
                setPreferredTimezone(value);
                setIsEdited(true);
              }}
              disabled={!userHasOrgWritePermission}
              value={preferredTimezone}
              id='orgDescTimezoneInput'
              options={timeZonesList}
              renderInput={(params) => <TextField {...params} {...register('preferredTimezone')} />}
              size='small'
            />
          </Box>
          <Box display='flex' flexDirection='column' gap={1}>
            <FormLabel htmlFor='descriptionInput'>Unit of Measure:</FormLabel>
            <ButtonGroup disabled={!userHasOrgWritePermission}>
              <Button
                onClick={() => {
                  setMeasurementUnit('imperial');
                  setIsEdited(true);
                }}
                variant={measurementUnit === 'imperial' ? 'contained' : 'outlined'}
              >
                Imperial
              </Button>
              <Button
                onClick={() => {
                  setMeasurementUnit('metric');
                  setIsEdited(true);
                }}
                variant={measurementUnit === 'metric' ? 'contained' : 'outlined'}
              >
                Metric
              </Button>
            </ButtonGroup>
          </Box>
        </Box>
        {userHasOrgWritePermission ? (
          <FormGroup row>
            <Button
              disabled={isMutationInFlight}
              color='secondary'
              sx={{
                height: 50,
                width: 200,
                marginRight: 2,
              }}
              onClick={handleNavigateBack}
              variant='contained'
            >
              Go Back
            </Button>
            <Button
              type='submit'
              disabled={!isEdited || isMutationInFlight}
              color='primary'
              sx={{ height: 50, width: 200 }}
              onClick={handleSubmit(onSubmit)}
              variant='contained'
              startIcon={isMutationInFlight ? <CircularProgress size={20} /> : null}
            >
              Update
            </Button>
          </FormGroup>
        ) : null}
      </FormControl>
    </Grid>
  );
}
type Props = {
  selectedOrg: SelectedOrgType;
};

export default function OrgDetailsPage({ selectedOrg }: Props) {
  const {
    data: orgDetailsData,
    loading: isLoading,
    error: queryError,
    refetch: refetchOrgData,
  } = useQuery(ADDITIONAL_SELECTED_ORG_DATA_QUERY, { variables: { accountId: selectedOrg?.id } });
  const { showBoundary: showErrorBoundary } = useErrorBoundary();

  if (queryError) {
    showErrorBoundary(queryError);
  }

  const userHasOrgWritePermission =
    selectedOrg?.userAccountScope?.roles?.account_write == null
      ? false
      : selectedOrg?.userAccountScope?.roles?.account_write;

  const pageDescription = userHasOrgWritePermission
    ? 'Manage information and settings relevant to a particular organization'
    : 'View information and settings for a particular organization';

  return (
    <PageContainerFrame pageTitles={['Org Details', selectedOrg?.name]}>
      <SettingsPanelFrame title='Organization Details' description={pageDescription}>
        <Box display='flex' flexDirection='column' gap={2}>
          <Box>
            <OrgDisplayAndSwitcher />
          </Box>
          {isLoading ? (
            <CircularProgress sx={{ alignSelf: 'center', marginTop: 10 }} size={100} />
          ) : (
            <DetailsInputPanel
              orgDetailsData={orgDetailsData}
              userHasOrgWritePermission={userHasOrgWritePermission}
              refetchOrgData={refetchOrgData}
            />
          )}
        </Box>
      </SettingsPanelFrame>
    </PageContainerFrame>
  );
}
