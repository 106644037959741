import { useState } from 'react';

import {
  Box,
  Modal,
  Typography,
  Card,
  CardHeader,
  CardActions,
  CardContent,
  Button,
  CircularProgress,
} from '@mui/material';

type Props = {
  onModalClose: () => void;
  modalProps?: unknown;
};

const modalStyle = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: 300,
  padding: 2,
};

export default function ConfirmDeleteAlertConfig({ onModalClose, modalProps }: Props) {
  const { onConfirm, alertTitle } = modalProps as {
    onConfirm: () => Promise<void>;
    alertTitle: string;
  };

  const [isLoading, setIsLoading] = useState(false);
  return (
    <Modal open={true} onClose={onModalClose}>
      <Card sx={modalStyle}>
        <CardHeader title='Confirm Delete' titleTypographyProps={{ variant: 'h5' }} />
        <CardContent>
          <Typography>
            All alerts for this configuration will be deleted permanently as well.
          </Typography>
          <Typography color='gray' variant='subtitle1'>
            {alertTitle}
          </Typography>
        </CardContent>
        <CardActions>
          <Box display='flex' flexGrow={1} justifyContent='flex-end' alignItems='center' gap={2}>
            <Button color='primary' onClick={onModalClose}>
              Cancel
            </Button>
            <Button
              sx={{ fontWeight: 500 }}
              color='error'
              onClick={() => {
                setIsLoading(true);
                onConfirm().finally(() => {
                  setIsLoading(false);
                  onModalClose();
                });
              }}
              disabled={isLoading}
              endIcon={isLoading && <CircularProgress size={20} color='error' />}
            >
              Confirm Delete
            </Button>
          </Box>
        </CardActions>
      </Card>
    </Modal>
  );
}
