import React, { useEffect } from 'react';
import { Box, Button, Card, CardContent, Grid, Typography } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { OrgAPISettingsPanelViewEnum } from 'Constants/OrgSettingsEnums';
import { FileCopy } from '@mui/icons-material';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import RoutePaths from 'Constants/RoutePaths';

const { PATH_ORGANIZATION_API_KEYS } = RoutePaths;

export default function OrgNewKeyView() {
  const navigate = useNavigate();
  const location = useLocation();
  const { newAPIKey } = location.state;

  useEffect(() => {
    // when the component unmounts we clear the API key from browser history state
    return () => {
      const clearedState = { ...window.history.state };
      clearedState.usr.newAPIKey = undefined;
      window.history.replaceState(clearedState, '');
    };
  }, []);

  return (
    <Box display='flex' flexDirection='column' gap={2}>
      <Card>
        <CardContent>
          <Grid container>
            <Grid item xs={10}>
              {newAPIKey ? (
                <Typography fontWeight='bold'>
                  Make sure to copy and store your newly created API key. You won&#39;t be able to
                  access it again!
                </Typography>
              ) : (
                <Typography fontWeight='bold'>Key no longer available</Typography>
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      {newAPIKey ? (
        <Card sx={{ background: 'none' }} variant='outlined'>
          <CardContent>
            <Box display='flex' flexDirection='row' alignItems='center'>
              <Typography fontWeight='semi-bold'>{newAPIKey}</Typography>
              <CopyToClipboard text={newAPIKey}>
                <Button>
                  <FileCopy />
                </Button>
              </CopyToClipboard>{' '}
            </Box>
            <Grid container>
              <Grid item xs={10}></Grid>
            </Grid>
          </CardContent>
        </Card>
      ) : null}
      <Button
        color='primary'
        sx={{
          alignSelf: 'flex-start',
          paddingLeft: 5,
          paddingRight: 5,
          height: 50,
        }}
        onClick={() => {
          navigate(PATH_ORGANIZATION_API_KEYS, {
            state: { panelView: OrgAPISettingsPanelViewEnum.ORG_KEY_LIST_VIEW },
          });
        }}
        variant='contained'
      >
        {newAPIKey ? (
          <Typography> I&#39;ve securely stored this key</Typography>
        ) : (
          <Typography> View my existing keys</Typography>
        )}
      </Button>
    </Box>
  );
}
