import { AutoAwesome as AutoAwesomeIcon } from '@mui/icons-material';
import { Box, Chip } from '@mui/material';
import PageContainerFrame from 'Components/HOC/PageContainerFrame';
import SettingsPanelFrame from 'Components/HOC/SettingsPanelFrame';
import LocationSelectorBreadcrumb from 'Components/LocationSelectorBreadcrumb/LocationSelectorBreadcrumb';
import useCurrentLocationList from 'Hooks/useCurrentLocationList';
import { OccupancyContextProvider, useOccupancyContext } from './context';
import OccupancyViewRouter from './PageViews/OccupancyViewRouter';
import { OccupancyPageProps } from './occupancy.types';

export default function OccupancyPageWithContext(props: OccupancyPageProps) {
  return (
    <OccupancyContextProvider {...props}>
      <OccupancyPage />
    </OccupancyContextProvider>
  );
}

function OccupancyPage() {
  const occupancyContext = useOccupancyContext();
  const [isLocationSelectorLoading, initLocationList] = useCurrentLocationList(
    occupancyContext.locationId
  );

  return (
    <PageContainerFrame pageTitles={['Occupancy']}>
      <SettingsPanelFrame
        title='Occupancy Analysis'
        description={
          <Box component='span' display='flex' alignItems='center'>
            <Chip label='Beta' variant='outlined' icon={<AutoAwesomeIcon fontSize='inherit' />} />
          </Box>
        }
      >
        <Box alignSelf='center'>
          {!isLocationSelectorLoading && (
            <LocationSelectorBreadcrumb
              preLoadedLocationList={initLocationList}
              onLocationSelectCallback={occupancyContext.onLocationChange}
            />
          )}
        </Box>
        <OccupancyViewRouter />
      </SettingsPanelFrame>
    </PageContainerFrame>
  );
}
