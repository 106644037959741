const opacitySetting = 0.4;
export const gaugeColors = {
  red: {
    default: `rgba(244, 39, 39, ${opacitySetting})`,
    selected: 'rgb(244, 39, 39)',
  },
  yellow: {
    default: `rgba(244, 211, 39, ${opacitySetting})`,
    selected: 'rgb(244, 211, 39)',
  },
  green: {
    default: `rgba(31, 199, 47, ${opacitySetting})`,
    selected: 'rgb(31, 199, 47)',
  },
  lightBlue: {
    default: `rgba(22, 190, 240, ${opacitySetting})`,
    selected: 'rgb(22, 190, 240)',
  },
  darkBlue: {
    default: `rgba(1, 68, 138, ${opacitySetting})`,
    selected: 'rgb(1, 68, 138)',
  },
};
export const metricCardIconColor = '#7c7c7c';
