import { useEffect, useState } from 'react';
import QuickMetricsCard from 'Components/MetricCards/QuickMetricsCard';
import { gaugeColors, metricCardIconColor } from 'Constants/OverviewConsts';
import CloudIcon from '@mui/icons-material/Cloud';
import RoutePaths from 'Constants/RoutePaths';
import { MetricReportTypeEnum } from 'Constants/FloorsViewEnums';
import MetricGauge from 'Components/MetricCards/ChartComponents/MetricGauge';
import type { GridConfigType } from 'Components/MetricCards/QuickMetricsCard';

import type { GaugeConfigType } from 'Constants/OverViewTypes';
import { cache } from 'Apollo/ApolloCache';
import moment from 'moment-timezone';
import { useTheme, Typography } from '@mui/material';
import { LOCATION_CORE_FRAGMENT } from 'fragments';
import { LocationCoreFragment } from '__generated__/graphql';

export type VOCMetricCardContainerProps = {
  vocDataToAverage?: (number | null | undefined)[];
  isLoading: boolean;
  startDate: string;
  endDate: string;
  selectedLocationID: string | undefined;
  selectedOrgID: string | undefined;
  gridConfig: GridConfigType;
  error?: boolean;
};

const TITLE = 'VOC';
const INFO_TEXT =
  'TVOCs, or Total Volatile Organic Compounds, are tiny chemicals that can be released into the air from things like cleaning products, paint, furniture, and even cooking. When TVOC levels get too high indoors, they can cause eye, nose, and throat irritation and may lead to headaches or dizziness. Keeping TVOC levels low, by using less harsh products or increasing ventilation, helps make indoor air cleaner and more comfortable to breathe.';
export default function VOCMetricCardContainer({
  vocDataToAverage,
  isLoading,
  startDate,
  endDate,
  selectedLocationID,
  gridConfig,
  error,
}: VOCMetricCardContainerProps) {
  const theme = useTheme();

  const [gaugeConfig, setGaugeConfig] = useState<GaugeConfigType>();

  useEffect(() => {
    const report = vocDataToAverage ?? [];
    const vocPpm = report.length === 0 ? null : Math.floor(report[report.length - 1] ?? 0);
    const selectedToolTipText = `${vocPpm} ppm`;
    const mainGaugeLabel = `${vocPpm} ppm`;
    const { red, yellow, green } = gaugeColors;

    const rangeConfig = [
      {
        defaultColor: green.default,
        selectedColor: green.selected,
        limit: 50,
        tooltip: {
          defaultText: 'Healthy VOC levels',
          selectedText: selectedToolTipText,
        },
      },
      {
        defaultColor: yellow.default,
        selectedColor: yellow.selected,
        limit: 100,
        tooltip: {
          defaultText: 'Moderate VOC levels',
          selectedText: selectedToolTipText,
        },
      },
      {
        defaultColor: red.default,
        selectedColor: red.selected,
        limit: 250,
        tooltip: {
          defaultText: 'Unhealthy VOC levels',
          selectedText: selectedToolTipText,
        },
      },
    ];

    setGaugeConfig({
      rangeConfig,
      mainGaugeLabel,
      value: vocPpm,
      minValue: -1,
      maxValue: 500,
    });
  }, [vocDataToAverage]);

  const selectedLocation = cache.readFragment<LocationCoreFragment>({
    id: `Location:${selectedLocationID}`,
    fragment: LOCATION_CORE_FRAGMENT
  });
  const numHoursDataRange = moment(endDate).diff(startDate, 'hour');

  return (
    <QuickMetricsCard
      isLoading={isLoading}
      icon={<CloudIcon sx={{ color: error ? theme.palette.error.main : metricCardIconColor }} />}
      title={TITLE}
      infoText={INFO_TEXT}
      link={`${RoutePaths.PATH_REPORTS}?locationID=${selectedLocationID}&selectedMetric=${MetricReportTypeEnum.VOC}`}
      gridConfig={gridConfig}
      error={error}
      showInfoInModal
    >
      {gaugeConfig ? <MetricGauge gaugeConfig={gaugeConfig} /> : null}
      {(!gaugeConfig ||
        (gaugeConfig && (gaugeConfig.value === null || gaugeConfig.value === undefined))) && (
        <>
          <Typography alignContent={'center'} color={'InactiveCaptionText'}>
            No data available for <b>{selectedLocation?.name}</b>
          </Typography>
          <Typography alignContent={'center'} fontStyle={'italic'} color={'InactiveCaptionText'}>
            in the last{' '}
            <b>
              {numHoursDataRange} {numHoursDataRange > 1 ? 'hours' : 'hour'}
            </b>
          </Typography>
        </>
      )}
    </QuickMetricsCard>
  );
}
