import React from 'react';
import ReactDOM from 'react-dom/client';
import AuthRouter from './AuthRouter';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { Authenticator } from '@aws-amplify/ui-react';
import { ApolloProvider } from '@apollo/client';
import { apolloClient } from 'Apollo/ApolloConfig';
import { Helmet } from 'react-helmet';
import { formatPageTitle } from 'Utils/formatPageTitle';
import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
const pageTitle = formatPageTitle([]);
root.render(
  <React.StrictMode>
    <Helmet>
      <title>{pageTitle}</title>
    </Helmet>
    <ApolloProvider client={apolloClient}>
      <Authenticator.Provider>
        <BrowserRouter>
          <AuthRouter />
        </BrowserRouter>
      </Authenticator.Provider>
    </ApolloProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
