import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { ModalNotificationTypeEnum, useModal } from 'Providers/ModalProvider';
import { ToastNotificationSeverityTypeEnum, useToast } from 'Providers/ToastProvider';
import { SupportedFileType } from '__generated__/graphql';

import ImageRendererWithUpload from 'Components/SharedUI/ImageRendererWithUpload';

const { Jpeg, Png, Jpg } = SupportedFileType;

export const GET_FILE_UPLOAD_URL = gql`
  query GetNewFileUploadUrl($input: NewFileUploadUrlInput!) {
    getNewFileUploadUrl(input: $input) {
      fileId
      uploadUrl
    }
  }
`;

const UPDATE_ACCOUNT_LOGO_IMAGE_PREFERENCE_MUTATION = gql`
  mutation UpdateAccountLogoImagePreference(
    $accountId: ID!
    $attributesToUpdate: AccountUpdateInput!
  ) {
    updateAccount(accountId: $accountId, attributesToUpdate: $attributesToUpdate) {
      id
      preferences {
        logo_image_url
      }
    }
  }
`;

export const uploadImageToS3 = async (imageUrl, file) => {
  try {
    await fetch(imageUrl, {
      method: 'PUT',
      body: file,
    });
  } catch (error) {
    console.error('Error uploading image:', error);
  }
};

export const getFormattedFileType = (fileTypeRaw: string): SupportedFileType => {
  const trimmedFileType = fileTypeRaw.split('/').pop();
  switch (trimmedFileType) {
    case Jpeg:
      return Jpeg;
    case Jpg:
      return Jpg;
    default:
      return Png;
  }
};

type Props = {
  accountId: string;
  imageURL: string | null | undefined;
  userHasEditPermission: boolean;
  size: 'small' | 'medium' | 'large';
};

export default function OrgProfilePhotoRendererContainer({
  accountId,
  imageURL,
  userHasEditPermission,
  size,
}: Props) {
  const [updateAccountLogoImage] = useMutation(UPDATE_ACCOUNT_LOGO_IMAGE_PREFERENCE_MUTATION);
  const [getFileUploadURL] = useLazyQuery(GET_FILE_UPLOAD_URL);
  const { dispatchModal } = useModal();
  const { dispatchToast } = useToast();

  const uploadAndUpdateOrgLogoImage = async (file: File) => {
    try {
      const fileUploadDataRes = await getFileUploadURL({
        variables: {
          input: {
            accountId,
            filesizeBytes: file?.size,
            filetype: getFormattedFileType(file?.type ?? ''),
          },
        },
      });
      const uploadURL = fileUploadDataRes?.data?.getNewFileUploadUrl?.uploadUrl ?? '';
      const newS3FileID = fileUploadDataRes?.data?.getNewFileUploadUrl?.fileId ?? '';
      await uploadImageToS3(uploadURL, file);
      await updateAccountLogoImage({
        variables: {
          accountId: accountId,
          attributesToUpdate: {
            preferences: {
              logo_image_id: newS3FileID,
            },
          },
        },
      });
      const notifConfig = {
        severity: ToastNotificationSeverityTypeEnum.SUCCESS,
        title: 'Profile Photo Updated',
        message: 'You have successfully updated your organizations profile photo',
      };
      dispatchToast(notifConfig);
    } catch (error) {
      const notifConfig = {
        severity: ToastNotificationSeverityTypeEnum.ERROR,
        title: 'Error Updating Profile Photo',
        message: 'Please try again',
      };
      dispatchToast(notifConfig);
      console.error('Error uploading image:', error);
    }
  };

  return (
    <>
      <ImageRendererWithUpload
        userHasEditPermission={userHasEditPermission}
        imageURL={imageURL}
        callBack={() =>
          dispatchModal({
            type: ModalNotificationTypeEnum.IMAGE_UPLOAD_MODAL,
            modalProps: {
              title: 'Upload Profile Image',
              imageUploadCallBack: uploadAndUpdateOrgLogoImage,
            },
          })
        }
        size={size}
      />
    </>
  );
}
