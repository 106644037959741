/* eslint-disable no-magic-numbers */
import {
  alpha,
  Box,
  LinearProgress,
  Link,
  Paper,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { AdminDevicesQuery } from '__generated__/graphql';
import type { SelectedOrgType } from 'Apollo/ApolloCache';
import { ToastNotificationSeverityTypeEnum, useToast } from 'Providers/ToastProvider';
import { ReactNode, useEffect, useRef } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Link as RouterLink } from 'react-router-dom';
import DeviceConnectivityCell from '../../DevicesListPage/PageViews/DeviceConnectivityCell';
import DeviceFirmwareCell from '../../DevicesListPage/PageViews/DeviceFirmwareCell';
import DeviceIconCell from '../../DevicesListPage/PageViews/DeviceIconCell';
import DeviceStateCell from '../../DevicesListPage/PageViews/DeviceStateCell';
import DeviceTitleCell from '../../DevicesListPage/PageViews/DeviceTitleCell';
import { DeviceOrgCell } from '../common';
import useSwitchOrg from 'Hooks/useSwitchOrg';

type DevicesListProps = {
  devices: NonNullable<AdminDevicesQuery['deviceSearch']>['devices'];
  totalCount: number;
  selectedOrg?: SelectedOrgType;
  loadNextPage?: () => void;
  postRegisterDevice: (
    serialNumber: string,
    org: Record<'id' | 'name', string>,
    location: Record<'id' | 'name', string>
  ) => void;
};

export default function DevicesListView({
  devices,
  totalCount,
  loadNextPage,
  postRegisterDevice,
}: DevicesListProps) {
  const [switchOrg, _] = useSwitchOrg();
  const theme = useTheme();
  const { dispatchToast } = useToast();
  const tableRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    // Manually add hover state to the table row. display: contents doesn't allow to set styles
    function mouseenterHandler(event: Event) {
      if (!event.target) return;
      const el = event.target as HTMLAnchorElement;
      el.childNodes.forEach((cnode) => {
        const child = cnode as HTMLDivElement;
        child.style.backgroundColor =
          theme.palette.mode === 'light'
            ? theme.palette.grey[200]
            : alpha(theme.palette.grey[700], 0.4);
      });
    }
    function mouseleaveHandler(event: Event) {
      if (!event.target) return;
      const el = event.target as HTMLAnchorElement;
      el.childNodes.forEach((cnode) => {
        const child = cnode as HTMLDivElement;
        child.style.backgroundColor = '';
      });
    }
    if (!tableRef.current) return;
    const tableEl = tableRef.current;
    tableEl.childNodes.forEach((node) => {
      node.addEventListener('mouseenter', mouseenterHandler);
      node.addEventListener('mouseleave', mouseleaveHandler);
    });
    return () => {
      tableEl.childNodes.forEach((node) => {
        node.removeEventListener('mouseenter', mouseenterHandler);
        node.removeEventListener('mouseleave', mouseleaveHandler);
      });
    };
  }, [tableRef, devices, theme]);
  if (!devices || devices.length === 0) {
    return (
      <Paper sx={{ padding: 2 }}>
        <Typography variant='h5'>No devices Found</Typography>
        <Typography variant='body1' color='gray'>
          Update the filters or add devices
        </Typography>
      </Paper>
    );
  }
  return (
    <Stack component={Paper}>
      <InfiniteScroll
        dataLength={devices.length}
        next={() => (loadNextPage ? loadNextPage() : void 0)}
        hasMore={devices.length < totalCount}
        loader={<LinearProgress />}
      >
        <Box width='100%' p={2} display='flex' justifyContent='space-between' alignItems='center'>
          <Typography
            sx={{
              fontWeight: 500,
            }}
          >
            {totalCount} devices that match your search criteria
          </Typography>
        </Box>
        <Stack
          ref={tableRef}
          width='100%'
          display='grid'
          gridTemplateColumns='.5fr minmax(300px, auto) 1fr 1fr 1fr 1fr'
        >
          {devices?.map((device) => {
            return (
              <Link
                key={`${device?.serialNumber}-${device?.nickname}`}
                component={RouterLink}
                color='inherit'
                to={`/devices/${device?.serialNumber}`}
                underline='none'
                display='contents'
                onClick={(e) => {
                  if (device?.account) {
                    const { target, nativeEvent } = e;
                    const clonedNativeEvent = new MouseEvent('click', nativeEvent);
                    e.stopPropagation();
                    e.preventDefault();
                    switchOrg(device.account);
                    target.dispatchEvent(clonedNativeEvent);
                    return;
                  }
                  dispatchToast({
                    severity: ToastNotificationSeverityTypeEnum.WARNING,
                    title: 'Device details unavailable',
                    message: 'Please register this device to an org to view its details',
                  });
                  e.preventDefault();
                }}
              >
                <GridItemWrapper>
                  <DeviceIconCell deviceType={device?.type ?? ''} />
                </GridItemWrapper>
                <GridItemWrapper>
                  <DeviceTitleCell
                    serialNumber={device?.serialNumber ?? ''}
                    fullLocation={
                      device?.fullLocationPath?.map((loc) => loc?.name)?.join(' / ') ?? ''
                    }
                    nickname={device?.nickname}
                  />
                </GridItemWrapper>
                <GridItemWrapper>
                  <DeviceOrgCell
                    account={device?.account}
                    serialNumber={device?.serialNumber}
                    postRegisterDevice={postRegisterDevice}
                  />
                </GridItemWrapper>
                <GridItemWrapper>
                  <DeviceFirmwareCell firmware={device?.firmwareVersion} />
                </GridItemWrapper>
                <GridItemWrapper>
                  <DeviceConnectivityCell connectivity={device?.connectivity} />
                </GridItemWrapper>
                <GridItemWrapper>
                  <DeviceStateCell state={device?.state} />
                </GridItemWrapper>
              </Link>
            );
          })}
        </Stack>
        {totalCount === devices.length && (
          <Box width='100%' p={2} textAlign='center'>
            <Typography
              sx={{
                fontWeight: 500,
              }}
              variant='body1'
            >
              You have viewed all the devices that match your current filters
            </Typography>
            <Typography variant='body2'>
              Modify the selected filters to find more devices
            </Typography>
          </Box>
        )}
      </InfiniteScroll>
    </Stack>
  );
}

function GridItemWrapper({ children }: { children: ReactNode }) {
  return (
    <Box px={1.5} py={1.5} width='100%'>
      {children}
    </Box>
  );
}
