import { BinInterval } from '__generated__/graphql';
import { createContext } from 'react';
import { OccupancyContextType, OccupancyView } from '../occupancy.types';

export const OccupancyContext = createContext<OccupancyContextType>({
  view: OccupancyView.OCCUPANCY_CHART,
  occupancyGraphLoading: false,
  currentInterval: BinInterval.FiveMinutes,
  isRecalibrating: false,
  filterHandler: (_: unknown) => Promise.resolve(void 0),
  onLocationChange: (_: unknown) => void 0,
  triggerCalibrationOnCurrentLocation: () => void 0,
  resetPage: () => void 0,
});
