import { useTheme, Box, IconButton, Tooltip } from '@mui/material';
import { DeviceType } from '__generated__/graphql';
import { deviceMap } from './DevicesListFilters';
interface DeviceIconCellProps {
  deviceType: DeviceType | string;
}

export default function DeviceIconCell({ deviceType }: DeviceIconCellProps) {
  const theme = useTheme();
  return (
    <Tooltip title={deviceMap[deviceType].value ?? '-'}>
      <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
        <IconButton disabled sx={{ padding: 0 }}>
          <img
            src={deviceMap[deviceType].icon[theme.palette.mode]}
            width='40'
            height='40'
            alt={deviceMap[deviceType].value}
            style={{
              objectFit: 'contain',
            }}
          />
        </IconButton>
      </Box>
    </Tooltip>
  );
}
