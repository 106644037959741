/* eslint-disable no-magic-numbers */
/* eslint-disable indent */
import { InfoOutlined as InfoIcon, ZoomOutMap as ZoomIcon } from '@mui/icons-material';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { BinInterval } from '__generated__/graphql';
import { Chart } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import { CrosshairPlugin } from 'chartjs-plugin-crosshair';
import zoomPlugin from 'chartjs-plugin-zoom';
import { formatTimestamp } from 'Components/AuthedPages/ReportsPage/MetricLineChartContainer';
import CSVDownloadButton from 'Components/SharedUI/CSVDownloadButton';
import moment from 'moment';
import { Fragment, useMemo } from 'react';
import { ChartProps, Line } from 'react-chartjs-2';
import { replaceConfigDataIfSkippedBinPeriod } from 'Utils/chartHelpers';
import { useOccupancyContext } from '../../context';
import { GROUPS } from '../../utils';
import { capitalize } from 'Utils/stringManipulation';
interface OccupancyViewProps {
  selectedInterval?: BinInterval;
}

Chart.register();
const CHART_ID = 'occupancy-chart';

export default function OccupancyView({ selectedInterval }: OccupancyViewProps) {
  const {
    occupancyGraphData,
    occupancyGraphError: queryError,
    locationCalibrationDetailsForOccupancy: selectedLocation,
  } = useOccupancyContext();
  const theme = useTheme();
  const isSmallerScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const report = occupancyGraphData?.report?.uvangel?.getLocationOccupancyForDuration;
  const chartOptions: ChartProps<'line'>['options'] | null = useMemo(() => {
    if (!report) return null;
    const xLimits = {
      min: Math.min(...report.map((reportItem) => new Date(reportItem?.timestamp).valueOf())),
      max: Math.max(...report.map((reportItem) => new Date(reportItem?.timestamp).valueOf())),
    };
    return {
      aspectRatio: isSmallerScreen ? 0.8 : 1.5,
      interaction: {
        intersect: false,
        mode: 'nearest' as const,
        axis: 'x' as const,
      },
      plugins: {
        legend: {
          display: true,
        },
        tooltip: {
          callbacks: {
            title: (context) => {
              const timeStamp = context[0].label;
              return formatTimestamp(timeStamp, void 0, true);
            },
            label: (context) => {
              const value = context?.raw as { x: Date; y: number };
              const roundedValue = Number(
                (typeof value.y === 'string' ? parseFloat(value.y) : value.y).toFixed(2)
              );
              return `${context.dataset.label}: ${roundedValue}%`;
            },
          },
        },
        crosshair: {
          line: {
            color: 'gray',
            dashPattern: [8, 8],
            width: 1,
          },
          sync: {
            enabled: true, // enable trace line syncing with other charts
            group: 1, // chart group
            suppressTooltips: false, // suppress tooltips when showing a synced tracer
          },
          zoom: {
            enabled: false, // we use the other zoom plugin for zooming, so this is to be disabled
          },
        },
        zoom: {
          limits: {
            x: xLimits,
          },
          pan: {
            enabled: true,
            mode: 'x' as const,
            modifierKey: 'shift' as const,
            scaleMode: 'x' as const,
            onPan: (chart) => {
              chart.chart.canvas.style.cursor = 'grabbing';
            },
            onPanComplete: (chart) => {
              chart.chart.canvas.style.cursor = 'grab';
            },
          },
          zoom: {
            pinch: {
              enabled: true,
            },
            drag: {
              enabled: true,
              backgroundColor: 'rgba(128,128,128,0.2)',
              borderColor: 'gray',
              borderWidth: 1,
              threshold: 1,
            },
            onZoom: (chart) => {
              chart.chart.canvas.style.cursor = 'grabbing';
            },
            onZoomComplete: (chart) => {
              chart.chart.canvas.style.cursor = 'grab';
            },
            mode: 'x' as const,
          },
        },
      },
      scales: {
        x: {
          type: 'time' as const,
          grid: {
            color: (context) => {
              const lineWidth =
                context.tick && context.tick.major
                  ? Chart.defaults.borderColor.toString()
                  : 'transparent';
              return lineWidth;
            },
          },
          ticks: {
            major: {
              enabled: true,
            },
            font: function (context) {
              if (context.tick && context.tick.major) {
                return {
                  size: 14, // 12 is 'normal'
                  weight: 'bold',
                };
              }
            },
            display: true,
          },
        },
        y_Occupancy: {
          title: { display: true, text: 'Occupancy' },
          display: false,
          type: 'linear',
          beginAtZero: true,
          grid: {
            display: false,
          },
          ticks: {
            callback: function (val) {
              return `${
                Number((typeof val === 'string' ? parseFloat(val) : val).toFixed(2))
              }%`;
            },
            precision: 0,
          },
          min: 0,
          max: 100,
        },
        y_Occupancy_Grouped: {
          title: { display: true, text: 'Occupancy' },
          display: true,
          type: 'category',
          beginAtZero: true,
          labels: GROUPS.slice()
            .reverse()
            .map((group) => capitalize(group.toLowerCase())),
          offset: true,
          grid: {
            offset: true,
          },
        },
        y_co2: {
          title: { display: true, text: 'CO2 (ppm)' },
          display: true,
          beginAtZero: true,
          position: 'right',
          grid: {
            display: false,
          },
          ticks: {
            callback: function (val) {
              return Number((typeof val === 'string' ? parseFloat(val) : val).toFixed(2));
            },
            precision: 0,
          },
        },
      },
    };
  }, [report, isSmallerScreen]);
  const chartData: ChartProps<'line'>['data'] | null = useMemo(() => {
    if (!report) return null;
    return {
      datasets: [
        {
          label: 'Occupancy',
          data: report?.map((reportItem) => ({
            x: new Date(reportItem?.timestamp).valueOf(),
            y: reportItem?.estimatedOccupancyPercentage || 0,
          })),
          yAxisID: 'y_Occupancy',
          fill: true,
          borderColor: theme.palette.primary.main,
          backgroundColor: `${theme.palette.primary.main}1A`,
          pointRadius: 0,
          tension: 0.2,
          segment: {
            borderColor: (context) =>
              replaceConfigDataIfSkippedBinPeriod(context, 'gray', selectedInterval),
            borderDash: (context) =>
              replaceConfigDataIfSkippedBinPeriod(context, [6, 6], selectedInterval),
          },
          spanGaps: true,
        },
        {
          label: 'CO2 (ppm)',
          data: report?.map((reportItem) => ({
            x: new Date(reportItem?.timestamp).valueOf(),
            y: reportItem?.co2Ppm as number,
          })),
          yAxisID: 'y_co2',
          hidden: false,
          fill: false,
          borderColor: 'lightgray',
          backgroundColor: 'lightgray',
          tension: 0.2,
          pointRadius: 0,
          segment: {
            borderColor: (context) =>
              replaceConfigDataIfSkippedBinPeriod(context, 'gray', selectedInterval),
            borderDash: (context) =>
              replaceConfigDataIfSkippedBinPeriod(context, [6, 6], selectedInterval),
          },
          spanGaps: true,
        },
      ],
    };
  }, [
    report,
    theme.palette.primary.main,
    selectedInterval,
  ]);
  return (
    <Card sx={{ width: '100%' }} elevation={0}>
      {!report || report.length === 0 || !chartData || !chartOptions ? (
        <CardHeader
          sx={{ p: 3 }}
          title='No Occupancy Report'
          subheader={
            queryError?.message
              ? queryError.message
              : 'Modify the selected filters to find a report'
          }
        />
      ) : (
        <Fragment>
          <CardHeader
            sx={{ pb: 0 }}
            avatar={
              <Tooltip
                title={
                  <Fragment>
                    <Typography variant='subtitle2'>Click & Drag to Zoom</Typography>
                    <Typography variant='subtitle2'>Hold Shift + Click & Drag to Pan</Typography>
                  </Fragment>
                }
              >
                <Avatar>
                  <InfoIcon />
                </Avatar>
              </Tooltip>
            }
            action={
              <Box display='flex' gap={1} alignItems='center'>
                <Tooltip title='Reset Zoom'>
                  <IconButton
                    sx={{
                      borderColor: (theme) => theme.palette.primary.main,
                      borderRadius: 1,
                      borderWidth: 1,
                      borderStyle: 'solid',
                    }}
                    color='primary'
                    onClick={() => Chart.getChart(CHART_ID)?.resetZoom()}
                  >
                    <ZoomIcon />
                  </IconButton>
                </Tooltip>
                <CSVDownloadButton
                  data={report ?? []}
                  fileName={`occupancy-${moment().format('MMM-DD-YYYY')}`}
                  formatDataCallback={(raw: Array<Record<string, unknown>>) => {
                    return raw.map((riskItem) => ({
                      location: selectedLocation?.name ?? 'All Locations',
                      timestamp: riskItem?.timestamp,
                      co2Ppm: riskItem?.co2Ppm,
                      co2Emission: riskItem?.co2Emission,
                      peopleUnits: riskItem?.peopleUnits,
                      roundedOccupancy: riskItem?.roundedOccupancy,
                    }));
                  }}
                />
              </Box>
            }
          />
          <CardContent>
            <Line
              id={CHART_ID}
              data={chartData}
              options={chartOptions}
              plugins={[CrosshairPlugin, annotationPlugin, zoomPlugin]}
              style={{
                cursor: 'grab',
              }}
            />
          </CardContent>
        </Fragment>
      )}
    </Card>
  );
}
