/* eslint-disable no-magic-numbers */
import {
  alpha,
  useTheme,
  Box,
  Paper,
  Typography,
  LinearProgress,
  Stack,
  Link,
} from '@mui/material';
import DeviceTitleCell from './DeviceTitleCell';
import DeviceFirmwareCell from './DeviceFirmwareCell';
import DeviceConnectivityCell from './DeviceConnectivityCell';
import DeviceStateCell from './DeviceStateCell';
import DeviceIconCell from './DeviceIconCell';
import type { SelectedOrgType } from 'Apollo/ApolloCache';
import InfiniteScroll from 'react-infinite-scroll-component';
import { DevicesQuery } from '__generated__/graphql';
import { Link as RouterLink } from 'react-router-dom';
import { ReactNode, useRef, useEffect } from 'react';
import DeviceSettingsTrigger from './DeviceSettingsTrigger';

type DevicesListProps = {
  devices: NonNullable<DevicesQuery['deviceSearch']>['devices'];
  totalCount: number;
  selectedOrg?: SelectedOrgType;
  simple?: boolean;
  loadNextPage?: () => void;
};

export default function DevicesListView({
  devices,
  totalCount,
  loadNextPage,
  simple,
}: DevicesListProps) {
  const theme = useTheme();
  const tableRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    // Manually add hover state to the table row. display: contents doesn't allow to set styles
    function mouseenterHandler(event: Event) {
      if (!event.target) return;
      const el = event.target as HTMLAnchorElement;
      el.childNodes.forEach((cnode) => {
        const child = cnode as HTMLDivElement;
        child.style.backgroundColor =
          theme.palette.mode === 'light'
            ? theme.palette.grey[200]
            : alpha(theme.palette.grey[700], 0.4);
      });
    }
    function mouseleaveHandler(event: Event) {
      if (!event.target) return;
      const el = event.target as HTMLAnchorElement;
      el.childNodes.forEach((cnode) => {
        const child = cnode as HTMLDivElement;
        child.style.backgroundColor = '';
      });
    }
    if (!tableRef.current) return;
    const tableEl = tableRef.current;
    tableEl.childNodes.forEach((node) => {
      node.addEventListener('mouseenter', mouseenterHandler);
      node.addEventListener('mouseleave', mouseleaveHandler);
    });
    return () => {
      tableEl.childNodes.forEach((node) => {
        node.removeEventListener('mouseenter', mouseenterHandler);
        node.removeEventListener('mouseleave', mouseleaveHandler);
      });
    };
  }, [tableRef, devices, theme]);
  if (!devices || devices.length === 0) {
    return (
      <Paper sx={{ padding: 2 }}>
        <Typography variant='h5'>No devices Found</Typography>
        <Typography variant='body1' color='gray'>
          Update the filters or add devices
        </Typography>
      </Paper>
    );
  }
  return (
    <Stack component={Paper}>
      <InfiniteScroll
        dataLength={devices.length}
        next={() => (loadNextPage ? loadNextPage() : void 0)}
        hasMore={devices.length < totalCount}
        loader={<LinearProgress />}
      >
        {!simple && (
          <Box width='100%' p={2} display='flex' justifyContent='space-between' alignItems='center'>
            <Typography
              sx={{
                fontWeight: 500,
              }}
            >
              {totalCount} devices that match your search criteria
            </Typography>
            <DeviceSettingsTrigger />
          </Box>
        )}
        <Stack
          ref={tableRef}
          width='100%'
          display='grid'
          gridTemplateColumns='.5fr minmax(300px, auto) 1fr 1fr 1fr'
        >
          {devices?.map((device) => {
            return (
              <Link
                key={`${device?.serialNumber}-${device?.nickname}`}
                component={RouterLink}
                color='inherit'
                to={`/devices/${device?.serialNumber}`}
                underline='none'
                display='contents'
              >
                <GridItemWrapper>
                  <DeviceIconCell deviceType={device?.type ?? ''} />
                </GridItemWrapper>
                <GridItemWrapper>
                  <DeviceTitleCell
                    serialNumber={device?.serialNumber ?? ''}
                    fullLocation={
                      device?.fullLocationPath?.map((loc) => loc?.name)?.join(' / ') ?? ''
                    }
                    nickname={device?.nickname}
                  />
                </GridItemWrapper>
                <GridItemWrapper>
                  <DeviceFirmwareCell firmware={device?.firmwareVersion} />
                </GridItemWrapper>
                <GridItemWrapper>
                  <DeviceConnectivityCell connectivity={device?.connectivity} />
                </GridItemWrapper>
                <GridItemWrapper>
                  <DeviceStateCell state={device?.state} />
                </GridItemWrapper>
              </Link>
            );
          })}
        </Stack>
        {totalCount === devices.length && !simple && (
          <Box width='100%' p={2} textAlign='center'>
            <Typography
              sx={{
                fontWeight: 500,
              }}
              variant='body1'
            >
              You have viewed all the devices that match your current filters
            </Typography>
            <Typography variant='body2'>
              Modify the selected filters to find more devices
            </Typography>
          </Box>
        )}
      </InfiniteScroll>
    </Stack>
  );
}

function GridItemWrapper({ children }: { children: ReactNode }) {
  return (
    <Box px={1.5} py={1.5} width='100%'>
      {children}
    </Box>
  );
}
