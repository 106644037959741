/* eslint-disable no-magic-numbers */
import { TempScaleEnum } from 'Constants/ConversionEnums';

export const convertTemperature = (
  tempCelsius: number,
  scale: TempScaleEnum | null | undefined
): number => {
  if (scale === TempScaleEnum.FAHRENHEIT) {
    // Convert Celsius to Fahrenheit: (°C × 9/5) + 32
    return (tempCelsius * 9) / 5 + 32;
  } else if (scale === TempScaleEnum.KELVIN) {
    // Convert Celsius to Kelvin: °C + 273.15
    return tempCelsius + 273.15;
  } else {
    // If the scale is already Celsius, return the temperature as is.
    return tempCelsius;
  }
};

export const convertTemperatureFromFahrenheit = (
  tempFahrenheit: number,
  scale: TempScaleEnum | null | undefined
): number => {
  if (scale === TempScaleEnum.CELSIUS) {
    // Convert  to Celsius: (°F - 32 × 5/9)
    return (tempFahrenheit - 32) * (5 / 9);
  } else if (scale === TempScaleEnum.KELVIN) {
    // Convert Fahrenheit to Kelvin: °C + 273.15
    const cel = (tempFahrenheit - 32) * (5 / 9);
    return cel + 273.15;
  } else {
    // If the scale is already Fahrenheit, return the temperature as is.
    return tempFahrenheit;
  }
};

export const convertUnitOfMeasurePreferenceToTempScaleEnum = (
  pref: string | undefined | null
): TempScaleEnum => {
  // we init tempScale to existing org pref if available
  // we default to Fahrenheit
  if (pref === 'imperial') {
    return TempScaleEnum.FAHRENHEIT;
  } else {
    return TempScaleEnum.CELSIUS;
  }
};
