/* eslint-disable no-magic-numbers */
import { useEffect, useMemo, useRef, useState } from 'react';

import { useLazyQuery } from '@apollo/client';
import { Alert, AlertTitle, Grid } from '@mui/material';
import { gql } from '__generated__/gql';
import { useSearchParams } from 'react-router-dom';

import type {
  GetDevicesMetricsDataQuery as GetDevicesMetricsDataResType,
  GetLocationMetricsDataQuery as LocationMetricQueryResType,
} from '__generated__/graphql';
import { TimeInterval as TimeIntervalEnum } from '__generated__/graphql';
import type { SelectedLocationType, SelectedOrgType } from 'Apollo/ApolloCache';
import { DateRangeEnum } from 'Components/SharedUI/DateRangePicker';
import { TempScaleEnum } from 'Constants/ConversionEnums';
import { MetricReportTypeEnum } from 'Constants/FloorsViewEnums';
import { convertTemperature } from 'Utils/conversionTools';
import {
  autoSelectedInterval as generateAutoSelectedInterval,
  manualIntervalOptions as generateManualIntervalOptions,
} from 'Utils/manageIntervals';
import { TimeStampGenerator } from 'Utils/timeStampGeneration';
import type { ChartDataListType } from './MetricLineChartContainer';
import MetricLineChartContainer from './MetricLineChartContainer';
import ReportsSideBarPanel from './ReportsSideBarPanel/ReportsSideBarPanel';

enum RequestedMetricDataTypeFields {
  INCLUDE_CO2 = 'includeCO2',
  INCLUDE_TEMP = 'includeTemp',
  INCLUDE_HUMIDITY = 'includeHumidity',
  INCLUDE_VOC = 'includeVOC',
  INCLUDE_IAQ = 'includeIAQ',
}

const milliSecondsInMin = 60000;
const refreshIntervaToTimeoutDurationMap = {
  EVERY_MINUTE: milliSecondsInMin,
  EVERY_FIVE_MIN: milliSecondsInMin * 5,
  EVERY_TEN_MIN: milliSecondsInMin * 10,
  EVERY_THIRTY_MIN: milliSecondsInMin * 30,
  EVERY_HOUR: milliSecondsInMin * 60,
};

const getRequestedMetricTypeField = (
  selectedMetric: MetricReportTypeEnum
): RequestedMetricDataTypeFields => {
  switch (selectedMetric) {
    case CO2:
      return RequestedMetricDataTypeFields.INCLUDE_CO2;
    case IAQ:
      return RequestedMetricDataTypeFields.INCLUDE_IAQ;
    case TEMP:
      return RequestedMetricDataTypeFields.INCLUDE_TEMP;
    case HUMIDITY:
      return RequestedMetricDataTypeFields.INCLUDE_HUMIDITY;
    case VOC:
      return RequestedMetricDataTypeFields.INCLUDE_VOC;
    default:
      return RequestedMetricDataTypeFields.INCLUDE_CO2;
  }
};
const timeStampGenerator = new TimeStampGenerator();

const getStartDateFromTimePeriod = (timePeriod: DateRangeEnum): string => {
  // Function should not be used to calc CUSTOM time time rage.
  switch (timePeriod) {
    case DateRangeEnum.PAST_HOUR:
      return timeStampGenerator.previous(1, 'hours');
    case DateRangeEnum.PAST_SIX_HOURS:
      return timeStampGenerator.previous(6, 'hours');
    case DateRangeEnum.PAST_DAY:
      return timeStampGenerator.previous(1, 'days');
    case DateRangeEnum.PAST_THREE_DAYS:
      return timeStampGenerator.previous(3, 'days');
    case DateRangeEnum.PAST_WEEK:
      return timeStampGenerator.previous(1, 'weeks');
    case DateRangeEnum.PAST_MONTH:
      return timeStampGenerator.previous(1, 'months');
    default:
      return timeStampGenerator.now();
  }
};

const { Hourly } = TimeIntervalEnum;
const { CO2, HUMIDITY, IAQ, TEMP, VOC } = MetricReportTypeEnum;

export type ChartConfigType = {
  title: string;
  dataSetLabel: string;
  timeInterval: TimeIntervalEnum;
  suggestedMinX?: string;
  suggestedMaxX?: string;
};

const GET_LOCATION_METRICS_QUERY = gql(`
  query GetLocationMetricsData(
    $accountId: ID!
    $locationIds: [ID!]!
    $timeFrame: TimeFrameInput!
    $includeCO2: Boolean = false
    $includeTemp: Boolean = false
    $includeHumidity: Boolean = false
    $includeVOC: Boolean = false
    $includeIAQ: Boolean = false
  ) {
    report {
      uvangel {
        getAggregatedMetricsForLocationsInTimeFrame(
          accountId: $accountId
          locationIds: $locationIds
          timeFrame: $timeFrame
        ) {
          location {
            ...LocationCore
          }
          data{
            x
            y {
              co2 @include(if: $includeCO2)
              temp @include(if: $includeTemp)
              humidity @include(if: $includeHumidity)
              voc @include(if: $includeVOC)
              iaq @include(if: $includeIAQ)
            }
          }
        }
      }
    }
}
`);

const GET_DEVICES_METRICS_QUERY = gql(`
  query GetDevicesMetricsData(
    $accountId: ID!
    $deviceIds: [String!]!
    $timeFrame: TimeFrameInput!
    $includeCO2: Boolean = false
    $includeTemp: Boolean = false
    $includeHumidity: Boolean = false
    $includeVOC: Boolean = false
    $includeIAQ: Boolean = false
  ) {
    report {
      uvangel {
        getAggregatedMetricsForDevicesInTimeFrame(
          accountId: $accountId, 
          deviceIds: $deviceIds, 
          timeFrame: $timeFrame) {
          device{
            nickname
            serialNumber
          }
          data {
            x
            y {
              co2 @include(if: $includeCO2)
              temp @include(if: $includeTemp)
              humidity @include(if: $includeHumidity)
              voc @include(if: $includeVOC)
              iaq @include(if: $includeIAQ)
            }
          }
        }
      }
    }
  }
`);

const getDataSetLabel = (tempScale: TempScaleEnum): string => {
  if (tempScale === TempScaleEnum.FAHRENHEIT) {
    return '°F';
  } else if (tempScale === TempScaleEnum.KELVIN) {
    return 'K';
  } else {
    return '°C';
  }
};

const getFormattedChartData = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  chartDataRaw: any,
  selectedMetric: MetricReportTypeEnum,
  tempScale: TempScaleEnum
) => {
  const formattedChartDataList = chartDataRaw.map((dataPoint) => {
    const locationLabel = dataPoint.location?.name;
    const deviceLabel = dataPoint?.device?.nickname || dataPoint?.device?.serialNumber;
    const label = locationLabel || deviceLabel;
    const dataList = dataPoint.data.map((dataPoint) => {
      if (!dataPoint) {
        return;
      }
      const { x: timeStamp, y: metricData } = dataPoint;
      if (metricData) {
        let targetMetricValue;
        if (selectedMetric === TEMP) {
          targetMetricValue = metricData.temp
            ? convertTemperature(metricData[selectedMetric] ?? 0, tempScale)
            : null;
        } else {
          targetMetricValue = metricData[selectedMetric];
        }
        if (typeof targetMetricValue === 'number') {
          targetMetricValue = parseFloat(targetMetricValue.toFixed(5));
        }
        const metricType = selectedMetric === TEMP ? tempScale : selectedMetric;
        return { timeStamp, value: targetMetricValue, metricType };
      }
    });

    return { label, data: dataList };
  }) as ChartDataListType;
  return formattedChartDataList;
};

type Props = {
  selectedLocation: SelectedLocationType;
  selectedOrg: SelectedOrgType;
  alertHighlight?: {
    startDate: string;
    endDate: string;
  };
};

export default function ReportsContainer({ selectedLocation, selectedOrg, alertHighlight }: Props) {
  const timeoutRef = useRef<null | number>(null);
  const selectedOrgID = selectedOrg?.id ?? '';
  const selectedLocationID = selectedLocation?.id;
  const [searchParams, setSearchParams] = useSearchParams();
  const [formattedChartDataList, setFormattedChartDataList] = useState<ChartDataListType>([]);
  const [chartConfig, setChartConfig] = useState<ChartConfigType>({
    title: 'CO2',
    dataSetLabel: 'ppm',
    timeInterval: Hourly,
  });

  const [refreshInterval, setRefreshInterval] = useState<number | ''>('');

  const selectedMetric = searchParams.get('selectedMetric') as MetricReportTypeEnum;
  const selectedIntervalFromUrl = searchParams.get('selectedInterval') as TimeIntervalEnum | 'Auto';
  const selectedTimePeriod = searchParams.get('selectedTimePeriod') as DateRangeEnum;
  const startDate = searchParams.get('startDate') as string;
  const endDate = searchParams.get('endDate') as string;
  const selectedInterval: TimeIntervalEnum = useMemo(() => {
    if (selectedIntervalFromUrl !== 'Auto') {
      return selectedIntervalFromUrl as TimeIntervalEnum;
    }
    return generateAutoSelectedInterval(startDate, endDate);
  }, [selectedIntervalFromUrl, startDate, endDate]);
  const tempScale = searchParams.get('tempScale') as TempScaleEnum;
  const subLocationsListRaw = searchParams.get('selectedSubLocations');
  const locationIds = !subLocationsListRaw
    ? [selectedLocation?.id]
    : (JSON.parse(subLocationsListRaw) as string[]);
  const selectedDevicesRaw = searchParams.get('selectedDevices');
  const selectedDevices = !selectedDevicesRaw ? [] : JSON.parse(selectedDevicesRaw);
  const isDevicesQueryBeingRequested = selectedDevices.length > 0;

  const metricsFields = {
    [CO2]: { title: 'CO2', dataSetLabel: 'ppm' },
    [HUMIDITY]: { title: 'Relative Humidity', dataSetLabel: '%' },
    [IAQ]: { title: 'IAQ', dataSetLabel: 'iaq' },
    [TEMP]: { title: 'Temperature', dataSetLabel: getDataSetLabel(tempScale) },
    [VOC]: { title: 'VOC', dataSetLabel: 'ppm' },
  };

  const onLocationMetricDataComplete = (res: LocationMetricQueryResType) => {
    if (isDevicesQueryBeingRequested) {
      // Apollo useLazyQuery has a bug/quirk where it sometimes refetches even without
      // being explicitly called. This is to prevent locationData from being loaded on top
      // the explicitly requested device data.
      return;
    }
    if (refreshInterval !== '') {
      setRefetchTimer();
    }
    const locationsMetricDataListRaw =
      res?.report?.uvangel?.getAggregatedMetricsForLocationsInTimeFrame ?? [];
    const formattedChartDataList = getFormattedChartData(
      locationsMetricDataListRaw,
      selectedMetric,
      tempScale
    );

    const { dataSetLabel, title } = metricsFields[selectedMetric];
    setChartConfig({
      dataSetLabel,
      title,
      timeInterval: selectedInterval,
      suggestedMinX: startDate,
      suggestedMaxX: endDate,
    });
    setFormattedChartDataList(formattedChartDataList);
  };

  const [getLocationMetricsData, { loading: isLocationMetricsLoading, error: queryError1 }] =
    useLazyQuery(GET_LOCATION_METRICS_QUERY, {
      onCompleted: onLocationMetricDataComplete,
      notifyOnNetworkStatusChange: true,
    });

  const fetchLocationData = (payload) => {
    getLocationMetricsData(payload);
  };

  const fetchDeviceData = (payload) => {
    getDevicesMetrics(payload);
  };

  useEffect(() => {
    // This useEffect initializes the the data for the report
    const requestedMetricTypeField = getRequestedMetricTypeField(selectedMetric);
    if (isDevicesQueryBeingRequested) {
      fetchDeviceData({
        variables: {
          accountId: selectedOrgID,
          deviceIds: selectedDevices,
          timeFrame: {
            startDate,
            endDate,
            timeInterval: selectedInterval,
          },
          [requestedMetricTypeField]: true,
        },
      });
    } else {
      fetchLocationData({
        variables: {
          accountId: selectedOrgID,
          locationIds,
          timeFrame: {
            startDate,
            endDate,
            timeInterval: selectedInterval,
          },
          [requestedMetricTypeField]: true,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  const onDevicesMetricDataComplete = (res: GetDevicesMetricsDataResType) => {
    if (!isDevicesQueryBeingRequested) {
      // Apollo useLazyQuery has a bug/quirk where it sometimes refetches even without
      // being explicitly called. This is to prevent device data from being loaded on top
      // the explicitly requested location data.
      return;
    }
    if (refreshInterval !== '') {
      setRefetchTimer();
    }
    const devicesMetricDataListRaw =
      res?.report?.uvangel?.getAggregatedMetricsForDevicesInTimeFrame ?? [];
    const formattedChartDataList = getFormattedChartData(
      devicesMetricDataListRaw,
      selectedMetric,
      tempScale
    );
    setFormattedChartDataList(formattedChartDataList);
    const { dataSetLabel, title } = metricsFields[selectedMetric];
    setChartConfig({
      dataSetLabel,
      title,
      timeInterval: selectedInterval,
      suggestedMinX: startDate,
      suggestedMaxX: endDate,
    });
  };

  const [getDevicesMetrics, { loading: isDevicesMetricsLoading, error: queryError2 }] =
    useLazyQuery(GET_DEVICES_METRICS_QUERY, {
      onCompleted: onDevicesMetricDataComplete,
    });

  const isLoading = isDevicesMetricsLoading || isLocationMetricsLoading;

  const error = queryError1 ?? queryError2;

  const handleRefetchQuery = () => {
    const requestedMetricTypeField = getRequestedMetricTypeField(selectedMetric);
    let updatedStartDate = startDate;
    let updatedEndDate = endDate;
    if (selectedTimePeriod !== DateRangeEnum.CUSTOM) {
      // we re-calculate the start and end date at the time of search
      // unless a custom time range was provided.
      updatedStartDate = getStartDateFromTimePeriod(selectedTimePeriod);
      updatedEndDate = timeStampGenerator.now();
    }
    if (isDevicesQueryBeingRequested) {
      fetchDeviceData({
        variables: {
          accountId: selectedOrgID,
          deviceIds: selectedDevices,
          timeFrame: {
            startDate: updatedStartDate,
            endDate: updatedEndDate,
            timeInterval: selectedInterval,
          },
          [requestedMetricTypeField]: true,
        },
      });
    } else {
      fetchLocationData({
        variables: {
          accountId: selectedOrgID,
          locationIds,
          timeFrame: {
            startDate: updatedStartDate,
            endDate: updatedEndDate,
            timeInterval: selectedInterval,
          },
          [requestedMetricTypeField]: true,
        },
      });
    }
  };

  const cancelRefetch = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  };

  const setRefetchTimer = () => {
    const timeOutDuration = refreshIntervaToTimeoutDurationMap[refreshInterval];
    if (timeoutRef.current) {
      cancelRefetch();
    }
    timeoutRef.current = window.setTimeout(() => {
      handleRefetchQuery();
    }, timeOutDuration);
  };

  useEffect(() => {
    // clears auto refetch timeout on un-mount
    return () => {
      if (timeoutRef.current) {
        cancelRefetch();
      }
    };
  }, []);

  useEffect(() => {
    if (!selectedInterval) return;
    const allowedIntervals = generateManualIntervalOptions(startDate, endDate);
    const currentInterval = allowedIntervals.includes(selectedInterval)
      ? selectedInterval
      : allowedIntervals[0];

    if (currentInterval === selectedInterval) return;

    setSearchParams((prev) => ({
      ...Object.fromEntries(prev.entries()),
      selectedInterval: currentInterval,
    }));
  }, [selectedInterval, startDate, endDate, setSearchParams]);

  const handleSetRefreshInterval = (interval: number) => {
    setRefreshInterval(interval);
  };
  const resetRefreshInteval = () => {
    setRefreshInterval('');
  };

  return (
    <Grid container spacing={2} width='100%'>
      <Grid item xs={12} sm={12} md={12} lg={9} xl={9}>
        {error ? (
          <Alert severity='warning'>
            <AlertTitle>
              An error occurred. Try selecting a new location or requesting data over a smaller
              range.
            </AlertTitle>
            Please refresh the page or change filters
          </Alert>
        ) : (
          <MetricLineChartContainer
            chartConfig={chartConfig}
            chartDataList={formattedChartDataList}
            isLoading={isLoading}
            alertHighlight={alertHighlight}
          />
        )}
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
        <ReportsSideBarPanel
          isLoading={isLoading}
          handleRefetchQuery={handleRefetchQuery}
          selectedLocationID={selectedLocationID}
          selectedOrgID={selectedOrgID}
          cancelRefetch={cancelRefetch}
          resetRefreshInteval={resetRefreshInteval}
          handleSetRefreshInterval={handleSetRefreshInterval}
          refreshInterval={refreshInterval}
        />
      </Grid>
    </Grid>
  );
}
