import React, { useState } from 'react';
import {
  AppBar,
  Button,
  Box,
  Divider,
  Typography,
  Toolbar,
  Link,
  SvgIcon,
  Menu,
  MenuItem,
  CircularProgress,
} from '@mui/material';
import { ReactComponent as UVALightLogo } from 'assets/uva_light_logo.svg';
import { AccountCircle as AccountCircleIcon } from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu';
import { useAuthenticator } from '@aws-amplify/ui-react';
import PalletteModeCard from './Cards/PaletteModeCard';
import { Link as RouterLink } from 'react-router-dom';
import RoutePaths from 'Constants/RoutePaths';
import { useSignOut } from 'Hooks/useSignOut';
import UserAvatar from 'Components/SharedUI/UserAvatar';
import RouteStatusEnum from 'Constants/RouteStatusEnum';

const { STATUS_AUTHENTICATED } = RouteStatusEnum;

type LinkConfigType = {
  ariaLabel: string;
  navigationCallback: () => void;
  label: string;
  path: RoutePaths | string;
};

type LinkConfigListType = Array<LinkConfigType>;

const { PATH_LOGIN, PATH_HOME } = RoutePaths;

const EXTERNAL_DOC_PATH = 'https://uvangel-cloud-api.readme.io/';

const linksConfigList: LinkConfigListType = [
  {
    ariaLabel: 'Home',
    navigationCallback: () => (window.location.href = PATH_HOME),
    label: ' Home',
    path: PATH_HOME,
  },
  {
    ariaLabel: 'Documentation',
    navigationCallback: () => window.open(EXTERNAL_DOC_PATH, '_blank'),
    label: 'Docs',
    path: EXTERNAL_DOC_PATH,
  },
];

export default function NavBar() {
  const isLoading = false;
  const { authStatus } = useAuthenticator((context) => [context.route]);
  const [userSettingsAnchorElement, setUserSettingsAnchorElement] = useState<null | HTMLElement>(
    null
  );
  const isUserSettingsOpen = Boolean(userSettingsAnchorElement);
  const [smallLinkMenuAnchorElement, setSmallLinkMenuAnchorElement] = useState<null | HTMLElement>(
    null
  );
  const isSmallLinkMenuOpen = Boolean(smallLinkMenuAnchorElement);
  const handleSignOut = useSignOut();
  const handleSmallLinkMenuClick = (event: React.MouseEvent<HTMLElement>): void => {
    if (smallLinkMenuAnchorElement) {
      setSmallLinkMenuAnchorElement(null);
    } else {
      setSmallLinkMenuAnchorElement(event.currentTarget);
    }
  };

  const handleUserSettingsMenuClick = (event: React.MouseEvent<HTMLElement>): void => {
    if (userSettingsAnchorElement) {
      setUserSettingsAnchorElement(null);
    } else {
      setUserSettingsAnchorElement(event.currentTarget);
    }
  };

  const currentUserInfoString = localStorage.getItem('CURRENT_USER_INFO');
  let firstName = '';
  let lastName = '';
  if (currentUserInfoString) {
    const currentUser = JSON.parse(currentUserInfoString);
    firstName = currentUser?.firstName;
    lastName = currentUser?.lastName;
  }

  return (
    <AppBar color='primary' position='sticky'>
      <Toolbar disableGutters sx={{ display: 'flex', justifyContent: 'space-between' }}>
        {/* Links menu container for smaller screen sizes */}
        <Box sx={{ display: { sm: 'flex', md: 'none' } }}>
          <Button color='inherit' onClick={handleSmallLinkMenuClick}>
            <MenuIcon />
          </Button>
          <Menu
            anchorEl={smallLinkMenuAnchorElement}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            open={isSmallLinkMenuOpen}
            onClick={handleSmallLinkMenuClick}
          >
            {linksConfigList.map((config) => {
              return (
                <MenuItem
                  onClick={config.navigationCallback}
                  aria-label={config.ariaLabel}
                  color='inherit'
                  key={config.label}
                >
                  <Typography margin={1}>{config.label}</Typography>
                </MenuItem>
              );
            })}
          </Menu>
        </Box>
        <Box display='flex' alignItems='center' marginLeft={2}>
          <SvgIcon fontSize='large'>
            <UVALightLogo />
          </SvgIcon>
          <Typography marginLeft={1} variant='h5'>
            UV Angel Cloud™
          </Typography>
          {/* Links container for larger screen sizes */}
          <Box marginLeft={3} sx={{ display: { xs: 'none', md: 'flex' } }}>
            {linksConfigList.map((config) => {
              return (
                <Link
                  aria-label={config.ariaLabel}
                  color='inherit'
                  component={RouterLink}
                  key={config.label}
                  marginRight={1}
                  to={config.path}
                  target={config.path === EXTERNAL_DOC_PATH ? '_blank' : ''}
                  underline='hover'
                >
                  {config.label}
                </Link>
              );
            })}
          </Box>
        </Box>
        <>
          {authStatus === STATUS_AUTHENTICATED ? (
            <>
              <Button
                aria-controls='menu-appbar'
                aria-haspopup='true'
                aria-label='User settings menu'
                color='inherit'
                disabled={isLoading}
                onClick={handleUserSettingsMenuClick}
                size='large'
              >
                {isLoading ? (
                  <CircularProgress color='inherit' size={20} />
                ) : (
                  <UserAvatar height={33} width={33} firstName={firstName} lastName={lastName} />
                )}
              </Button>
              <Menu
                anchorEl={userSettingsAnchorElement}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                open={isUserSettingsOpen}
                onClick={handleUserSettingsMenuClick}
              >
                <MenuItem
                  disableRipple
                  onClick={(e) => {
                    const target = e.target as HTMLInputElement;
                    if (target.id !== 'palleteModeButton') {
                      e.stopPropagation();
                    }
                  }}
                  sx={{
                    '&:hover': { backgroundColor: 'transparent' },
                  }}
                >
                  <PalletteModeCard />
                </MenuItem>
                <Divider />
                <MenuItem onClick={handleSignOut}>
                  <Typography>Log out</Typography>
                </MenuItem>
              </Menu>
            </>
          ) : (
            <>
              <Button
                aria-controls='menu-appbar'
                aria-haspopup='true'
                aria-label='User settings menu'
                color='inherit'
                disabled={isLoading}
                onClick={handleUserSettingsMenuClick}
                size='large'
              >
                {isLoading ? (
                  <CircularProgress color='inherit' size={20} />
                ) : (
                  <AccountCircleIcon sx={{ height: 33, width: 33 }} />
                )}
              </Button>
              <Menu
                anchorEl={userSettingsAnchorElement}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                open={isUserSettingsOpen}
                onClick={handleUserSettingsMenuClick}
              >
                <MenuItem
                  disableRipple
                  onClick={(e) => {
                    const target = e.target as HTMLInputElement;
                    if (target.id !== 'palleteModeButton') {
                      e.stopPropagation();
                    }
                  }}
                  sx={{
                    '&:hover': { backgroundColor: 'transparent' },
                  }}
                >
                  <PalletteModeCard />
                </MenuItem>
                <Divider />
                <MenuItem component={RouterLink} to={PATH_LOGIN}>
                  <Typography>Log in</Typography>
                </MenuItem>
              </Menu>
            </>
          )}
        </>
      </Toolbar>
    </AppBar>
  );
}
