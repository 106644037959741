import { Avatar, Typography } from '@mui/material';

type Props = {
  backgroundColor?: string;
  firstName: string;
  lastName: string;
  height?: number;
  width?: number;
  fontSize?: number;
};

export default function UserAvatar({
  backgroundColor,
  height = 30,
  width = 30,
  fontSize = 12,
  firstName,
  lastName,
}: Props) {
  const firstNamePrefix = firstName ? firstName[0] : '';
  const lastNamePrefix = lastName ? lastName[0] : '';
  return (
    <Avatar sx={{ height, width, backgroundColor }}>
      <Typography fontSize={fontSize}>{firstNamePrefix + lastNamePrefix}</Typography>
    </Avatar>
  );
}
