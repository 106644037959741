/* eslint-disable no-magic-numbers */
import { useCallback, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Box,
  Button,
  Typography,
  CircularProgress,
  FormControl,
  InputBase,
  FormHelperText,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { gql, useMutation, useReactiveVar } from '@apollo/client';
import { useToast, ToastNotificationSeverityTypeEnum } from 'Providers/ToastProvider';
import { selectedOrgVar } from 'Apollo/ApolloCache';
import { deviceMap } from 'Components/AuthedPages/DevicesListPage/PageViews/DevicesListFilters';

interface Props {
  onModalClose: () => void;
  modalProps?: unknown;
}

type UnregisterDeviceModalProps = {
  serialNumber?: string | null;
  type?: string | null;
  onSuccess: () => void;
};

const UNREGISTER_DEVICE = gql`
  mutation UnregisterDevice($accountId: ID!, $serialNumber: String!) {
    unregisterDevice(accountId: $accountId, serialNumber: $serialNumber)
  }
`;

export default function EditDeviceModal({ onModalClose, modalProps }: Props) {
  const { serialNumber, type, onSuccess } = modalProps as UnregisterDeviceModalProps;
  const { dispatchToast } = useToast();
  const selectedOrg = useReactiveVar(selectedOrgVar);

  const [unregisterSafe, setUnregisterSafe] = useState<string>();

  const [unregisterDevice, { loading }] = useMutation(UNREGISTER_DEVICE);
  const unregisterHandler = useCallback(() => {
    if (!selectedOrg?.id || !serialNumber) {
      dispatchToast({
        severity: ToastNotificationSeverityTypeEnum.WARNING,
        title: 'Unexpected error occurred',
        message: 'Please contact support',
      });
      return;
    }
    unregisterDevice({
      variables: {
        accountId: selectedOrg?.id,
        serialNumber,
      },
    })
      .then((d) => {
        if (d.errors) {
          throw new Error('Unregister Device Failed');
        }
        dispatchToast({
          severity: ToastNotificationSeverityTypeEnum.SUCCESS,
          title: 'Device Unregistered Successfully',
        });
        onSuccess();
      })
      .catch((e) => {
        dispatchToast({
          severity: ToastNotificationSeverityTypeEnum.ERROR,
          title: 'Could not unregister device',
          message: e.message,
        });
      })
      .finally(() => {
        onModalClose();
      });
  }, [serialNumber, selectedOrg, dispatchToast, onSuccess, unregisterDevice, onModalClose]);
  return (
    <Dialog
      open={true}
      onClose={() => {
        onModalClose();
      }}
      fullWidth
      maxWidth='sm'
    >
      <DialogTitle>Unregister Device</DialogTitle>
      <IconButton
        onClick={onModalClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Typography variant='body1'>
          Are you sure you want to unregister the following{' '}
          <b>{deviceMap[type ?? '']?.value ?? ''}</b> device?
        </Typography>
        <Typography variant='subtitle1'>{serialNumber}</Typography>
        <FormControl fullWidth>
          <InputBase
            sx={{
              mt: 1,
              width: '100%',
              bgcolor: (theme) =>
                theme.palette.mode === 'light' ? theme.palette.grey[200] : theme.palette.grey[700],
              padding: 1,
              borderRadius: 1,
            }}
            onChange={(e) => setUnregisterSafe(e.target.value)}
            placeholder='unregister'
            value={unregisterSafe}
          />
          <FormHelperText sx={{ ml: 0 }}>
            Please type <code>unregister</code>
          </FormHelperText>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Box width='100%' gap={1} display='flex' justifyContent='flex-end' alignItems='center'>
          <Button
            color='primary'
            sx={{ fontWeight: 500 }}
            onClick={onModalClose}
            disabled={loading}
          >
            Cancel
          </Button>
          <Button
            disabled={loading || unregisterSafe !== 'unregister'}
            color='error'
            sx={{ fontWeight: 500 }}
            onClick={unregisterHandler}
            endIcon={loading && <CircularProgress color='inherit' size={16} />}
          >
            Yes
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}
