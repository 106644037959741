import React, { useState } from 'react';
import { gql } from '__generated__/gql';
import { useMutation } from '@apollo/client';
import {
  Box,
  CircularProgress,
  Button,
  Typography,
  Card,
  CardContent,
  Grid,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { useToast, ToastNotificationSeverityTypeEnum } from 'Providers/ToastProvider';
import { OrgIntegrationsPanelViewEnum } from 'Constants/OrgSettingsEnums';
import RoutePaths from 'Constants/RoutePaths';

import type { SelectedOrgType } from 'Apollo/ApolloCache';
import type { AccountIntegrationType } from '../OrgIntegrationsPage';
import type { GetSelectedOrgIntegrationsDataQuery as IntegrationsDataType } from '__generated__/graphql';

const { PATH_ORGANIZATION_INTEGRATIONS } = RoutePaths;

const REMOVE_ACCOUNT_INTEGRATION_MUTATION = gql(`
  mutation RemoveAccountIntegration($accountIntegrationId: ID!) {
    removeAccountIntegration(accountIntegrationId: $accountIntegrationId)
  }
`);

type CardTextRowProps = {
  label: string;
  content: string | null;
};

function CardTextRow({ label, content }: CardTextRowProps) {
  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));
  if (!content) {
    return null;
  }
  return (
    <Box display='flex' flexDirection={isMediumScreen ? 'column' : 'row'} gap={1}>
      <Typography fontWeight='bold'>{label}</Typography>
      <Typography marginBottom={0.5}>{content}</Typography>
    </Box>
  );
}

type Props = {
  data: IntegrationsDataType | undefined;
  selectedOrg: SelectedOrgType | null;
  panelView: OrgIntegrationsPanelViewEnum;
  setTargetIntegration: (arg: AccountIntegrationType) => void;
  userHasOrgWritePermission: boolean;
};

export default function IntegrationsListView({
  data,
  setTargetIntegration,
  userHasOrgWritePermission,
}: Props) {
  const [RemoveAccountIntegration] = useMutation(REMOVE_ACCOUNT_INTEGRATION_MUTATION);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [deletingButtonId, setDeletingButtonId] = useState<string | undefined>(undefined);
  const integrationsData = data?.account?.accountIntegrations ?? [];
  const navigate = useNavigate();
  const theme = useTheme();
  const { dispatchToast } = useToast();
  const handleDelete = async (targetID = '', provider) => {
    try {
      setIsDisabled(true);
      await RemoveAccountIntegration({
        variables: {
          accountIntegrationId: targetID,
        },
        update: (cache) => {
          const account = data?.account ?? {};
          cache.modify({
            id: cache.identify(account),
            fields: {
              accountIntegrations(existingIntegrations, { readField }) {
                return existingIntegrations.filter(
                  (existingIntegration) => targetID !== readField('id', existingIntegration)
                );
              },
            },
          });
        },
      });
      const notifConfig = {
        severity: ToastNotificationSeverityTypeEnum.INFO,
        title: 'Integration Removed',
        message: `You've successfully an integration for the provider: ${provider}`,
      };
      dispatchToast(notifConfig);
      setIsDisabled(false);
    } catch (error) {
      const notifConfig = {
        severity: ToastNotificationSeverityTypeEnum.ERROR,
        title: 'Error Deleting Integration',
        message: `Error deleting integration for provider: ${provider}. Please try again`,
      };
      dispatchToast(notifConfig);
      console.error('ERROR: REMOVE_ACCOUNT_INTEGRATION_MUTATION - ', error);
      setIsDisabled(false);
    }
    setDeletingButtonId(undefined);
  };

  function IntegrationsListRenderer() {
    if (integrationsData.length === 0) {
      return (
        <Typography fontWeight='bold' color='primary.secondary'>
          There currently are no integrations available for this organization
        </Typography>
      );
    }
    return (
      <Box display='flex' flexDirection='column' gap={2}>
        {data ? (
          integrationsData?.map((integration) => {
            const { description, id, provider, expirationTimestamp } = integration ?? {};
            const cardContentList = [
              { label: 'Description:', content: description ?? null },
              { label: 'Expiration:', content: expirationTimestamp ?? 'n/a' },
            ];
            return (
              <Card key={id} elevation={0} variant='outlined'>
                <CardContent>
                  <Grid container>
                    <Grid item container xs={10}>
                      <Typography color='primary.main'>{provider}</Typography>
                    </Grid>
                    <Grid item container justifyContent='flex-end' xs={2}>
                      {userHasOrgWritePermission ? (
                        <Box display='flex' flexDirection='row' gap={1}>
                          <Button
                            disabled={isDisabled}
                            onClick={() => {
                              setTargetIntegration(integration);
                              navigate(PATH_ORGANIZATION_INTEGRATIONS, {
                                state: {
                                  panelView: OrgIntegrationsPanelViewEnum.UPDATE_INTEGRATIONS_VIEW,
                                },
                              });
                            }}
                            sx={{
                              height: 30,
                              width: 90,
                              backgroundColor: theme.palette.info.main,
                              color: theme.palette.info.contrastText,
                            }}
                            variant='contained'
                          >
                            Update
                          </Button>
                          <Button
                            disabled={isDisabled}
                            onClick={() => {
                              setDeletingButtonId(id);
                              handleDelete(id, provider);
                            }}
                            sx={{
                              height: 30,
                              width: 90,
                              backgroundColor: theme.palette.error.main,
                              color: theme.palette.warning.contrastText,
                            }}
                            variant='contained'
                          >
                            {deletingButtonId === id ? <CircularProgress size={20} /> : 'Delete'}
                          </Button>
                        </Box>
                      ) : null}
                    </Grid>
                    <Grid item justifyContent='flex-end' xs={6}>
                      {cardContentList.map((row) => {
                        return (
                          <CardTextRow key={row.label} label={row.label} content={row.content} />
                        );
                      })}
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            );
          })
        ) : (
          <CircularProgress sx={{ alignSelf: 'center', marginTop: 10 }} size={100} />
        )}
      </Box>
    );
  }

  return (
    <Box display='flex' flexDirection='column' gap={2}>
      {data ? (
        <IntegrationsListRenderer />
      ) : (
        <CircularProgress sx={{ alignSelf: 'center', marginTop: 10 }} size={100} />
      )}
    </Box>
  );
}
