/* eslint-disable no-magic-numbers */
import { createTheme } from '@mui/material';
import type {
  Components,
  PaletteMode as PaletteModeType,
  Theme as ThemeType,
  SimplePaletteColorOptions,
} from '@mui/material';

interface PalleteExtensions {
  sidebar: SimplePaletteColorOptions;
  textPrimary: SimplePaletteColorOptions;
  textSecondary: SimplePaletteColorOptions;
}
declare module '@mui/material/styles/createPalette' {
  interface Palette extends PalleteExtensions {}

  interface PaletteOptions extends Partial<PalleteExtensions> {}
}

const { palette } = createTheme();
// here we store the parent colorScheme of the app.
// This scheme is used to feed the light and partially
// the dark color palettes.
const baseColorScheme = {
  background: {
    default: '#f1f1f1',
    paper: '#FAFBFC',
  },
  sidebar: palette.augmentColor({
    color: { main: '#14264b', contrastText: '#fff' },
    name: 'sidebar',
  }),
  textPrimary: palette.augmentColor({
    color: { main: '#1e1e1e' },
    name: 'textPrimary',
  }),
  textSecondary: palette.augmentColor({
    color: { main: '#464747' },
    name: 'textSecondary',
  }),
  primary: palette.augmentColor({
    color: { main: '#0093c8', contrastText: '#fff' },
    name: 'primary',
  }),
  success: palette.augmentColor({
    color: { main: '#329c74', contrastText: '#fff' },
    name: 'success',
  }),
  error: palette.augmentColor({
    color: { main: '#ff6d62', contrastText: '#fff' },
    name: 'error',
  }),
  info: palette.augmentColor({
    color: { main: '#00bcd4', contrastText: '#fff' },
    name: 'info',
  }),
};
const lightMode: PaletteModeType = 'light';
const lightPalette = {
  mode: lightMode,
  ...baseColorScheme,
};

const { primary, success, info, error } = baseColorScheme;
const darkMode: PaletteModeType = 'dark';
const darkPalette = {
  mode: darkMode,
  background: {
    default: '#101214',
    paper: '#161A1D',
  },
  sidebar: palette.augmentColor({
    color: { main: '#161A1D' },
    name: 'sidebar',
  }),
  textPrimary: palette.augmentColor({
    color: { main: '#fafbfc' },
    name: 'textPrimary',
  }),
  textSecondary: palette.augmentColor({
    color: { main: '#8696A7' },
    name: 'textSecondary',
  }),
  primary: palette.augmentColor({
    color: { main: primary.light },
    name: 'primary',
  }),
  info: palette.augmentColor({
    color: { main: info.light },
    name: 'info',
  }),
  success: palette.augmentColor({
    color: { main: success.light },
    name: 'success',
  }),
  error: palette.augmentColor({
    color: { main: error.light },
    name: 'error',
  }),
};

const typography = {
  fontFamily: ['avenir', 'sans-serif'].join(','),
};

const DEFAULT_ELEVATION = 2;

const components: Components = {
  MuiPaper: {
    defaultProps: {
      elevation: DEFAULT_ELEVATION,
    },
  },
  MuiCard: {
    defaultProps: {
      elevation: DEFAULT_ELEVATION,
    },
  },
};

export const getThemeConfig = (paletteMode: PaletteModeType = 'light'): ThemeType => {
  const palette = paletteMode === 'light' ? lightPalette : darkPalette;

  return createTheme({
    typography,
    palette,
    components,
  });
};
