import { Box, IconButton, CircularProgress } from '@mui/material';
import { useMutation } from '@apollo/client';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { gql } from '__generated__/gql';
import { useToast, ToastNotificationSeverityTypeEnum } from 'Providers/ToastProvider';
import { GET_CURRENT_ORG_USER_LIST_QUERY } from '../OrgUsersPage';
import { useModal, ModalNotificationTypeEnum } from 'Providers/ModalProvider';
import { RoleEnum } from 'Constants/OrgSettingsEnums';
import type { SelectedOrgType } from 'Apollo/ApolloCache';
import { Maybe } from '__generated__/graphql';

const REMOVE_USER_FROM_ORG_MUTATION = gql(`
  mutation UnregisterUser($userId: ID!, $accountId: ID!) {
    unregisterUser(userId: $userId, accountId: $accountId) {
      id
      firstName
      lastName
    }
  }
`);

type Props = {
  firstName: string;
  lastName: string;
  userID: string;
  selectedOrg: SelectedOrgType;
  isRemovingUser: boolean;
  roleType: RoleEnum;
  rootLocationId?: Maybe<string>;
  setIsRemovingUser: (arg: boolean) => void;
};

export default function UserActionCellCard({
  userID,
  rootLocationId,
  selectedOrg,
  firstName,
  lastName,
  isRemovingUser,
  setIsRemovingUser,
  roleType,
}: Props) {
  const [removeUserFromOrg, { loading: isMutationInFlight }] = useMutation(
    REMOVE_USER_FROM_ORG_MUTATION
  );
  const { dispatchModal } = useModal();
  const { dispatchToast } = useToast();
  const { name: orgName } = selectedOrg ?? {};

  const handleEditUser = () => {
    dispatchModal({
      type: ModalNotificationTypeEnum.EDIT_USER_DETAILS_MODAL,
      modalProps: {
        userID,
        firstName,
        lastName,
        roleType,
        selectedOrg,
        rootLocationId,
      },
    });
  };

  const handleDeleteUser = async () => {
    setIsRemovingUser(true);
    try {
      await removeUserFromOrg({
        variables: {
          userId: userID,
          accountId: selectedOrg?.id ?? '',
        },
        update: (cache) => {
          // we obtain latest version of the data from Apollo cache
          const currentCachedData = cache.readQuery({
            query: GET_CURRENT_ORG_USER_LIST_QUERY,
            variables: { accountId: selectedOrg?.id ?? '' },
          });

          const filteredUsersList = currentCachedData?.account?.users?.filter(
            (user) => user?.id !== userID
          );
          const updatedData = {
            ...currentCachedData,
            account: {
              ...currentCachedData?.account,
              id: currentCachedData?.account?.id ?? '',
              users: filteredUsersList,
            },
          };
          cache.writeQuery({
            query: GET_CURRENT_ORG_USER_LIST_QUERY,
            data: updatedData,
            variables: { accountId: selectedOrg?.id ?? '' },
          });
        },
      });
      const fullUserName = firstName + ' ' + lastName;
      const notifConfig = {
        severity: ToastNotificationSeverityTypeEnum.INFO,
        title: 'User Removed:',
        message: `You've sucessfully ${fullUserName} removed from ${orgName}`,
      };
      dispatchToast(notifConfig);
    } catch (error) {
      const notifConfig = {
        severity: ToastNotificationSeverityTypeEnum.ERROR,
        title: 'Error Removing User:',
        message: 'Something went wrong. Please try again',
      };
      dispatchToast(notifConfig);
      console.error(error);
    }
    setIsRemovingUser(false);
  };
  return (
    <Box display='flex' flexDirection='row' alignItems='center' justifyContent='center'>
      <IconButton disabled={isRemovingUser} color='inherit' onClick={handleDeleteUser}>
        {isMutationInFlight ? <CircularProgress size={20} /> : <DeleteOutlineIcon />}
      </IconButton>
      <IconButton color='inherit' onClick={handleEditUser}>
        <EditIcon />
      </IconButton>
    </Box>
  );
}
