/* eslint-disable no-nested-ternary */
import { CircularProgress, Grid, Stack } from '@mui/material';
import { useOccupancyContext } from '../context';
import OccupancyFilters from './components/OccupancyFilters';
import OccupancyGraphView from './components/OccupancyGraphView';
import OccupancyHeatmap from './components/OccupancyHeatmap';
import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import moment from 'moment-timezone';
export function OccupancyGraphAndFiltersView() {
  const { occupancyGraphLoading, currentInterval } = useOccupancyContext();
  const [params] = useSearchParams();
  
  const timePeriod = useMemo((): { startDate: Date, endDate: Date } | undefined => {
    const endDate = params.get('end-date');
    const startDate = params.get('start-date');
    if (!endDate || !startDate) {
      return;
    }
    const endDateM = moment(endDate);
    const startDateM = moment(startDate);

    return {
      startDate: startDateM.toDate(),
      endDate: endDateM.toDate()
    };
  }, [params]);

  const timePeriodIsGreaterThanWeek = timePeriod ? (moment(timePeriod.endDate).diff(moment(timePeriod.startDate), 'days') >= 7) : false;
  
  // eslint-disable-next-line no-magic-numbers
  const mainItemGridSize = timePeriodIsGreaterThanWeek ? 9 : 12;
  // eslint-disable-next-line no-magic-numbers
  const sideItemGridSize = timePeriodIsGreaterThanWeek ? 3 : 12;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12} lg={mainItemGridSize} xl={mainItemGridSize} order={{lg: 2, md: 1}}>
        {occupancyGraphLoading ? (
          <Stack alignItems="center">
            <CircularProgress />
          </Stack>
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={9} xl={9} order={{xl: 1, lg: 1, md:2, sm: 2, xs: 2}}>
              <OccupancyGraphView selectedInterval={currentInterval} />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={3} xl={3} order={{xl: 2, lg: 2, md:1, sm: 1, xs: 1}}>
              <OccupancyFilters timeInterval={currentInterval} />
            </Grid>
          </Grid>
        )}
      </Grid>
      {timePeriodIsGreaterThanWeek ?
        <Grid item xs={12} sm={12} md={12} lg={sideItemGridSize} xl={sideItemGridSize} order={{lg: 1, md: 2}}>
          {occupancyGraphLoading ? (
            <Stack alignItems="center">
              <CircularProgress />
            </Stack>
          ) : (
            <OccupancyHeatmap gridConfig={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }} />
          )}
        </Grid> : undefined
      }
    </Grid>
  );
}
