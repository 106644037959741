import { useState, MouseEvent, useMemo, useCallback, Fragment } from 'react';
import {
  useTheme,
  Card,
  CardContent,
  CardHeader,
  Avatar,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Slider,
  CardActions,
  Button,
  Box,
  Tooltip,
  CircularProgress,
} from '@mui/material';
import { LightOutlined as LightIcon, MoreVert as MoreVertIcon } from '@mui/icons-material';
import { IntShadowValue } from '__generated__/graphql';
import moment from 'moment';
import { useToast, ToastNotificationSeverityTypeEnum } from 'Providers/ToastProvider';
interface DeviceDownlightBrightnessCardProps {
  deviceDownlightBrightness?: IntShadowValue | null;
  update: (brightness: number) => Promise<void>;
}
export default function DeviceDownlightBrightnessCard({
  deviceDownlightBrightness,
  update,
}: DeviceDownlightBrightnessCardProps) {
  const theme = useTheme();
  const toast = useToast();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedAction, setSelectedAction] = useState<string>();
  const [brightnessLevel, setBrightnessLevel] = useState<number>(
    deviceDownlightBrightness?.value ?? 0
  );
  const [updateLoading, setUpdateLoading] = useState(false);
  const open = useMemo(() => Boolean(anchorEl), [anchorEl]);
  const brightnessLevelText = useMemo(() => {
    let text = '';
    if (
      deviceDownlightBrightness?.value !== undefined &&
      deviceDownlightBrightness?.value !== null
    ) {
      text = `Brightness: ${deviceDownlightBrightness?.value ?? 0}%`;
    }
    if (deviceDownlightBrightness?.pendingValue) {
      text += ` (Pending: ${deviceDownlightBrightness?.pendingValue ?? 0}%)`;
    }
    return text;
  }, [deviceDownlightBrightness]);

  const handleClickListItem = useCallback((event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);
  const handleMenuItemClick = useCallback((_: MouseEvent<HTMLElement>, action: string) => {
    setSelectedAction(action);
    setAnchorEl(null);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleUpdateDeviceBrightness = useCallback(() => {
    setUpdateLoading(true);
    update(brightnessLevel)
      .then(() => {
        toast.dispatchToast({
          severity: ToastNotificationSeverityTypeEnum.SUCCESS,
          title: `Updated device brightness level to ${brightnessLevel}%`,
          message: 'Please allow up to 1 minute for changes to be reflected',
        });
      })
      .catch(() => {
        toast.dispatchToast({
          severity: ToastNotificationSeverityTypeEnum.ERROR,
          title: 'Failed to update LED Brightness level',
        });
      })
      .finally(() => {
        setUpdateLoading(false);
        setSelectedAction(void 0);
      });
  }, [update, brightnessLevel, toast]);

  return (
    <Card>
      <Tooltip
        placement='top'
        title={`As of ${moment(deviceDownlightBrightness?.timestamp).fromNow()}`}
      >
        <Fragment>
          <CardHeader
            avatar={
              <Avatar
                sx={{ backgroundColor: theme.palette.info.main }}
                aria-label='device-brightness'
              >
                <LightIcon color='inherit' sx={{ height: 28, width: 28 }} />
              </Avatar>
            }
            title='LED Downlight'
            titleTypographyProps={{ variant: 'body2', fontWeight: 500 }}
            subheader={brightnessLevelText}
            action={
              updateLoading ? (
                <CircularProgress thickness={2} />
              ) : (
                <IconButton aria-label='settings' onClick={handleClickListItem}>
                  <MoreVertIcon />
                </IconButton>
              )
            }
          />
        </Fragment>
      </Tooltip>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem onClick={(event) => handleMenuItemClick(event, 'update-brightness')}>
          <Typography variant='subtitle2'>Update Downlight Brightness</Typography>
        </MenuItem>
        <MenuItem onClick={(event) => handleMenuItemClick(event, 'apply-all')} disabled>
          <Typography variant='subtitle2'>Apply Changes to All</Typography>
        </MenuItem>
      </Menu>
      {selectedAction === 'update-brightness' && (
        <>
          <CardContent sx={{ paddingX: 6, paddingTop: 4 }}>
            <Slider
              valueLabelDisplay='on'
              min={0}
              step={1}
              max={100}
              marks={[
                {
                  value: 0,
                  label: '0%',
                },
                {
                  value: 100,
                  label: '100%',
                },
              ]}
              valueLabelFormat={valueLabel}
              value={brightnessLevel}
              onChange={(_, val) => setBrightnessLevel(val as number)}
              disabled={updateLoading}
            />
          </CardContent>
          <CardActions>
            <Box display='flex' flexGrow={1} justifyContent='end' alignItems='center' gap={0.5}>
              <Button
                variant='text'
                color='primary'
                onClick={() => {
                  setSelectedAction(void 0);
                  setBrightnessLevel(deviceDownlightBrightness?.value ?? 0);
                }}
              >
                Cancel
              </Button>
              <Button
                variant='text'
                color='error'
                onClick={() => {
                  handleUpdateDeviceBrightness();
                }}
                disabled={
                  updateLoading || brightnessLevel === (deviceDownlightBrightness?.value ?? 0)
                }
              >
                Ok
              </Button>
            </Box>
          </CardActions>
        </>
      )}
    </Card>
  );
}

function valueLabel(value: number) {
  return <Typography variant='subtitle2'>{value}%</Typography>;
}
