import { Fragment } from 'react';
import { Typography, Grid } from '@mui/material';
import { deviceMap } from 'Components/AuthedPages/DevicesListPage/PageViews/DevicesListFilters';
import { DeviceFaultCard } from '../common';
import { DeviceActiveFaults } from '__generated__/graphql';
interface DeviceDetailsFaultsProps {
  activeFaults?: DeviceActiveFaults | null;
  deviceType?: string | null;
}

export default function DeviceDetailsFaults({
  activeFaults,
  deviceType,
}: DeviceDetailsFaultsProps) {
  if (!activeFaults?.description?.value) {
    return null;
  }
  return (
    <Fragment>
      <Typography variant='h6'>{deviceMap[deviceType ?? ''].value} Faults</Typography>
      <Grid container>
        <Grid item xs={12} sm={12} lg={3} xl={3}>
          <DeviceFaultCard code={activeFaults.code} type={activeFaults?.description?.value} />
        </Grid>
      </Grid>
    </Fragment>
  );
}
