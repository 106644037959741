import React, { useState } from 'react';
import { gql, useLazyQuery } from '@apollo/client';
import { Box, CircularProgress, Paper } from '@mui/material';
import { useQuery } from '@apollo/client';
import { useErrorBoundary } from 'react-error-boundary';

import OrgDisplayAndSwitcher from 'Components/SharedUI/OrgDisplayAndSwitcher';
import PageContainerFrame from 'Components/HOC/PageContainerFrame';
import SettingsPanelFrame from 'Components/HOC/SettingsPanelFrame';
import NoActiveSubscriptions from './NoActiveSubscriptions';
import SubscriptionDetails from './SubscriptionDetails';

import type { SelectedOrgType } from 'Apollo/ApolloCache';

const GET_STRIPE_BILLING_PORTAL_SESSION_QUERY = gql`
  query getStripeBillingPortalSessionData($input: BillingSessionInput!) {
    billingPortalSession(input: $input) {
      sessionId
      url
    }
  }
`;

const SELECTED_ORG_SUBSCRIPTION_DATA_QUERY = gql`
  query selectedOrgSubscriptionData($accountId: ID!) {
    account(accountId: $accountId) {
      isStripeCustomer
      subscriptions {
        stripeProductName
        subscriptionStatus
        currentPeriodStart
        currentPeriodEnd
      }
    }
  }
`;

type Props = {
  selectedOrg: SelectedOrgType;
};

export default function OrgSubscriptionPage({ selectedOrg }: Props) {
  const {
    data: orgSubscriptionData,
    loading: isSubDataLoading,
    error: orgSubscriptionQueryError,
  } = useQuery(SELECTED_ORG_SUBSCRIPTION_DATA_QUERY, { variables: { accountId: selectedOrg?.id } });
  const { isStripeCustomer, subscriptions } = orgSubscriptionData?.account ?? {};
  const [isRedirecting, setIsRedirecting] = useState(false);
  const fullUrlPath = window.location.href;
  const userHasOrgWritePermission =
    selectedOrg?.userAccountScope?.roles?.account_write == null
      ? false
      : selectedOrg?.userAccountScope?.roles?.account_write;
  const [
    getStripeBillingPortalSession,
    { data: _data, loading: isPortalSessionLoading, error: billingSessionQueryError },
  ] = useLazyQuery(GET_STRIPE_BILLING_PORTAL_SESSION_QUERY);

  const isLoading = isPortalSessionLoading || isSubDataLoading;

  const handleGetPortalSession = async () => {
    setIsRedirecting(true);
    try {
      const { data } = await getStripeBillingPortalSession({
        variables: { input: { accountId: selectedOrg?.id, returnUrl: fullUrlPath } },
      });

      if (data?.billingPortalSession?.url) {
        // Redirect the user
        window.location.href = data.billingPortalSession.url;
      }
    } catch (error) {
      setIsRedirecting(true);
      console.error('Error fetching Stripe session:', error);
    }
  };
  const { showBoundary: showErrorBoundary } = useErrorBoundary();

  const queryError = orgSubscriptionQueryError || billingSessionQueryError;

  if (queryError) {
    showErrorBoundary(queryError);
  }

  const pageDescription = userHasOrgWritePermission
    ? 'View and manage subscription settings'
    : 'View subscription settings for a particular organization';

  return (
    <PageContainerFrame pageTitles={['Subscriptions', selectedOrg?.name]}>
      <SettingsPanelFrame title='Organization Subscription' description={pageDescription}>
        <OrgDisplayAndSwitcher />
        <Box
          component={Paper}
          display='flex'
          flexDirection='column'
          justifyContent={isLoading ? 'center' : 'flex-start'}
          alignItems={isLoading ? 'center' : ''}
          minWidth={300}
          maxWidth={600}
          gap={1}
          width='100%'
          height={300}
        >
          {isSubDataLoading ? (
            <CircularProgress size={100} />
          ) : (
            <>
              {subscriptions.length === 0 ? (
                <NoActiveSubscriptions
                  userHasOrgWritePermission={userHasOrgWritePermission}
                  isRedirecting={isRedirecting}
                  isStripeCustomer={isStripeCustomer}
                  handleGetPortalSession={handleGetPortalSession}
                />
              ) : (
                subscriptions.map((subscription, index) => (
                  <SubscriptionDetails
                    key={index}
                    subscription={subscription}
                    isRedirecting={isRedirecting}
                    isLoading={isLoading}
                    handleGetPortalSession={handleGetPortalSession}
                  />
                ))
              )}
            </>
          )}
        </Box>
      </SettingsPanelFrame>
    </PageContainerFrame>
  );
}
