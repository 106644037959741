import React, { useMemo } from 'react';
import {
  SyncDisabled as SyncDisabledIcon,
  Sync as SyncIcon,
  SyncAlt as SyncAltIcon,
} from '@mui/icons-material';
import { SpeedDial, SpeedDialAction, Box, Typography } from '@mui/material';
import { POLL_STATES } from 'Hooks/usePolling';

interface PollingSpeedDialProps {
  pollState: POLL_STATES;
  setPollState: (state: POLL_STATES) => void;
}
export default React.memo(function PollingSpeedDial({
  pollState,
  setPollState,
}: PollingSpeedDialProps) {
  const actions = useMemo(
    () => [
      {
        name: 'Live',
        icon: <SyncIcon />,
        pollState: POLL_STATES.LIVE,
        onClick: () => {
          setPollState(POLL_STATES.LIVE);
        },
      },
      {
        name: 'Periodic',
        icon: <SyncAltIcon />,
        pollState: POLL_STATES.PERIODIC,
        onClick: () => {
          setPollState(POLL_STATES.PERIODIC);
        },
      },
      {
        name: 'Disabled',
        icon: <SyncDisabledIcon />,
        pollState: POLL_STATES.DISABLED,
        onClick: () => {
          setPollState(POLL_STATES.DISABLED);
        },
      },
    ],
    [setPollState]
  );
  return (
    <SpeedDial
      ariaLabel='Sync Settings'
      sx={{ position: 'fixed', bottom: 32, right: 32, alignItems: 'end' }}
      FabProps={{
        variant: 'extended',
      }}
      icon={
        <Box sx={{ display: 'flex', flexDirection: 'end' }} gap={1}>
          {pollState === POLL_STATES.DISABLED && <SyncDisabledIcon />}
          {pollState === POLL_STATES.LIVE && (
            <SyncIcon
              sx={{
                animation: 'spin 2.5s linear infinite',
                '@keyframes spin': {
                  '0%': {
                    transform: 'rotate(360deg)',
                  },
                  '100%': {
                    transform: 'rotate(0deg)',
                  },
                },
              }}
            />
          )}
          {pollState === POLL_STATES.PERIODIC && <SyncAltIcon />}
          <Typography variant='inherit'>sync</Typography>
        </Box>
      }
    >
      {actions.map((action) => (
        <SpeedDialAction
          key={action.name}
          icon={action.icon}
          tooltipTitle={action.name}
          tooltipOpen
          onClick={action.onClick}
        />
      ))}
    </SpeedDial>
  );
});
