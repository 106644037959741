/* eslint-disable indent */
/* eslint-disable no-magic-numbers */
import { useLazyQuery, useMutation, useReactiveVar } from '@apollo/client';
import {
  Box,
  Button,
  CircularProgress,
  Stack,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from '@mui/material';
import {
  BinInterval,
  CalibrateLocationMutation,
  CalibrateLocationMutationVariables,
  OptimizeLocationOccupancyQuery,
  OptimizeLocationOccupancyQueryVariables,
  SetBaselineLocationMetadataMutation,
  SetBaselineLocationMetadataMutationVariables,
} from '__generated__/graphql';
import { selectedOrgVar } from 'Apollo/ApolloCache';
import moment from 'moment-timezone';
import { ToastNotificationSeverityTypeEnum, useToast } from 'Providers/ToastProvider';
import { useCallback, useState } from 'react';
import { useOccupancyContext } from '../../context';
import {
  CALIBRATE_LOCATION,
  OPTIMIZE_LOCATION_OCCUPANCY,
  SET_BASELINE_LOCATION_METADATA,
} from '../../Occupancy.gql';
import OccupancyCalibrationGraph from './OccupancyCalibrationGraph';

import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
export default function OccupancyCalibrationHelper() {
  const selectedOrg = useReactiveVar(selectedOrgVar);
  const {
    locationCalibrationDetailsForOccupancy,
    occupancyGraphData,
    occupancyGraphError,
    occupancyGraphLoading,
    filterHandler,
    resetPage,
  } = useOccupancyContext();
  const { dispatchToast } = useToast();
  const [
    fetchOccupancyOptimizedParams,
    { data: occupancyOptParams, loading: occupancyOptParamsLoading },
  ] = useLazyQuery<OptimizeLocationOccupancyQuery, OptimizeLocationOccupancyQueryVariables>(
    OPTIMIZE_LOCATION_OCCUPANCY
  );

  const [setBaselineLocationMetadata, { loading: settingBaselineLocationMetadata }] = useMutation<
    SetBaselineLocationMetadataMutation,
    SetBaselineLocationMetadataMutationVariables
  >(SET_BASELINE_LOCATION_METADATA);

  const [calibrateCurrentLocation, { loading: updatingLocation }] = useMutation<
    CalibrateLocationMutation,
    CalibrateLocationMutationVariables
  >(CALIBRATE_LOCATION);

  const [activeStep, setActiveStep] = useState(0);
  const [maxRoomCapacityFt3, setMaxRoomCapacityFt3] = useState(
    locationCalibrationDetailsForOccupancy?.metadata?.airVolumeFt3
  );
  const [maxOccupants, setMaxOccupants] = useState(
    locationCalibrationDetailsForOccupancy?.metadata?.maximumOccupancy
  );
  const [dateRange, setDateRange] = useState<[moment.Moment, moment.Moment]>([
    moment().subtract(24, 'hours'),
    moment(),
  ]);
  const [providedMaxOccupancy, setProvidedMaxOccupancy] = useState<number>();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    if (activeStep === 0) {
      resetPage();
      return;
    }
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const initializeOccupancyParams = useCallback(async () => {
    try {
      const startDate = dateRange?.at(0)?.toISOString();
      const endDate = dateRange?.at(1)?.toISOString();
      if (
        !startDate ||
        !endDate ||
        !providedMaxOccupancy ||
        !locationCalibrationDetailsForOccupancy?.id ||
        !selectedOrg?.id
      ) {
        throw new Error('Date Range or max occupancy in the range not provided');
      }
      const tempOccOptParams = await fetchOccupancyOptimizedParams({
        variables: {
          input: {
            accountId: selectedOrg.id,
            locationId: locationCalibrationDetailsForOccupancy.id,
            timeRange: {
              startDate: startDate,
              endDate: endDate,
            },
            occupancyDataPoints: [
              {
                timestamp: endDate,
                occupancyCount: providedMaxOccupancy,
              },
            ],
            co2BinPeriod: BinInterval.FifteenMinutes,
          },
        },
      });
      if (tempOccOptParams.error) {
        throw new Error(tempOccOptParams.error.message ?? 'Failed to Calibrate Occupancy');
      }

      await filterHandler({
        input: {
          accountId: selectedOrg.id,
          locationId: locationCalibrationDetailsForOccupancy.id,
          timeRange: {
            startDate: startDate,
            endDate: endDate,
          },
          co2BinPeriod: BinInterval.FifteenMinutes,
          baselineCo2PpmOverride:
            tempOccOptParams.data?.report?.uvangel?.optimizeLocationOccupancy?.optimizedCO2Baseline,
          ventilationRateAchOverride:
            tempOccOptParams.data?.report?.uvangel?.optimizeLocationOccupancy
              ?.optimizedVentilationRate,
        },
      });

      handleNext();
    } catch (err: unknown) {
      dispatchToast({
        severity: ToastNotificationSeverityTypeEnum.ERROR,
        title: 'Could not fetch Calibration Details',
        message: (err as Error).message,
      });
      console.error('Failed to fetch occupancy params ', err);
    }
  }, [
    dateRange,
    providedMaxOccupancy,
    locationCalibrationDetailsForOccupancy?.id,
    selectedOrg?.id,
    fetchOccupancyOptimizedParams,
    filterHandler,
    dispatchToast,
  ]);

  const setInitialLocationMetadata = useCallback(async () => {
    if (
      !selectedOrg?.id ||
      !locationCalibrationDetailsForOccupancy?.id ||
      !maxRoomCapacityFt3 ||
      !maxOccupants
    ) {
      console.error('Org or Location not present - unable to calibrate');
      dispatchToast({
        severity: ToastNotificationSeverityTypeEnum.ERROR,
        title: 'Failed to calibrate location',
        message: 'Org or Location not present',
      });
      return;
    }
    setBaselineLocationMetadata({
      variables: {
        accountId: selectedOrg.id,
        locationId: locationCalibrationDetailsForOccupancy.id,
        airVolumeFt3: maxRoomCapacityFt3,
        maximumOccupancy: maxOccupants,
      },
      onCompleted() {
        dispatchToast({
          severity: ToastNotificationSeverityTypeEnum.SUCCESS,
          title: 'Updated Location Metadata',
        });
        handleNext();
      },
      onError(error) {
        console.log('error', error);
        dispatchToast({
          severity: ToastNotificationSeverityTypeEnum.ERROR,
          title: 'Failed to update location metadata',
          message: error.message,
        });
      },
    });
  }, [
    selectedOrg,
    locationCalibrationDetailsForOccupancy,
    maxRoomCapacityFt3,
    maxOccupants,
    dispatchToast,
    setBaselineLocationMetadata,
  ]);

  const calibrateLocation = useCallback(async () => {
    const optParams = occupancyOptParams?.report?.uvangel?.optimizeLocationOccupancy;
    if (
      !selectedOrg?.id ||
      !locationCalibrationDetailsForOccupancy?.id ||
      !optParams ||
      !optParams.optimizedCO2Baseline ||
      !optParams.optimizedVentilationRate ||
      !maxRoomCapacityFt3 ||
      !maxOccupants
    ) {
      console.error('Org or Location not present - unable to calibrate');
      dispatchToast({
        severity: ToastNotificationSeverityTypeEnum.ERROR,
        title: 'Failed to calibrate location',
        message: 'Org or Location not present',
      });
      return;
    }
    calibrateCurrentLocation({
      variables: {
        accountId: selectedOrg.id,
        locationId: locationCalibrationDetailsForOccupancy.id,
        airVolumeFt3: maxRoomCapacityFt3,
        maximumOccupancy: maxOccupants,
        co2BaselinePPM: optParams.optimizedCO2Baseline,
        assumedAch: optParams.optimizedVentilationRate,
      },
      onCompleted() {
        dispatchToast({
          severity: ToastNotificationSeverityTypeEnum.SUCCESS,
          title: 'Location Calibration successful',
        });
        resetPage();
      },
      onError(error) {
        console.log('error', error);
        dispatchToast({
          severity: ToastNotificationSeverityTypeEnum.ERROR,
          title: 'Failed to calibrate location',
          message: error.message,
        });
      },
    });
  }, [
    occupancyOptParams?.report?.uvangel?.optimizeLocationOccupancy,
    selectedOrg,
    locationCalibrationDetailsForOccupancy,
    calibrateCurrentLocation,
    maxRoomCapacityFt3,
    maxOccupants,
    dispatchToast,
    resetPage,
  ]);

  const steps = [
    {
      label: 'Set Room Details',
      content: (
        <Stack spacing={2} py={2} width='fit-content'>
          <TextField
            name='maxRoomCapacityFt3'
            label='Room Size'
            variant='outlined'
            type='number'
            value={maxRoomCapacityFt3}
            onChange={(e) => {
              const value = parseInt(e.target.value);
              setMaxRoomCapacityFt3(Number.isNaN(value) ? void 0 : value);
            }}
            InputProps={{
              endAdornment: (
                <Typography sx={{ ml: 2 }}>
                  ft<sup>3</sup>
                </Typography>
              ),
            }}
          />
          <TextField
            name='maximumOccupancy'
            label='Maximum Occupant Capacity'
            variant='outlined'
            type='number'
            value={maxOccupants}
            onChange={(e) => {
              const value = parseInt(e.target.value);
              setMaxOccupants(Number.isNaN(value) ? void 0 : value);
            }}
          />
        </Stack>
      ),
      canMove: maxRoomCapacityFt3 && maxOccupants && maxRoomCapacityFt3 > 0 && maxOccupants > 0,
      loading: settingBaselineLocationMetadata,
      callback: setInitialLocationMetadata,
    },
    {
      label: 'Provide Occupancy Sample',
      content: (
        <Stack py={2} spacing={2}>
          <Typography>
            Provide a time range and the maximum amount of occupants that were in the space at a
            single moment. For the best results, the provided time range should span at least 24
            hours.
          </Typography>
          <Stack width='max-content' gap={2}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DateTimePicker
                label='Start Date'
                value={dateRange?.at(0)}
                maxDateTime={dateRange?.at(1)}
                onChange={(date) => setDateRange((prev) => [date as moment.Moment, prev[1]])}
                views={['year', 'day', 'hours', 'minutes']}
              />
              <DateTimePicker
                label='End Date'
                value={dateRange?.at(1)}
                minDateTime={dateRange?.at(0)}
                onChange={(date) => setDateRange((prev) => [prev[0], date as moment.Moment])}
                views={['year', 'day', 'hours', 'minutes']}
              />
            </LocalizationProvider>
            <TextField
              name='providedMaxOccupancy'
              label='Maximum Occupancy'
              variant='outlined'
              type='number'
              value={providedMaxOccupancy}
              onChange={(e) => {
                const value = parseInt(e.target.value);
                setProvidedMaxOccupancy(isNaN(value) ? void 0 : value);
              }}
              helperText='Max occupants observed at single time'
            />
          </Stack>
        </Stack>
      ),
      callback: initializeOccupancyParams,
      loading: occupancyGraphLoading || occupancyOptParamsLoading,
      canMove:
        providedMaxOccupancy && providedMaxOccupancy > 0 && !!dateRange.at(0) && !!dateRange.at(1),
    },
    {
      label: 'Verify Calibrated Occupancy',
      content: (
        <OccupancyCalibrationGraph
          selectedInterval={BinInterval.FifteenMinutes}
          startDate={dateRange[0].toISOString()}
          endDate={dateRange[1].toISOString()}
        />
      ),
      callback: calibrateLocation,
      loading: updatingLocation,
      canMove:
        !!occupancyGraphData?.report?.uvangel?.getLocationOccupancyForDuration &&
        !occupancyGraphError,
    },
  ];
  return (
    <Stepper activeStep={activeStep} orientation='vertical'>
      {steps.map((step, index) => {
        const isFinalStep = index === steps.length - 1;
        return (
          <Step key={step.label}>
            <StepLabel
              optional={
                index === steps.length - 1 ? (
                  <Typography variant='caption'>Last step</Typography>
                ) : null
              }
            >
              {step.label}
            </StepLabel>
            <StepContent>
              {step.content}
              <Box sx={{ mb: 2 }}>
                <Button color='warning' onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
                  {index > 0 ? 'Back' : 'Cancel'}
                </Button>
                {step.canMove && (
                  <Button
                    disableElevation
                    onClick={step.callback}
                    sx={{ mt: 1, mr: 1 }}
                    color={isFinalStep ? 'success' : 'primary'}
                    variant={isFinalStep ? 'contained' : 'text'}
                    endIcon={step.loading && <CircularProgress size={16} color='inherit' />}
                  >
                    {isFinalStep ? 'Finish Calibration' : 'Continue'}
                  </Button>
                )}
              </Box>
            </StepContent>
          </Step>
        );
      })}
    </Stepper>
  );
}
