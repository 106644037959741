import React, { useState } from 'react';
import { Box, Card, Typography } from '@mui/material';

import UserActionCellCard from './TableCells/UserActionCellCard';
import { getRoleType, formatTimestamp, formatStatus } from 'Utils/orgUserManagementUtils';

import type { SelectedOrgType } from 'Apollo/ApolloCache';
import type { GetCurrentOrgUserListQuery as OrgUserListType } from '__generated__/graphql';

type OrgUserType = NonNullable<NonNullable<OrgUserListType['account']>['users']>[number];

type CardTextRowProps = {
  label: string;
  content: string;
};

function CardTextRow({ label, content }: CardTextRowProps) {
  if (!content) {
    return null;
  }
  return (
    <Box display='flex' flexDirection='row' gap={1}>
      <Typography fontWeight='bold'>{label}</Typography>
      <Typography marginBottom={0.5}>{content}</Typography>
    </Box>
  );
}

type UserCardProps = {
  user: OrgUserType;
  selectedOrg: SelectedOrgType;
};

function UserCard({ user, selectedOrg }: UserCardProps) {
  const {
    id: userID,
    accountScope,
    firstName,
    lastName,
    email,
    status,
    createdAt,
    lastLogin,
  } = user ?? {};
  const [isRemovingUser, setIsRemovingUser] = useState<boolean>(false);
  const userName = `${firstName} ${lastName}`;
  const { roles, rootLocation } = accountScope ?? {};
  const roleFormatted = getRoleType(roles);
  const dateRegistered = formatTimestamp(createdAt);
  const lastLoginFormatted = formatTimestamp(lastLogin);
  const statusFormatted = formatStatus(status);
  const cardContentList: Array<{ label: string; content: string }> = [
    { label: 'Username:', content: userName },
    { label: 'Email:', content: email ?? '' },
    { label: 'Role:', content: roleFormatted },
    { label: 'Date registered:', content: dateRegistered },
    { label: 'Status:', content: statusFormatted },
    { label: 'Last login:', content: lastLoginFormatted },
  ];
  return (
    <Card sx={{ minWidth: 400, maxWidth: 500 }}>
      <Box display='flex' flexDirection='row' justifyContent='space-between' padding={2}>
        <Box display='flex' flexDirection='column'>
          {cardContentList.map((card, index) => (
            <CardTextRow key={index} label={card.label} content={card?.content} />
          ))}
        </Box>
        <Box display='flex' flexDirection='column'>
          <UserActionCellCard
            firstName={firstName ?? ''}
            lastName={lastName ?? ''}
            userID={userID ?? ''}
            selectedOrg={selectedOrg}
            rootLocationId={rootLocation?.id}
            isRemovingUser={isRemovingUser}
            roleType={roleFormatted}
            setIsRemovingUser={setIsRemovingUser}
          />
        </Box>
      </Box>
    </Card>
  );
}

type Props = {
  userListdata: OrgUserListType | undefined;
  selectedOrg: SelectedOrgType;
};

export default function OrgUserManagementCardList({ userListdata, selectedOrg }: Props) {
  const { users } = userListdata?.account ?? {};
  return (
    <Box display='flex' flexDirection='column' gap={2}>
      {users?.map((user) => (
        <UserCard key={user?.id} user={user} selectedOrg={selectedOrg} />
      ))}
    </Box>
  );
}
