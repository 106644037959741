/* eslint-disable indent */
import { DocumentNode, gql, useQuery, useReactiveVar } from '@apollo/client';
import { selectedOrgVar } from 'Apollo/ApolloCache';
import { useMemo } from 'react';

/**
 * Custom hook to fetch Location data using GraphQL fragments
 * @template T - Unionization of all template types. used to set typed return structure.
 * @param locationId - The ID of the location to fetch
 * @param fragments - GraphQL fragment documents to include in the query
 * @returns {QueryResult<{ location?: T }, { accountId: string; locationId: string }>}
 * @throws {Error} When no fragments are provided
 */
export default function useFetchLocationWithFragments<T>(
  locationId?: string | null,
  ...fragments: Array<DocumentNode>
) {
  const selectedOrg = useReactiveVar(selectedOrgVar);
  const LOCATION_QUERY = useMemo(() => {
    if (!fragments || fragments.length === 0) {
      throw new Error('useFetchLocationWithFragments: At least one GraphQL fragment must be provided');
    }
    return gql`
        ${fragments.map((frag) => frag.loc?.source.body).join('\n')}
        query LocationWithFragments($accountId: ID!, $locationId: ID!){
            location(accountId: $accountId, locationId: $locationId) {
                ${fragments
                  .map((frag) => {
                    const def = frag.definitions.at(0) as { name: { value: string } };
                    return '...' + def.name.value;
                  })
                  .join('\n')}
            }
        }
    `;
  }, [fragments]);

  const queryResults = useQuery<
    { location?: T },
    { accountId: string; locationId: string }
  >(LOCATION_QUERY, {
    skip: !locationId || !selectedOrg?.id,
    variables: {
      accountId: selectedOrg?.id ?? '',
      locationId: locationId ?? '',
    },
    notifyOnNetworkStatusChange: true,
    onError(error) {
      console.error('Error Fetching Location: ', error);
    },
  });

  return queryResults;
}
