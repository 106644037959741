import { Box, Typography, Button } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import RoutePaths from 'Constants/RoutePaths';
import { Helmet } from 'react-helmet';
import { formatPageTitle } from 'Utils/formatPageTitle';

const { PATH_HOME } = RoutePaths;
const pageTitle = formatPageTitle(['Access Denied']);

export default function AccessDeniedPage() {
  return (
    <Box
      flexDirection='column'
      display='flex'
      justifyContent='center'
      alignItems='center'
      marginTop={10}
    >
      <Helmet defer={false}>
        <title>{pageTitle}</title>
      </Helmet>
      <Typography fontWeight='bold' fontSize={40}>
        Access Denied
      </Typography>
      <Typography fontSize={20}>You are not currently authorized to view this page.</Typography>
      <Typography fontSize={20}>
        If you believe you should have access, please contact your administrator for assistance.
      </Typography>
      <Button
        component={RouterLink}
        to={PATH_HOME}
        color='primary'
        sx={{ marginTop: 3 }}
        variant='contained'
      >
        Go to home Page
      </Button>
    </Box>
  );
}
