/* eslint-disable no-magic-numbers */
import { LngLatBoundsLike } from 'mapbox-gl';

export interface ImdfProps {
  // Room id. Mapbox doesn't support string ids.
  roomId: string;
  roomName?: string;
  // value to be printed next to the room name
  value?: string;
  // if true, no room name & value will be printed
  hideValue?: boolean;
  hasSubrooms?: boolean;
  fillColor?: string;
  fillOutlineColor?: string;
  selectedFillColor?: string;
  selectedFillOutlineColor?: string;
  selectedFillOutlineWidth?: number;
  // A point representing visual center of the feature. A good place to put a marker on.
  displayPoint?: {
    coordinates: GeoJSON.Position;
  };
}
export type ImdfFeature<
  G extends GeoJSON.Geometry,
  P extends ImdfProps = ImdfProps
> = GeoJSON.Feature<G, P>;

export function getImageDimensions(
  rawBase64Data?: string
): Promise<{ height: number; width: number }> {
  // };
  return new Promise<{ height: number; width: number }>((resolve, reject) => {
    const dataUriDescriptors = [
      'data:image/png;base64,',
      'data:image/jpg;base64,',
      'data:image/jpeg;base64,',
    ];

    if (!rawBase64Data) {
      resolve({ width: 0, height: 0 });
      return;
    }

    const foundDescriptor = dataUriDescriptors.find((descriptor) =>
      rawBase64Data.startsWith(descriptor)
    );

    if (!foundDescriptor) {
      console.error('Image is not a png, jpg or jpeg!');
      resolve({ width: 0, height: 0 });
      return;
    }
    const img = new Image();
    img.onload = () => resolve({ width: img.width, height: img.height });
    img.onerror = reject;
    img.src = rawBase64Data;
  });
}

/**
 * Look through all features' geometries and find a rectangle/bound that contains all points.
 */
export function findBounds<G extends GeoJSON.Geometry>(
  maxBounds: LngLatBoundsLike,
  features?: ImdfFeature<G>[]
): LngLatBoundsLike {
  let lngLow = 180,
    lngHigh = -180,
    latLow = 90,
    latHigh = -90;

  if (!features?.length) {
    return maxBounds;
  }

  function considerPosition(pos: GeoJSON.Position) {
    if (pos[0] < lngLow) {
      lngLow = pos[0];
    }
    if (pos[0] > lngHigh) {
      lngHigh = pos[0];
    }
    if (pos[1] < latLow) {
      latLow = pos[1];
    }
    if (pos[1] > latHigh) {
      latHigh = pos[1];
    }
  }

  function considerGeometry(geometry: GeoJSON.Geometry) {
    switch (geometry?.type) {
      case 'Point':
        considerPosition(geometry.coordinates);
        break;
      case 'Polygon':
        geometry.coordinates.forEach((posArray) =>
          posArray.forEach((pos) => considerPosition(pos))
        );
        break;
      case 'LineString':
        geometry.coordinates.forEach((pos) => considerPosition(pos));
        break;
      case 'MultiPoint':
        geometry.coordinates.forEach((pos) => considerPosition(pos));
        break;
      case 'MultiLineString':
        geometry.coordinates.forEach((posArray) =>
          posArray.forEach((pos) => considerPosition(pos))
        );
        break;
      case 'MultiPolygon':
        geometry.coordinates.forEach((polyArray) =>
          polyArray.forEach((posArray) => posArray.forEach((pos) => considerPosition(pos)))
        );
        break;
      default:
      // ignore unknown geometry
    }
  }
  features.forEach((feature) => considerGeometry(feature.geometry));

  const verticalOffset = (latHigh - latLow) * 0.333;
  return [lngLow, Math.max(-90, latLow - verticalOffset), lngHigh, latHigh];
}
