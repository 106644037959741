import React, { useState } from 'react';
import { OrgIntegrationsPanelViewEnum } from 'Constants/OrgSettingsEnums';
import {
  Box,
  Button,
  MenuItem,
  Select,
  FormGroup,
  FormControl,
  FormLabel,
  CircularProgress,
  TextField,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { gql, useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { useToast, ToastNotificationSeverityTypeEnum } from 'Providers/ToastProvider';
import RoutePaths from 'Constants/RoutePaths';

import type { GetSelectedOrgIntegrationsDataQuery as IntegrationsDataType } from '__generated__/graphql';
import type { SelectedOrgType } from 'Apollo/ApolloCache';

const { PATH_ORGANIZATION_INTEGRATIONS } = RoutePaths;

function getExpirationTimestamp(days: string): string {
  if (days === 'none') {
    return 'none';
  }
  const expirationDate = new Date();
  expirationDate.setDate(expirationDate.getDate() + Number(days));
  // Need to confirm this is correct timestamp format for this endpoint
  return expirationDate.toISOString();
}

const CREATE_ACCOUNT_INTEGRATION = gql`
  mutation createAccountIntegration(
    $accountId: ID!
    $provider: String!
    $externalApiKey: String!
    $description: String
    $expirationTimestamp: Date
  ) {
    createAccountIntegration(
      accountId: $accountId
      provider: $provider
      externalApiKey: $externalApiKey
      description: $description
      expirationTimestamp: $expirationTimestamp
    ) {
      accountId
      id
    }
  }
`;

type Props = {
  data: IntegrationsDataType | undefined;
  selectedOrg: SelectedOrgType | null;
};

export default function AddIntegrationsView({ selectedOrg, data: orgData }: Props) {
  const [createAccountIntegration, { loading: isMutationInFlight }] = useMutation(
    CREATE_ACCOUNT_INTEGRATION
  );
  const { dispatchToast } = useToast();
  const [selectedExpiration, setSelectedExpiration] = useState<string>('');
  const { register, handleSubmit, formState, setValue } = useForm();
  const navigate = useNavigate();
  const onSubmit = async (data) => {
    try {
      // provider is currently hard-coded until dynamically implemented
      const variables = {
        provider: '911_INFORM',
        accountId: selectedOrg?.id,
        externalApiKey: data.key,
      };
      // optional field
      if (data.description) {
        variables['description'] = data.description;
      }
      // optional field
      if (data.expirationTimestamp !== 'none') {
        variables['expirationTimestamp'] = data.expirationTimestamp;
      }
      await createAccountIntegration({
        variables,
        update: (cache, { data: response }) => {
          const account = orgData?.account ?? {};
          cache.modify({
            id: cache.identify(account),
            fields: {
              accountIntegrations(existingIntegrations = [], { readField }) {
                const newIntegration = {
                  __typename: 'AccountIntegration',
                  id: response.createAccountIntegration.id,
                  provider: '911_INFORM',
                  expirationTimestamp: data.expirationTimestamp ?? null,
                  description: data.description ?? null,
                };

                const newIntegrationRef = cache.writeFragment({
                  data: newIntegration,
                  fragment: gql`
                    fragment NewAccountIntegration on AccountIntegration {
                      id
                      description
                      expirationTimestamp
                      provider
                    }
                  `,
                });
                // check to make sure newKey doesnt already exist in cache
                if (
                  existingIntegrations.some(
                    (existingIntegration) =>
                      readField('id', existingIntegration) === newIntegration.id
                  )
                ) {
                  return existingIntegrations;
                }
                return [...existingIntegrations, newIntegrationRef];
              },
            },
          });
        },
      });
      const notifConfig = {
        severity: ToastNotificationSeverityTypeEnum.SUCCESS,
        title: 'Integration Added',
        message: 'You\'ve successfully added an integration for your organization',
      };
      dispatchToast(notifConfig);
      navigate(PATH_ORGANIZATION_INTEGRATIONS, {
        state: { panelView: OrgIntegrationsPanelViewEnum.INTEGRATIONS_LIST_VIEW },
      });
    } catch (error) {
      const notifConfig = {
        severity: ToastNotificationSeverityTypeEnum.ERROR,
        title: 'Error Adding Integration',
        message: 'Error adding integration. Please try again',
      };
      dispatchToast(notifConfig);
      console.error('ERROR: CREATE_ACCOUNT_INTEGRATION - ', error);
    }
  };

  return (
    <Box display='flex' flexDirection='column' width={600}>
      <FormControl>
        <Box display='flex' flexDirection='column' gap={0.5} width={425} marginBottom={2}>
          <FormLabel htmlFor='keyNameInput'>Key</FormLabel>
          <TextField
            {...register('key', { required: true })}
            id='keyNameInput'
            variant='outlined'
            size='small'
          />
        </Box>
        <Box display='flex' flexDirection='column' gap={0.5} width={425} marginBottom={2}>
          <FormLabel htmlFor='descriptionInput'>Description</FormLabel>
          <TextField
            {...register('description')}
            id='descriptionInput'
            variant='outlined'
            size='small'
          />
        </Box>
        <Box display='flex' flexDirection='column' gap={0.5} marginBottom={2}>
          <FormLabel htmlFor='expirationInput'>Expiration</FormLabel>
          <Select
            value={selectedExpiration}
            id='expirationInput'
            size='small'
            sx={{ width: 200 }}
            onChange={(e) => {
              setSelectedExpiration(e.target.value);
              const timeStamp = getExpirationTimestamp(e.target.value);
              setValue('expirationTimestamp', timeStamp);
            }}
          >
            <MenuItem value={'7'}>7 days</MenuItem>
            <MenuItem value={'30'}>30 days</MenuItem>
            <MenuItem value={'60'}>60 days</MenuItem>
            <MenuItem value={'90'}>90 days</MenuItem>
            <MenuItem value={'none'}>No expiration</MenuItem>
          </Select>
        </Box>
        <FormGroup row>
          <Button
            disabled={isMutationInFlight}
            color='secondary'
            sx={{
              height: 50,
              width: 200,
              marginRight: 2,
            }}
            onClick={() =>
              navigate(PATH_ORGANIZATION_INTEGRATIONS, {
                state: { panelView: OrgIntegrationsPanelViewEnum.INTEGRATIONS_LIST_VIEW },
              })
            }
            variant='contained'
          >
            Go Back
          </Button>
          <Button
            type='submit'
            disabled={!formState.isValid || isMutationInFlight}
            color='primary'
            sx={{ height: 50, width: 200 }}
            onClick={handleSubmit(onSubmit)}
            variant='contained'
            startIcon={isMutationInFlight ? <CircularProgress size={20} /> : null}
          >
            Add Integration
          </Button>
        </FormGroup>
      </FormControl>
    </Box>
  );
}
