/* eslint-disable no-nested-ternary */
/* eslint-disable no-magic-numbers */
import { useMutation, useQuery } from '@apollo/client';
import { Close as CloseIcon, Search as SearchIcon } from '@mui/icons-material';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  InputBase,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import { debounce } from 'lodash';
import React, { useCallback, useState } from 'react';
import { useErrorBoundary } from 'react-error-boundary';
import { truncateString } from 'Utils/stringManipulation';
import { GET_AVAILABLE_ACCOUNTS_QUERY, ADMIN_REGISTER_DEVICE_TO_ORG } from './graphql';
import {
  GetAvailableAccountsDataQuery,
  GetAvailableAccountsDataQueryVariables,
  RegisterDeviceToOrgMutation,
  RegisterDeviceToOrgMutationVariables,
} from '__generated__/graphql';
import { ToastNotificationSeverityTypeEnum, useToast } from 'Providers/ToastProvider';

type Props = {
  onModalClose: () => void;
  modalProps: unknown;
};
const maxResults = 10;

export default function AdminRegisterDeviceToOrgModal({ onModalClose, modalProps }: Props) {
  const { serialNumber, onSuccess } = modalProps as {
    serialNumber: string;
    onSuccess: (
      serialNumber: string,
      org: Record<'id' | 'name', string>,
      location: Record<'id' | 'name', string>
    ) => void;
  };

  const { dispatchToast } = useToast();

  const [nextToken, setNextToken] = useState<string>('');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [orgList, setOrgList] = useState<
    NonNullable<GetAvailableAccountsDataQuery['accounts']>['accounts']
  >([]);
  const [pageRange, setPageRange] = useState({ start: 0, end: maxResults });
  const [page, setPage] = useState<number>(0);
  const [maxPageRequested, setMaxPageRequested] = useState<number>(0);
  const [searchString, setSearchString] = useState<string>('');
  const [registeringDeviceOrgId, setRegisteringDeviceOrgId] = useState<string>();
  const {
    data,
    loading: isLoading,
    error: queryError,
    refetch,
  } = useQuery<GetAvailableAccountsDataQuery, GetAvailableAccountsDataQueryVariables>(
    GET_AVAILABLE_ACCOUNTS_QUERY,
    {
      notifyOnNetworkStatusChange: true,
      onCompleted: (data) => {
        const nextToken = data?.accounts?.nextToken;
        setNextToken(nextToken ?? '');
        if (orgList && orgList.length === 0) {
          // loads the first list of orgs to init the component
          const initOrgList = data?.accounts?.accounts;
          setOrgList(initOrgList);
        }
      },
      variables: {
        pagination: {
          maxResults,
          nextToken: '',
        },
        searchString: '',
      },
    }
  );
  const [registerDevice, { loading: mutationInFlight }] = useMutation<
    RegisterDeviceToOrgMutation,
    RegisterDeviceToOrgMutationVariables
  >(ADMIN_REGISTER_DEVICE_TO_ORG);
  const { showBoundary: showErrorBoundary } = useErrorBoundary();
  if (queryError) {
    showErrorBoundary(queryError);
  }

  const handleFetchNextGroupOfOrgItems = async (
    searchString?: string,
    resetToken?: string
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): Promise<any> => {
    const res = await refetch({
      pagination: {
        maxResults,
        nextToken: resetToken ?? nextToken,
      },
      searchString: searchString ?? '',
    });
    return res;
  };

  const handleChangePage = async (
    _event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    const nextButtonClicked = newPage > page;
    if (nextButtonClicked) {
      setPageRange((prevRange) => {
        return {
          start: prevRange.end,
          end: prevRange.end + maxResults,
        };
      });
      // we check to see if we need fetch more pages from server.
      if (newPage > maxPageRequested) {
        const res = await handleFetchNextGroupOfOrgItems(searchString);
        const updatedOrgList = res?.data?.accounts?.accounts;
        setOrgList((prev) => [...(prev ?? []), ...updatedOrgList]);
      }
    } else {
      // back button was clicked
      setPageRange((prevRange) => {
        return {
          start: prevRange.start - maxResults,
          end: prevRange.start,
        };
      });
    }
    if (newPage > maxPageRequested) {
      setMaxPageRequested(newPage);
    }
    setPage(newPage);
  };

  // Must wrap debounce in useCallback. Otherwise React loses connection to the original
  // debounce func ref. This is needed order for prev debounce requests to be overriden
  // when the input changes before the elapsed time.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedRefetch = useCallback(
    debounce(async (searchString: string) => {
      const resetToken = '';
      const res = await handleFetchNextGroupOfOrgItems(searchString, resetToken);
      const updatedOrgList = res?.data?.accounts?.accounts;
      setPageRange({ start: 0, end: maxResults });
      setPage(0);
      setMaxPageRequested(0);
      setOrgList(updatedOrgList);
    }, 1000),
    []
  );

  const handleOnInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    setSearchString(inputValue);
    setNextToken('');
    debouncedRefetch(inputValue);
  };

  const registerDeviceHandler = useCallback(
    (org: Record<'id' | 'name', string>, location: Record<'id' | 'name', string>) =>
      (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (mutationInFlight) {
          return;
        }
        e.stopPropagation();
        setRegisteringDeviceOrgId(org.id);
        registerDevice({
          variables: {
            accountId: org.id,
            locationId: location.id,
            deviceIdentifiers: [serialNumber],
          },
        })
          .then(() => {
            onSuccess(serialNumber, org, location);
            dispatchToast({
              severity: ToastNotificationSeverityTypeEnum.SUCCESS,
              title: 'Device Registered Successfully',
            });
          })
          .catch(() => {
            dispatchToast({
              severity: ToastNotificationSeverityTypeEnum.ERROR,
              title: 'Device could not be Registered Successfully',
            });
          })
          .finally(() => {
            setRegisteringDeviceOrgId(void 0);
            onModalClose();
          });
      },
    [dispatchToast, mutationInFlight, onModalClose, onSuccess, registerDevice, serialNumber]
  );
  const totalCountOfOrgItemsAvailableOnServer = data?.accounts?.totalCount ?? 0;
  const orgListSliced = orgList?.slice(pageRange.start, pageRange.end) ?? [];
  const numberOfEmptyRowsToAdd = maxResults - orgListSliced.length;
  const hasNoData = numberOfEmptyRowsToAdd === maxResults;
  return (
    <Dialog
      open={true}
      onClose={() => {
        onModalClose();
      }}
      fullWidth
      maxWidth='md'
    >
      <DialogTitle>
        <Typography variant='h6' component='span'>
          Select Organization
        </Typography>
        <Typography variant='body2' color='grey'>
          Please choose to which organization would you register device: <b>{serialNumber}</b>
        </Typography>
      </DialogTitle>
      <IconButton
        onClick={onModalClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <InputBase
          sx={{
            width: '100%',
            bgcolor: (theme) =>
              theme.palette.mode === 'light' ? theme.palette.grey[200] : theme.palette.grey[700],
            padding: 1,
            borderRadius: 1,
          }}
          disabled={isLoading}
          onChange={handleOnInputChange}
          placeholder='Enter text to search for a particular organization'
          value={searchString}
          startAdornment={
            <SearchIcon
              sx={{
                color: (theme) => theme.palette.text.primary,
                marginRight: 1,
              }}
            />
          }
        />

        <Box
          display='flex'
          flexDirection='column'
          justifyContent='flex-start'
          alignItems='center'
          paddingY={2}
          gap={2}
          height='100%'
          width='100%'
        >
          {isLoading ? (
            <CircularProgress size={100} />
          ) : hasNoData ? (
            <Stack direction='column' alignItems='center' gap={1} marginTop={5}>
              <Typography fontSize={30} fontWeight='bold'>
                No Results
              </Typography>
              <Typography fontSize={20}>Please try searching for another organization</Typography>
            </Stack>
          ) : (
            <TableContainer
              sx={{ maxHeight: 500, overflowY: 'auto', scrollbarWidth: 'slim', marginBottom: 2 }}
            >
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>Account Name</TableCell>
                    <TableCell># Members</TableCell>
                    <TableCell># Locations</TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {orgListSliced.map((org) => {
                    if (!org) return;
                    const { id, name, numMembers, numLocations, rootLocation } = org;
                    const formattedName = truncateString(name, 30);
                    return (
                      <TableRow key={id}>
                        <TableCell width={375} sx={{ whiteSpace: 'nowrap' }}>
                          {formattedName}
                        </TableCell>
                        <TableCell>{numMembers}</TableCell>
                        <TableCell>{numLocations}</TableCell>
                        <TableCell>
                          <Button
                            endIcon={
                              registeringDeviceOrgId === (id as string | undefined) && (
                                <CircularProgress size='sm' />
                              )
                            }
                            sx={{ cursor: mutationInFlight ? 'not-allowed' : 'pointer' }}
                            onClick={registerDeviceHandler(
                              { id, name },
                              {
                                id: rootLocation?.id ?? '',
                                name: rootLocation?.name ?? 'All Locations',
                              }
                            )}
                          >
                            Select
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
                <TableFooter sx={{ position: 'absolute', bottom: 0, right: 24 }}>
                  <TableRow>
                    <TablePagination
                      count={totalCountOfOrgItemsAvailableOnServer}
                      onPageChange={handleChangePage}
                      page={page}
                      rowsPerPage={maxResults}
                      rowsPerPageOptions={[]}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
}
