/* eslint-disable indent */
import { useEffect } from 'react';
import ReportsContainer from './ReportsContainer';

import { Box, CircularProgress } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import LocationSelectorBreadcrumb from 'Components/LocationSelectorBreadcrumb/LocationSelectorBreadcrumb';
import PageContainerFrame from 'Components/HOC/PageContainerFrame';
import { MetricReportTypeEnum } from 'Constants/FloorsViewEnums';
import { TimeInterval as TimeIntervalEnum } from '__generated__/graphql';
import { TimeStampGenerator } from 'Utils/timeStampGeneration';
import { TempScaleEnum } from 'Constants/ConversionEnums';
import { convertUnitOfMeasurePreferenceToTempScaleEnum } from 'Utils/conversionTools';
import { DateRangeEnum } from 'Components/SharedUI/DateRangePicker';
import type { SelectedLocationType, SelectedOrgType } from 'Apollo/ApolloCache';
import useCurrentLocationList from 'Hooks/useCurrentLocationList';
import SettingsPanelFrame from 'Components/HOC/SettingsPanelFrame';

const timeStampGenerator = new TimeStampGenerator();
const getDefaultFilterSettings = () => {
  const initStartDate: string = timeStampGenerator.previous(1, 'days');
  const initEndDate: string = timeStampGenerator.now();
  const defaultSettings: {
    selectedMetric: MetricReportTypeEnum;
    selectedTimePeriod: DateRangeEnum;
    selectedInterval: TimeIntervalEnum;
    startDate: string;
    endDate: string;
    tempScale: TempScaleEnum;
    alertHighlightStartDate?: string;
    alertHighlightEndDate?: string;
  } = {
    selectedMetric: MetricReportTypeEnum.CO2,
    selectedTimePeriod: DateRangeEnum.PAST_DAY,
    selectedInterval: TimeIntervalEnum.Hourly,
    startDate: initStartDate,
    endDate: initEndDate,
    tempScale: TempScaleEnum.FAHRENHEIT,
  };
  return defaultSettings;
};

type Props = {
  selectedOrg: SelectedOrgType;
  selectedLocation: SelectedLocationType;
};
const MIN_REQ_PARAMS = 4;
export default function ReportsPage({ selectedOrg, selectedLocation }: Props) {
  const [searchParams, setSearchParams] = useSearchParams();
  const locationIDFromQueryParams = searchParams.get('locationID');

  const [isQueryLoading, initLocationList] = useCurrentLocationList(
    locationIDFromQueryParams ?? selectedLocation?.id
  );

  useEffect(() => {
    const unitOfMeasurePreference = selectedOrg?.preferences?.unit_of_measure;
    const rootLocationID = selectedOrg?.rootLocation?.id ?? '';
    if (
      !searchParams.get('locationID') ||
      Array.from(searchParams.entries()).length < MIN_REQ_PARAMS
    ) {
      const defaultFilterSettings = getDefaultFilterSettings();
      const tempScale =
        convertUnitOfMeasurePreferenceToTempScaleEnum(unitOfMeasurePreference) ??
        TempScaleEnum.FAHRENHEIT;
      const filterSettings = {
        ...defaultFilterSettings,
        tempScale,
      };
      // searches existing URL params for filter setting and stores them.
      // otherwise default values are used.
      searchParams.forEach((value, key) => {
        filterSettings[key] = value;
      });
      setSearchParams(
        (prev) => ({
          locationID: prev.get('locationID') ?? selectedLocation?.id ?? rootLocationID,
          ...filterSettings,
        }),
        { replace: true }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  const updateSearchParams = (locationID: string) => {
    setSearchParams(
      (prev) => {
        const existingParams = Object.fromEntries(prev.entries());

        // changing locations makes these preexisting value useless, delete it
        delete existingParams['selectedDevices'];
        delete existingParams['selectedSubLocations'];

        return {
          ...existingParams,
          locationID,
        };
      },
      { replace: true }
    );
  };

  return (
    <PageContainerFrame pageTitles={['Data Explorer']}>
      <SettingsPanelFrame title='Data Explorer'>
        <Box display='flex' flexDirection='column' gap={2}>
            {isQueryLoading || Array.from(searchParams.entries()).length < MIN_REQ_PARAMS ? (
            <CircularProgress size={100} />
          ) : (
            <>
              <Box alignSelf='center'>
                <LocationSelectorBreadcrumb
                  preLoadedLocationList={initLocationList}
                  onLocationSelectCallback={updateSearchParams}
                />
              </Box>
              {selectedLocation && (
                <ReportsContainer
                  key={selectedLocation?.id}
                  selectedOrg={selectedOrg}
                  selectedLocation={selectedLocation}
                  alertHighlight={
                    searchParams && searchParams.get('alertHighlightStartDate')
                      ? {
                          startDate: searchParams.get('alertHighlightStartDate') || '',
                          endDate: searchParams.get('alertHighlightEndDate') || '',
                        }
                      : undefined
                  }
                />
              )}
            </>
          )}
        </Box>
      </SettingsPanelFrame>
    </PageContainerFrame>
  );
}
