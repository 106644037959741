import { useMemo } from 'react';
import { useTheme, Card, CardHeader, Avatar, Tooltip } from '@mui/material';
import { MeetingRoomOutlined as DoorIcon } from '@mui/icons-material';
import moment from 'moment';
import { BooleanShadowValue } from '__generated__/graphql';
interface DeviceDoorCardProps {
  deviceDoor?: BooleanShadowValue | null;
}
export default function DeviceDoorCard({ deviceDoor }: DeviceDoorCardProps) {
  const theme = useTheme();
  const doorStatus = useMemo(() => {
    const value: boolean | null | undefined = deviceDoor?.value;
    if (value) {
      return 'Closed';
    }
    return 'Open';
  }, [deviceDoor]);
  return (
    <Tooltip placement='top' title={`As of ${moment(deviceDoor?.timestamp).fromNow()}`}>
      <Card>
        <CardHeader
          avatar={
            <Avatar sx={{ backgroundColor: theme.palette.info.main }} aria-label='device-type'>
              <DoorIcon color='inherit' sx={{ height: 28, width: 28 }} />
            </Avatar>
          }
          title='Door'
          titleTypographyProps={{ variant: 'body2', fontWeight: 500 }}
          subheader={doorStatus}
        />
      </Card>
    </Tooltip>
  );
}
