import React, { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import {
  Box,
  Button,
  MenuItem,
  Select,
  FormGroup,
  FormControlLabel,
  Checkbox,
  FormControl,
  FormLabel,
  CircularProgress,
  TextField,
  Typography,
  Grid,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { OrgAPISettingsPanelViewEnum } from 'Constants/OrgSettingsEnums';
import { GetSelectedOrgDataQuery as SelectedOrgDataType } from '__generated__/graphql';
import { useToast, ToastNotificationSeverityTypeEnum } from 'Providers/ToastProvider';
import RoutePaths from 'Constants/RoutePaths';

const { PATH_ORGANIZATION_API_KEYS } = RoutePaths;

function getExpirationTimestamp(days: string): string {
  if (days === 'none') {
    return 'none';
  }
  const expirationDate = new Date();
  expirationDate.setDate(expirationDate.getDate() + Number(days));
  // api requires ISO format in current implementation
  return expirationDate.toISOString();
}

export const CREATE_ACCOUNT_API_KEY = gql`
  mutation CreateAccountApiKey(
    $accountId: ID!
    $rootLocationId: ID!
    $name: String!
    $description: String
    $expirationTimestamp: Date
    $apiKeyScope: AccountApiKeyScopeInput
  ) {
    createAccountApiKey(
      accountId: $accountId
      rootLocationId: $rootLocationId
      name: $name
      description: $description
      expirationTimestamp: $expirationTimestamp
      apiKeyScope: $apiKeyScope
    ) {
      unencryptedApiKey
      apiKey {
        description
        id
        lastUsedTimestamp
        name
        expirationTimestamp
        apiKeyScope {
          location_read
          location_write
          envData_read
          envData_write
          device_read
          device_write
          account_read
          account_write
        }
      }
    }
  }
`;

type Props = {
  selectedOrgData: SelectedOrgDataType | undefined;
};

export default function OrgKeyGenView({ selectedOrgData }: Props) {
  const navigate = useNavigate();
  const { register, handleSubmit, formState, setValue } = useForm();
  const [selectedExpiration, setSelectedExpiration] = useState<string>('');
  const [createAccountApiKey, { loading: isMutationInFlight }] =
    useMutation(CREATE_ACCOUNT_API_KEY);
  const { dispatchToast } = useToast();
  const onSubmit = async (data) => {
    try {
      const variables = {
        accountId: selectedOrgData?.account?.id,
        rootLocationId: selectedOrgData?.account?.rootLocation?.id,
        name: data.keyName,
        apiKeyScope: {
          location_read: data.location_read,
          location_write: data.location_write,
          envData_read: data.envData_read,
          envData_write: data.envData_write,
          device_read: data.device_read,
          device_write: data.device_write,
          account_read: data.account_read,
          account_write: data.account_write,
        },
      };
      // optional field
      if (data.description) {
        variables['description'] = data.description;
      }
      // optional field
      if (data.expirationTimestamp !== 'none') {
        variables['expirationTimestamp'] = data.expirationTimestamp;
      }
      const result = await createAccountApiKey({
        variables,
        update: (cache, { data: res }) => {
          const account = selectedOrgData?.account ?? {};
          cache.modify({
            id: cache.identify(account),
            fields: {
              accountApiKeys(existingAPIKeys = [], { readField }) {
                const newKey = {
                  __typename: 'AccountApiKey',
                  ...res.createAccountApiKey.apiKey,
                };

                const newKeyRef = cache.writeFragment({
                  data: newKey,
                  fragment: gql`
                    fragment NewAccountApiKey on AccountApiKey {
                      description
                      id
                      lastUsedTimestamp
                      name
                      expirationTimestamp
                      apiKeyScope {
                        location_read
                        location_write
                        envData_read
                        envData_write
                        device_read
                        device_write
                        account_read
                        account_write
                      }
                    }
                  `,
                });
                // check to make sure newKey doesnt already exist in cache
                if (
                  existingAPIKeys.some((existingKey) => readField('id', existingKey) === newKey.id)
                ) {
                  return existingAPIKeys;
                }
                return [...existingAPIKeys, newKeyRef];
              },
            },
          });
        },
      });
      const newAPIKey = result.data?.createAccountApiKey?.unencryptedApiKey;
      navigate(PATH_ORGANIZATION_API_KEYS, {
        state: { panelView: OrgAPISettingsPanelViewEnum.ORG_NEW_KEY_VIEW, newAPIKey },
      });
    } catch (error) {
      const notifConfig = {
        severity: ToastNotificationSeverityTypeEnum.ERROR,
        title: 'Error Creating API Key',
        message: 'Something went wrong. Please try again',
      };
      dispatchToast(notifConfig);
      console.error('ERROR: CREATE_ACCOUNT_API_KEY - ', error);
    }
  };

  return (
    <Box display='flex' flexDirection='column' width={600}>
      <FormControl>
        <Box display='flex' flexDirection='column' gap={0.5} width={425} marginBottom={2}>
          <FormLabel htmlFor='keyNameInput'>Key name</FormLabel>
          <TextField
            {...register('keyName', { required: true })}
            id='keyNameInput'
            variant='outlined'
            size='small'
          />
        </Box>
        <Box display='flex' flexDirection='column' gap={0.5} width={425} marginBottom={2}>
          <FormLabel htmlFor='descriptionInput'>Description</FormLabel>
          <TextField
            {...register('description')}
            id='descriptionInput'
            variant='outlined'
            size='small'
          />
        </Box>
        <Box display='flex' flexDirection='column' gap={0.5} marginBottom={2}>
          <FormLabel htmlFor='expirationInput'>Expiration</FormLabel>
          <Select
            value={selectedExpiration}
            id='expirationInput'
            size='small'
            sx={{ width: 200 }}
            onChange={(e) => {
              setSelectedExpiration(e.target.value);
              const timeStamp = getExpirationTimestamp(e.target.value);
              setValue('expirationTimestamp', timeStamp);
            }}
          >
            <MenuItem value={'7'}>7 days</MenuItem>
            <MenuItem value={'30'}>30 days</MenuItem>
            <MenuItem value={'60'}>60 days</MenuItem>
            <MenuItem value={'90'}>90 days</MenuItem>
            <MenuItem value={'none'}>No expiration</MenuItem>
          </Select>
        </Box>
        <Box marginBottom={2}>
          <FormLabel id='demo-radio-buttons-group-label'>Access scope:</FormLabel>
          <FormGroup>
            <Grid container spacing={0} alignItems='center'>
              <Grid item xs={4}>
                <FormControlLabel
                  control={
                    <Checkbox
                      defaultChecked={true}
                      {...register('location_read')}
                      sx={{ color: '#BDBDBD' }}
                    />
                  }
                  label='location:read'
                />
              </Grid>
              <Grid item xs={8}>
                <Typography>Access location information</Typography>
              </Grid>
              <Grid item xs={4}>
                <FormControlLabel
                  control={<Checkbox {...register('location_write')} sx={{ color: '#BDBDBD' }} />}
                  label='location:write'
                />
              </Grid>
              <Grid item xs={8}>
                <Typography>Manage location information</Typography>
              </Grid>
              <Grid item xs={4}>
                <FormControlLabel
                  control={
                    <Checkbox
                      defaultChecked={true}
                      {...register('envData_read')}
                      sx={{ color: '#BDBDBD' }}
                    />
                  }
                  label='env:read'
                />
              </Grid>
              <Grid item xs={8}>
                <Typography>Access environmental data</Typography>
              </Grid>
              <Grid item xs={4}>
                <FormControlLabel
                  control={<Checkbox {...register('envData_write')} sx={{ color: '#BDBDBD' }} />}
                  label='env:write'
                />
              </Grid>
              <Grid item xs={8}>
                <Typography>Publish environmental data</Typography>
              </Grid>
              <Grid item xs={4}>
                <FormControlLabel
                  control={<Checkbox {...register('device_read')} sx={{ color: '#BDBDBD' }} />}
                  label='device:read'
                />
              </Grid>
              <Grid item xs={8}>
                <Typography>Access device information</Typography>
              </Grid>
              <Grid item xs={4}>
                <FormControlLabel
                  control={<Checkbox {...register('device_write')} sx={{ color: '#BDBDBD' }} />}
                  label='device:write'
                />
              </Grid>
              <Grid item xs={8}>
                <Typography>Publish device information</Typography>
              </Grid>
              <Grid item xs={4}>
                <FormControlLabel
                  control={<Checkbox {...register('account_read')} sx={{ color: '#BDBDBD' }} />}
                  label='account:read'
                />
              </Grid>
              <Grid item xs={8}>
                <Typography>Access account information</Typography>
              </Grid>
              <Grid item xs={4}>
                <FormControlLabel
                  control={<Checkbox {...register('account_write')} sx={{ color: '#BDBDBD' }} />}
                  label='account:write'
                />
              </Grid>
              <Grid item xs={8}>
                <Typography>Publish account information</Typography>
              </Grid>
            </Grid>
          </FormGroup>
        </Box>
        <FormGroup row>
          <Button
            disabled={isMutationInFlight}
            color='secondary'
            sx={{
              height: 50,
              width: 200,
              marginRight: 2,
            }}
            onClick={() =>
              navigate(PATH_ORGANIZATION_API_KEYS, {
                state: { panelView: OrgAPISettingsPanelViewEnum.ORG_KEY_LIST_VIEW },
              })
            }
            variant='contained'
          >
            Go Back
          </Button>
          <Button
            type='submit'
            disabled={!formState.isValid || isMutationInFlight}
            color='primary'
            sx={{ height: 50, width: 200 }}
            onClick={handleSubmit(onSubmit)}
            variant='contained'
            startIcon={isMutationInFlight ? <CircularProgress size={20} /> : null}
          >
            Create Key
          </Button>
        </FormGroup>
      </FormControl>
    </Box>
  );
}
