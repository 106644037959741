import { useLocation, Navigate } from 'react-router-dom';
import { useAuthenticator } from '@aws-amplify/ui-react';
import CircularProgress from '@mui/material/CircularProgress';
import RoutePaths from 'Constants/RoutePaths';
import Box from '@mui/material/Box';
import RouteStatusEnum from 'Constants/RouteStatusEnum';

const { STATUS_AUTHENTICATED, STATUS_IDLE } = RouteStatusEnum;
const { PATH_LOGIN } = RoutePaths;

type Props = { children: JSX.Element };

// RequireAuth is a higher order component that when used to wrap child components
// will automatically re-route the user to the root route if the user has not been authenticated.
// Returns a load spinner during loading or idle state from useAuthenticator "route".
export function RequireAuth({ children }: Props): JSX.Element {
  const location = useLocation();
  const { route } = useAuthenticator();
  if (route === STATUS_IDLE) {
    return (
      <Box display='flex' justifyContent='center' alignItems='center'>
        <CircularProgress />
      </Box>
    );
  }
  if (route === STATUS_AUTHENTICATED) {
    return children;
  }
  return <Navigate to={PATH_LOGIN} state={{ from: location }} replace />;
}
